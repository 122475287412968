import React, { useState, ReactNode } from 'react'
import styled from 'styled-components'
import { Fonts, Icon } from 'components/base-ui'

interface CollapseProps {
  children?: ReactNode
  label: string
}

const Collapse: React.FC<CollapseProps> = ({ label, children }) => {
  const [isShowContent, setIsShowContent] = useState<boolean>(false)

  return (
    <Container>
      <Header onClick={() => setIsShowContent(!isShowContent)}>
        <Label>{label}</Label>
        <BtnCollapse show={isShowContent}>
          <Icon name="carret-down" />
        </BtnCollapse>
      </Header>
      {isShowContent && <Content>{children}</Content>}
    </Container>
  )
}

export default Collapse

const Container = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.lightGrey};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Label = styled(Fonts.H2)`
  color: ${(p) => p.theme.colors.primary};
`

const BtnCollapse = styled.div<{ show: boolean }>`
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: white;
    font-size: 10px;
    position: relative;
    top: 1px;
  }

  ${(p) =>
    p.show
      ? `
    transform: rotate(180deg);
  `
      : ''}
`

const Content = styled.div`
  padding-top: 5px;
`
