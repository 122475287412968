import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ModalConfirmBottom from 'components/modals/ModalConfirmBottom'

interface ModalPendingPaymentProps {
  isOpen: boolean
  onCloseModal: () => void
}

const personPaymentImage = `${process.env.PUBLIC_URL}/assets/dine-in/person-payment.png`

const ModalPendingPayment: FC<ModalPendingPaymentProps> = ({ isOpen, onCloseModal }) => {
  const { t } = useTranslation()

  return (
    <ModalConfirmBottom
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      onAccept={onCloseModal}
      title={t('dineInAnotherOneCheckingout')}
      label={t('dineInAnotherOneCheckingoutDesc')}
      imagePath={personPaymentImage}
      imageType="png"
      btnAccept={t('dineInViewGlobalCart')}
      isGenerateSrc
    />
  )
}

export default ModalPendingPayment
