import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Modal as BaseModal, Fonts, Button } from 'components/base-ui'
import { generateSrcSet } from 'helpers/image'

type ModalWithImageContentImage = {
  imagePath: string
  imageSize?: string
  isGenerateSrc?: boolean
  imageType?: string
}

interface ModalWithImageContent extends ModalWithImageContentImage {
  title: string
  description?: string
  buttonText: string
  onRequestClose: () => void
}

type ModalWithImageConfig = {
  shouldCloseOnOverlayClick?: boolean
  buttonColor?: string
  hideCloseButton?: boolean
  md?: boolean
}

interface ModalWithImageProps extends ModalWithImageContent, ModalWithImageConfig {
  isOpen: boolean
  isLoading: boolean
}

const ModalWithImage: FC<ModalWithImageProps> = ({
  isOpen,
  isLoading,
  onRequestClose,
  shouldCloseOnOverlayClick,
  buttonColor,
  buttonText,
  hideCloseButton = false,
  imagePath,
  imageSize = '70px',
  imageType = 'png',
  isGenerateSrc = false,
  title,
  description = '',
  md = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      isLoading={isLoading}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      hideCloseButton={hideCloseButton}
      zindex="9999"
    >
      <ModalContent className="modal-content">
        {isGenerateSrc ? (
          <Image
            className="modal-image"
            imageSize={imageSize}
            alt=""
            src={imagePath}
            srcSet={generateSrcSet(imagePath, imageType)}
          />
        ) : (
          <Image className="modal-image" imageSize={imageSize} alt="" src={imagePath} />
        )}
        <Title className="modal-title">{title}</Title>
        {description && <Description className="modal-desc">{description}</Description>}
        <ButtonCloseModal className="button-modal-image" onClick={onRequestClose} buttonColor={buttonColor} md={md}>
          {buttonText}
        </ButtonCloseModal>
      </ModalContent>
    </Modal>
  )
}

export default ModalWithImage

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 7px;
  padding: 15px 0;
  text-align: center;
`

const Modal = styled(BaseModal)`
  button {
    margin-bottom: 14px;
  }
`

const Title = styled(Fonts.H2)`
  padding-top: 15px;
`

const Description = styled(Fonts.SmallTitleRegular)`
  font-size: 14px;
`

const Image = styled.img<{ imageSize: string }>`
  width: ${({ imageSize }) => imageSize};
  height: auto;
`

const ButtonCloseModal = styled(Button)<{ buttonColor?: string; md: boolean }>`
  margin-top: 20px;
  min-width: fit-content;
  padding-left: 35px;
  padding-right: 35px;
  background: ${({ buttonColor, theme }) => (buttonColor ? buttonColor : theme.colors.primary)};
  border: 1px solid ${({ buttonColor, theme }) => (buttonColor ? buttonColor : theme.colors.primary)};

  .no-touch &:hover,
  .touch &:active {
    background-color: ${({ theme }) => theme.colors.white}!important;
    color: ${({ buttonColor, theme }) => (buttonColor ? buttonColor : theme.colors.primary)};
  }

  ${(md) => md && `min-width: 85%;`}
`

// Internal Hook for ModalWithImage
interface UseModalWithImageConfig extends ModalWithImageConfig, Omit<ModalWithImageContentImage, 'imagePath'> {
  imagePath?: string
}

interface UseModalWithImageContent extends Omit<ModalWithImageContent, 'imagePath'> {
  imagePath?: string
}

export const useModalWithImage = (props?: UseModalWithImageConfig) => {
  const [content, setContent] = useState<UseModalWithImageContent>()
  const [isOpen, setIsOpen] = useState<ModalWithImageProps['isOpen']>(false)
  const [isLoading, setIsLoading] = useState<ModalWithImageProps['isLoading']>(false)

  const show = (data: UseModalWithImageContent) => {
    setContent(data)
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
    content?.onRequestClose()
  }

  const imagePath = content?.imagePath ? content.imagePath : props?.imagePath ? props.imagePath : ''

  return {
    show,
    close,
    setIsLoading,
    props: {
      ...props,
      ...content,
      title: content?.title ? content.title : '',
      imagePath,
      buttonText: content?.buttonText ? content.buttonText : '',
      onRequestClose: content?.onRequestClose ? content.onRequestClose : () => {},
      isOpen,
      isLoading,
    },
  }
}
