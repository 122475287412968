import React, { FC, useRef } from 'react'
import Select, { components } from 'react-select'
import styled, { css, createGlobalStyle } from 'styled-components'
import classNames from 'classnames'
import { Fonts, Icon } from 'components/base-ui'

import { weight } from 'components/base-ui/Fonts'
import border from 'styles/border'
import spacing from 'styles/spacing'

import { getSrcSetStr } from 'helpers/image'

interface ThemeProps {
  img?: boolean
  icon?: boolean
  disabled?: boolean
}

interface SelectOption {
  value: string
  label: string
  themeCustom?: string
}

interface Props {
  disabled?: boolean
  showArrow: boolean
  lg?: boolean
  xlg?: boolean
  green?: boolean
  zIndex?: number
  menuHeight?: string
  themeCustom?: string
  float: boolean
  search?: string
  clearable?: boolean
  icon?: string
  iconPlaceholder?: string
  placeholder: string
  value: any
  menuPortalTarget: HTMLElement | null
  menuIsOpen?: boolean
  onFocus?: (e: FocusEvent) => void
  selectChange: (e: SelectOption) => void
  options: SelectOption[]
}

const CustomSelect: FC<Props> = ({
  disabled,
  showArrow,
  lg,
  xlg,
  green,
  zIndex = 101,
  menuHeight,
  themeCustom,
  float,
  search,
  clearable,
  icon,
  iconPlaceholder = '',
  value = '',
  placeholder = '',
  menuIsOpen,
  onFocus = () => {},
  menuPortalTarget = null,
  selectChange,
  options = [],
}) => {
  const selectRef = useRef(null)
  const _classNames = classNames({
    Select: true,
    float,
    disabled,
    showArrow,
    lg,
    xlg,
  })

  options.map((item) => {
    return (item.themeCustom = themeCustom ? themeCustom : 'default')
  })

  return (
    <SelectWrapper icon={icon ? true : false} float={float} green={green}>
      <GlobalStyled green={green} />
      <Select
        menuPlacement="auto"
        ref={selectRef}
        isSearchable={search ? true : false}
        isClearable={clearable}
        isRtl=""
        isDisabled={disabled}
        components={{
          Control,
          SingleValue,
          Option,
          Placeholder,
          IndicatorsContainer: showArrow ? IndicatorsContainer : () => null,
        }}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex,
          }),
          menu: (base) => ({
            ...base,
            borderRadius: '8px',
            boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)',
          }),
          menuList: (base) => ({
            ...base,
            ...(menuHeight && { maxHeight: menuHeight }),
          }),
        }}
        value={value}
        defaultValue={value}
        onChange={(e: SelectOption) => {
          if (e) {
            handleChange(e)
          }
        }}
        placeholder={placeholder}
        options={options}
        onFocus={onFocus}
        className={_classNames}
        lg={lg}
        xlg={xlg}
        icon={icon}
        iconPlaceholder={iconPlaceholder}
        maxMenuHeight={200}
        menuPortalTarget={menuPortalTarget}
        menuIsOpen={menuIsOpen}
      />
      {icon && <Icon name={icon} />}
    </SelectWrapper>
  )

  function handleChange(e: SelectOption) {
    selectChange(e)
  }
}

export default CustomSelect

const themes = css<ThemeProps>`
  display: flex;
  align-items: center;
  min-height: 56px;
  line-height: 1em;
  font-size: 16px;
  padding: 0 15px 0 15px;

  ${({ img }) =>
    img
      ? `
    min-height: 56px;
    padding-left: 15px;
  `
      : ''}

  ${({ icon }) =>
    icon
      ? `
    padding-left: 40px;
  `
      : ''}

  border-color: transparent !important;
  background-color: transparent !important;
  color: ${({ theme }) => theme.colors.base} !important;
`

const StyledIcon = styled((props) => <Icon {...props} />)`
  margin-right: 5px;
  font-size: 20px;
`

const CustomPlaceholder = styled.div<ThemeProps>`
  ${themes}

  padding: 0 7px 0 7px;
  min-height: 56px;
`

function Placeholder({ children, disabled, cx, ...rest }) {
  const { iconPlaceholder } = rest.selectProps

  return (
    <CustomPlaceholder disabled={disabled} {...rest}>
      {iconPlaceholder && <StyledIcon name={iconPlaceholder} />}
      {children}
    </CustomPlaceholder>
  )
}

const SelectWrapper = styled.div<{ float: boolean; icon?: boolean; green?: boolean }>`
  position: relative;

  ${({ float }) =>
    float
      ? `
    width: 100%;
    `
      : ''}

  ${({ icon, theme }) =>
    icon
      ? `
    i {
      font-size: 19px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translate3d(0, -50%, 0);
      color: ${theme.colors.orange};
      pointer-events: none;
    }
    `
      : ''}

  ${({ icon, green, theme }) =>
    icon && green
      ? `
    i {
      font-size: 19px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translate3d(0, -50%, 0);
      color: ${theme.colors.second};
      pointer-events: none;
    }
    `
      : ''}
`

function Control(props) {
  const { lg, xlg } = props.selectProps

  return (
    <StyledControler menuIsOpen={props.menuIsOpen} lg={lg} xlg={xlg}>
      <components.Control {...props} />
    </StyledControler>
  )
}

const StyledControler = styled.div<{ menuIsOpen: boolean; lg: boolean; xlg: boolean }>`
  width: 100%;
  border-radius: ${border.radius.lg}px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  min-height: 56px;

  ${(props) =>
    props.menuIsOpen
      ? `
    box-shadow: 0 0 0 1px ${props.theme.colors.primary};
    `
      : ''}

  ${(props) =>
    props.lg
      ? `
    min-height: 56px;
    `
      : ''}

  ${(props) =>
    props.xlg
      ? `
    min-height: 62px;
    `
      : ''}

  > div {
    height: 100%;
    border: 0;
    box-shadow: none;
    border-radius: ${border.radius.lg}px;

    ${(props) =>
      props.menuIsOpen
        ? `
      background-color: ${props.theme.colors.highlight};
      `
        : ''}

    > div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

const GlobalStyled = createGlobalStyle<{ green?: boolean }>`
  .Select {
    display: inline-flex;
    width: auto;

    border-radius: ${border.radius.lg}px;
    min-height: 56px;
    justify-content: flex-start;
    border: 0;
    font-size: 16px;
    font-weight: ${weight.normal};
    background-color: transparent;
    color: ${(props) => props.theme.colors.base};

    i {
      margin-right: 10px;
      color: ${(props) => props.theme.colors.orange};

      ${(props) =>
        props.green
          ? `
        color: ${props.theme.colors.second};
        `
          : ''}
    }

    input {
      font-size: 16px;
    }

    .Select-input {
      height: 29px;
      padding-left: 0 !important;
      background-color: transparent !important;
    }

    &.lg {
      min-height: 56px;

      .Select-control {
        height: 56px !important;
      }
    }

    &.float {
      display: flex;
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    &.disabled {
      .Select-control {
        cursor: default !important;
      }
    }

    &.is-focused {
      .Select-control {
        box-shadow: none !important;
      }
    }

    &.has-value {
      .Select-arrow {
        border-color: black transparent transparent !important;
      }

      &.is-open {
        .Select-arrow {
          border-color: transparent transparent black !important;
        }
      }
    }

    &.showArrow {
      .Select-arrow-zone {
        position: relative;
        top: -1px;
        display: table-cell !important;
      }
    }

    &.is-open {
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors.green300};
      background-color: ${(props) => props.theme.colors.highlight};

      .Select-arrow-zone {
        top: -2px;
      }
    }
  }

  .Select-arrow {
    border-color: black transparent transparent;
  }

  .Select-control {
    height: 48px !important;
    border: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    cursor: pointer !important;

    > *:last-child {
      padding-right: 0;
    }
  }

  .Select-menu-outer {
    margin-top: 10px !important;
    border-radius: ${border.radius.lg}px !important;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15) !important;
    border: 0 !important;
    overflow: auto !important;

    > div {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
  }

  .Select-arrow-zone {
    width: 14px;
    display: none !important;
  }
`

function SingleValue({ innerProps, isDisabled, ...props }) {
  const { icon, icon_value, label, img, hires2x, hires3x, hideIconValue = false } = props.data

  const _icon = icon_value ? icon_value : icon
  const srcSetStr = getSrcSetStr({ img, hires2x, hires3x })

  return (
    <CustomValue img={img} icon={_icon}>
      {img && <CustomOptionImg src={`${process.env.PUBLIC_URL}${img}`} srcSet={srcSetStr} />}
      {_icon && !hideIconValue ? <StyledIcon name={_icon} /> : null}
      <Fonts.ActionBaseRegular as="span">{label}</Fonts.ActionBaseRegular>
    </CustomValue>
  )
}

const CustomValue = styled.div<ThemeProps>`
  ${themes}

  padding: 0 7px 0 7px;
  white-space: nowrap;

  & + input {
    position: absolute;
  }
`

function IndicatorsContainer(props) {
  return (
    <div>
      <IconCaretDown name="carret-down" isOpen={props.selectProps.menuIsOpen} />
    </div>
  )
}

const IconCaretDown = styled((props) => <Icon {...props} />)`
  display: block;
  position: relative;
  top: 1px;
  font-size: 5px;
  color: ${(props) => props.theme.colors.primary} !important;
  margin-right: 12px !important;

  ${({ isOpen }) =>
    isOpen
      ? `
    top: -1;
    transform: rotate(180deg);
    `
      : ''}
`

function Option({ innerProps, isDisabled, ...props }) {
  const { data, isFocused, isSelected } = props
  const {
    className,
    icon,
    label,
    title,
    themeCustom,
    img,
    hires2x = false,
    hires3x = false,
    hideIconOption = false,
  } = data

  const srcSetStr = getSrcSetStr({ img, hires2x, hires3x })

  if (isDisabled) {
    return null
  }

  return (
    <CustomOptions
      icon={icon}
      themeCustom={themeCustom}
      className={className}
      isSelected={isSelected}
      isFocused={isFocused}
      title={title}
      {...innerProps}
    >
      {img && <CustomOptionImg src={`${process.env.PUBLIC_URL}${img}`} srcSet={srcSetStr} />}
      {icon && !hideIconOption ? <StyledIcon name={icon} /> : null}
      <Fonts.ActionBaseRegular as="span">{label}</Fonts.ActionBaseRegular>
    </CustomOptions>
  )
}

const CustomOptionImg = styled.img`
  margin-right: ${spacing.md}px;
`

const CustomOptions = styled.div<ThemeProps & { themeCustom: string; isFocused: boolean; isSelected: boolean }>`
  ${themes}
  min-height: auto;
  min-height: initial;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 15px !important;
  position: relative;

  // Theme Default
  ${({ themeCustom, theme }) =>
    themeCustom === 'default'
      ? `
    color: ${theme.colors.base} !important;
    &:hover {
      border-color: transparent !important;
      background-color: ${theme.colors.highlight} !important;
      color: ${theme.colors.base} !important;
    }
  `
      : null}

  &:hover {
    ${({ isFocused, theme }) =>
      isFocused
        ? `
      border-color: transparent !important;
      background-color: ${theme.colors.highlight} !important;
      color: ${theme.colors.primary} !important;
    `
        : ''}
  }

  ${({ themeCustom, isSelected, theme }) =>
    themeCustom === 'default' && isSelected
      ? `
    border-color: transparent !important;
    background-color: transparent !important;
    color: ${theme.colors.primary} !important;

    &:hover {
      border-color: ${theme.colors.highlight} !important;
      background-color: ${theme.colors.highlight} !important;
      color: #fff !important;
    }
  `
      : null}
`
