import { CustomerPaymentTypeEnum } from 'graphQL/hatostore/generate/operations'
import { PaymentTypeEnum } from 'graphQL/dinein/generate/operations'
import { i18n } from 'helpers/i18next'
import { CouponDetailType } from 'types/coupon'

const fullUrl: string = window.location.href

const checkPathIsOpen = (path: string) => {
  return fullUrl.includes(path)
}

export const checkDineInIsOpen = () => checkPathIsOpen('dine-in')
export const checkBuffetIsOpen = () => checkPathIsOpen('buffet')

export const convertTypeCustomerPaymentTypeEnumToPaymentTypeEnum = (
  paymentMethod: CustomerPaymentTypeEnum
): PaymentTypeEnum => {
  switch (paymentMethod) {
    // Manual specific convert for match directly value of enum for avoid issue miss matching key from codegen that auto capitalize value to key.
    // ISSUE cases:
    // - SCBPromptPay -> ScbPromptPay
    // - KBankThaiQR -> KBankThaiQr
    // - There is no type CashOnDelivery in PaymentTypeEnum
    case CustomerPaymentTypeEnum.BankTransfer:
      return PaymentTypeEnum.BankTransfer
    case CustomerPaymentTypeEnum.KBankThaiQr:
      return PaymentTypeEnum.KBankThaiQr
    case CustomerPaymentTypeEnum.KPaymentKPlusNumber:
      return PaymentTypeEnum.KPaymentKPlusNumber
    case CustomerPaymentTypeEnum.KPaymentPromptPayNumber:
      return PaymentTypeEnum.KPaymentPromptPayNumber
    case CustomerPaymentTypeEnum.LinePay:
      return PaymentTypeEnum.LinePay
    case CustomerPaymentTypeEnum.OmiseCreditCard:
      return PaymentTypeEnum.OmiseCreditCard
    case CustomerPaymentTypeEnum.OmisePromptPay:
      return PaymentTypeEnum.OmisePromptPay
    case CustomerPaymentTypeEnum.OmiseTrueMoneyWallet:
      return PaymentTypeEnum.OmiseTrueMoneyWallet
    case CustomerPaymentTypeEnum.ScbPromptPay:
      return PaymentTypeEnum.ScbPromptPay
    default:
      return PaymentTypeEnum.None
  }
}

export const checkInvalidCampaign = (campaign: CouponDetailType, memberTotalPoints) => {
  const { pointsRequire, invalidConditions } = campaign
  const isPointNotEnough = pointsRequire > memberTotalPoints
  const inActive = isPointNotEnough || invalidConditions?.length > 0 // type of invalidConditions from API can be Array and null

  const buttonText = isPointNotEnough
    ? i18n.t('dineIn.campaign.invalidConditions.pointNotEnough')
    : inActive
    ? i18n.t(`dineIn.campaign.invalidConditions.${invalidConditions[0]}`)
    : i18n.t('dineIn.campaign.redeem')

  return { buttonText, inActive }
}
