import { useTranslation } from 'react-i18next'
import { LANGUAGES } from 'helpers/language'

export interface Language {
  th: string
  en: string
}

const useLanguage = () => {
  const {
    i18n: { language },
  } = useTranslation()

  const getPropertyByLang = ({ th, en }: Language) => {
    switch (language) {
      case LANGUAGES.TH:
        return th
      case LANGUAGES.EN:
        return en
      default:
        return en ? en : th
    }
  }

  return { getPropertyByLang }
}

export default useLanguage
