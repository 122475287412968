import React, { CSSProperties, FC, ReactNode, useRef } from 'react'
import Select, { components } from 'react-select'
import styled, { css, createGlobalStyle } from 'styled-components'
import classNames from 'classnames'
import { Fonts, Icon } from 'components/base-ui'

import { weight } from 'components/base-ui/Fonts'
import border from 'styles/border'
import spacing from 'styles/spacing'

import { getSrcSetStr } from 'helpers/image'

interface ThemeProps {
  img?: boolean
  icon?: boolean
  disabled?: boolean
}

interface SelectOption {
  value: string
  label: string
  themeCustom?: string
}

interface CustomSelectProps {
  children?: ReactNode
  disabled?: boolean
  showArrow: boolean
  lg?: boolean
  xlg?: boolean
  green?: boolean
  zIndex?: number
  minWidth?: string
  menuHeight?: string
  themeCustom?: string
  float: boolean
  search?: boolean
  clearable?: boolean
  icon?: string
  iconPlaceholder?: string
  placeholder: string
  value: any
  menuPortalTarget?: HTMLElement | null
  menuIsOpen?: boolean
  onFocus?: (e: FocusEvent) => void
  selectChange: (e: SelectOption) => void
  options: SelectOption[]
  notFocusHilight?: boolean
  colorPlaceholder?: 'default' | 'primary'
  colorValue?: 'default' | 'primary'
  stylePlaceholder?: CSSProperties
}

const CustomSelect: FC<CustomSelectProps> = ({
  children,
  disabled,
  showArrow,
  lg,
  xlg,
  green,
  zIndex = 101,
  menuHeight,
  notFocusHilight = false,
  ...rest
}) => {
  const selectRef = useRef(null)

  const { themeCustom, float, search, clearable = false } = rest
  const _classNames = classNames({
    Select: true,
    float,
    disabled,
    showArrow,
    lg,
    xlg,
  })

  rest.options.map((item) => {
    return (item.themeCustom = themeCustom ? themeCustom : 'default')
  })

  return (
    <SelectWrapper
      icon={rest.icon ? true : false}
      float={float}
      green={green ? true : false}
      data-testid="select-categories"
    >
      <GlobalStyled green={green ? true : false} />
      <Select
        menuPlacement="auto"
        ref={selectRef}
        isSearchable={search ? true : false}
        isClearable={clearable}
        isRtl=""
        isDisabled={disabled}
        components={{
          Control,
          SingleValue,
          Option,
          Placeholder,
          IndicatorsContainer: showArrow ? IndicatorsContainer : () => null,
        }}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex,
          }),
          menu: (base) => ({
            ...base,
            borderRadius: '8px',
            boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)',
          }),
          menuList: (base) => ({
            ...base,
            ...(menuHeight && { maxHeight: menuHeight }),
          }),
          valueContainer: (base) => ({
            ...base,
            flexWrap: 'initial',
          }),
        }}
        value={rest.value}
        defaultValue={rest.value}
        onChange={(e) => {
          if (e) {
            handleChange(e)
          }
        }}
        placeholder={rest.placeholder}
        options={rest.options}
        onFocus={rest.onFocus}
        className={_classNames}
        lg={lg}
        xlg={xlg}
        notFocusHilight={notFocusHilight}
        icon={rest.icon}
        iconPlaceholder={rest.iconPlaceholder}
        stylePlaceholder={rest.stylePlaceholder}
        colorPlaceholder={rest.colorPlaceholder}
        colorValue={rest.colorValue}
        maxMenuHeight={200}
        menuPortalTarget={rest.menuPortalTarget}
        menuIsOpen={rest.menuIsOpen}
        onMenuOpen={() => {
          setTimeout(() => {
            const selectedEl = document.getElementsByClassName('-option--is-selected')[0]
            if (selectedEl) {
              selectedEl.scrollIntoView({ block: 'nearest', inline: 'start' })
            }
          }, 15)
        }}
      />
      {rest.icon && <Icon name={rest.icon} />}
    </SelectWrapper>
  )

  function handleChange(e) {
    rest.selectChange(e)
  }
}

export default CustomSelect

const themes = css<ThemeProps>`
  display: flex;
  align-items: center;
  min-height: 48px;
  line-height: 1em;
  font-size: 16px;
  padding: 0 15px 0 15px;

  ${({ img }) =>
    img
      ? `
    min-height: 56px;
    padding-left: 15px;
  `
      : ''}

  ${({ icon }) =>
    icon
      ? `
    padding-left: 40px;
  `
      : ''}

  border-color: transparent !important;
  background-color: transparent !important;
  color: ${({ theme }) => theme.colors.base} !important;
`

const StyledIcon = styled((props) => <Icon {...props} />)`
  margin-right: 5px;
  font-size: 20px;
`

const CustomPlaceholder = styled.div<ThemeProps>`
  ${themes}

  padding: 0 7px 0 7px;
  min-height: 48px;

  ${({ color, theme }) =>
    color === 'primary' &&
    `
    color: ${theme.colors.primary} !important;
  `}
`

interface PlaceholderProps {
  children: ReactNode
  disabled: boolean
  selectProps: { iconPlaceholder: boolean; stylePlaceholder: CSSProperties; colorPlaceholder: string }
}

function Placeholder({ children, disabled, selectProps }: PlaceholderProps) {
  const { iconPlaceholder, stylePlaceholder, colorPlaceholder } = selectProps

  return (
    <CustomPlaceholder disabled={disabled} color={colorPlaceholder} style={stylePlaceholder}>
      {iconPlaceholder && <StyledIcon name={iconPlaceholder} />}
      {children}
    </CustomPlaceholder>
  )
}

const SelectWrapper = styled.div<{ float: boolean; icon: boolean; green: boolean }>`
  position: relative;

  ${({ float }) =>
    float
      ? `
    width: 100%;
    `
      : ''}

  ${({ icon, theme }) =>
    icon
      ? `
    i {
      font-size: 19px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translate3d(0, -50%, 0);
      color: ${theme.colors.orange};
      pointer-events: none;
    }
    `
      : ''}

  ${({ icon, green, theme }) =>
    icon && green
      ? `
    i {
      font-size: 19px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translate3d(0, -50%, 0);
      color: ${theme.colors.second};
      pointer-events: none;
    }
    `
      : ''}
`

interface ControlProps {
  menuIsOpen: boolean
  selectProps: {
    lg: boolean
    xlg: boolean
    notFocusHilight: boolean
  }
}

const Control: FC<ControlProps> = ({ selectProps, menuIsOpen, ...props }) => {
  const { lg, xlg, notFocusHilight } = selectProps

  return (
    <StyledControler menuIsOpen={menuIsOpen} lg={lg} xlg={xlg} notFocusHilight={notFocusHilight}>
      <components.Control {...props} />
    </StyledControler>
  )
}

const StyledControler = styled.div<{ menuIsOpen: boolean; notFocusHilight: boolean; lg: boolean; xlg: boolean }>`
  width: 100%;
  border-radius: ${border.radius.lg}px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  min-height: 48px;

  ${({ menuIsOpen, theme, notFocusHilight }) =>
    menuIsOpen && !notFocusHilight
      ? `
    box-shadow: 0 0 0 1px ${theme.colors.primary};
    `
      : ''}

  ${({ lg }) =>
    lg
      ? `
    min-height: 56px;
    `
      : ''}

  ${({ xlg }) =>
    xlg
      ? `
    min-height: 62px;
    `
      : ''}

  > div {
    height: 100%;
    border: 0;
    box-shadow: none;
    border-radius: ${border.radius.lg}px;

    ${({ menuIsOpen, notFocusHilight, theme }) =>
      menuIsOpen && !notFocusHilight
        ? `
      background-color: ${theme.colors.highlight};
      `
        : ''}

    > div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

const GlobalStyled = createGlobalStyle<{ green: boolean }>`
  .Select {
    display: inline-flex;
    width: auto;

    border-radius: ${border.radius.lg}px;
    min-height: 48px;
    justify-content: flex-start;
    border: 0;
    font-size: 16px;
    font-weight: ${weight.normal};
    background-color: transparent;
    color: ${({ theme }) => theme.colors.base};

    i {
      margin-right: 10px;
      color: ${({ theme }) => theme.colors.orange};

      ${({ green, theme }) =>
        green
          ? `
        color: ${theme.colors.second};
        `
          : ''}
    }

    input {
      font-size: 16px;
    }

    .Select-input {
      height: 29px;
      padding-left: 0 !important;
      background-color: transparent !important;
    }

    &.lg {
      min-height: 56px;

      .Select-control {
        height: 56px !important;
      }
    }

    &.float {
      display: flex;
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    &.disabled {
      .Select-control {
        cursor: default !important;
      }
    }

    &.is-focused {
      .Select-control {
        box-shadow: none !important;
      }
    }

    &.has-value {
      .Select-arrow {
        border-color: black transparent transparent !important;
      }

      &.is-open {
        .Select-arrow {
          border-color: transparent transparent black !important;
        }
      }
    }

    &.showArrow {
      .Select-arrow-zone {
        position: relative;
        top: -1px;
        display: table-cell !important;
      }
    }

    &.is-open {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.green300};
      background-color: ${({ theme }) => theme.colors.highlight};

      .Select-arrow-zone {
        top: -2px;
      }
    }
  }

  .Select-arrow {
    border-color: black transparent transparent;
  }

  .Select-control {
    height: 48px !important;
    border: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    cursor: pointer !important;

    > *:last-child {
      padding-right: 0;
    }
  }

  .Select-menu-outer {
    margin-top: 10px !important;
    border-radius: ${border.radius.lg}px !important;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15) !important;
    border: 0 !important;
    overflow: auto !important;

    > div {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
  }

  .Select-arrow-zone {
    width: 14px;
    display: none !important;
  }
`

interface SingleValueProps {
  selectProps
  data: {
    icon: string
    icon_value: string
    label: string
    themeCustom: any
    img: string
    hires2x: boolean
    hires3x: boolean
    hideIconValue: boolean
  }
}

const SingleValue: FC<SingleValueProps> = ({ selectProps, data }) => {
  const { icon, icon_value, label, img, hires2x, hires3x, hideIconValue = false } = data
  const { colorValue } = selectProps

  const _icon = icon_value ? icon_value : icon
  const srcSetStr = getSrcSetStr({ img, hires2x, hires3x })

  return (
    <CustomValue img={img ? true : false} icon={_icon ? true : false} color={colorValue}>
      {img && <CustomOptionImg src={`${process.env.PUBLIC_URL}${img}`} srcSet={srcSetStr} />}
      {_icon && !hideIconValue ? <StyledIcon name={_icon} /> : null}
      <Fonts.ActionBaseBold as="span">{label}</Fonts.ActionBaseBold>
    </CustomValue>
  )
}

const CustomValue = styled.div<ThemeProps>`
  ${themes}

  padding: 0 7px 0 7px;
  white-space: nowrap;

  & + input {
    position: absolute;
  }

  ${({ color, theme }) =>
    color === 'primary' &&
    `
    color: ${theme.colors.primary} !important;
  `}
`

interface IndicatorsContainerProps {
  selectProps: {
    menuIsOpen: boolean
  }
}

const IndicatorsContainer: FC<IndicatorsContainerProps> = ({ selectProps }) => {
  return (
    <div className="carret-down">
      <IconCaretDown name="carret-down" isOpen={selectProps.menuIsOpen} />
    </div>
  )
}

const IconCaretDown = styled((props) => <Icon {...props} />)`
  display: block;
  position: relative;
  top: 1px;
  font-size: 6px;
  color: ${({ theme }) => theme.colors.primary} !important;
  margin-right: 20px !important;

  ${({ isOpen }) =>
    isOpen
      ? `
    top: -1;
    transform: rotate(180deg);
    `
      : ''}
`

interface OptionProps {
  innerProps: any
  isDisabled: boolean
  data: {
    className: string
    icon: string
    icon_value: string
    title: string
    label: string
    themeCustom: any
    img: string
    hires2x: boolean
    hires3x: boolean
    hideIconValue: boolean
    hideIconOption: boolean
  }
  isFocused: boolean
  isSelected: boolean
}

const Option: FC<OptionProps> = ({ innerProps, isDisabled, data, isFocused, isSelected }) => {
  const {
    className,
    icon,
    label,
    title,
    themeCustom,
    img,
    hires2x = false,
    hires3x = false,
    hideIconOption = false,
  } = data

  const srcSetStr = getSrcSetStr({ img, hires2x, hires3x })

  if (isDisabled) {
    return null
  }

  return (
    <CustomOptions
      icon={icon}
      themeCustom={themeCustom}
      className={className + `${isSelected ? ' -option--is-selected' : ''}`}
      isSelected={isSelected}
      isFocused={isFocused}
      title={title}
      {...innerProps}
    >
      {img && <CustomOptionImg src={`${process.env.PUBLIC_URL}${img}`} srcSet={srcSetStr} />}
      {icon && !hideIconOption ? <StyledIcon name={icon} /> : null}
      <Fonts.ActionBaseBold as="span">{label}</Fonts.ActionBaseBold>
    </CustomOptions>
  )
}

const CustomOptionImg = styled.img`
  margin-right: ${spacing.md}px;
`

const CustomOptions = styled.div<ThemeProps & { themeCustom: string; isFocused: boolean; isSelected: boolean }>`
  ${themes}
  min-height: auto;
  min-height: initial;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 15px !important;
  position: relative;

  // Theme Default
  ${({ themeCustom, theme }) =>
    themeCustom === 'default'
      ? `
    color: ${theme.colors.base} !important;
    &:hover {
      border-color: transparent !important;
      background-color: ${theme.colors.highlight} !important;
      color: ${theme.colors.base} !important;
    }
  `
      : null}

  &:hover {
    ${({ isFocused, theme }) =>
      isFocused
        ? `
      border-color: transparent !important;
      background-color: ${theme.colors.highlight} !important;
      color: ${theme.colors.primary} !important;
    `
        : ''}
  }

  ${({ themeCustom, isSelected, theme }) =>
    themeCustom === 'default' && isSelected
      ? `
    border-color: transparent !important;
    background-color: transparent !important;
    color: ${theme.colors.primary} !important;

    &:hover {
      border-color: ${theme.colors.base} !important;
      background-color: ${theme.colors.base} !important;
      color: #fff !important;
    }
  `
      : null}
`
