import React, { FC, ReactNode, useContext, useEffect } from 'react'
import styled from 'styled-components'
import ScrollPage from 'components/base-ui/ScrollPage'
import { DineInContext } from 'contexts/DineInProvider'

interface Props {
  children: ReactNode
  isShow: boolean
  isFromLeft?: boolean
  animation?: boolean
  onDisplay?: VoidFunction
  onClose?: VoidFunction
}
const OverlayPage: FC<Props> = ({
  children,
  isShow,
  isFromLeft,
  animation = false,
  onDisplay = () => {},
  onClose = () => {},
}) => {
  const { useDisableBodyScroll } = useContext(DineInContext)

  useEffect(() => {
    if (isShow) {
      onDisplay()
    }
    return () => onClose()
  }, [isShow, onDisplay, onClose])

  return (
    <OverlayPageWrapper isShow={isShow} isFromLeft={isFromLeft} animation={animation}>
      <ScrollPage useDisableBodyScroll={useDisableBodyScroll}>{children}</ScrollPage>
    </OverlayPageWrapper>
  )
}

export default OverlayPage

interface OverlayPageWrapperProps {
  isShow: boolean
  isFromLeft?: boolean
  animation: boolean
}
const OverlayPageWrapper = styled.div<OverlayPageWrapperProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: white;
  transform: translate3d(100%, 0, 0);
  pointer-events: none;
  visibility: hidden;

  ${({ animation }) => animation && `transition: 0.3s;`}

  ${({ isFromLeft }) => (isFromLeft ? `transform: translate3d(-100%, 0, 0);` : '')}

  ${({ isShow }) =>
    isShow
      ? `
    visibility: visible;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
    `
      : ''}
`
