import styled, { css } from 'styled-components'
import { ThemeColor } from 'types/color'

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing.horizontalSpacingL};
`

const largeStyle = css`
  width: 56px;
  height: 56px;
  i {
    font-size: ${({ theme }) => theme.fontSize.xxl};
  }
`

const BtnBackStyled = styled.div<{ color: keyof typeof ThemeColor; lg: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05), 0px 1px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  transition: 0.6s;

  i {
    font-size: ${({ theme }) => theme.fontSize.base};
    color: ${({ color, theme }) => (color === 'primary' ? theme.colors.primary : theme.colors.secondary)};
    transition: 0.6s;
  }

  ${({ lg }) => lg && largeStyle}

  .touch &.touch {
    transition: 0s;
    background-color: ${({ color, theme }) => (color === 'primary' ? theme.colors.primary : theme.colors.secondary)};
    transform: scale(1.05);

    i {
      transition: 0s;
      color: white;
    }
  }
`

export { BtnBackStyled, Container }
