import React, { FC } from 'react'
import styled from 'styled-components'
import { Button as BaseButton, Fonts, Modal } from 'components/base-ui'
import { useTranslation } from 'react-i18next'

type Props = {
  isOpen: boolean
  title?: string
  description?: string
  onClick: () => void
}

const ModalReadyToCheckout: FC<Props> = ({ isOpen, title, description, onClick }) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} shouldCloseOnOverlayClick={false} hideCloseButton>
      <ModalContent>
        <Fonts.H2>{title}</Fonts.H2>
        <Fonts.BaseTitleRegular>{description}</Fonts.BaseTitleRegular>
        <Button onClick={onClick} md>
          {t('buffet.modal.readyToCheckout.confirm')}
        </Button>
      </ModalContent>
    </Modal>
  )
}

export default ModalReadyToCheckout

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  padding-bottom: 25px;
  padding-top: 30px;
  text-align: center;
`

const Button = styled(BaseButton)`
  margin-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: fit-content;
`
