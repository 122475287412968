import * as Sentry from '@sentry/browser'
import { useEffect, useContext } from 'react'
import { AppContext } from 'contexts/AppProvider'
import { sleep } from 'helpers'
import { LANGUAGES, getLangIDbyLineLang } from 'helpers/language'
import { setUserIdAnalytics } from 'helpers/analytics'
import { getLiff } from 'helpers/liff'
import {
  setTokenSession,
  setCountMaxRetriesInitializeFailed,
  getCountMaxRetriesInitializeFailed,
} from 'helpers/localStorage'
import axios from 'axios'

import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import getFirebaseConfig from 'configs/firebase/firebaseConfig'
import { isDevelopment } from 'variables/environment'
import { changeLanguage } from 'i18next'
import growhthBook from 'configs/growthbook'

const firebaseConfig = getFirebaseConfig()
const isEnableLiffMock = isDevelopment

// This workaround for hook request get the fresh liff translation. (ISSUE: LIFF init failed
// ===============================================================
const originalFetch = window.fetch
function customFetch(url, options) {
  if (url.toString().startsWith('https://liffsdk.line-scdn.net/xlt/') && url.toString().endsWith('.json')) {
    url = url + '?ts=' + Math.random()
  }
  return originalFetch(url, options)
}
window.fetch = customFetch
// ===============================================================

initializeApp(firebaseConfig)
const auth = process.env.REACT_APP_AUTH_FIREBAE === 'true' && getAuth()
const firebaseEndpoint = `${process.env.REACT_APP_API_HOSTNAME}/firebase/liff/custom-token`
const liff = getLiff()

function useGetLiffUser(liffId, onLiffInitialed = () => {}) {
  const {
    userProfile,
    setUserProfile,
    setUserEmail,
    setAccessToken,
    setLang,
    setIsLiffInit,
    isFacebookWebviewInit,
    isFacebookWebview,
    setIsOpenModalLIFFInitailedFailed,
  } = useContext(AppContext)

  const authWithLineAccessToken = async () => {
    if (!liff.isInClient() && isEnableLiffMock) liff.login()
    const profile = await liff.getProfile()

    growhthBook.setAttributes({ lineUID: profile?.userId })

    const lang = LANGUAGES.TH
    const accessToken = await liff.getAccessToken()
    setTokenSession(accessToken)
    const email = liff.getDecodedIDToken()?.email ? liff.getDecodedIDToken()?.email : ''
    setUserProfile(profile)
    setUserEmail(email)
    setAccessToken(accessToken)
    setUserIdAnalytics({ userProfile: profile })

    const selectedLang = getLangIDbyLineLang(lang)
    setLang(selectedLang)
    changeLanguage(selectedLang)
  }

  const authWithFirebaseCustomToken = async () => {
    const profile = await liff.getProfile()
    const lang = LANGUAGES.TH
    const accessToken = await liff.getAccessToken()
    axios.get(firebaseEndpoint, { headers: { Authorization: `Bearer ${accessToken}` } }).then((res) => {
      signInWithCustomToken(auth, res.data.token)
        .then((userCredential) => {
          const user = userCredential.user
          setUserProfile(profile)
          setAccessToken(user.accessToken)
          setUserIdAnalytics({ userProfile: profile })

          const selectedLang = getLangIDbyLineLang(lang)
          setLang(selectedLang)
          changeLanguage(selectedLang)
        })
        .catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message
          console.log('errorCode: ', errorCode)
          console.log('errorMessage: ', errorMessage)
        })
    })
  }

  useEffect(() => {
    if (userProfile === '' && isFacebookWebviewInit && !isFacebookWebview && liffId) {
      const increaseCountMaxReties = () => {
        setCountMaxRetriesInitializeFailed(getCountMaxRetriesInitializeFailed() + 1)
      }

      const resetCountMaxReties = () => {
        setCountMaxRetriesInitializeFailed(0)
      }

      const initFn = async () => {
        const maxRetries = 3
        const maxRefreshMaxRetries = 3
        let retries = 0
        let err

        while (retries < maxRetries) {
          try {
            await liff.init({ liffId, mock: isEnableLiffMock })
            if (liff.isInClient() || liff.isLoggedIn()) {
              process.env.REACT_APP_AUTH_FIREBAE === 'true' ? authWithFirebaseCustomToken() : authWithLineAccessToken()
            } else {
              setLang(LANGUAGES.TH)
              changeLanguage(LANGUAGES.TH)
            }

            resetCountMaxReties()
            setIsLiffInit(true)
            onLiffInitialed()
            break
          } catch (_err) {
            if (
              /The access token expired/i.test(_err.message) ||
              /Cannot get context token/i.test(_err.message) ||
              /Invalid "exp" value in ID_TOKEN/i.test(_err.message) ||
              _err.code === 'INVALID_ID_TOKEN'
            ) {
              !isDevelopment &&
                Sentry.withScope((scope) => {
                  scope.setTag('LIFF Error Code', _err.code)
                  Sentry.captureException(new Error(`LIFF initialed error: ${_err.code} -> ${_err.message}`))
                })
              setIsOpenModalLIFFInitailedFailed(true)
              break
            } else {
              await sleep(1000)
              retries++
              err = _err
            }
          }
        }

        if (retries === maxRetries) {
          if (getCountMaxRetriesInitializeFailed() === maxRefreshMaxRetries) {
            resetCountMaxReties()
            !isDevelopment &&
              Sentry.withScope((scope) => {
                scope.setTag('LIFF Error Code', err.code)
                Sentry.captureException(
                  new Error(
                    `LIFF initialed error after ${maxRetries} retries and ${maxRefreshMaxRetries} refresh: ${err.code} -> ${err.message}`
                  )
                )
              })
            setIsOpenModalLIFFInitailedFailed(true)
          } else {
            increaseCountMaxReties()
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          }
        }
      }

      initFn()
    }
  }, [isFacebookWebviewInit, liffId])

  return false
}

export default useGetLiffUser
