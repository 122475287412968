import styled from 'styled-components'

import spacing from 'styles/spacing'
import { Container, Fonts } from 'components/base-ui'

const SectionHeader = styled(Container)<{ noPaddingTop: boolean }>`
  padding-top: 80px;
  padding-bottom: 0;
  margin-bottom: ${spacing.lg}px;

  ${({ noPaddingTop }) =>
    noPaddingTop
      ? `
    padding-top: 35px;
    `
      : ''}
`

const SectionContent = styled.div`
  margin-bottom: ${spacing.lg}px;

  a {
    color: ${(p) => p.theme.colors.primary};
    font-weight: bold;
  }

  ol > li {
    padding: 5px 0;
  }

  ${Fonts.BaseTitleBold} {
    margin-bottom: 10px;
  }
`

const Table = styled.table`
  th {
    border: 1px solid black;
    padding: 10px;
    background-color: ${(p) => p.theme.colors.lightGrey};
    vertical-align: middle;
    text-align: center;
  }

  td {
    border: 1px solid black;
    padding: 10px;
    vertical-align: top;
  }

  ul {
    margin: 0;
    padding-left: 20px;
  }
`

const exportObj = {
  SectionHeader,
  SectionContent,
  Table,
}

export default exportObj
