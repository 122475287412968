import { AddressPlaceData, PlaceType } from 'helpers/address'

/**
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 * Helpers - localStorage
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 */
let staticBackupLocalStorage: string | null = null // Static backup for issue IOS force clear localStorage even open browser when low memory.
const localStorageVersion = '1.0.28'

export const STORAGE_NAME = {
  session: 'indiedish-liff-line-session',
}

export const getSession = () => {
  const localStorageSession = localStorage.getItem(STORAGE_NAME.session)
  const session = localStorageSession
    ? JSON.parse(String(localStorageSession))
    : JSON.parse(String(staticBackupLocalStorage))

  if (session && session.version === localStorageVersion) {
    return session
  } else {
    removeSession()
    return null
  }
}

export const setSession = (session: { [key: string]: any }) => {
  // Add Version
  session.version = localStorageVersion
  const sessionStr = JSON.stringify(session)
  localStorage.setItem(STORAGE_NAME.session, sessionStr)
  staticBackupLocalStorage = sessionStr
}

export const removeSession = () => localStorage.removeItem(STORAGE_NAME.session)

export const setCartSession = (cart: { [key: string]: any }) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.cart = cart
  setSession(newSession)
}

export const getCartSession = () => {
  const session = getSession()
  return session && session.cart ? session.cart : []
}

export const setAddressSession = (address: AddressPlaceData) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.address = address
  setSession(newSession)
}

export const getAddressSession = () => {
  const session = getSession()
  return session && session.address ? session.address : null
}

export const getListUserAlreadyPromoBuy1Get1 = () => {
  const session = getSession()
  return session && session.listUserAlreadyUsePromo ? session.listUserAlreadyUsePromo : []
}

export const setTimerShowPromotSet = (date: string) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.timerShowPromoSet = date
  setSession(newSession)
}

export const getTimerShowPromotSet = () => {
  const session = getSession()
  return session && session.timerShowPromoSet ? session.timerShowPromoSet : ''
}

export const setStackItemToAddCartAfterLogin = (item: { [key: string]: any } | null) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.stackItem = item
  setSession(newSession)
}

export const getStackItemToAddCartAfterLogin = () => {
  const session = getSession()
  return session && session.stackItem ? session.stackItem : ''
}

export const setGooglePlaceSession = (googlePlace: PlaceType) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.googlePlace = googlePlace
  setSession(newSession)
}

export const getGooglePlaceSession = () => {
  const session = getSession()
  return session && session.googlePlace ? session.googlePlace : null
}

export const setVendorIDSession = (vendorID: number) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.vendorID = vendorID
  setSession(newSession)
}

export const getVendorIDSession = () => {
  const session = getSession()
  return session && session.vendorID ? session.vendorID : null
}

export const setLocationIDSession = (locationID: number) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.locationID = locationID
  setSession(newSession)
}

export const getLocationIDSession = () => {
  const session = getSession()
  return session && session.locationID ? session.locationID : null
}

export const setCurrentSelectedAddressIDSession = (addressID: number) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.addressID = addressID
  setSession(newSession)
}

export const getCurrentSelectedAddressIDSession = () => {
  const session = getSession()
  return session && session.addressID ? session.addressID : null
}

export const setTokenSession = (token: string) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.token = token
  setSession(newSession)
}

export const getTokenSession = () => {
  const session = getSession()
  return session && session.token ? session.token : ''
}

export const clearLocalStorage = ({ exceptKeys = [] }: { exceptKeys: string[] }) => {
  const storageEntries = Object.entries(localStorage)
  storageEntries.forEach((item) => {
    const key = item[0]
    if (!exceptKeys.includes(key)) {
      localStorage.removeItem(key)
    }
  })
}

export const setCountMaxRetriesInitializeFailed = (count: number) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.countMaxRetriesInitializeFailed = count
  setSession(newSession)
}

export const getCountMaxRetriesInitializeFailed = (): number => {
  const session = getSession()
  return session && session.countMaxRetriesInitializeFailed ? session.countMaxRetriesInitializeFailed : 0
}
