import styled from 'styled-components'
import spacing from 'styles/spacing'

export default styled.div<{ md?: boolean; header?: boolean }>`
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;

  ${(props) =>
    props.md
      ? `
    padding: 0 ${spacing.md}px;
    `
      : ''}

  ${(props) =>
    props.header
      ? `
    padding-top: ${spacing.md}px;
    `
      : ''}
`
