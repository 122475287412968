import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { isDevelopment } from 'variables/environment'
import addressSlice from './slices/addressSlice'
import buffetSlice from './slices/buffetSlice'
import privateCartSlice from './slices/privateCartSlice'
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, privateCartSlice)

export const store = configureStore({
  reducer: {
    privateCart: persistedReducer,
    address: addressSlice,
    buffet: buffetSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: isDevelopment,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
