export enum SubPageName {
  Home = '/buffet',
  ProductDetail = '/buffet/product',
  PrivateCart = '/buffet/private-cart',
  GlobalCart = '/buffet/global-cart',
  Checkout = '/buffet/checkout',
  SelectPaymentMethod = '/buffet/select-payment-method',
  Promotions = '/buffet/promotions',
  ShareCoupon = '/buffet/share-coupon',
  InviteFriends = '/buffet/invite-friends',
  ConfirmShareCoupon = '/buffet/confirm-share-coupon',
}
