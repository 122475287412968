import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LinkWithPreload } from 'routes'

import { weight } from 'components/base-ui/Fonts'
import { Icon } from 'components/base-ui'

const LabelNotice = ({ title, text, ...rest }) => {
  return (
    <LinkWithPreload to="/pan-detail">
      <LabelBox {...rest}>
        <IconNotice name="notice-circle" size="13px" />
        <div>
          <Title {...rest}>{title}</Title>
          <Des {...rest}>{text}</Des>
        </div>
        <IconCarret name="carret-left" size="12px" />
      </LabelBox>
    </LinkWithPreload>
  )
}

LabelNotice.propTypes = {
  text: PropTypes.string,
  show: PropTypes.bool,
}

export default LabelNotice

const IconNotice = styled((props) => <Icon {...props} />)`
  color: ${(props) => props.theme.colors.blue500};
  margin-right: 10px;
  margin-top: 4px;
  align-self: flex-start;

  ${(props) =>
    props.success
      ? `
    color: ${props.theme.colors.green500};
  `
      : ''}

  ${(props) =>
    props.warning
      ? `
    color: ${props.theme.colors.yellow500};
  `
      : ''}
`

const IconCarret = styled((props) => <Icon {...props} />)`
  margin-right: 5px;
  margin-left: 30px;
  transform: rotate(180deg);
  color: ${(props) => props.theme.colors.blue500};

  ${(props) =>
    props.success
      ? `
    color: ${props.theme.colors.green500};
  `
      : ''}

  ${(props) =>
    props.warning
      ? `
    color: ${props.theme.colors.yellow500};
  `
      : ''}
`

const LabelBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
  border-radius: 8px;
  padding: 8px 10px;
  border: 1px solid ${(props) => props.theme.colors.blue300};
  background-color: ${(props) => props.theme.colors.lightBlueBg};

  ${(props) =>
    props.success
      ? `
    border: 1px solid ${props.theme.colors.green500};
    background-color: ${props.theme.colors.green50};
  `
      : ''}

  ${(props) =>
    props.warning
      ? `
    border: 1px solid ${props.theme.colors.yellow500};
    background-color: rgba(255, 196, 50, .05);
  `
      : ''}
`

const Title = styled.div`
  font-size: 12px;
  font-weight: ${weight.bold};
  color: ${(props) => props.theme.colors.gray800};
`

const Des = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.gray800};
`
