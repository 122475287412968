import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import ModalTimeUp from './modal/ModalTimeUp'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import { buffetSelector, resetBuffetModal, setBuffetModal } from 'store/slices/buffetSlice'
import ModalUpgradePackage from './modal/ModalUpgradePackage'
import useLanguage from 'hooks/useLanguage'
import ModalConfirmOverrideCheckout from 'components/dine-in/modal/ModalConfirmOverrideCheckout'
import ModalContinueCheckout from 'components/dine-in/modal/ModalContinueCheckout'
import ModalPendingPayment from 'components/dine-in/modal/ModalPendingPayment'
import ModalReadyToCheckout from './modal/ModalReadyToCheckout'
import { useHistory } from 'react-router-dom'
import { SubPageName } from 'enums/buffet'
import { useTranslation } from 'react-i18next'
import ModalCallingStaff from './modal/ModalCallingStaff'
import {
  OrderWarningEnum,
  useCallStaffMutation,
  useCancelCheckoutMutation,
  useInitiateCheckoutMutation,
} from 'graphQL/dinein/generate/operations'
import { dineInClient } from 'variables/services'
import * as Sentry from '@sentry/browser'
import { isProduction } from 'variables/environment'
import useValidateCurrentOrder, { OrderInvalidEnum } from 'hooks/buffet/useValidateCurrentOrder'
import useHandleBuffetOrder from 'hooks/buffet/useHandleBuffetOrder'
import { LabelBox } from 'components/base-ui'
import ModalConfirmCheckout from 'pages/buffet/GlobalCart/ModalConfirmCheckout'

export enum ModalEnum {
  None = 'None',
  UpgradePackage = 'UpgradePackage',
  TimeUp = 'TimeUp',
  ConfirmOverrideCheckout = 'ConfirmOverrideCheckout',
  ContinueCheckout = 'ContinueCheckout',
  PendingPayment = 'PendingPayment',
  ReadyToCheckout = 'ReadyToCheckout',
  CallingStaff = 'CallingStaff',
  ConfirmCheckout = 'ConfirmCheckout', // should active only in Global Cart page
}

const BuffetModals = () => {
  const dispatch = useAppDispatch()
  const buffet = useSelector(buffetSelector)
  const { getPropertyByLang } = useLanguage()
  const history = useHistory()
  const { t } = useTranslation()
  const [isShowSuccessLabel, setIsShowSuccessLabel] = useState(false)
  const [initiateCheckoutProcessing, setInitiateCheckoutProcessing] = useState(false)
  const { validateCurrentOrder } = useValidateCurrentOrder()
  const { handleValidateResult, handleBuffetEndTime } = useHandleBuffetOrder({})
  const [callStaff] = useCallStaffMutation({
    client: dineInClient,
  })
  const [cancelCheckout] = useCancelCheckoutMutation({
    client: dineInClient,
    variables: {
      orderID: buffet.table.orderID,
    },
    onCompleted: () => dispatch(resetBuffetModal()),
  })

  const [initiateCheckout] = useInitiateCheckoutMutation({
    client: dineInClient,
  })

  const handleCancelCallStaff = async () => {
    try {
      setIsShowSuccessLabel(false)
      dispatch(setBuffetModal({ type: ModalEnum.CallingStaff, isProcessing: true }))
      await callStaff({
        variables: {
          orderID: buffet.table.orderID,
          reason: null!,
        },
        onCompleted: (result) => {
          setIsShowSuccessLabel(true)
        },
      })
      dispatch(resetBuffetModal())
    } catch (error) {
      dispatch(resetBuffetModal())
      isProduction && Sentry.captureException(new Error(`Buffet error cancel call staff: ${JSON.stringify(error)}`))
    }
  }

  const handleOverrideCheckout = async () => {
    setInitiateCheckoutProcessing(true)
    await initiateCheckout({
      variables: {
        orderID: buffet.table.orderID,
        override: true,
      },
      onCompleted: () => {
        dispatch(resetBuffetModal())
        setTimeout(() => setInitiateCheckoutProcessing(false), 300)
        history.push(SubPageName.Checkout)
      },
    })
  }

  const handleContinueCheckout = () => {
    try {
      const validateResult = validateCurrentOrder(buffet.currentOrder, false)
      handleBuffetEndTime(buffet.currentOrder?.buffetEndTime)
      if (validateResult.isOK) {
        initiateCheckout({
          variables: {
            orderID: buffet.table.orderID,
            override: false,
          },
          onCompleted: (result) => {
            const { orderInvalids, orderWarnings } = result.initiateCheckout
            const isOK = orderInvalids.length === 0 || orderWarnings.length === 0
            if (isOK) {
              dispatch(resetBuffetModal())
              history.push(SubPageName.Checkout)
            } else {
              if (orderWarnings.includes(OrderWarningEnum.WarningOverrideCheckout)) {
                dispatch(setBuffetModal({ type: ModalEnum.ConfirmOverrideCheckout, isProcessing: false }))
              }
            }
          },
        })
      } else if (validateResult.invalid === OrderInvalidEnum.OwnerNeedToCheckout) {
        dispatch(resetBuffetModal())
        history.push(SubPageName.Checkout)
      } else {
        handleValidateResult(validateResult, buffet.currentOrder)
      }
    } catch (error) {
      dispatch(resetBuffetModal())
      isProduction &&
        Sentry.captureException(new Error(`Buffet error when user continue checkout : ${JSON.stringify(error)}`))
    }
  }

  const handleCancelCheckout = async () => {
    try {
      const validateResult = validateCurrentOrder(buffet.currentOrder, false)
      handleBuffetEndTime(buffet.currentOrder?.buffetEndTime)
      if (validateResult.isOK || validateResult.invalid === OrderInvalidEnum.OwnerNeedToCheckout) {
        await cancelCheckout()
      } else {
        handleValidateResult(validateResult, buffet.currentOrder)
      }
    } catch (error) {
      dispatch(resetBuffetModal())
      isProduction &&
        Sentry.captureException(new Error(`Buffet error when user cancel checkout : ${JSON.stringify(error)}`))
    }
  }

  const handleViewGlobalCart = () => {
    dispatch(resetBuffetModal())
    history.push(SubPageName.GlobalCart)
  }

  const handleConfirmCheckout = async () => {
    try {
      dispatch(setBuffetModal({ type: ModalEnum.ConfirmCheckout, isProcessing: true }))
      await initiateCheckout({
        variables: {
          orderID: buffet.table.orderID,
          override: false,
        },
        onCompleted: (result) => {
          const { orderWarnings } = result.initiateCheckout
          if (orderWarnings.includes(OrderWarningEnum.WarningOverrideCheckout)) {
            dispatch(setBuffetModal({ type: ModalEnum.ConfirmOverrideCheckout, isProcessing: false }))
          } else {
            dispatch(resetBuffetModal())
            history.push(SubPageName.Checkout)
          }
        },
      })
    } catch (error) {
      dispatch(resetBuffetModal())
    }
  }

  return (
    <>
      {isShowSuccessLabel && (
        <SuccessLabel title={t('buffet.label.cancelCallStaff')} text="" type="success" size="md" />
      )}

      <ModalTimeUp
        isOpen={buffet.modal.type === ModalEnum.TimeUp}
        onRequestClose={() => {
          dispatch(resetBuffetModal())
          history.push(SubPageName.GlobalCart)
        }}
      />
      <ModalUpgradePackage
        packageName={getPropertyByLang({ en: buffet.currentPackage.nameEn, th: buffet.currentPackage.nameTh })}
        isOpen={buffet.modal.type === ModalEnum.UpgradePackage}
        onRequestClose={() => dispatch(resetBuffetModal())}
      />
      <ModalConfirmOverrideCheckout
        onOverrideCheckout={handleOverrideCheckout}
        onViewGlobalCart={handleViewGlobalCart}
        onRequestClose={() => dispatch(resetBuffetModal())}
        isProcessing={initiateCheckoutProcessing}
        isOpen={buffet.modal.type === ModalEnum.ConfirmOverrideCheckout}
      />
      <ModalContinueCheckout
        isOpen={buffet.modal.type === ModalEnum.ContinueCheckout}
        onContinue={handleContinueCheckout}
        onCancel={handleCancelCheckout}
      />
      <ModalPendingPayment
        isOpen={buffet.modal.type === ModalEnum.PendingPayment}
        onCloseModal={handleViewGlobalCart}
      />
      <ModalReadyToCheckout
        isOpen={buffet.modal.type === ModalEnum.ReadyToCheckout}
        title={t('buffet.modal.readyToCheckout.title')}
        description={t('buffet.modal.readyToCheckout.description')}
        onClick={() => {
          dispatch(resetBuffetModal())
          history.push(SubPageName.GlobalCart)
        }}
      />
      <ModalCallingStaff
        isOpen={buffet.modal.type === ModalEnum.CallingStaff}
        isLoading={buffet.modal.isProcessing}
        onRequestClose={() => dispatch(resetBuffetModal())}
        onConfirm={handleCancelCallStaff}
      />

      {/* should active only in Global Cart page */}
      <ModalConfirmCheckout
        isOpen={buffet.modal.type === ModalEnum.ConfirmCheckout}
        isLoading={buffet.modal.isProcessing}
        onAccept={handleConfirmCheckout}
        onRequestClose={handleCancelCheckout}
        onCancel={handleCancelCheckout}
      />
    </>
  )
}

export default BuffetModals

const slideDownAnimation = keyframes`
  0% {
    top: -40px;
    opacity: 0;
  }
  10%{
    top: 15px;
    opacity: 1;
  }
  90%{
    top: 15px;
    opacity: 1;
  }
  100% {
    top: -40px;
    opacity: 0;
  }
`

const SuccessLabel = styled(LabelBox)`
  z-index: 100;
  position: fixed;
  left: 50%;
  opacity: 0;
  top: -40px;
  width: 90vw;
  transform: translateX(-50%);
  color: #4a9500;
  background: rgba(248, 255, 244, 0.9);
  animation: ${slideDownAnimation} 3.6s linear;
`
