import React, { useRef } from 'react'
import Modal from 'react-modal'
import styled, { createGlobalStyle } from 'styled-components'

import media from 'styles/media'
import { Button } from 'components/base-ui'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

function StyledModal({
  children,
  onRequestClose = () => {},
  zindex,
  isLoading = false,
  btnClose,
  hideCloseButton = false,
  shouldCloseOnOverlayClick = true,
  ...rest
}) {
  const { padding } = rest
  const modalRef = useRef(null)

  return (
    <Modal
      ref={modalRef}
      {...rest}
      overlayClassName="modal-bottom"
      contentLabel="Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      closeTimeoutMS={300}
      onRequestClose={handleOnClose}
      onAfterOpen={() => disableBodyScroll(modalRef?.current?.node)}
      onAfterClose={() => enableBodyScroll(modalRef?.current?.node)}
      shouldReturnFocusAfterClose={false}
      overlayRef={(item) => {
        if (item) {
          setTimeout(() => (item.scrollTop = 0))
        }
      }}
      style={{
        overlay: {
          display: 'flex',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent',
          padding: '30px',
          overflowY: 'auto',
          zIndex: zindex ? zindex : '100',
        },
        content: {
          position: 'absolute',
          top: 'initial',
          right: '0',
          bottom: '0',
          left: '0',
          display: 'block',
          minWidth: '100%',
          overflow: 'hidden',
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
          padding: '0',
          border: '0',
          background: 'transparent',
          borderRadius: '0',
          maxHeight: '100vh',
        },
      }}
    >
      {!hideCloseButton && (
        <CloseButton onClick={handleOnClose}>
          <CloseButtonLine />
          <CloseButtonLine />
        </CloseButton>
      )}
      <Container padding={padding}>
        {children}
        {btnClose && (
          <ButtonWrapper>
            <Button minWidth="160px" onClick={handleOnClose}>
              {btnClose}
            </Button>
          </ButtonWrapper>
        )}
      </Container>
      <GlobalStyled />
    </Modal>
  )

  function handleOnClose(event) {
    if (isLoading !== true) {
      onRequestClose()
    }
  }
}

export default StyledModal

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 17px;
  right: 15px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
`

const CloseButtonLine = styled.div`
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  background-color: black;

  &:first-child {
    transform: rotate(45deg);
  }

  &:nth-child(2n) {
    transform: rotate(135deg);
  }
`

const Container = styled.div`
  padding: ${(props) => (props.padding ? '0' : '25px')};
  padding-top: 40px;
  border: 1px solid #ccc;
  background: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  ${(props) =>
    props.padding
      ? `
    margin-bottom: 40px;
    `
      : ''}

  @media (${media.screenSmMax}) {
    ${(props) =>
      props.padding
        ? `
      margin-top: 20px;
      margin-bottom: 40px;
      `
        : ''}
  }
`

const GlobalStyled = createGlobalStyle`
  .ReactModal__Overlay{
    opacity: 0;
    transition: opacity 0.3s;

    @media (${media.screenSmMax}) {
      padding: 15px !important;
    }

    &.modal-bottom {
      padding: 0 !important;
    }
  }

  .ReactModal__Overlay::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content {
    opacity: 0;
    transform: translate(0, 20px);
    transition: opacity 0.3s, transform 0.3s;
  }

  .ReactModal__Content--after-open {    
    opacity: 1;
    transform: translate(0, 0);
  }

  .ReactModal__Content--before-close {
    opacity: 0;
    transform: translate(0, 20px);
  }

  .iphoneX,
  .ios {
    .ReactModal__Content {
      transform: translate(0, 0);
    }

    .ReactModal__Content--before-close {
      opacity: 0;
      transform: translate(0, 0);
    }
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
`
