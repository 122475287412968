import React from 'react'
import styled from 'styled-components'
import { getSrcSetStr } from 'helpers/image'
import { Fonts } from 'components/base-ui'

enum HatoLogoSizeEnum {
  sm = 'sm',
  md = 'md',
}

enum HatoLogoColorEnum {
  primary = 'primary',
  white = 'white',
}

interface PoweredByHatoProps {
  size?: keyof typeof HatoLogoSizeEnum
  color?: keyof typeof HatoLogoColorEnum
}

const PoweredByHato = ({ size = 'md', color = 'primary' }: PoweredByHatoProps) => {
  const hatoImg =
    color === 'primary'
      ? `${process.env.PUBLIC_URL}/assets/hato/hatohub-logo-color.png`
      : `${process.env.PUBLIC_URL}/assets/hato/hatohub-logo-white.png`

  return (
    <Wrapper size={size}>
      <Text size={size} color={color}>
        Powered by
      </Text>
      <HatoLogo
        size={size}
        alt="hatohub-logo"
        src={hatoImg}
        srcSet={getSrcSetStr({
          img: hatoImg,
          hires2x: true,
          hires3x: true,
        })}
      />
    </Wrapper>
  )
}

export default PoweredByHato

const Wrapper = styled.div<{ size: keyof typeof HatoLogoSizeEnum }>`
  display: flex;
  align-items: center;
  column-gap: 4px;

  ${({ size }) =>
    size === HatoLogoSizeEnum.sm &&
    `
    column-gap: 2px;
  `}
`

const Text = styled(Fonts.SmallTitleRegular)<{
  size: keyof typeof HatoLogoSizeEnum
  color: keyof typeof HatoLogoColorEnum
}>`
  color: ${({ theme }) => theme.colors.darkGrey};

  ${({ color, theme }) =>
    color === HatoLogoColorEnum.white &&
    `
    color: ${theme.colors.white};
  `}

  ${({ size }) =>
    size === HatoLogoSizeEnum.sm &&
    `
    font-size: 10px;
  `}
`

const HatoLogo = styled.img<{ size: keyof typeof HatoLogoSizeEnum }>`
  ${({ size }) =>
    size === HatoLogoSizeEnum.sm &&
    `
    height: 15px;
  `}

  ${({ size }) =>
    size === HatoLogoSizeEnum.md &&
    `
    height: 21px;
  `}
`
