import React, { CSSProperties, FC, useMemo } from 'react'
import { ThemeColor } from 'types/color'
import Icon from 'components/base-ui/Icon'
import { onClick } from 'helpers/fastClick'
import { HeadLineLarge } from 'components/base-ui/FontsV2'
import { Container, BtnBackStyled } from './styled'

export interface BtnBackProps {
  title?: string
  className?: string
  enableFastClick?: boolean
  onClick: (e: any) => void
  color?: keyof typeof ThemeColor
  bold?: boolean
  lg?: boolean
  style?: CSSProperties
}

const BtnBack: FC<BtnBackProps> = ({
  title,
  onClick: btnOnClick,
  enableFastClick = false,
  color = ThemeColor.primary,
  bold = false,
  className = '',
  lg = false,
  style = {},
}) => {
  const _className = `${className ? `${className} ` : ''} button`
  const iconName = bold ? 'arrow-left-bold' : 'arrow-left'

  const clickObj = useMemo(() => {
    if (btnOnClick) {
      return enableFastClick ? { ...onClick((e) => btnOnClick(e)) } : { onClick: btnOnClick }
    }
    return {}
  }, [btnOnClick, enableFastClick])

  return (
    <Container>
      <BtnBackStyled data-testid="btn-back" color={color} lg={lg} className={_className} {...clickObj} style={style}>
        <Icon name={iconName} />
      </BtnBackStyled>
      {title && <HeadLineLarge>{title}</HeadLineLarge>}
    </Container>
  )
}

export default BtnBack
