import { useContext } from 'react'
import { AppContext } from 'contexts/AppProvider'

import {
  useQuery as useQueryReact,
  useLazyQuery as useLazyQueryReact,
  useMutation as useMutationReact,
} from '@apollo/client'

const getHookOptions = (options = {}, isFacebookWebview = false, isFacebookWebviewInit = false) => {
  let _options = options

  // if have skip
  if (options.hasOwnProperty('skip')) {
    _options.skip = _options.skip || !isFacebookWebviewInit
  } else {
    _options.skip = !isFacebookWebviewInit
  }

  // // if options have context
  if (options.context) {
    _options.context = isFacebookWebview
      ? {
          ...options.context,
          uri: process.env.REACT_APP_API_HOSTNAME + '/messengerapi/graphql',
        }
      : options.context
  } else {
    _options = isFacebookWebview
      ? {
          ...options,
          context: {
            uri: process.env.REACT_APP_API_HOSTNAME + '/messengerapi/graphql',
          },
        }
      : options
  }

  return _options
}

export const useQuery = (gql, options = {}) => {
  const { isFacebookWebview, isFacebookWebviewInit } = useContext(AppContext)
  const _options = getHookOptions(options, isFacebookWebview, isFacebookWebviewInit)
  return useQueryReact(gql, _options)
}

export const useLazyQuery = (gql, options = {}) => {
  const { isFacebookWebview, isFacebookWebviewInit } = useContext(AppContext)
  const _options = getHookOptions(options, isFacebookWebview, isFacebookWebviewInit)
  return useLazyQueryReact(gql, _options)
}

export const useMutation = (gql, options = {}) => {
  let _isFacebookWebview = false
  let _isFacebookWebviewInit = false
  const appContext = useContext(AppContext)
  if (appContext) {
    _isFacebookWebview = appContext.isFacebookWebview
    _isFacebookWebviewInit = appContext.isFacebookWebviewInit
  } else {
    _isFacebookWebview = options.isFacebookWebview
    _isFacebookWebviewInit = options.isFacebookWebviewInit
  }

  const _options = getHookOptions(options, _isFacebookWebview, _isFacebookWebviewInit)
  return useMutationReact(gql, _options)
}
