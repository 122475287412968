import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { PrivateCartType, ProductCartType } from 'types/cart'
import { isSameModifiers, isSameCustomNotes, isSameServiceType } from 'helpers/cart'

export interface PrivateCartState {
  list: PrivateCartType[]
  current: PrivateCartType | undefined
  orderId: number
  totalQuantity: number
  selectedIndex: number
}

const initialState: PrivateCartState = {
  list: [],
  current: undefined,
  orderId: 0,
  totalQuantity: 0,
  selectedIndex: -1,
}

export const privateCartSlice = createSlice({
  name: 'privateCart',
  initialState,
  reducers: {
    initCurrentPrivateCart: (privateCart: PrivateCartState, { payload }: PayloadAction<{ orderId: number }>) => {
      privateCart.orderId = payload.orderId
      privateCart.current = privateCart.list.find((cart) => cart.orderId === payload.orderId)
      privateCart.totalQuantity =
        privateCart.current?.products.reduce((previousQuantity, product) => previousQuantity + product.quantity, 0) || 0
    },
    addProductToPrivateCart: (privateCart: PrivateCartState, { payload }: PayloadAction<ProductCartType>) => {
      const { modifiers, item, quantity } = payload
      let newModifiers = modifiers.map((modifier) => {
        const { modifierID } = modifier
        const modifierGroup = item.modifierGroups.find(({ modifierGroup }) =>
          modifierGroup.modifiers.find((mf) => mf.id === modifierID)
        )
        return {
          ...modifier,
          multipleQuantity: modifierGroup ? modifierGroup.modifierGroup.multipleQuantity : false,
        }
      })

      if (privateCart.current) {
        const currentProductInCart = privateCart.current.products.find(
          (productInCart) =>
            productInCart.item.id === item.id &&
            isSameModifiers(productInCart.modifiers, newModifiers) &&
            isSameCustomNotes(productInCart, payload) &&
            isSameServiceType(productInCart, payload)
        )
        if (currentProductInCart) {
          const willUpdateProductCart =
            isSameModifiers(currentProductInCart.modifiers, newModifiers) &&
            isSameCustomNotes(currentProductInCart, payload) &&
            isSameServiceType(currentProductInCart, payload)
          if (willUpdateProductCart) {
            const productCartToUpdate: ProductCartType = {
              ...currentProductInCart,
              quantity: currentProductInCart.quantity + quantity,
            }
            const otherProducts = privateCart.current.products.filter(
              (_product) =>
                productCartToUpdate.item.id !== _product.item.id ||
                !isSameModifiers(_product.modifiers, newModifiers) ||
                !isSameCustomNotes(_product, productCartToUpdate) ||
                !isSameServiceType(_product, productCartToUpdate)
            )
            privateCart.current.products = [...otherProducts, productCartToUpdate]
            const otherCartList = privateCart.list.filter((cart) => cart.orderId !== privateCart.orderId)
            privateCart.list = [...otherCartList, privateCart.current]
          } else {
            privateCart.current.products.push({ ...payload, modifiers: newModifiers })
            const otherCartList = privateCart.list.filter((cart) => cart.orderId !== privateCart.orderId)
            privateCart.list = [...otherCartList, privateCart.current]
          }
        } else {
          privateCart.current.products.push({ ...payload, modifiers: newModifiers })
          const otherCartList = privateCart.list.filter((cart) => cart.orderId !== privateCart.orderId)
          privateCart.list = [...otherCartList, privateCart.current]
        }
      } else {
        privateCart.list = [{ orderId: privateCart.orderId, products: [payload] }]
      }
      privateCart.current = privateCart.list.find((cart) => cart.orderId === privateCart.orderId)
      privateCart.totalQuantity =
        privateCart.current?.products.reduce((previousQuantity, product) => previousQuantity + product.quantity, 0) || 0
    },
    setPrivateCartSelectedIndex: (privateCart: PrivateCartState, { payload }: PayloadAction<number>) => {
      privateCart.selectedIndex = payload
    },
    updatePrivateCartItem: (privateCart: PrivateCartState, { payload }: PayloadAction<ProductCartType>) => {
      if (privateCart.current) {
        const oldProduct = privateCart.current.products[privateCart.selectedIndex]
        privateCart.current.products[privateCart.selectedIndex] = { ...oldProduct, ...payload }
        const otherCartList = privateCart.list.filter((cart) => cart.orderId !== privateCart.orderId)
        privateCart.list = [...otherCartList, privateCart.current]
        privateCart.selectedIndex = -1
        privateCart.current = privateCart.list.find((cart) => cart.orderId === privateCart.orderId)
        privateCart.totalQuantity =
          privateCart.current?.products.reduce((previousQuantity, product) => previousQuantity + product.quantity, 0) ||
          0
      }
    },
    removePrivateCartItem: (privateCart: PrivateCartState, { payload }: PayloadAction<number>) => {
      if (privateCart.current) {
        privateCart.current.products.splice(payload, 1)
        const otherCartList = privateCart.list.filter((cart) => cart.orderId !== privateCart.orderId)
        privateCart.list = [...otherCartList, privateCart.current]
      }
    },
    clearCurrentPrivateCart: (privateCart: PrivateCartState) => {
      const otherCartList = privateCart.list.filter((cart) => cart.orderId !== privateCart.orderId)
      privateCart.list = otherCartList
      privateCart.current = undefined
      privateCart.totalQuantity = 0
    },
  },
})

export const {
  initCurrentPrivateCart,
  addProductToPrivateCart,
  setPrivateCartSelectedIndex,
  updatePrivateCartItem,
  removePrivateCartItem,
  clearCurrentPrivateCart,
} = privateCartSlice.actions
export const privateCartSelector = (state: RootState) => state.privateCart
export default privateCartSlice.reducer
