const queriesCurrentCart = `
  id
  containerReturnInfo {
    scheduledReturnDate
    scheduledReturnTimeslot
  }
  lineChannelID
  shippingAddressID
  locationID
  hasContainer
  returnContainer
  requestUtensils
  pickUp
  taxInvoice {
    id
    taxID
    companyName
    address
  }
  location {
    id
    nameEn
    nameTh
    vendorID
    businessHours {
      id
      active
      dayOfWeek
      startTime
      endTime
    }
  }
  lineItems {
    id
    productID
    quantity
    totalSatangs
    customNotes
    useContainer
    containerType
    invalids
    product {
      id
      discountSatangs
      priceSatangs
      totalPriceSatangs
      sku
      nameTh
      nameEn
      customNotesLabelTh
      customNotesLabelEn
      skipCustomNotesScreen
      eligibleContainerType
      excludedFromPromotion
      maxQuantity
      promotionBadge
      locationProducts {
        id
        inStockQuantity
      }
      category {
        id
        nameTh
        nameEn
      }
      images {
        id
        resizableURL
      }
      vendor {
        id
        nameEn
        nameTh
      }
      modifierGroups {
        id
        active
        position
        maximumQuantity
        minimumQuantity
        modifierGroupID
        productID
        modifierGroup {
          id
          nameEn
          nameTh
          vendorID
          multipleQuantity
          modifiers {
            id
            active
            nameEn
            nameTh
            position
            images {
              id
              alt
              resizableURL
            }
            priceSatangs
            invalids
            product {
              id
              nameEn
              nameTh
            }
          }
        }
      }
    }
    modifiers {
      id
      modifierID
      quantity
      modifier {
        id
        modifierGroupID
        active
        nameEn
        nameTh
        position
        images {
          id
          alt
          resizableURL
        }
        priceSatangs
        invalids
        product {
          id
          nameEn
          nameTh
        }
      }
    }
  }
  lineItemTotalSatangs
  totalQuantity
  deliverableDays {
    date
    timeslots
  }
  deliveryDate
  deliveryTimeslot
  estDeliveryMins
  shippingTotalSatangs
  depositTotalSatangs
  adjustmentCashTotalSatangs
  adjustmentStaffCashTotalSatangs
  totalDueSatangs
  totalSatangs
  totalWithDepositSatangs
  lineItemVatSatangs
  specialInstructions
  adjustments {
    labelEn
    labelTh
    fullAmountSatangs
    amountSatangs
    promotion {
      descriptionEn
      descriptionTh
      id
      nameEn
      nameTh
      type
      memberTierRankConditions
      targetEntity
    }
    promotionID
    memberCouponID
    externalCouponID
    externalCoupon {
      id
      isAvailable
      endTime
      amount
      serialNumber
      rewardType
      thumbnailImageURL
      name
      staffPrivilegeType
    }
    adjustmentType
    source
    invalidReason
  }
  omiseCardID
  omiseCardBrand
  omiseCardLastDigits
  omiseCardholderName
  paymentPhone
  paymentType
  memberTransaction {
    earnedAt
    earnedPoints
    id
    membership {
      id
      memberID
      firstName
      lastName
      provider
      memberTier
      totalPoints
    }
  }
`

export default queriesCurrentCart
