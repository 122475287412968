import React, { useRef } from 'react'

// Typescript: Any Case
// Now we can not find the right Type.
function useCombinedRefs(...refs: any): any {
  const targetRef = useRef<HTMLElement>()

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

export default useCombinedRefs
