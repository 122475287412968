import { useState, useEffect, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, css } from 'styled-components'

import { Fonts } from 'components/base-ui'
import { onClick } from 'helpers/fastClick'
import { AppContext } from 'contexts/AppProvider'
import { getSrcSetStr } from 'helpers/image'
import { useTranslation } from 'react-i18next'

const SwitchPickUpComponent = ({
  asyncDefaultValue,
  defaultValue = '',
  children,
  onChange = () => {},
  enableFastClick = true,
  bgDark = false,
}) => {
  const { lang } = useContext(AppContext)
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState('')

  const options = useMemo(() => {
    return [
      {
        value: 'delivery',
        label: t('switchTabDelivery'),
        icon: `${process.env.PUBLIC_URL}/assets/generic/pick-up-motor.png`,
        iconInactive: `${process.env.PUBLIC_URL}/assets/generic/pick-up-motor-inactive.png`,
      },
      {
        value: 'pickup',
        label: t('switchTabPickUp'),
        icon: `${process.env.PUBLIC_URL}/assets/generic/pick-up-bag.png`,
        iconInactive: `${process.env.PUBLIC_URL}/assets/generic/pick-up-bag-inactive.png`,
      },
    ]
  }, [lang])

  useEffect(() => {
    if (options.length > 0) {
      setSelectedTab(options[0].value)
    } else {
      setSelectedTab(defaultValue)
    }
  }, [])

  useEffect(() => {
    if (typeof asyncDefaultValue !== 'undefined') {
      setSelectedTab(asyncDefaultValue)
    }
  }, [asyncDefaultValue])

  return (
    <SwitchPickUp {...{ bgDark }}>
      {options.map((option, i) => {
        const { value, label, icon, iconInactive } = option
        const active = value === selectedTab

        const clickObj = enableFastClick
          ? {
              ...onClick(() => {
                onChange(value)
                if (asyncDefaultValue === null || asyncDefaultValue === undefined) {
                  setSelectedTab(value)
                }
              }),
            }
          : {
              onClick: () => {
                onChange(value)
                if (asyncDefaultValue === null || asyncDefaultValue === undefined) {
                  setSelectedTab(value)
                }
              },
            }

        return (
          <SwtichTab key={i} {...clickObj} data-testid={`switch-${label}`} {...{ bgDark, active }}>
            <IconPickupInactive
              invisible={active}
              alt=""
              src={iconInactive}
              srcSet={getSrcSetStr({
                img: iconInactive,
                hires2x: true,
                hires3x: true,
              })}
            />

            {value === 'delivery' && (
              <AnimationMotorSpeed runAnimation={active}>
                <IconMotorcycle
                  runAnimation={active}
                  alt=""
                  src={icon}
                  srcSet={getSrcSetStr({
                    img: icon,
                    hires2x: true,
                    hires3x: true,
                  })}
                />
              </AnimationMotorSpeed>
            )}
            {value === 'pickup' && (
              <AnimationBagSwing runAnimation={active}>
                <IconBag
                  runAnimation={active}
                  alt=""
                  src={icon}
                  srcSet={getSrcSetStr({
                    img: icon,
                    hires2x: true,
                    hires3x: true,
                  })}
                />
              </AnimationBagSwing>
            )}
            <Fonts.BaseTitleBold style={{ marginLeft: value === 'delivery' ? '4px' : '' }}>{label}</Fonts.BaseTitleBold>
          </SwtichTab>
        )
      })}
    </SwitchPickUp>
  )
}

SwitchPickUpComponent.propTypes = {
  asyncDefaultValue: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  bgDark: PropTypes.bool,
}

export default SwitchPickUpComponent

const SwitchPickUp = styled.div`
  position: relative;
  display: flex;
  border-radius: 8px;
  height: 28px;
  box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.primary};
  background-color: #f1f1f1;

  ${(props) =>
    props.bgDark
      ? `
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.3);
      pointer-events: none;
      border-radius: 8px;
    }
  `
      : ''}
`

const SwtichTab = styled.div`
  display: flex;
  align-items: center;
  padding-right: 6px;
  padding-left: 2px;
  color: ${(props) => props.theme.colors.darkGrey};

  ${Fonts.BaseTitleBold} {
    white-space: nowrap;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  ${(props) =>
    props.active
      ? `
    color: white;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    background-color: ${props.theme.colors.primary};
  `
      : ''}

  ${(props) =>
    props.bgDark
      ? `
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  `
      : ''}
`

const motorcycleSpeed = keyframes`
  20% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(10px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const motorcycleChokeUp = keyframes`
  0 {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-20deg);
  }
  75% {
    transform: rotate(0);
  }
`

const pickupBagSwing = keyframes`
  25% {
    transform: rotate(12deg);
  }
  50% {
    transform: rotate(-12deg);
  }
  100% {
    transform: rotate(0);
  }
`

const pickupBag = keyframes`
  0 {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -3px, 0);
  }
  80% {
    transform: translate3d(0, 2px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const IconPickupInactive = styled.img`
  position: relative;
  top: -6px;

  ${(props) =>
    props.invisible
      ? `
    opacity: 0;
  `
      : ''}
`

const IconPickup = styled(IconPickupInactive)`
  position: absolute;
  top: -10px;
`
const motorcycleSpeedAnimation = css`
  animation: ${motorcycleSpeed} 1s 2;
`
const AnimationMotorSpeed = styled.div`
  position: absolute;
  top: 0;

  ${(props) => (props.runAnimation ? motorcycleSpeedAnimation : '')}
`

const motorcycleChokeAnimation = css`
  transform-origin: 8px 30px;
  animation: ${motorcycleChokeUp} 1s 2;
`
const IconMotorcycle = styled(IconPickup)`
  ${(props) => (props.runAnimation ? motorcycleChokeAnimation : `opacity: 0;`)}
`

const bagSwingAnimation = css`
  animation: ${pickupBagSwing} 0.75s 2;
`
const AnimationBagSwing = styled.div`
  transform-origin: left top;
  position: absolute;
  top: 0;

  ${(props) => (props.runAnimation ? bagSwingAnimation : `opacity: 0;`)}
`

const bagAnimation = css`
  animation: ${pickupBag} 0.75s 2;
`
const IconBag = styled(IconPickup)`
  ${(props) => (props.runAnimation ? bagAnimation : '')}
`
