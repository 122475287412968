import React, { useContext } from 'react'
import { getLiff, redirectToChatScreen } from 'helpers/liff'
import { useTranslation } from 'react-i18next'
import ScreenWithImage from 'components/dine-in/ScreenWithImage'
import { AppContext } from 'contexts/AppProvider'

const liff = getLiff()
const checkImg = `${process.env.PUBLIC_URL}/assets/generic/modal/check.png`

const PaymentSuccess = () => {
  const { t } = useTranslation()
  const { brand } = useContext(AppContext)

  const onClickOk = () => {
    if (liff.isInClient()) {
      redirectToChatScreen(brand?.liffID.lineID)
    }
  }

  return (
    <ScreenWithImage
      id="payment-success-screen"
      imagePath={checkImg}
      imageSize="70px"
      isGenerateSrc
      title={t('dineInPaymentSuccessTitle')}
      description={t('dineInPaymentSuccessDesc')}
      isShowButton={liff.isInClient()}
      buttonText={t('btnOK')}
      buttonOutline={true}
      onClick={onClickOk}
    />
  )
}

export default PaymentSuccess
