import React, { useContext } from 'react'
import styled from 'styled-components'

import { Modal, Fonts } from 'components/base-ui'

import { AppContext } from 'contexts/AppProvider'
import { THEMES_UI } from 'brands'
import { useTranslation } from 'react-i18next'

interface ModalNoticeNearestStoreProps {
  onRequestClose: VoidFunction
  isOpen: boolean
  zindex: string
}

const ModalNoticeNearestStore = (props: ModalNoticeNearestStoreProps) => {
  const { themeUI } = useContext(AppContext)
  const { t } = useTranslation()

  return (
    <Modal hideCloseButton={false} {...props}>
      {themeUI === THEMES_UI.GENERIC && (
        <ModalContainerGeneric>
          <img
            alt="out-of-delivery-area"
            src={`${process.env.PUBLIC_URL}/assets/generic/modal/out-of-delivery-area.png`}
            srcSet={`${process.env.PUBLIC_URL}/assets/generic/modal/out-of-delivery-area.png 1x, ${process.env.PUBLIC_URL}/assets/generic/modal/out-of-delivery-area@2x.png 2x,
                ${process.env.PUBLIC_URL}/assets/generic/modal/out-of-delivery-area@3x.png 3x,
                `}
          />
          <ModalTitleGeneric>{t('modalGenericNoticeNearestStoreTitle')}</ModalTitleGeneric>
          <ModalTextGeneric>{t('modalGenericNoticeNearestStoreDes')}</ModalTextGeneric>
        </ModalContainerGeneric>
      )}

      {themeUI === THEMES_UI.GON && (
        <ModalContainerGeneric>
          <img
            alt="out-of-delivery-area-food-passion"
            src={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-out-of-area.png`}
            srcSet={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-out-of-area.png 1x, ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-out-of-area@2x.png 2x,
                ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-out-of-area@3x.png 3x,
                `}
          />
          <ModalTitleGeneric>{t('modalGenericNoticeNearestStoreTitle')}</ModalTitleGeneric>
          <ModalTextGeneric>{t('modalNoticeNearestStoreDes')}</ModalTextGeneric>
        </ModalContainerGeneric>
      )}
    </Modal>
  )
}

export default ModalNoticeNearestStore

const ModalContainerGeneric = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 350px;
  margin-bottom: -10px;
`

const ModalTitleGeneric = styled(Fonts.H2)`
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
  color: black;
`

const ModalTextGeneric = styled(Fonts.Base)`
  text-align: center;
  color: black;
`
