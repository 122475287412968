import styled from 'styled-components'

const OverlayPage = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  transform: translate3d(100%, 0, 0);
  transition: 0.3s;
  pointer-events: none;
  visibility: hidden;

  ${(props) =>
    props.show
      ? `
    visibility: visible;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
    `
      : ''}
`

export default OverlayPage
