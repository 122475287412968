import React from 'react'
import styled from 'styled-components'
import ModalConfirm from 'components/base-ui/ModalConfirm'
import { useTranslation } from 'react-i18next'
import { Fonts } from 'components/base-ui'

type Props = {
  isOpen: boolean
  isLoading: boolean
  onRequestClose: () => void
  onAccept: () => void
  onCancel: () => void
}

function ModalConfirmCheckout({ isOpen, isLoading, onRequestClose, onAccept, onCancel }: Props) {
  const { t } = useTranslation()
  return (
    <ModalConfirm
      testId="checkOutModalConfirm"
      isOpen={isOpen}
      isLoading={isLoading}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      hideCloseButton={false}
      buttonWidth="90%"
      btnAccept={{
        content: t('buffet.modal.confirmCheckout.confirm'),
        isProcessing: isLoading,
        onClick: onAccept,
      }}
      btnCancel={{
        outline: true,
        content: t('buffet.modal.confirmCheckout.cancel'),
        onClick: onCancel,
      }}
    >
      <ModalContent>
        <Fonts.H3>{t('buffet.modal.confirmCheckout.title')}</Fonts.H3>
        <Fonts.BaseTitleRegular style={{ fontSize: 14 }}>
          {t('buffet.modal.confirmCheckout.description')}
        </Fonts.BaseTitleRegular>
      </ModalContent>
    </ModalConfirm>
  )
}

export default ModalConfirmCheckout

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 7px;
  padding-bottom: 20px;
  text-align: center;
`
