// ***** THIS FILE IS GENERATED, DO NOT EDIT! *****
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T
export type InputMaybe<T> = T
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export enum ActivityLevelEnum {
  HighActivity = 'HighActivity',
  LowActivity = 'LowActivity',
  ModerateActivity = 'ModerateActivity',
}

export type Address = {
  /** Full address string (excluding province and zipcode) */
  address: Maybe<Scalars['String']>
  buildingType: Maybe<BuildingTypeEnum>
  callOnArrival: Maybe<Scalars['Boolean']>
  deliveryMethod: Maybe<DeliveryMethodEnum>
  detail: Maybe<Scalars['String']>
  /** Email of contact person */
  email: Maybe<Scalars['String']>
  /** Floors such as "30-34" */
  floors: Maybe<Scalars['String']>
  geocodedAddressEn: Maybe<Scalars['String']>
  geocodedAddressTh: Maybe<Scalars['String']>
  geocodedDistrictEn: Maybe<Scalars['String']>
  geocodedDistrictTh: Maybe<Scalars['String']>
  geocodedPostcode: Maybe<Scalars['String']>
  geocodedProvinceEn: Maybe<Scalars['String']>
  geocodedProvinceTh: Maybe<Scalars['String']>
  geocodedSubdistrictEn: Maybe<Scalars['String']>
  geocodedSubdistrictTh: Maybe<Scalars['String']>
  googlePlaceID: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  lastUsed: Maybe<Scalars['Boolean']>
  latitude: Maybe<Scalars['Float']>
  /** Location or building name such as "AIA Capital Center Building" */
  locationLabel: Maybe<Scalars['String']>
  longitude: Maybe<Scalars['Float']>
  /** Fullname of contact person */
  name: Maybe<Scalars['String']>
  /** Nickname of address */
  nickname: Maybe<Scalars['String']>
  note: Maybe<Scalars['String']>
  /** Phone number of contact person */
  phone: Maybe<Scalars['String']>
  postcode: Maybe<Scalars['String']>
  province: Maybe<Scalars['String']>
  temporary: Maybe<Scalars['Boolean']>
  userID: Maybe<Scalars['Int']>
}

export type AddressInput = {
  address?: InputMaybe<Scalars['String']>
  /** Building type */
  buildingType?: InputMaybe<BuildingTypeEnum>
  /** Call on arrival flag */
  callOnArrival?: InputMaybe<Scalars['Boolean']>
  /** Delivery method for address */
  deliveryMethod?: InputMaybe<DeliveryMethodEnum>
  detail?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  floors?: InputMaybe<Scalars['String']>
  /** Google place ID */
  googlePlaceID?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  latitude?: InputMaybe<Scalars['Float']>
  /** Location or building name such as "AIA Capital Center Building" */
  locationLabel?: InputMaybe<Scalars['String']>
  longitude?: InputMaybe<Scalars['Float']>
  /** Fullname of contact person */
  name?: InputMaybe<Scalars['String']>
  /** Nickname of address */
  nickname?: InputMaybe<Scalars['String']>
  note?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  /** Flag to know which is a temporary address */
  temporary?: InputMaybe<Scalars['Boolean']>
}

export enum AdjustmentInvalidReasonEnumType {
  CodeHasBeenUsed = 'CodeHasBeenUsed',
  CouponHasBeenUsed = 'CouponHasBeenUsed',
  Expired = 'Expired',
  InsufficientTotalDue = 'InsufficientTotalDue',
  InvalidConditions = 'InvalidConditions',
  NotCashVoucher = 'NotCashVoucher',
  NotExisted = 'NotExisted',
}

export enum AdjustmentSourceEnumType {
  ExternalCoupon = 'ExternalCoupon',
  ExternalMemberCoupon = 'ExternalMemberCoupon',
  MemberCoupon = 'MemberCoupon',
  Promotion = 'Promotion',
}

export enum AdjustmentTargetEntityTypeEnum {
  LineItem = 'LineItem',
  ProductLevel = 'ProductLevel',
  ShippingFee = 'ShippingFee',
}

export enum AdjustmentTypeEnumType {
  Cash = 'Cash',
  Concession = 'Concession',
  Discount = 'Discount',
}

export type BankAccountType = {
  brand: Maybe<BankBrandTypeEnum>
  name: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
}

export enum BankBrandTypeEnum {
  Bbl = 'BBL',
  Boa = 'BOA',
  Cimb = 'CIMB',
  Gsb = 'GSB',
  Icbc = 'ICBC',
  KBank = 'KBank',
  Ktb = 'KTB',
  Kiatnakin = 'Kiatnakin',
  Krungsri = 'Krungsri',
  PromptPay = 'PromptPay',
  Scb = 'SCB',
  StandardCharteredBank = 'StandardCharteredBank',
  TBank = 'TBank',
  Tmb = 'TMB',
  TiscoBank = 'TiscoBank',
  Uob = 'UOB',
}

export enum BuildingTypeEnum {
  CondoOffice = 'CondoOffice',
  House = 'House',
}

export type BusinessHourType = {
  active: Maybe<Scalars['Boolean']>
  /** Day of week starting from 0 which is Sunday. */
  dayOfWeek: Maybe<Scalars['Int']>
  /** The time that the business closes in format HH:MM */
  endTime: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  /** The time that the business opens in format HH:MM */
  startTime: Maybe<Scalars['String']>
}

export type CartAndFpMemberCouponsResultType = {
  autoPromotions: Maybe<Array<Maybe<CartPromotionType>>>
  cart: Maybe<CustomerCartType>
  coupons: Maybe<Array<Maybe<ExternalCouponType>>>
  featuredPromotion: Maybe<FeaturedPromotionType>
  manualPromotions: Maybe<Array<Maybe<CartPromotionType>>>
}

export type CartAndHhMemberCouponsResultType = {
  autoPromotions: Maybe<Array<Maybe<CartPromotionType>>>
  cart: Maybe<CustomerCartType>
  coupons: Maybe<Array<Maybe<CartMemberCouponType>>>
  featuredPromotion: Maybe<FeaturedPromotionType>
  manualPromotions: Maybe<Array<Maybe<CartPromotionType>>>
}

export type CartAndPromotionsType = {
  autoPromotions: Maybe<Array<Maybe<CartPromotionType>>>
  cart: Maybe<CustomerCartType>
}

export enum CartCorrectionEnum {
  InactiveLineItemsRemoved = 'InactiveLineItemsRemoved',
}

export enum CartInvalidEnum {
  InvalidContainerReturnDate = 'InvalidContainerReturnDate',
  InvalidDeliveryDateTime = 'InvalidDeliveryDateTime',
  InvalidExceedLineItemMaxQuantity = 'InvalidExceedLineItemMaxQuantity',
  InvalidExternalCoupon = 'InvalidExternalCoupon',
  InvalidLineItem = 'InvalidLineItem',
  InvalidLineItemModifierQuantity = 'InvalidLineItemModifierQuantity',
  InvalidMinimumLineItemTotal = 'InvalidMinimumLineItemTotal',
  InvalidMinimumTotal = 'InvalidMinimumTotal',
  InvalidPayment = 'InvalidPayment',
  InvalidPickUpNotAvailable = 'InvalidPickUpNotAvailable',
  InvalidPromotion = 'InvalidPromotion',
  InvalidShippingAddress = 'InvalidShippingAddress',
  InvalidTotalDueMoreThanPrevious = 'InvalidTotalDueMoreThanPrevious',
}

export type CartMemberCouponType = {
  coupon: Maybe<MemberCouponType>
  isAvailable: Maybe<Scalars['Boolean']>
}

export enum CartPromotionInvalidEnum {
  Conflicting = 'Conflicting',
  Duplicate = 'Duplicate',
  InvalidConditions = 'InvalidConditions',
  /** For Food Passion staff privilege */
  InvalidLineId = 'InvalidLineID',
  NotExistedOrExpired = 'NotExistedOrExpired',
  /** For Food Passion staff privilege */
  QrCodeNotFound = 'QRCodeNotFound',
  /** For Food Passion staff privilege */
  StaffPrivilegeAlreadyApplied = 'StaffPrivilegeAlreadyApplied',
  Used = 'Used',
}

export type CartPromotionType = {
  code: Maybe<Scalars['String']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  memberTierRankConditions: Maybe<Array<Maybe<PromotionMemberTierRankConditionEnumType>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  orderServiceTypeConditions: Maybe<Array<Maybe<OrderServiceTypeConditionEnum>>>
  strategyType: Maybe<PromotionStrategyTypeEnum>
  targetEntity: Maybe<AdjustmentTargetEntityTypeEnum>
}

export type Category = {
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  productColorScheme: Maybe<ProductColorSchemeEnum>
  vendorID: Maybe<Scalars['Int']>
}

export type ContainerReturnInfoInputType = {
  /** Scheduled return date in format YYYY-MM-DD */
  scheduledReturnDate: Scalars['String']
  scheduledReturnTimeslot: Scalars['String']
}

export type CouponImageType = {
  resizeableURL: Maybe<Scalars['String']>
}

export type CreditCardType = {
  cardBrand: Maybe<Scalars['String']>
  cardID: Maybe<Scalars['String']>
  cardLastDigits: Maybe<Scalars['String']>
  cardholderName: Maybe<Scalars['String']>
}

export type CustomerAdjustmentInvalidDataType = {
  minimumSatangs: Maybe<Scalars['Int']>
  remainingMinimumSatangs: Maybe<Scalars['Int']>
}

export type CustomerAdjustmentPromotion = {
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  memberTierRankConditions: Maybe<Array<Maybe<PromotionMemberTierRankConditionEnumType>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  targetEntity: Maybe<AdjustmentTargetEntityTypeEnum>
  type: Maybe<PromotionTypeEnum>
}

export type CustomerAdjustmentType = {
  adjustmentType: Maybe<AdjustmentTypeEnumType>
  amountSatangs: Maybe<Scalars['Int']>
  externalCoupon: Maybe<ExternalCouponType>
  externalCouponID: Maybe<Scalars['Int']>
  fullAmountSatangs: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  invalidData: Maybe<CustomerAdjustmentInvalidDataType>
  invalidReason: Maybe<AdjustmentInvalidReasonEnumType>
  labelEn: Maybe<Scalars['String']>
  labelTh: Maybe<Scalars['String']>
  memberCoupon: Maybe<MemberCouponType>
  memberCouponID: Maybe<Scalars['Int']>
  promotion: Maybe<CustomerAdjustmentPromotion>
  promotionID: Maybe<Scalars['Int']>
  source: Maybe<AdjustmentSourceEnumType>
}

export type CustomerCartInput = {
  containerReturnInfo?: InputMaybe<ContainerReturnInfoInputType>
  /** Delivery date in format YYYY-MM-DD */
  deliveryDate?: InputMaybe<Scalars['String']>
  deliveryTimeslot?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  lineChannelID?: InputMaybe<Scalars['String']>
  lineItems?: InputMaybe<Array<InputMaybe<CustomerCartLineItemInput>>>
  locationID?: InputMaybe<Scalars['Int']>
  paymentPhone?: InputMaybe<Scalars['String']>
  paymentType?: InputMaybe<CustomerPaymentTypeEnum>
  pickUp?: InputMaybe<Scalars['Boolean']>
  requestUtensils?: InputMaybe<Scalars['Boolean']>
  returnContainer?: InputMaybe<Scalars['Boolean']>
  shippingAddressID?: InputMaybe<Scalars['Int']>
  specialInstructions?: InputMaybe<Scalars['String']>
  taxInvoice?: InputMaybe<InvoiceInput>
}

export type CustomerCartLineItemInput = {
  customNotes?: InputMaybe<Scalars['String']>
  modifiers?: InputMaybe<Array<InputMaybe<CustomerCartLineItemModifierInput>>>
  productID: Scalars['Int']
  quantity: Scalars['Int']
  useContainer?: InputMaybe<Scalars['Boolean']>
}

export type CustomerCartLineItemModifierInput = {
  modifierID: Scalars['Int']
  quantity: Scalars['Int']
}

export type CustomerCartLineItemType = {
  containerType: Maybe<ProductContainerTypeEnum>
  customNotes: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  invalids: Maybe<Array<Maybe<LineItemInvalidEnum>>>
  /** ID of submitting line item */
  lineItemBatchID: Maybe<Scalars['Int']>
  modifiers: Maybe<Array<Maybe<LineItemModifierType>>>
  pricePerUnitSatangs: Maybe<Scalars['Int']>
  product: Maybe<ProductType>
  productID: Maybe<Scalars['Int']>
  quantity: Maybe<Scalars['Int']>
  totalSatangs: Maybe<Scalars['Int']>
  useContainer: Maybe<Scalars['Boolean']>
}

export type CustomerCartResultType = {
  cart: Maybe<CustomerCartType>
  /** Invalid states of the cart */
  cartInvalids: Maybe<Array<Maybe<CartInvalidEnum>>>
  /** Corrections automatically applied to the cart cart */
  corrections: Maybe<Array<Maybe<CartCorrectionEnum>>>
  /** Invalids when adding promotion to cart */
  promotionInvalids: Maybe<Array<Maybe<CartPromotionInvalidEnum>>>
}

export type CustomerCartType = {
  /** Sum of cash voucher amount */
  adjustmentCashTotalSatangs: Maybe<Scalars['Int']>
  /** Sum of staff cash amount */
  adjustmentStaffCashTotalSatangs: Maybe<Scalars['Int']>
  /** Sum of discount amount */
  adjustmentTotalSatangs: Maybe<Scalars['Int']>
  adjustments: Maybe<Array<Maybe<CustomerAdjustmentType>>>
  containerReturnInfo: Maybe<OrderContainerReturnInfo>
  deliverableDays: Maybe<Array<Maybe<DeliverableDayType>>>
  /** Delivery date in format YYYY-MM-DD */
  deliveryDate: Maybe<Scalars['String']>
  deliveryDistanceM: Maybe<Scalars['Int']>
  deliveryTimeslot: Maybe<Scalars['String']>
  /** Sum of deposit amount */
  depositTotalSatangs: Maybe<Scalars['Int']>
  estDeliveryMins: Maybe<Scalars['Int']>
  /** If the order is excluding vat this field will be true */
  excludingVat: Maybe<Scalars['Boolean']>
  hasContainer: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  lineChannelID: Maybe<Scalars['String']>
  lineItemTotalExcludingVatSatangs: Maybe<Scalars['Int']>
  lineItemTotalSatangs: Maybe<Scalars['Int']>
  /** Value from VAT 7 percents from total. */
  lineItemVatSatangs: Maybe<Scalars['Int']>
  lineItems: Maybe<Array<Maybe<CustomerCartLineItemType>>>
  /** Vendor location or branch */
  location: Maybe<LocationType>
  /** Vendor location ID */
  locationID: Maybe<Scalars['Int']>
  memberTransaction: Maybe<OrderMemberTransactionType>
  /**
   * Deprecated
   * @deprecated Use memberTransaction field instead
   */
  membership: Maybe<OrderMembershipType>
  omiseCardBrand: Maybe<Scalars['String']>
  omiseCardID: Maybe<Scalars['String']>
  omiseCardLastDigits: Maybe<Scalars['String']>
  omiseCardholderName: Maybe<Scalars['String']>
  paymentPhone: Maybe<Scalars['String']>
  paymentType: Maybe<CustomerPaymentTypeEnum>
  pickUp: Maybe<Scalars['Boolean']>
  requestUtensils: Maybe<Scalars['Boolean']>
  returnContainer: Maybe<Scalars['Boolean']>
  shippingAddress: Maybe<Address>
  shippingAddressID: Maybe<Scalars['Int']>
  shippingTotalSatangs: Maybe<Scalars['Int']>
  specialInstructions: Maybe<Scalars['String']>
  taxInvoice: Maybe<OrderTaxInvoiceType>
  /** Total amount after cash voucher */
  totalDueSatangs: Maybe<Scalars['Int']>
  totalQuantity: Maybe<Scalars['Int']>
  totalSatangs: Maybe<Scalars['Int']>
  /** Everything including deposit amount */
  totalWithDepositSatangs: Maybe<Scalars['Int']>
}

export type CustomerLocationProduct = {
  active: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  /** Returns null if product doesn't track stock or still got plenty in stock. Returns 0 if out of stock. Returns a number if it's low on stock. */
  inStockQuantity: Maybe<Scalars['Int']>
  locationID: Maybe<Scalars['Int']>
  productID: Maybe<Scalars['Int']>
}

export type CustomerOrderCheckoutType = {
  KPaymentResult: Maybe<KPaymentResultType>
  /** Invalid states of the cart */
  cartInvalids: Maybe<Array<Maybe<CartInvalidEnum>>>
  /** Charge Result for Omise payment method */
  chargeResult: Maybe<OmiseChargeType>
  /** Corrections automatically applied to the cart cart */
  corrections: Maybe<Array<Maybe<CartCorrectionEnum>>>
  order: Maybe<CustomerOrderType>
}

export type CustomerOrderFilter = {
  /** Order status must be in this list */
  statusList?: InputMaybe<Array<InputMaybe<OrderStatusEnum>>>
  /** Filter order by type */
  type?: InputMaybe<OrderTypeEnum>
  vendorID?: InputMaybe<Scalars['Int']>
}

export type CustomerOrderType = {
  /** Sum of cash voucher amount */
  adjustmentCashTotalSatangs: Maybe<Scalars['Int']>
  /** Sum of staff cash amount */
  adjustmentStaffCashTotalSatangs: Maybe<Scalars['Int']>
  /** Sum of discount amount */
  adjustmentTotalSatangs: Maybe<Scalars['Int']>
  /** Discounts and promotions */
  adjustments: Maybe<Array<Maybe<CustomerAdjustmentType>>>
  deliveryDate: Maybe<Scalars['String']>
  deliveryDistanceM: Maybe<Scalars['Int']>
  /** If deliveryTimeslot is ASAP, this value will be estimate delivery time */
  deliveryTime: Maybe<Scalars['String']>
  deliveryTimeslot: Maybe<Scalars['String']>
  /** Sum of deposit amount */
  depositTotalSatangs: Maybe<Scalars['Int']>
  estDeliveryMins: Maybe<Scalars['Int']>
  /** If the order is excluding vat this field will be true */
  excludingVat: Maybe<Scalars['Boolean']>
  hasContainer: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  lineItemTotalExcludingVatSatangs: Maybe<Scalars['Int']>
  lineItemTotalSatangs: Maybe<Scalars['Int']>
  /** Value from VAT 7 percents from total. */
  lineItemVatSatangs: Maybe<Scalars['Int']>
  lineItems: Maybe<Array<Maybe<CustomerCartLineItemType>>>
  location: Maybe<LocationType>
  locationID: Maybe<Scalars['Int']>
  membership: Maybe<OrderMembershipType>
  /** Order number */
  number: Maybe<Scalars['String']>
  payments: Maybe<Array<Maybe<PaymentType>>>
  /** Placed-At time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  placedAt: Maybe<Scalars['String']>
  refunds: Maybe<Array<Maybe<RefundType>>>
  returnContainer: Maybe<Scalars['Boolean']>
  shippingAddress: Maybe<Address>
  shippingAddressID: Maybe<Scalars['Int']>
  shippingTotalSatangs: Maybe<Scalars['Int']>
  specialInstructions: Maybe<Scalars['String']>
  status: Maybe<OrderStatusEnum>
  taxInvoice: Maybe<OrderTaxInvoiceType>
  /** Total amount after cash voucher */
  totalDueSatangs: Maybe<Scalars['Int']>
  totalQuantity: Maybe<Scalars['Int']>
  totalSatangs: Maybe<Scalars['Int']>
  /** Sum of everything plus deposit amount */
  totalWithDepositSatangs: Maybe<Scalars['Int']>
  type: Maybe<OrderTypeEnum>
  user: Maybe<LiffUser>
  userID: Maybe<Scalars['Int']>
}

export type CustomerOrderWithPaginationType = {
  orders: Maybe<Array<Maybe<CustomerOrderType>>>
  pagination: Maybe<PaginationType>
}

export type CustomerPaymentInput = {
  /** Phone Number (For Omise Truemoney Wallet or KPayment PayPlus. This is optional, required if payment type is OmiseTrueMoneyWallet, KPaymentKPlusNumber or KPaymentPromptPayNumber) */
  phoneNumber?: InputMaybe<Scalars['String']>
  /** Return URI after checking out (This is optional, default value is empty) */
  returnURI?: InputMaybe<Scalars['String']>
  /** Payment Type (Cash|CashOnDelivery|BankTransfer|LinePay|PromptPay|OmiseCreditCard|SCBPromptPay|OmisePromptPay|OmiseTrueMoneyWallet|KPaymentKPlusNumber|KPaymentPromptPayNumber) */
  type: CustomerPaymentTypeEnum
}

export enum CustomerPaymentTypeEnum {
  BankTransfer = 'BankTransfer',
  CashOnDelivery = 'CashOnDelivery',
  KBankThaiQr = 'KBankThaiQR',
  KPaymentKPlusNumber = 'KPaymentKPlusNumber',
  KPaymentPromptPayNumber = 'KPaymentPromptPayNumber',
  LinePay = 'LinePay',
  None = 'None',
  OmiseCreditCard = 'OmiseCreditCard',
  OmisePromptPay = 'OmisePromptPay',
  OmiseTrueMoneyWallet = 'OmiseTrueMoneyWallet',
  ScbPromptPay = 'SCBPromptPay',
}

export type DeliverableDayType = {
  /** Date in format YYYY-MM-DD */
  date: Maybe<Scalars['String']>
  /** "ASAP" or timeslot in format "11:00 - 12:00" in sorted order */
  timeslots: Maybe<Array<Maybe<Scalars['String']>>>
}

export type DeliveryFeeAndTime = {
  cookingMins: Maybe<Scalars['Int']>
  deliverableDays: Maybe<Array<Maybe<DeliverableDayType>>>
  feeSatangs: Maybe<Scalars['Int']>
  totalDeliveryMins: Maybe<Scalars['Int']>
  transportMins: Maybe<Scalars['Int']>
}

export enum DeliveryMethodEnum {
  AtDoor = 'AtDoor',
  AtGuard = 'AtGuard',
  AtLobby = 'AtLobby',
  AtReception = 'AtReception',
  ToPerson = 'ToPerson',
}

export enum DietaryRestrictionEnum {
  Chicken = 'Chicken',
  Egg = 'Egg',
  Fish = 'Fish',
  Milk = 'Milk',
  Nut = 'Nut',
  Soy = 'Soy',
  Vegetarian = 'Vegetarian',
}

export enum ExternalCouponRewardTypeCodeEnum {
  Ca = 'CA',
  Co = 'CO',
  Dc = 'DC',
  Pv = 'PV',
  Rc = 'RC',
  Tk = 'TK',
  Vc = 'VC',
}

export enum ExternalCouponRewardTypeEnum {
  Cash = 'Cash',
  Discount = 'Discount',
}

export type ExternalCouponType = {
  amount: Maybe<Scalars['Int']>
  code: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  endTime: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  imageURL: Maybe<Scalars['String']>
  isAvailable: Maybe<Scalars['Boolean']>
  memberCouponRefID: Maybe<Scalars['String']>
  membershipID: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  rewardType: Maybe<ExternalCouponRewardTypeEnum>
  rewardTypeCode: Maybe<ExternalCouponRewardTypeCodeEnum>
  serialNumber: Maybe<Scalars['String']>
  staffPrivilegeType: Maybe<StaffPrivilegeTypeTypeEnum>
  startTime: Maybe<Scalars['String']>
  status: Maybe<Scalars['String']>
  thumbnailImageURL: Maybe<Scalars['String']>
}

export type FeaturedPromotionType = {
  /** If any, when the promotion is active and there is an invalid */
  adjustmentInvalidData: Maybe<CustomerAdjustmentInvalidDataType>
  /** If any, when the promotion is active and there is an invalid */
  adjustmentInvalidReason: Maybe<AdjustmentInvalidReasonEnumType>
  /** If the promotion is active */
  isApplied: Maybe<Scalars['Boolean']>
  memberTierRankConditions: Maybe<Array<Maybe<PromotionMemberTierRankConditionEnumType>>>
  promotionDescriptionEn: Maybe<Scalars['String']>
  promotionDescriptionTh: Maybe<Scalars['String']>
  promotionID: Maybe<Scalars['Int']>
  promotionNameEn: Maybe<Scalars['String']>
  promotionNameTh: Maybe<Scalars['String']>
  targetEntity: Maybe<AdjustmentTargetEntityTypeEnum>
}

export type FeedbackInput = {
  /** Feedback ID */
  id?: InputMaybe<Scalars['Int']>
  /** Feedback issue type list */
  issueTypes?: InputMaybe<Array<InputMaybe<FeedbackIssueTypeEnum>>>
  /** Feedback Message */
  message: Scalars['String']
  /** Order ID */
  orderID?: InputMaybe<Scalars['Int']>
  /** Rating (1-5) */
  rating: Scalars['Int']
}

export enum FeedbackIssueTypeEnum {
  Correctness = 'Correctness',
  Delivery = 'Delivery',
  DineInClean = 'DineInClean',
  DineInFoodQuality = 'DineInFoodQuality',
  DineInOrdering = 'DineInOrdering',
  DineInServiceSpeed = 'DineInServiceSpeed',
  DineInServiceStaff = 'DineInServiceStaff',
  FoodQuality = 'FoodQuality',
  Ordering = 'Ordering',
  ServiceSpeed = 'ServiceSpeed',
  Taste = 'Taste',
}

export type FeedbackType = {
  id: Maybe<Scalars['Int']>
  issueTypes: Maybe<Array<Maybe<Scalars['String']>>>
  message: Maybe<Scalars['String']>
  orderID: Maybe<Scalars['Int']>
  rating: Maybe<Scalars['Int']>
  userID: Maybe<Scalars['Int']>
}

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male',
}

export type GeolocationInput = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

/** HHMembershipPromotionType defines type membership promotion type */
export type HhMembershipPromotionType = {
  active: Maybe<Scalars['Boolean']>
  conditionDetailsEn: Maybe<Scalars['String']>
  conditionDetailsTh: Maybe<Scalars['String']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  /** End time (exclusive) in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  endTime: Maybe<Scalars['String']>
  extPromotionID: Maybe<Scalars['String']>
  /** For showing as QR for POS */
  hashedID: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<HhPromotionImage>>>
  ineligibleReasons: Maybe<Array<Maybe<IneligibleReasonEnumType>>>
  membershipProgramID: Maybe<Scalars['Int']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  points: Maybe<Scalars['Int']>
  position: Maybe<Scalars['Int']>
  priority: Maybe<Scalars['Int']>
  /** Start time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  startTime: Maybe<Scalars['String']>
  type: Maybe<MembershipPromotionTypeEnum>
}

export enum HhMerchantErrorEnumType {
  ErrInvalidBranchRefId = 'ErrInvalidBranchRefID',
  ErrInvalidCouponId = 'ErrInvalidCouponID',
  ErrInvalidPromotion = 'ErrInvalidPromotion',
  ErrInvalidPromotionId = 'ErrInvalidPromotionID',
  ErrInvalidTransactionId = 'ErrInvalidTransactionID',
  ErrMemberNotFound = 'ErrMemberNotFound',
  ErrMembershipPointsNotEnough = 'ErrMembershipPointsNotEnough',
  ErrMembershipSubscriptionRequired = 'ErrMembershipSubscriptionRequired',
}

export enum HhPointRedemptionFailureReasonEnum {
  Expired = 'Expired',
  IneligibleTierRank = 'IneligibleTierRank',
  InsufficientPoints = 'InsufficientPoints',
  MaxRedemption = 'MaxRedemption',
  MaxRedemptionPerCustomer = 'MaxRedemptionPerCustomer',
  NotActive = 'NotActive',
  NotYetStarted = 'NotYetStarted',
}

export type HhPromotionImage = {
  resizableURL: Maybe<Scalars['String']>
}

export enum HealthGoalEnum {
  Balanced = 'Balanced',
  Excreting = 'Excreting',
  Muscle = 'Muscle',
  WeightLoss = 'WeightLoss',
}

export type ImageType = {
  alt: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  /** Original URL of the media */
  originalURL: Maybe<Scalars['String']>
  /** For sorting images in collection */
  position: Maybe<Scalars['Int']>
  /** Image URL with {width} and {height} placeholder. Just replace those with actual value in pixel and image will get resized automatically. */
  resizableURL: Maybe<Scalars['String']>
  /** Thumbnail original URL of the media */
  thumbnailOriginalURL: Maybe<Scalars['String']>
  /** Thumbnail image URL with {width} and {height} placeholder. Just replace those with actual value in pixel and image will get resized automatically. */
  thumbnailResizableURL: Maybe<Scalars['String']>
  /** Thumbnail Image path (for video) in S3 */
  thumbnailUploadFile: Maybe<Scalars['String']>
  type: Maybe<ImageTypeEnum>
  /** Image path in S3 */
  uploadFile: Maybe<Scalars['String']>
}

export enum ImageTypeEnum {
  Cover = 'Cover',
  Horizontal = 'Horizontal',
  HorizontalThumbnail = 'HorizontalThumbnail',
  Thumbnail = 'Thumbnail',
  Vertical = 'Vertical',
  VerticalThumbnail = 'VerticalThumbnail',
}

export enum IneligibleReasonEnumType {
  IneligibleTierRank = 'IneligibleTierRank',
  InsufficientPoints = 'InsufficientPoints',
  MaxRedemption = 'MaxRedemption',
  MaxRedemptionPerCustomer = 'MaxRedemptionPerCustomer',
}

export type Ingredient = {
  id: Maybe<Scalars['Int']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
}

export enum InvalidModifierEnum {
  Inactive = 'Inactive',
  InsufficientStock = 'InsufficientStock',
  OutOfStock = 'OutOfStock',
  ProductInactive = 'ProductInactive',
}

export type InvoiceInput = {
  address?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  taxID?: InputMaybe<Scalars['String']>
}

export enum KPaymentResultFailureCodeEnum {
  InvalidPhone = 'InvalidPhone',
  ProcessingError = 'ProcessingError',
}

export enum KPaymentResultStatusEnum {
  Fail = 'fail',
  Success = 'success',
}

export type KPaymentResultType = {
  failureCode: Maybe<KPaymentResultFailureCodeEnum>
  failureMessage: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  status: Maybe<KPaymentResultStatusEnum>
}

export type KitchenStationBackupType = {
  id: Maybe<Scalars['Int']>
  kitchenStationBackup: Maybe<KitchenStationType>
  kitchenStationBackupID: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  printerIP: Maybe<Scalars['String']>
  printerName: Maybe<Scalars['String']>
  vendorID: Maybe<Scalars['Int']>
}

export type KitchenStationType = {
  id: Maybe<Scalars['Int']>
  kitchenStationBackupID: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  printerIP: Maybe<Scalars['String']>
  printerName: Maybe<Scalars['String']>
  vendorID: Maybe<Scalars['Int']>
}

export enum LanguageEnum {
  En = 'en',
  Th = 'th',
}

export type LiffUser = {
  activityLevel: Maybe<ActivityLevelEnum>
  bmi: Maybe<Scalars['Float']>
  caloriesPerMeal: Maybe<Scalars['Float']>
  dateOfBirth: Maybe<Scalars['String']>
  defaultPaymentMethod: Maybe<CustomerPaymentTypeEnum>
  defaultPaymentPhone: Maybe<Scalars['String']>
  dietaryRestrictions: Maybe<Array<Maybe<DietaryRestrictionEnum>>>
  email: Maybe<Scalars['String']>
  extMembershipPhone: Maybe<Scalars['String']>
  faceImage: Maybe<ImageType>
  gender: Maybe<GenderEnum>
  hasPlacedOrder: Maybe<Scalars['Boolean']>
  healthGoal: Maybe<HealthGoalEnum>
  heightCm: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  language: Maybe<Scalars['String']>
  lineDisplayName: Maybe<Scalars['String']>
  lineEmail: Maybe<Scalars['String']>
  /** Image URL for Line profile pic */
  linePicture: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  omiseCardBrand: Maybe<Scalars['String']>
  omiseCardID: Maybe<Scalars['String']>
  omiseCardLastDigits: Maybe<Scalars['String']>
  omiseCardholderName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  rememberCard: Maybe<Scalars['Boolean']>
  spicyLevel: Maybe<SpicyLevelEnum>
  weightKg: Maybe<Scalars['Int']>
}

export enum LineItemInvalidEnum {
  ExceedMaxQuantity = 'ExceedMaxQuantity',
  Inactive = 'Inactive',
  InsufficientStock = 'InsufficientStock',
  InvalidModifiers = 'InvalidModifiers',
  MembershipRequired = 'MembershipRequired',
  ModifierExceedMaximumQuantity = 'ModifierExceedMaximumQuantity',
  ModifierRequired = 'ModifierRequired',
  OutOfStock = 'OutOfStock',
  ServiceNotAllowed = 'ServiceNotAllowed',
  TierBirthMonthRequired = 'TierBirthMonthRequired',
  TierIneligible = 'TierIneligible',
}

export type LineItemModifierType = {
  id: Maybe<Scalars['Int']>
  modifier: Maybe<Modifier>
  modifierID: Maybe<Scalars['Int']>
  pricePerUnitSatangs: Maybe<Scalars['Int']>
  quantity: Maybe<Scalars['Int']>
  totalSatangs: Maybe<Scalars['Int']>
}

export type ListMemberCouponsResultType = {
  memberCoupons: Maybe<Array<Maybe<MemberCouponType>>>
  pagination: Maybe<PaginationType>
}

export type ListProductsForLiffv2Result = {
  pagination: Maybe<PaginationType>
  products: Maybe<Array<Maybe<ProductType>>>
}

export type LocationType = {
  active: Maybe<Scalars['Boolean']>
  /** Deprecated. Use other fields such as name, latitude and longitude instead. */
  address: Maybe<Address>
  addressDetailEn: Maybe<Scalars['String']>
  addressDetailTh: Maybe<Scalars['String']>
  /** address label is address label in Thai. We want to make it backward compatible. */
  addressLabel: Maybe<Scalars['String']>
  addressLabelEn: Maybe<Scalars['String']>
  /** The approximate distance (displacement) of location from specified lat/lng. This is available only in certain APIs */
  approxDeliveryDistanceM: Maybe<Scalars['Int']>
  businessHours: Maybe<Array<Maybe<BusinessHourType>>>
  deliveryContactPhone: Maybe<Scalars['String']>
  /** floors is in Thai because we want to make it backward compatiblea. */
  floors: Maybe<Scalars['String']>
  floorsEn: Maybe<Scalars['String']>
  geocodedAddressEn: Maybe<Scalars['String']>
  geocodedAddressTh: Maybe<Scalars['String']>
  geocodedDistrictEn: Maybe<Scalars['String']>
  geocodedDistrictTh: Maybe<Scalars['String']>
  geocodedPostcode: Maybe<Scalars['String']>
  geocodedProvinceEn: Maybe<Scalars['String']>
  geocodedProvinceTh: Maybe<Scalars['String']>
  geocodedSubdistrictEn: Maybe<Scalars['String']>
  geocodedSubdistrictTh: Maybe<Scalars['String']>
  googlePlaceID: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  /** Is the specified lat/lng in delivery area? */
  isInDeliveryArea: Maybe<Scalars['Boolean']>
  latitude: Maybe<Scalars['Float']>
  longitude: Maybe<Scalars['Float']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  shippingFeePolicy: Maybe<ShippingFeePolicyEnum>
  vendor: Maybe<VendorType>
  vendorID: Maybe<Scalars['Int']>
}

export type MemberCouponPromotionType = {
  conditionDetailsEn: Maybe<Scalars['String']>
  conditionDetailsTh: Maybe<Scalars['String']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  extPromotionID: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<CouponImageType>>>
  isSelfRedeemableCoupon: Maybe<Scalars['Boolean']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
}

export type MemberCouponQrCodeType = {
  couponCode: Maybe<Scalars['String']>
  durationSeconds: Maybe<Scalars['Int']>
  qrCode: Maybe<Scalars['String']>
}

export enum MemberCouponStatusEnumType {
  Active = 'Active',
  Expired = 'Expired',
  PendingStart = 'PendingStart',
  Redeemed = 'Redeemed',
}

export type MemberCouponType = {
  code: Maybe<Scalars['String']>
  /** created at in RFC3339 format "2019-02-21T04:21:03+07:00". */
  createdAt: Maybe<Scalars['String']>
  /** end date in "2006-01-02" format in Asia/Bangkok timezone */
  endDate: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  promotion: Maybe<MemberCouponPromotionType>
  /** redeemed at in RFC3339 format "2019-02-21T04:21:03+07:00" in Asia/Bangkok timezone */
  redeemedAt: Maybe<Scalars['String']>
  /** start date in "2006-01-02" format in Asia/Bangkok timezone */
  startDate: Maybe<Scalars['String']>
  status: Maybe<MemberCouponStatusEnumType>
}

export enum MemberProductIneligibleReasonEnum {
  MembershipRequired = 'MembershipRequired',
  TierBirthMonthRequired = 'TierBirthMonthRequired',
  TierIneligible = 'TierIneligible',
}

export enum MembershipCreateEnumType {
  AlreadyMember = 'AlreadyMember',
  DuplicatedPhone = 'DuplicatedPhone',
  OtpFailed = 'OTPFailed',
  Success = 'Success',
}

export enum MembershipGenderEnum {
  Female = 'Female',
  Male = 'Male',
  Unknown = 'Unknown',
}

export type MembershipHhCreateType = {
  membership: Maybe<MembershipType>
  status: Maybe<MembershipCreateEnumType>
}

export type MembershipHhLinkType = {
  membership: Maybe<MembershipType>
  status: Maybe<MembershipLinkEnumType>
}

export type MembershipHhLookUpType = {
  membership: Maybe<UnlinkedMembership>
  status: Maybe<MembershipLookUpEnumType>
}

export type MembershipHhUnlinktype = {
  status: Maybe<MembershipUnlinkEnum>
}

export type MembershipHhUpdateType = {
  membership: Maybe<MembershipType>
  status: Maybe<MembershipUpdateEnumType>
}

export enum MembershipLinkEnumType {
  AlreadyMember = 'AlreadyMember',
  DuplicatedPhone = 'DuplicatedPhone',
  OtpFailed = 'OTPFailed',
  /** Response in Mengnuanua physical card linking */
  QrCodeAlreadyLinked = 'QRCodeAlreadyLinked',
  Success = 'Success',
  UnlinkedMemberFound = 'UnlinkedMemberFound',
  UnlinkedMemberNotFound = 'UnlinkedMemberNotFound',
}

export enum MembershipLookUpEnumType {
  DuplicatedPhone = 'DuplicatedPhone',
  /** Response in Mengnuanua physical card linking */
  QrCodeAlreadyLinked = 'QRCodeAlreadyLinked',
  UnlinkedMemberFound = 'UnlinkedMemberFound',
  UnlinkedMemberNotFound = 'UnlinkedMemberNotFound',
}

export type MembershipPhoneAvailableType = {
  /** Flag to determine that membership phone is available */
  isPhoneAvailable: Maybe<Scalars['Boolean']>
  /** This is true when the phone is available but there are some other members have that phone number. This is possible when their phone number are not yet verified. */
  memberExists: Maybe<Scalars['Boolean']>
  /** Phone number */
  phone: Maybe<Scalars['String']>
}

export type MembershipProgramPublicType = {
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  isSubscriptionRequired: Maybe<Scalars['Boolean']>
  logoImage: Maybe<ImageType>
  membershipProvider: Maybe<MembershipProviderEnum>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  pointPeriodCalendarMonths: Maybe<Scalars['Int']>
  pointPeriodCalendarYears: Maybe<Scalars['Int']>
  tiers: Maybe<Array<Maybe<MembershipTierSettingPublicType>>>
}

export type MembershipProgramSubscriptionOptionType = {
  coverImage: Maybe<ImageType>
  coverImageID: Maybe<Scalars['Int']>
  durationMonths: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  membershipProgramID: Maybe<Scalars['Int']>
  plan: Maybe<Scalars['String']>
  priceSatangs: Maybe<Scalars['Int']>
  sku: Maybe<Scalars['String']>
  titleEn: Maybe<Scalars['String']>
  titleTh: Maybe<Scalars['String']>
}

export type MembershipPromotionListType = {
  membershipPromotions: Maybe<Array<Maybe<HhMembershipPromotionType>>>
  pagination: Maybe<PaginationType>
}

export enum MembershipPromotionTypeEnum {
  Auto = 'Auto',
  Coupon = 'Coupon',
  PointRedemptionForCoupon = 'PointRedemptionForCoupon',
  PointRedemptionWithPurchase = 'PointRedemptionWithPurchase',
}

export enum MembershipProviderEnum {
  FoodPassion = 'FoodPassion',
  HatoHeart = 'HatoHeart',
  Maguro = 'Maguro',
}

export type MembershipQrCodeType = {
  durationSeconds: Maybe<Scalars['Int']>
  qrCode: Maybe<Scalars['String']>
}

export type MembershipSubscriptionType = {
  durationMonths: Maybe<Scalars['Int']>
  endDate: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  membershipID: Maybe<Scalars['Int']>
  membershipProgramID: Maybe<Scalars['Int']>
  membershipProgramSubscriptionOption: Maybe<MembershipProgramSubscriptionOptionType>
  membershipProgramSubscriptionOptionID: Maybe<Scalars['Int']>
  note: Maybe<Scalars['String']>
  startDate: Maybe<Scalars['String']>
  transactionID: Maybe<Scalars['Int']>
}

/** MembershipTierDetail is type membership tier detail */
export type MembershipTierDetail = {
  endDate: Maybe<Scalars['String']>
  pointEarned: Maybe<Scalars['Int']>
  pointRequired: Maybe<Scalars['Int']>
  rank: Maybe<Scalars['Int']>
  tier: Maybe<Scalars['String']>
}

/** MembershipTierProgressType is type of membership tier progress */
export type MembershipTierProgressType = {
  keepTier: Maybe<MembershipTierDetail>
  upTier: Maybe<MembershipTierDetail>
}

export type MembershipTierSettingPublicType = {
  cardImage: Maybe<ImageType>
  id: Maybe<Scalars['Int']>
  rank: Maybe<Scalars['Int']>
  refName: Maybe<Scalars['String']>
}

export type MembershipType = {
  allowPromoMsg: Maybe<Scalars['Boolean']>
  currentMembershipSubscription: Maybe<MembershipSubscriptionType>
  /** format YYYY-MM-DD */
  dateOfBirth: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  gender: Maybe<MembershipGenderEnum>
  id: Maybe<Scalars['Int']>
  isPhoneVerified: Maybe<Scalars['Boolean']>
  isSubscriptionActive: Maybe<Scalars['Boolean']>
  lastName: Maybe<Scalars['String']>
  legacyCardIDs: Maybe<Array<Maybe<Scalars['String']>>>
  memberID: Maybe<Scalars['String']>
  memberTier: Maybe<Scalars['String']>
  membershipProgramID: Maybe<Scalars['Int']>
  phone: Maybe<Scalars['String']>
  pointExpirations: Maybe<Array<Maybe<PointExpirationType>>>
  provider: Maybe<MembershipProviderEnum>
  /** format YYYY-MM-DD */
  subscriptionEndDate: Maybe<Scalars['String']>
  subscriptionPlan: Maybe<Scalars['String']>
  termsAccepted: Maybe<Scalars['Boolean']>
  totalPoints: Maybe<Scalars['Int']>
  userID: Maybe<Scalars['Int']>
}

export enum MembershipUnlinkEnum {
  MemberNotFound = 'MemberNotFound',
  Success = 'Success',
}

export enum MembershipUpdateEnumType {
  DuplicatedPhone = 'DuplicatedPhone',
  OtpFailed = 'OTPFailed',
  Success = 'Success',
}

export type Modifier = {
  active: Maybe<Scalars['Boolean']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  invalids: Maybe<Array<Maybe<InvalidModifierEnum>>>
  modifierGroup: Maybe<ModifierGroup>
  modifierGroupID: Maybe<Scalars['Int']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  priceSatangs: Maybe<Scalars['Int']>
  product: Maybe<ProductWithoutModifierType>
  productID: Maybe<Scalars['Int']>
}

export type ModifierGroup = {
  id: Maybe<Scalars['Int']>
  modifiers: Maybe<Array<Maybe<Modifier>>>
  multipleQuantity: Maybe<Scalars['Boolean']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  vendorID: Maybe<Scalars['Int']>
}

export type ModifierGroupWithProduct = {
  id: Maybe<Scalars['Int']>
  multipleQuantity: Maybe<Scalars['Boolean']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  vendorID: Maybe<Scalars['Int']>
}

export type NutritionType = {
  calories: Maybe<Scalars['Float']>
  caloriesFromFat: Maybe<Scalars['Float']>
  carbG: Maybe<Scalars['Float']>
  cholesterolMg: Maybe<Scalars['Float']>
  fatG: Maybe<Scalars['Float']>
  fiberG: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Int']>
  proteinG: Maybe<Scalars['Float']>
  saturatedFatG: Maybe<Scalars['Float']>
  servingsPerItem: Maybe<Scalars['Float']>
  sodiumMg: Maybe<Scalars['Float']>
  sugarsG: Maybe<Scalars['Float']>
  transFatG: Maybe<Scalars['Float']>
}

export type OmiseChargeType = {
  /** Authorize URI for Omise TrueMoneyWallet payment type */
  authorizeURI: Maybe<Scalars['String']>
  /** Charge ID from Omise */
  chargeID: Maybe<Scalars['String']>
  /** Failure Code from Omise */
  failureCode: Maybe<Scalars['String']>
  /** Failure Message from Omise */
  failureMessage: Maybe<Scalars['String']>
  /** Charge Status from Omise (pending|successful|failed|reversed) */
  status: Maybe<Scalars['String']>
}

export type OmiseTokenType = {
  /** Card brand eg. VISA */
  brand: Maybe<Scalars['String']>
  /** Token Card ID */
  id: Maybe<Scalars['String']>
  /** Card Last 4 Digits */
  lastDigits: Maybe<Scalars['String']>
}

export type OrderContainerReturnInfo = {
  daysTillReturned: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  orderID: Maybe<Scalars['Int']>
  returnDate: Maybe<Scalars['String']>
  scheduledReturnDate: Maybe<Scalars['String']>
  scheduledReturnTimeslot: Maybe<Scalars['String']>
  status: Maybe<OrderContainerReturnStatusEnum>
  userID: Maybe<Scalars['Int']>
}

export enum OrderContainerReturnStatusEnum {
  Deposited = 'Deposited',
  ReturnScheduled = 'ReturnScheduled',
  Returned = 'Returned',
  RiderDispatched = 'RiderDispatched',
}

export type OrderMemberTransactionType = {
  /** earned at time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  earnedAt: Maybe<Scalars['String']>
  earnedPoints: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  membership: Maybe<OrderMembershipType>
}

export type OrderMembershipType = {
  /** earned at time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  earnedAt: Maybe<Scalars['String']>
  earnedPoints: Maybe<Scalars['Int']>
  firstName: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  lastName: Maybe<Scalars['String']>
  memberID: Maybe<Scalars['String']>
  memberTier: Maybe<Scalars['String']>
  orderID: Maybe<Scalars['Int']>
  phone: Maybe<Scalars['String']>
  provider: Maybe<MembershipProviderEnum>
  totalPoints: Maybe<Scalars['Int']>
  userID: Maybe<Scalars['Int']>
}

export enum OrderServiceTypeConditionEnum {
  Buffet = 'Buffet',
  DineInAndTakeaway = 'DineInAndTakeaway',
  HatoDelivery = 'HatoDelivery',
  Pickup = 'Pickup',
}

export enum OrderStatusEnum {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Confirmed = 'Confirmed',
  Cooking = 'Cooking',
  OutForDelivery = 'OutForDelivery',
  PartiallyRefunded = 'PartiallyRefunded',
  Placed = 'Placed',
  ReadyForDelivery = 'ReadyForDelivery',
  ReadyToCook = 'ReadyToCook',
  Refunded = 'Refunded',
}

export type OrderTaxInvoiceType = {
  address: Maybe<Scalars['String']>
  companyName: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  taxID: Maybe<Scalars['String']>
}

export enum OrderTypeEnum {
  DineInOrder = 'DineInOrder',
  Order = 'Order',
}

export enum OtpInvalidReasonEnum {
  ExceedMaxIntervalRequest = 'ExceedMaxIntervalRequest',
}

export type OtpType = {
  invalidReason: Maybe<OtpInvalidReasonEnum>
  phone: Maybe<Scalars['String']>
  smsProvider: Maybe<SmsProviderEnum>
  token: Maybe<Scalars['String']>
}

export type PaginationInputType = {
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type PaginationType = {
  count: Maybe<Scalars['Int']>
  limit: Maybe<Scalars['Int']>
  offset: Maybe<Scalars['Int']>
  total: Maybe<Scalars['Int']>
}

export type PaymentBankSlipImageType = {
  id: Maybe<Scalars['Int']>
  /** File path in S3 */
  uploadFile: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
}

export enum PaymentStatusEnum {
  Failed = 'Failed',
  Paid = 'Paid',
  PartiallyRefunded = 'PartiallyRefunded',
  PendingPayment = 'PendingPayment',
  PendingVerification = 'PendingVerification',
  Refunded = 'Refunded',
}

export type PaymentType = {
  amountSatangs: Maybe<Scalars['Int']>
  bankSlipImages: Maybe<Array<Maybe<PaymentBankSlipImageType>>>
  changeBackAmountSatangs: Maybe<Scalars['Int']>
  failureMessage: Maybe<Scalars['String']>
  failureReason: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  status: Maybe<PaymentStatusEnum>
  tenderedAmountSatangs: Maybe<Scalars['Int']>
  type: Maybe<PaymentTypeEnum>
}

export enum PaymentTypeEnum {
  BankTransfer = 'BankTransfer',
  Cash = 'Cash',
  CashOnDelivery = 'CashOnDelivery',
  Concession = 'Concession',
  CorpInvoice = 'CorpInvoice',
  KBankThaiQr = 'KBankThaiQR',
  KPaymentKPlusNumber = 'KPaymentKPlusNumber',
  KPaymentPromptPayNumber = 'KPaymentPromptPayNumber',
  LinePay = 'LinePay',
  None = 'None',
  OmiseCreditCard = 'OmiseCreditCard',
  OmisePromptPay = 'OmisePromptPay',
  OmiseTrueMoneyWallet = 'OmiseTrueMoneyWallet',
  PromptPay = 'PromptPay',
  ScbPromptPay = 'SCBPromptPay',
}

export type PointExpirationType = {
  /** format YYYY-MM-DD */
  expirationDate: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  points: Maybe<Scalars['Int']>
}

export type PointRedemptionCampaignConditionType = {
  type: Maybe<PointRedemptionCampaignConditionTypeEnum>
  value: Maybe<Scalars['String']>
}

export enum PointRedemptionCampaignConditionTypeEnum {
  MaxRedemptions = 'MaxRedemptions',
  MaxRedemptionsPerCustomer = 'MaxRedemptionsPerCustomer',
  TimeOfDay = 'TimeOfDay',
}

export type PointRedemptionCampaignConditionsType = {
  conditions: Maybe<Array<Maybe<PointRedemptionCampaignConditionType>>>
}

export type PointRedemptionCampaignType = {
  active: Maybe<Scalars['Boolean']>
  campaignRefID: Maybe<Scalars['String']>
  conditionDetailsEn: Maybe<Scalars['String']>
  conditionDetailsTh: Maybe<Scalars['String']>
  conditionMaxRedemptions: Maybe<Scalars['Int']>
  conditionMaxRedemptionsPerCustomer: Maybe<Scalars['Int']>
  /** Time of day in HH:MM format */
  conditionTimeOfDayEnd: Maybe<Scalars['String']>
  /** Time of day in HH:MM format */
  conditionTimeOfDayStart: Maybe<Scalars['String']>
  conditions: Maybe<PointRedemptionCampaignConditionsType>
  couponRefID: Maybe<Scalars['String']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  /** End time (exclusive) in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  endTime: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  invalidConditions: Maybe<Array<Maybe<PointRedemptionCampaignConditionTypeEnum>>>
  membershipProvider: Maybe<MembershipProviderEnum>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  points: Maybe<Scalars['Int']>
  position: Maybe<Scalars['Int']>
  rewardType: Maybe<PointRedemptionRewardTypeEnum>
  /** Start time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  startTime: Maybe<Scalars['String']>
  vendor: Maybe<VendorType>
  vendorID: Maybe<Scalars['Int']>
}

export enum PointRedemptionFailureReasonTypeEnum {
  Error = 'Error',
  InactiveCampaign = 'InactiveCampaign',
  InsufficientPoints = 'InsufficientPoints',
  InvalidConditions = 'InvalidConditions',
}

export enum PointRedemptionRewardTypeEnum {
  ExternalCoupon = 'ExternalCoupon',
}

export enum ProductColorSchemeEnum {
  BlueLagoon = 'BlueLagoon',
  ButterflyPeaLatte = 'ButterflyPeaLatte',
  Latte = 'Latte',
  MangoSmoothie = 'MangoSmoothie',
  Matcha = 'Matcha',
  MatchaLatte = 'MatchaLatte',
  Mojito = 'Mojito',
  Ovaltine = 'Ovaltine',
  SoyMilk = 'SoyMilk',
  StrawberrySmoothie = 'StrawberrySmoothie',
  ThaiTea = 'ThaiTea',
  ThaiTeaLatte = 'ThaiTeaLatte',
  TomatoJuice = 'TomatoJuice',
  Wheatgrass = 'Wheatgrass',
  WheatgrassSmoothie = 'WheatgrassSmoothie',
}

export enum ProductContainerTypeEnum {
  Generic = 'Generic',
}

export enum ProductMemberTierRankConditionsEnum {
  BirthMonth = 'BirthMonth',
  Eligible = 'Eligible',
  NotEligible = 'NotEligible',
}

export type ProductModifierConfig = {
  defaultQuantity: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  modifierID: Maybe<Scalars['Int']>
}

export type ProductModifierGroup = {
  active: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  maximumQuantity: Maybe<Scalars['Int']>
  minimumQuantity: Maybe<Scalars['Int']>
  modifierGroup: Maybe<ModifierGroup>
  modifierGroupID: Maybe<Scalars['Int']>
  position: Maybe<Scalars['Int']>
  productID: Maybe<Scalars['Int']>
}

export enum ProductTagEnum {
  BestSeller = 'BestSeller',
  Biodegradable = 'Biodegradable',
  BoostingImmuneSystem = 'BoostingImmuneSystem',
  CanBeGlutenFree = 'CanBeGlutenFree',
  CanBeJ = 'CanBeJ',
  CholesterolFree = 'CholesterolFree',
  Compostable = 'Compostable',
  ContainsEgg = 'ContainsEgg',
  ContainsFish = 'ContainsFish',
  ContainsProbiotic = 'ContainsProbiotic',
  ContainsShrimp = 'ContainsShrimp',
  DairyFree = 'DairyFree',
  DirectFromFarmers = 'DirectFromFarmers',
  Exclusive = 'Exclusive',
  FreeRange = 'FreeRange',
  FrequentlyPurchased = 'FrequentlyPurchased',
  GainingMuscle = 'GainingMuscle',
  GlutenFree = 'GlutenFree',
  GoodForBlood = 'GoodForBlood',
  GoodForBone = 'GoodForBone',
  GoodForBrain = 'GoodForBrain',
  GoodForEyes = 'GoodForEyes',
  GoodForSkin = 'GoodForSkin',
  HighAntioxidant = 'HighAntioxidant',
  HighCalcium = 'HighCalcium',
  HighFiber = 'HighFiber',
  HighIron = 'HighIron',
  HighOmegaFat = 'HighOmegaFat',
  HighProtein = 'HighProtein',
  HighVitaminA = 'HighVitaminA',
  HighVitaminB = 'HighVitaminB',
  HighVitaminC = 'HighVitaminC',
  HighVitaminD = 'HighVitaminD',
  Homemade = 'Homemade',
  ImprovingDigestion = 'ImprovingDigestion',
  JMenu = 'JMenu',
  Keto = 'Keto',
  LandSafe = 'LandSafe',
  Light = 'Light',
  LocallySourced = 'LocallySourced',
  LowCal = 'LowCal',
  LowCarb = 'LowCarb',
  LowFat = 'LowFat',
  LowGi = 'LowGI',
  LowSodium = 'LowSodium',
  LowSugar = 'LowSugar',
  LowUricAcid = 'LowUricAcid',
  New = 'New',
  NoAddedSugar = 'NoAddedSugar',
  NoMsg = 'NoMSG',
  NoPlastic = 'NoPlastic',
  NoPreservatives = 'NoPreservatives',
  NonGmo = 'NonGMO',
  OceanLifeSafe = 'OceanLifeSafe',
  OrganicIngredients = 'OrganicIngredients',
  Paleo = 'Paleo',
  PeanutFree = 'PeanutFree',
  PesticideFree = 'PesticideFree',
  PlantBased = 'PlantBased',
  RecentlyPurchased = 'RecentlyPurchased',
  Recommended = 'Recommended',
  RecycleablePackaging = 'RecycleablePackaging',
  Signature = 'Signature',
  SoyFree = 'SoyFree',
  SugarFree = 'SugarFree',
  SupportsLocalCommunity = 'SupportsLocalCommunity',
  Sustainable = 'Sustainable',
  SustainablyFarmed = 'SustainablyFarmed',
  Vegan = 'Vegan',
  Vegetarian = 'Vegetarian',
  WeightControl = 'WeightControl',
  YourFavorite = 'YourFavorite',
}

export type ProductType = {
  active: Maybe<Scalars['Boolean']>
  brand: Maybe<Scalars['String']>
  category: Maybe<Category>
  categoryID: Maybe<Scalars['Int']>
  /** Color scheme set to this product. This does not consider its modifiers and category. For display, use derivedColorScheme. */
  colorScheme: Maybe<ProductColorSchemeEnum>
  /** Label or guide for users to input custom notes. */
  customNotesLabelEn: Maybe<Scalars['String']>
  /** Label or guide for users to input custom notes. */
  customNotesLabelTh: Maybe<Scalars['String']>
  /** Color scheme of product for display. The value is derived from the color scheme of product itself, its modifiers and its category. */
  derivedColorScheme: Maybe<ProductColorSchemeEnum>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  /** Amount to discount from actual total price. Note that this should be positive amount. */
  discountSatangs: Maybe<Scalars['Int']>
  eligibleContainerType: Maybe<ProductContainerTypeEnum>
  /** End time (exclusive) in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  endTime: Maybe<Scalars['String']>
  /** Exclude products from listing in LIFF when this field is true. */
  excludedFromListing: Maybe<Scalars['Boolean']>
  /** Exclude product from promotion when this field is set to true */
  excludedFromPromotion: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  ingredientList: Maybe<Array<Maybe<Ingredient>>>
  /** Deprecated. Use ingredientList instead. */
  ingredients: Maybe<Scalars['String']>
  kitchenStation: Maybe<KitchenStationBackupType>
  kitchenStationID: Maybe<Scalars['Int']>
  locationProducts: Maybe<Array<Maybe<CustomerLocationProduct>>>
  maxQuantity: Maybe<Scalars['Int']>
  /** Max spicy level considering the spicy level of the product and modifiers */
  maxSpicyLevel: Maybe<SpicyLevelEnum>
  memberIneligibleReason: Maybe<MemberProductIneligibleReasonEnum>
  memberTierRankConditions: Maybe<Array<Maybe<ProductMemberTierRankConditionsEnum>>>
  /** Deprecated */
  modifierConfigs: Maybe<ProductModifierConfig>
  /** Modifier groups associated to product */
  modifierGroups: Maybe<Array<Maybe<ProductModifierGroup>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  /** Nutrition for the product excluding its modifiers */
  nutrition: Maybe<NutritionType>
  /** Nutrition for the product including all its modifiers */
  nutritionTotal: Maybe<NutritionType>
  onDemand: Maybe<Scalars['Boolean']>
  position: Maybe<Scalars['Int']>
  /** Price of products excluding its modifiers */
  priceSatangs: Maybe<Scalars['Int']>
  promotionBadge: Maybe<PromotionStrategyTypeEnum>
  promotionProduct: Maybe<PromotionProductType>
  shortName: Maybe<Scalars['String']>
  /** Skip the screen to add custom notes when adding to cart in LIFF . */
  skipCustomNotesScreen: Maybe<Scalars['Boolean']>
  /** Skip the screen to select modifiers when adding to cart in POS. */
  skipModifierScreen: Maybe<Scalars['Boolean']>
  sku: Maybe<Scalars['String']>
  /** Spicy level of the product excluding modifiers */
  spicyLevel: Maybe<SpicyLevelEnum>
  /** Start time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  startTime: Maybe<Scalars['String']>
  tags: Maybe<Array<Maybe<ProductTagEnum>>>
  tasteDescriptionEn: Maybe<Scalars['String']>
  tasteDescriptionTh: Maybe<Scalars['String']>
  /** Price of the product including its modifiers. Already taken into discount. */
  totalPriceSatangs: Maybe<Scalars['Int']>
  vendor: Maybe<VendorType>
  vendorID: Maybe<Scalars['Int']>
}

export type ProductWithoutModifierType = {
  active: Maybe<Scalars['Boolean']>
  brand: Maybe<Scalars['String']>
  category: Maybe<Category>
  categoryID: Maybe<Scalars['Int']>
  /** Color scheme set to this product. This does not consider its modifiers and category. For display, use derivedColorScheme. */
  colorScheme: Maybe<ProductColorSchemeEnum>
  /** Label or guide for users to input custom notes. */
  customNotesLabelEn: Maybe<Scalars['String']>
  /** Label or guide for users to input custom notes. */
  customNotesLabelTh: Maybe<Scalars['String']>
  /** Color scheme of product for display. The value is derived from the color scheme of product itself, its modifiers and its category. */
  derivedColorScheme: Maybe<ProductColorSchemeEnum>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  /** Amount to discount from actual total price. Note that this should be positive amount. */
  discountSatangs: Maybe<Scalars['Int']>
  eligibleContainerType: Maybe<ProductContainerTypeEnum>
  /** End time (exclusive) in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  endTime: Maybe<Scalars['String']>
  /** Exclude products from listing in LIFF when this field is true. */
  excludedFromListing: Maybe<Scalars['Boolean']>
  /** Exclude product from promotion when this field is set to true */
  excludedFromPromotion: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  ingredientList: Maybe<Array<Maybe<Ingredient>>>
  /** Deprecated. Use ingredientList instead. */
  ingredients: Maybe<Scalars['String']>
  kitchenStation: Maybe<KitchenStationBackupType>
  kitchenStationID: Maybe<Scalars['Int']>
  maxQuantity: Maybe<Scalars['Int']>
  /** Max spicy level considering the spicy level of the product and modifiers */
  maxSpicyLevel: Maybe<SpicyLevelEnum>
  memberIneligibleReason: Maybe<MemberProductIneligibleReasonEnum>
  memberTierRankConditions: Maybe<Array<Maybe<ProductMemberTierRankConditionsEnum>>>
  /** Deprecated */
  modifierConfigs: Maybe<ProductModifierConfig>
  /** Modifier groups associated to product */
  modifierGroups: Maybe<Array<Maybe<ModifierGroupWithProduct>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  /** Nutrition for the product excluding its modifiers */
  nutrition: Maybe<NutritionType>
  /** Nutrition for the product including all its modifiers */
  nutritionTotal: Maybe<NutritionType>
  onDemand: Maybe<Scalars['Boolean']>
  position: Maybe<Scalars['Int']>
  /** Price of products excluding its modifiers */
  priceSatangs: Maybe<Scalars['Int']>
  promotionBadge: Maybe<PromotionStrategyTypeEnum>
  shortName: Maybe<Scalars['String']>
  /** Skip the screen to add custom notes when adding to cart in LIFF . */
  skipCustomNotesScreen: Maybe<Scalars['Boolean']>
  /** Skip the screen to select modifiers when adding to cart in POS. */
  skipModifierScreen: Maybe<Scalars['Boolean']>
  sku: Maybe<Scalars['String']>
  /** Spicy level of the product excluding modifiers */
  spicyLevel: Maybe<SpicyLevelEnum>
  /** Start time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  startTime: Maybe<Scalars['String']>
  tags: Maybe<Array<Maybe<ProductTagEnum>>>
  tasteDescriptionEn: Maybe<Scalars['String']>
  tasteDescriptionTh: Maybe<Scalars['String']>
  /** Price of the product including its modifiers. Already taken into discount. */
  totalPriceSatangs: Maybe<Scalars['Int']>
  vendor: Maybe<VendorType>
  vendorID: Maybe<Scalars['Int']>
}

export type PromotionCodeType = {
  code: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  promotionID: Maybe<Scalars['Int']>
  redeemedAt: Maybe<Scalars['String']>
  redeemedByOrderID: Maybe<Scalars['Int']>
  redeemedByUserID: Maybe<Scalars['Int']>
  updatedAt: Maybe<Scalars['String']>
}

export enum PromotionMemberTierRankConditionEnumType {
  BirthMonth = 'BirthMonth',
  Eligible = 'Eligible',
  NotEligible = 'NotEligible',
}

export type PromotionPointRedemptionInputType = {
  promotionID: Scalars['Int']
  quantity: Scalars['Int']
}

export type PromotionProductGroupType = {
  minimumAmountSatangs: Maybe<Scalars['Int']>
  productIDs: Maybe<Array<Maybe<Scalars['Int']>>>
  products: Maybe<Array<Maybe<ProductType>>>
  quantity: Maybe<Scalars['Int']>
  rewardType: Maybe<PromotionRewardTypeEnum>
  rewardValue: Maybe<Scalars['Float']>
  type: Maybe<PromotionProductGroupTypeEnum>
}

export enum PromotionProductGroupTypeEnum {
  Condition = 'Condition',
  ConditionAndReward = 'ConditionAndReward',
}

export type PromotionProductType = {
  productGroupRewards: Maybe<Array<Maybe<PromotionProductGroupType>>>
  promotion: Maybe<CartPromotionType>
}

export enum PromotionRewardTypeEnum {
  FixedAmountSatangs = 'FixedAmountSatangs',
  FixedPercentage = 'FixedPercentage',
}

export type PromotionSetMenuConditionsType = {
  setMenuEntries: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']>>>>>
}

export enum PromotionStrategyTypeEnum {
  BuyXGetYForZ = 'BuyXGetYForZ',
  ManualBuy1Get1 = 'ManualBuy1Get1',
  ManualBuy2ForDiscount = 'ManualBuy2ForDiscount',
  ManualBuy2Get1 = 'ManualBuy2Get1',
  ManualBuy3ForDiscount = 'ManualBuy3ForDiscount',
  ManualBuy3Get1 = 'ManualBuy3Get1',
  ManualBuy4ForDiscount = 'ManualBuy4ForDiscount',
  ManualBuy4Get1 = 'ManualBuy4Get1',
  ManualBuy5ForDiscount = 'ManualBuy5ForDiscount',
  ManualBuy5Get1 = 'ManualBuy5Get1',
  ManualBuy6ForDiscount = 'ManualBuy6ForDiscount',
  ManualBuy6Get1 = 'ManualBuy6Get1',
  ManualBuy7ForDiscount = 'ManualBuy7ForDiscount',
}

export enum PromotionTypeEnum {
  Auto = 'Auto',
  Manual = 'Manual',
  ManualExternal = 'ManualExternal',
}

export type RedeemHhCouponResultType = {
  error: Maybe<HhMerchantErrorEnumType>
  success: Maybe<Scalars['Boolean']>
}

export type RedeemHhPointsResultType = {
  failureReason: Maybe<HhPointRedemptionFailureReasonEnum>
  success: Maybe<Scalars['Boolean']>
}

export type RedeemPointsResultType = {
  failureReason: Maybe<PointRedemptionFailureReasonTypeEnum>
  invalidConditions: Maybe<Array<Maybe<PointRedemptionCampaignConditionTypeEnum>>>
  membership: Maybe<MembershipType>
  success: Maybe<Scalars['Boolean']>
}

export enum RefundReasonEnum {
  AccidentalCharge = 'AccidentalCharge',
  CancelledOrder = 'CancelledOrder',
  Others = 'Others',
  ReturnedGoods = 'ReturnedGoods',
}

export type RefundType = {
  amountSatangs: Maybe<Scalars['Int']>
  /** Time of issuing the refund in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  createdAt: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  issueRefund: Maybe<Scalars['Boolean']>
  orderID: Maybe<Scalars['Int']>
  paymentType: Maybe<PaymentTypeEnum>
  reason: Maybe<RefundReasonEnum>
  reasonDetail: Maybe<Scalars['String']>
}

export type RemoveAddressResultType = {
  success: Maybe<Scalars['Boolean']>
}

export type RootMutation = {
  addCreditCard: Maybe<CreditCardType>
  address: Maybe<Address>
  applyPromoCodeToCart: Maybe<CustomerCartResultType>
  /** Apply one of promotion types to cart. */
  applyPromoToCart: Maybe<CustomerCartResultType>
  /** Attach bankslip S3 filepath with a given payment */
  attachBankSlip: Maybe<CustomerOrderType>
  /** Returns null if there is no membership associated to phone number but it won't remove current membership in cart. */
  attachMembershipToCart: Maybe<OrderMembershipType>
  /** Checkout the cart using specified payments */
  checkout: Maybe<CustomerOrderCheckoutType>
  createMembership: Maybe<MembershipHhCreateType>
  createOrUpdateCart: Maybe<CustomerCartResultType>
  feedback: Maybe<FeedbackType>
  linkMembership: Maybe<MembershipHhLinkType>
  linkMembershipByCardID: Maybe<MembershipHhLinkType>
  redeemHHMemberCoupon: Maybe<RedeemHhCouponResultType>
  redeemHHPoints: Maybe<RedeemHhPointsResultType>
  /** Redeem points using a point redemption campaign for LIFF user */
  redeemPoints: Maybe<RedeemPointsResultType>
  removeAddress: Maybe<RemoveAddressResultType>
  /** Remove one adjustment which matching promotionID or externalCouponID. Provide either promotionID or externalCouponID associated to adjustment to remove */
  removeCartAdjustment: Maybe<CustomerCartResultType>
  removeCreditCard: Maybe<CreditCardType>
  requestSmsOtp: Maybe<OtpType>
  /** reset current user associated to Line LIFF */
  resetCurrentUser: Maybe<LiffUser>
  /** Update User Preferences for LIFF */
  setUserPreferences: Maybe<LiffUser>
  unlinkMembership: Maybe<MembershipHhUnlinktype>
  updateCartPaymentMethod: Maybe<CustomerCartType>
  /** Update current user based on access token */
  updateCurrentUser: Maybe<LiffUser>
  updateMembership: Maybe<MembershipHhUpdateType>
}

export type RootMutationAddCreditCardArgs = {
  token: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootMutationAddressArgs = {
  address: AddressInput
  clearExistingAddresses?: InputMaybe<Scalars['Boolean']>
  skipAreaCheck?: InputMaybe<Scalars['Boolean']>
}

export type RootMutationApplyPromoCodeToCartArgs = {
  promoCode: Scalars['String']
}

export type RootMutationApplyPromoToCartArgs = {
  cartID: Scalars['Int']
  externalMemberCouponRefID?: InputMaybe<Scalars['String']>
  hatoHeartMemberCouponID?: InputMaybe<Scalars['Int']>
  promoCode?: InputMaybe<Scalars['String']>
}

export type RootMutationAttachBankSlipArgs = {
  orderID: Scalars['Int']
  slipPath: Scalars['String']
}

export type RootMutationAttachMembershipToCartArgs = {
  cartID: Scalars['Int']
  phone: Scalars['String']
}

export type RootMutationCheckoutArgs = {
  cartID: Scalars['Int']
  payment: CustomerPaymentInput
}

export type RootMutationCreateMembershipArgs = {
  allowPromoMsg?: InputMaybe<Scalars['Boolean']>
  dateOfBirth: Scalars['String']
  firstName: Scalars['String']
  gender: MembershipGenderEnum
  lastName: Scalars['String']
  legacyCardIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  otpPasscode: Scalars['String']
  otpToken: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootMutationCreateOrUpdateCartArgs = {
  cart: CustomerCartInput
}

export type RootMutationFeedbackArgs = {
  feedback: FeedbackInput
}

export type RootMutationLinkMembershipArgs = {
  allowPromoMsg?: InputMaybe<Scalars['Boolean']>
  dateOfBirth?: InputMaybe<Scalars['String']>
  otpPasscode: Scalars['String']
  otpToken: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootMutationLinkMembershipByCardIdArgs = {
  allowPromoMsg?: InputMaybe<Scalars['Boolean']>
  cardID: Scalars['String']
  dateOfBirth?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<MembershipGenderEnum>
  lastName?: InputMaybe<Scalars['String']>
  otpPasscode: Scalars['String']
  otpToken: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootMutationRedeemHhMemberCouponArgs = {
  couponID: Scalars['Int']
}

export type RootMutationRedeemHhPointsArgs = {
  memberID: Scalars['String']
  promotion: PromotionPointRedemptionInputType
}

export type RootMutationRedeemPointsArgs = {
  pointRedemptionCampaignID: Scalars['Int']
  quantity: Scalars['Int']
}

export type RootMutationRemoveAddressArgs = {
  addressID: Scalars['Int']
}

export type RootMutationRemoveCartAdjustmentArgs = {
  cartID: Scalars['Int']
  externalCouponID?: InputMaybe<Scalars['Int']>
  hatoHeartMemberCouponID?: InputMaybe<Scalars['Int']>
  promotionID?: InputMaybe<Scalars['Int']>
}

export type RootMutationRemoveCreditCardArgs = {
  cardID: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootMutationRequestSmsOtpArgs = {
  phone: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootMutationSetUserPreferencesArgs = {
  language?: InputMaybe<LanguageEnum>
}

export type RootMutationUnlinkMembershipArgs = {
  memberID: Scalars['String']
}

export type RootMutationUpdateCartPaymentMethodArgs = {
  cardID?: InputMaybe<Scalars['String']>
  cartID: Scalars['Int']
  phoneNumber?: InputMaybe<Scalars['String']>
  type: CustomerPaymentTypeEnum
}

export type RootMutationUpdateCurrentUserArgs = {
  extMembershipPhone?: InputMaybe<Scalars['String']>
  lineEmail?: InputMaybe<Scalars['String']>
}

export type RootMutationUpdateMembershipArgs = {
  allowPromoMsg?: InputMaybe<Scalars['Boolean']>
  dateOfBirth?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<MembershipGenderEnum>
  lastName?: InputMaybe<Scalars['String']>
  otpPasscode?: InputMaybe<Scalars['String']>
  otpToken?: InputMaybe<Scalars['String']>
}

export type RootQuery = {
  /** Get address by current user */
  address: Maybe<Array<Maybe<Address>>>
  /** Get Pre-Signed URL for upload slip */
  bankSlipUploadURL: Maybe<SlipUploadUrlType>
  cartAndFPMemberCoupons: Maybe<CartAndFpMemberCouponsResultType>
  cartAndHHMemberCoupons: Maybe<CartAndHhMemberCouponsResultType>
  checkPaymentStatus: Maybe<PaymentType>
  /** Gets current user's cart */
  currentCart: Maybe<CustomerCartType>
  /** Gets current user's cart and auto promotions */
  currentCartAndPromotions: Maybe<CartAndPromotionsType>
  /** Gets current membership details for a LIFF user */
  currentMembership: Maybe<MembershipType>
  /** Get current user based on access token */
  currentUser: Maybe<LiffUser>
  /** Lookup delivery fee by lat/lng. Return nil if out of delivery area. */
  deliveryFeeAndTimeByDistance: Maybe<DeliveryFeeAndTime>
  /** Lookup delivery fee by lat/lng for a vendor based on its nearest location  */
  deliveryFeeAndTimeForVendor: Maybe<VendorDeliveryFeeAndTimeType>
  /** Generate QR Code for member coupon */
  generateMemberCouponQRCode: Maybe<MemberCouponQrCodeType>
  /** Generate QR Code for membership */
  generateMemberQRCode: Maybe<MembershipQrCodeType>
  /** Get membership tier rank progress */
  getMembershipTierProgress: Maybe<MembershipTierProgressType>
  /** Get current user based on access token */
  isLineFriend: Maybe<Scalars['Boolean']>
  /** Check if phone number is available for registration. */
  isMembershipPhoneAvailable: Maybe<MembershipPhoneAvailableType>
  /** List all categories */
  listCategories: Maybe<Array<Maybe<Category>>>
  /** List categories for vendor */
  listCategoriesForVendor: Maybe<Array<Maybe<Category>>>
  listCreditCards: Maybe<Array<Maybe<CreditCardType>>>
  /** List nearest vendor locations. Only one location per vendor. */
  listLocationsForVendor: Maybe<Array<Maybe<LocationType>>>
  listMemberCoupons: Maybe<ListMemberCouponsResultType>
  listMembershipPromotions: Maybe<MembershipPromotionListType>
  /** Gets order histories with pagination */
  listOrders: Maybe<CustomerOrderWithPaginationType>
  /** List point redemption campaigns for a LIFF user */
  listPointRedemptionCampaigns: Maybe<Array<Maybe<PointRedemptionCampaignType>>>
  /** Deprecated. Use v2 instead. */
  listProducts: Maybe<Array<Maybe<ProductType>>>
  /** List active products for LIFF */
  listProductsV2: Maybe<ListProductsForLiffv2Result>
  /** List active scheduled contents with highest priority for each placement */
  listScheduledContents: Maybe<Array<Maybe<ScheduledContentType>>>
  /** List active set menu promotions */
  listSetMenuPromotions: Maybe<Array<Maybe<SetMenuPromotionType>>>
  /** Get location by ID */
  location: Maybe<LocationType>
  /** LookUpUnlinkedMembership looks up for unique unlinked membership verified with phone or phone and date of birth */
  lookupUnlinkedMembership: Maybe<MembershipHhLookUpType>
  /** LookUpUnlinkedMembership looks up for unique unlinked membership verified with card id */
  lookupUnlinkedMembershipByCardID: Maybe<MembershipHhLookUpType>
  memberCoupon: Maybe<MemberCouponType>
  /** Gets membership program for LIFF users */
  membershipProgram: Maybe<MembershipProgramPublicType>
  /** List nearest vendor locations. Only one location per vendor. */
  nearestVendorLocations: Maybe<Array<Maybe<LocationType>>>
  /** Get Omise card information by current user */
  omiseCard: Maybe<OmiseTokenType>
  /** Get a order detail by ID */
  order: Maybe<CustomerOrderType>
  /** Get product */
  product: Maybe<ProductType>
  /** Gets products to display in For You section. */
  productsForYou: Maybe<Array<Maybe<ProductType>>>
  /** Get active scheduled content by ID */
  scheduledContent: Maybe<ScheduledContentType>
  /** Get vendor */
  vendor: Maybe<VendorType>
  /** Get vendor setting */
  vendorSetting: Maybe<VendorSettingType>
}

export type RootQueryBankSlipUploadUrlArgs = {
  extension: Scalars['String']
  orderID: Scalars['Int']
}

export type RootQueryCartAndFpMemberCouponsArgs = {
  vendorID?: InputMaybe<Scalars['Int']>
}

export type RootQueryCartAndHhMemberCouponsArgs = {
  vendorID?: InputMaybe<Scalars['Int']>
}

export type RootQueryCheckPaymentStatusArgs = {
  orderID: Scalars['Int']
}

export type RootQueryCurrentCartArgs = {
  vendorID?: InputMaybe<Scalars['Int']>
}

export type RootQueryCurrentCartAndPromotionsArgs = {
  vendorID?: InputMaybe<Scalars['Int']>
}

export type RootQueryCurrentMembershipArgs = {
  refresh?: InputMaybe<Scalars['Boolean']>
}

export type RootQueryDeliveryFeeAndTimeByDistanceArgs = {
  deliveryGeolocation: GeolocationInput
  isPickUp?: InputMaybe<Scalars['Boolean']>
  lineItemQuantity: Scalars['Int']
  locationID: Scalars['Int']
}

export type RootQueryDeliveryFeeAndTimeForVendorArgs = {
  deliveryGeolocation: GeolocationInput
  lineItemQuantity: Scalars['Int']
  vendorID: Scalars['Int']
}

export type RootQueryGenerateMemberCouponQrCodeArgs = {
  memberCouponID: Scalars['Int']
}

export type RootQueryIsMembershipPhoneAvailableArgs = {
  phone: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootQueryListCategoriesForVendorArgs = {
  locationID?: InputMaybe<Scalars['Int']>
  onlyActiveProduct?: InputMaybe<Scalars['Boolean']>
  vendorID: Scalars['Int']
}

export type RootQueryListCreditCardsArgs = {
  vendorID: Scalars['Int']
}

export type RootQueryListLocationsForVendorArgs = {
  afterLocationID?: InputMaybe<Scalars['Int']>
  deliveryGeolocation?: InputMaybe<GeolocationInput>
  includeOutOfDeliveryArea?: InputMaybe<Scalars['Boolean']>
  keyword?: InputMaybe<Scalars['String']>
  size?: InputMaybe<Scalars['Int']>
  useRouteDistance?: InputMaybe<Scalars['Boolean']>
  vendorID: Scalars['Int']
}

export type RootQueryListMemberCouponsArgs = {
  pagination?: InputMaybe<PaginationInputType>
  statuses: Array<InputMaybe<MemberCouponStatusEnumType>>
}

export type RootQueryListMembershipPromotionsArgs = {
  limit: Scalars['Int']
  offset: Scalars['Int']
  type?: InputMaybe<MembershipPromotionTypeEnum>
}

export type RootQueryListOrdersArgs = {
  filter?: InputMaybe<CustomerOrderFilter>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
}

export type RootQueryListPointRedemptionCampaignsArgs = {
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
}

export type RootQueryListProductsArgs = {
  categoryID?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  locationID?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  vendorID?: InputMaybe<Scalars['Int']>
}

export type RootQueryListProductsV2Args = {
  categoryID?: InputMaybe<Scalars['Int']>
  keyword?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  locationID?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  vendorID?: InputMaybe<Scalars['Int']>
}

export type RootQueryListScheduledContentsArgs = {
  filter: ScheduledContentFilter
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type RootQueryLocationArgs = {
  deliveryGeolocation?: InputMaybe<GeolocationInput>
  id: Scalars['Int']
  useRouteDistance?: InputMaybe<Scalars['Boolean']>
}

export type RootQueryLookupUnlinkedMembershipArgs = {
  dateOfBirth?: InputMaybe<Scalars['String']>
  phone: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootQueryLookupUnlinkedMembershipByCardIdArgs = {
  cardID: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootQueryMemberCouponArgs = {
  couponID: Scalars['Int']
}

export type RootQueryMembershipProgramArgs = {
  vendorID: Scalars['Int']
}

export type RootQueryNearestVendorLocationsArgs = {
  deliveryGeolocation?: InputMaybe<GeolocationInput>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  useRouteDistance?: InputMaybe<Scalars['Boolean']>
}

export type RootQueryOrderArgs = {
  id: Scalars['Int']
}

export type RootQueryProductArgs = {
  id: Scalars['Int']
  locationID?: InputMaybe<Scalars['Int']>
}

export type RootQueryScheduledContentArgs = {
  deliveryGeolocation?: InputMaybe<GeolocationInput>
  id: Scalars['Int']
}

export type RootQueryVendorArgs = {
  deliveryGeolocation?: InputMaybe<GeolocationInput>
  id: Scalars['Int']
  useRouteDistance?: InputMaybe<Scalars['Boolean']>
}

export type RootQueryVendorSettingArgs = {
  vendorID: Scalars['Int']
}

export enum SmsProviderEnum {
  Aws = 'AWS',
  EightByEight = 'EightByEight',
  MobileConnect = 'MobileConnect',
  ThaiBulk = 'ThaiBulk',
}

export enum ScheduledContentChannelEnum {
  Buffet = 'Buffet',
  Delivery = 'Delivery',
  DineIn = 'DineIn',
}

export type ScheduledContentFilter = {
  channel?: InputMaybe<ScheduledContentChannelEnum>
  placements: Array<InputMaybe<ScheduledContentPlacementEnum>>
  vendorID: Scalars['Int']
}

export enum ScheduledContentPlacementEnum {
  Hero1 = 'Hero1',
  Hero2 = 'Hero2',
  Hero3 = 'Hero3',
  Hero4 = 'Hero4',
  Hero5 = 'Hero5',
  HeroHome1 = 'HeroHome1',
  HeroHome2 = 'HeroHome2',
}

export type ScheduledContentType = {
  categoryID: Maybe<Scalars['Int']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  placement: Maybe<ScheduledContentPlacementEnum>
  priority: Maybe<Scalars['Int']>
  products: Maybe<Array<Maybe<ProductType>>>
  titleEn: Maybe<Scalars['String']>
  titleTh: Maybe<Scalars['String']>
  type: Maybe<ScheduledContentTypeEnum>
  uri: Maybe<Scalars['String']>
  vendorID: Maybe<Scalars['Int']>
}

export enum ScheduledContentTypeEnum {
  Category = 'Category',
  SingleMedia = 'SingleMedia',
  SingleProduct = 'SingleProduct',
}

export type SetMenuPromotionType = {
  active: Maybe<Scalars['Boolean']>
  code: Maybe<Scalars['String']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  /** End time (exclusive) in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  endTime: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  membershipPromotionID: Maybe<Scalars['Int']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  priority: Maybe<Scalars['Int']>
  refID: Maybe<Scalars['String']>
  rewardType: Maybe<PromotionRewardTypeEnum>
  rewardValue: Maybe<Scalars['Float']>
  setMenuConditions: Maybe<PromotionSetMenuConditionsType>
  showOnList: Maybe<Scalars['Boolean']>
  /** Start time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  startTime: Maybe<Scalars['String']>
  totalPromotionCodes: Maybe<Scalars['Int']>
  uniquePromotionCodes: Maybe<Array<Maybe<PromotionCodeType>>>
  vendorID: Maybe<Scalars['Int']>
}

export enum ShippingFeePolicyEnum {
  Distance = 'Distance',
  Zone = 'Zone',
}

export enum SpicyLevelEnum {
  Hot = 'Hot',
  Medium = 'Medium',
  Mild = 'Mild',
  NotSpicy = 'NotSpicy',
}

export enum StaffPrivilegeTypeTypeEnum {
  Cash = 'Cash',
  DiscountPercentage = 'DiscountPercentage',
}

export type UnlinkedMembership = {
  firstName: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  memberID: Maybe<Scalars['String']>
}

export type VendorDeliveryFeeAndTimeType = {
  deliveryFeeAndTime: Maybe<DeliveryFeeAndTime>
  nearestLocation: Maybe<LocationType>
}

export type VendorSettingType = {
  bankAccounts: Maybe<Array<Maybe<BankAccountType>>>
  excludingVat: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  orderMinimumLineItemTotalSatangs: Maybe<Scalars['Int']>
  orderMinimumTotalSatangs: Maybe<Scalars['Int']>
  paymentMethods: Maybe<Array<Maybe<CustomerPaymentTypeEnum>>>
  vendorID: Maybe<Scalars['Int']>
}

export type VendorType = {
  active: Maybe<Scalars['Boolean']>
  /** Flag to determine if vendor is available for pick up. */
  canPickUp: Maybe<Scalars['Boolean']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  locations: Maybe<Array<Maybe<LocationType>>>
  logoImage: Maybe<ImageType>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  nearestLocation: Maybe<LocationType>
  quoteEn: Maybe<Scalars['String']>
  quoteTh: Maybe<Scalars['String']>
}

export type VendorTypeNearestLocationArgs = {
  deliveryGeolocation?: InputMaybe<GeolocationInput>
}

export type SlipUploadUrlType = {
  /** File Path on AWS S3 */
  filePath: Maybe<Scalars['String']>
  /** Pre-Signed URL for upload slip */
  url: Maybe<Scalars['String']>
}

export type AddCreditCardMutationVariables = Exact<{
  vendorID: Scalars['Int']
  token: Scalars['String']
}>

export type AddCreditCardMutation = {
  addCreditCard: { cardID: string; cardBrand: string; cardLastDigits: string; cardholderName: string }
}

export type CheckoutMutationVariables = Exact<{
  cartID: Scalars['Int']
  paymentType: CustomerPaymentTypeEnum
  phoneNumber?: InputMaybe<Scalars['String']>
  returnURI?: InputMaybe<Scalars['String']>
}>

export type CheckoutMutation = {
  checkout: {
    corrections: Array<CartCorrectionEnum>
    cartInvalids: Array<CartInvalidEnum>
    KPaymentResult: {
      failureCode: KPaymentResultFailureCodeEnum
      failureMessage: string
      phone: string
      status: KPaymentResultStatusEnum
    }
    chargeResult: {
      authorizeURI: string
      chargeID: string
      failureCode: string
      failureMessage: string
      status: string
    }
    order: {
      id: number
      deliveryDate: string
      deliveryTimeslot: string
      payments: Array<{ id: number; type: PaymentTypeEnum }>
    }
  }
}

export type FeedbackMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>
  orderID?: InputMaybe<Scalars['Int']>
  rating: Scalars['Int']
  message: Scalars['String']
  issueTypes?: InputMaybe<Array<InputMaybe<FeedbackIssueTypeEnum>> | InputMaybe<FeedbackIssueTypeEnum>>
}>

export type FeedbackMutation = {
  feedback: { id: number; userID: number; orderID: number; rating: number; message: string; issueTypes: Array<string> }
}

export type LinkMembershipMutationVariables = Exact<{
  otpPasscode: Scalars['String']
  vendorID: Scalars['Int']
  dateOfBirth?: InputMaybe<Scalars['String']>
  otpToken: Scalars['String']
  allowPromoMsg?: InputMaybe<Scalars['Boolean']>
}>

export type LinkMembershipMutation = {
  linkMembership: {
    status: MembershipLinkEnumType
    membership: {
      firstName: string
      id: number
      lastName: string
      memberID: string
      memberTier: string
      provider: MembershipProviderEnum
      totalPoints: number
      userID: number
      dateOfBirth: string
      gender: MembershipGenderEnum
      phone: string
      allowPromoMsg: boolean
      isSubscriptionActive: boolean
      subscriptionEndDate: string
      subscriptionPlan: string
      legacyCardIDs: Array<string>
      pointExpirations: Array<{ id: number; expirationDate: string; points: number }>
      currentMembershipSubscription: {
        membershipProgramSubscriptionOption: { coverImage: { originalURL: string; resizableURL: string } }
      }
    }
  }
}

export type LinkMembershipByCardIdMutationVariables = Exact<{
  cardID: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  otpToken: Scalars['String']
  otpPasscode: Scalars['String']
  vendorID: Scalars['Int']
  gender?: InputMaybe<MembershipGenderEnum>
  dateOfBirth?: InputMaybe<Scalars['String']>
  allowPromoMsg?: InputMaybe<Scalars['Boolean']>
}>

export type LinkMembershipByCardIdMutation = {
  linkMembershipByCardID: {
    status: MembershipLinkEnumType
    membership: {
      firstName: string
      id: number
      lastName: string
      memberID: string
      memberTier: string
      provider: MembershipProviderEnum
      totalPoints: number
      userID: number
      dateOfBirth: string
      gender: MembershipGenderEnum
      phone: string
      allowPromoMsg: boolean
      isSubscriptionActive: boolean
      subscriptionEndDate: string
      legacyCardIDs: Array<string>
      pointExpirations: Array<{ id: number; expirationDate: string; points: number }>
      currentMembershipSubscription: {
        membershipProgramSubscriptionOption: { coverImage: { originalURL: string; resizableURL: string } }
      }
    }
  }
}

export type RedeemHhMemberCouponMutationVariables = Exact<{
  couponID: Scalars['Int']
}>

export type RedeemHhMemberCouponMutation = {
  redeemHHMemberCoupon: { error: HhMerchantErrorEnumType; success: boolean }
}

export type RedeemPointsMutationVariables = Exact<{
  pointRedemptionCampaignID: Scalars['Int']
  quantity: Scalars['Int']
}>

export type RedeemPointsMutation = {
  redeemPoints: {
    failureReason: PointRedemptionFailureReasonTypeEnum
    success: boolean
    invalidConditions: Array<PointRedemptionCampaignConditionTypeEnum>
    membership: {
      firstName: string
      id: number
      lastName: string
      memberID: string
      memberTier: string
      provider: MembershipProviderEnum
      totalPoints: number
      userID: number
      pointExpirations: Array<{ id: number; expirationDate: string; points: number }>
    }
  }
}

export type RemoveCreditCardMutationVariables = Exact<{
  vendorID: Scalars['Int']
  cardID: Scalars['String']
}>

export type RemoveCreditCardMutation = { removeCreditCard: { cardID: string } }

export type RequestSmsOtpMutationVariables = Exact<{
  phone: Scalars['String']
  vendorID: Scalars['Int']
}>

export type RequestSmsOtpMutation = {
  requestSmsOtp: { phone: string; token: string; invalidReason: OtpInvalidReasonEnum }
}

export type AddressMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  address?: InputMaybe<Scalars['String']>
  locationLabel?: InputMaybe<Scalars['String']>
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
  detail?: InputMaybe<Scalars['String']>
  note?: InputMaybe<Scalars['String']>
  buildingType?: InputMaybe<BuildingTypeEnum>
  deliveryMethod?: InputMaybe<DeliveryMethodEnum>
  callOnArrival?: InputMaybe<Scalars['Boolean']>
  skipAreaCheck?: InputMaybe<Scalars['Boolean']>
  nickname?: InputMaybe<Scalars['String']>
  temporary?: InputMaybe<Scalars['Boolean']>
  googlePlaceID?: InputMaybe<Scalars['String']>
}>

export type AddressMutation = {
  address: {
    id: number
    name: string
    phone: string
    detail: string
    note: string
    locationLabel: string
    latitude: number
    longitude: number
    address: string
    buildingType: BuildingTypeEnum
    deliveryMethod: DeliveryMethodEnum
    callOnArrival: boolean
    lastUsed: boolean
    nickname: string
    temporary: boolean
    googlePlaceID: string
  }
}

export type UpdateCartPaymentMethodMutationVariables = Exact<{
  cartID: Scalars['Int']
  type: CustomerPaymentTypeEnum
  cardID?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
}>

export type UpdateCartPaymentMethodMutation = {
  updateCartPaymentMethod: {
    id: number
    paymentType: CustomerPaymentTypeEnum
    paymentPhone: string
    omiseCardID: string
  }
}

export type UpdateCurrentUserMutationVariables = Exact<{
  lineEmail?: InputMaybe<Scalars['String']>
  extMembershipPhone?: InputMaybe<Scalars['String']>
}>

export type UpdateCurrentUserMutation = {
  updateCurrentUser: {
    id: number
    email: string
    defaultPaymentMethod: CustomerPaymentTypeEnum
    defaultPaymentPhone: string
    language: string
    omiseCardID: string
    omiseCardBrand: string
    omiseCardLastDigits: string
    lineEmail: string
    extMembershipPhone: string
  }
}

export type UpdateLineEmailCurrentUserMutationVariables = Exact<{
  lineEmail?: InputMaybe<Scalars['String']>
}>

export type UpdateLineEmailCurrentUserMutation = {
  updateCurrentUser: {
    id: number
    email: string
    defaultPaymentMethod: CustomerPaymentTypeEnum
    defaultPaymentPhone: string
    language: string
    omiseCardID: string
    omiseCardBrand: string
    omiseCardLastDigits: string
    lineEmail: string
  }
}

export type UpdateMembershipMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  otpToken?: InputMaybe<Scalars['String']>
  otpPasscode?: InputMaybe<Scalars['String']>
  allowPromoMsg?: InputMaybe<Scalars['Boolean']>
  gender?: InputMaybe<MembershipGenderEnum>
  dateOfBirth?: InputMaybe<Scalars['String']>
}>

export type UpdateMembershipMutation = {
  updateMembership: {
    status: MembershipUpdateEnumType
    membership: {
      firstName: string
      id: number
      lastName: string
      memberID: string
      memberTier: string
      provider: MembershipProviderEnum
      totalPoints: number
      userID: number
      dateOfBirth: string
      gender: MembershipGenderEnum
      phone: string
      allowPromoMsg: boolean
      isSubscriptionActive: boolean
      subscriptionEndDate: string
      subscriptionPlan: string
      legacyCardIDs: Array<string>
      pointExpirations: Array<{ id: number; expirationDate: string; points: number }>
      currentMembershipSubscription: {
        membershipProgramSubscriptionOption: { coverImage: { originalURL: string; resizableURL: string } }
      }
    }
  }
}

export type ApplyPromoToCartMutationVariables = Exact<{
  cartID: Scalars['Int']
  promoCode?: InputMaybe<Scalars['String']>
  externalMemberCouponRefID?: InputMaybe<Scalars['String']>
  hatoHeartMemberCouponID?: InputMaybe<Scalars['Int']>
}>

export type ApplyPromoToCartMutation = {
  applyPromoToCart: {
    cartInvalids: Array<CartInvalidEnum>
    corrections: Array<CartCorrectionEnum>
    promotionInvalids: Array<CartPromotionInvalidEnum>
    cart: {
      id: number
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        promotionID: number
        externalCouponID: number
        adjustmentType: AdjustmentTypeEnumType
        source: AdjustmentSourceEnumType
        invalidReason: AdjustmentInvalidReasonEnumType
        externalCoupon: {
          id: number
          isAvailable: boolean
          endTime: string
          amount: number
          serialNumber: string
          rewardType: ExternalCouponRewardTypeEnum
          thumbnailImageURL: string
          name: string
        }
      }>
    }
  }
}

export type CartAndFpMemberCouponsQueryVariables = Exact<{ [key: string]: never }>

export type CartAndFpMemberCouponsQuery = {
  cartAndFPMemberCoupons: {
    cart: {
      id: number
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        promotionID: number
        memberCouponID: number
        externalCouponID: number
        adjustmentType: AdjustmentTypeEnumType
        source: AdjustmentSourceEnumType
        invalidReason: AdjustmentInvalidReasonEnumType
        promotion: {
          descriptionEn: string
          descriptionTh: string
          id: number
          nameEn: string
          nameTh: string
          type: PromotionTypeEnum
          memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
          targetEntity: AdjustmentTargetEntityTypeEnum
        }
        memberCoupon: {
          id: number
          endDate: string
          redeemedAt: string
          startDate: string
          status: MemberCouponStatusEnumType
          promotion: {
            id: number
            conditionDetailsEn: string
            conditionDetailsTh: string
            descriptionEn: string
            descriptionTh: string
            extPromotionID: string
            nameEn: string
            nameTh: string
            images: Array<{ resizeableURL: string }>
          }
        }
        externalCoupon: {
          id: number
          isAvailable: boolean
          endTime: string
          amount: number
          serialNumber: string
          rewardType: ExternalCouponRewardTypeEnum
          thumbnailImageURL: string
          name: string
          description: string
          memberCouponRefID: string
        }
        invalidData: { minimumSatangs: number; remainingMinimumSatangs: number }
      }>
    }
    coupons: Array<{
      id: number
      isAvailable: boolean
      endTime: string
      amount: number
      serialNumber: string
      rewardType: ExternalCouponRewardTypeEnum
      thumbnailImageURL: string
      name: string
      memberCouponRefID: string
    }>
    autoPromotions: Array<{
      id: number
      code: string
      descriptionEn: string
      descriptionTh: string
      nameEn: string
      nameTh: string
      memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
      targetEntity: AdjustmentTargetEntityTypeEnum
    }>
    manualPromotions: Array<{
      id: number
      code: string
      descriptionEn: string
      descriptionTh: string
      nameEn: string
      nameTh: string
      memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
      targetEntity: AdjustmentTargetEntityTypeEnum
    }>
  }
}

export type CartAndHhMemberCouponsQueryVariables = Exact<{ [key: string]: never }>

export type CartAndHhMemberCouponsQuery = {
  cartAndHHMemberCoupons: {
    cart: {
      id: number
      adjustments: Array<{
        labelEn: string
        labelTh: string
        amountSatangs: number
        promotionID: number
        memberCouponID: number
        externalCouponID: number
        adjustmentType: AdjustmentTypeEnumType
        source: AdjustmentSourceEnumType
        invalidReason: AdjustmentInvalidReasonEnumType
        promotion: {
          descriptionEn: string
          descriptionTh: string
          id: number
          nameEn: string
          nameTh: string
          type: PromotionTypeEnum
          memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
          targetEntity: AdjustmentTargetEntityTypeEnum
        }
        memberCoupon: {
          id: number
          endDate: string
          redeemedAt: string
          startDate: string
          status: MemberCouponStatusEnumType
          promotion: {
            id: number
            conditionDetailsEn: string
            conditionDetailsTh: string
            descriptionEn: string
            descriptionTh: string
            extPromotionID: string
            nameEn: string
            nameTh: string
            images: Array<{ resizeableURL: string }>
          }
        }
        externalCoupon: {
          id: number
          isAvailable: boolean
          endTime: string
          amount: number
          serialNumber: string
          rewardType: ExternalCouponRewardTypeEnum
          thumbnailImageURL: string
          name: string
          description: string
        }
        invalidData: { minimumSatangs: number; remainingMinimumSatangs: number }
      }>
    }
    coupons: Array<{
      isAvailable: boolean
      coupon: {
        id: number
        endDate: string
        redeemedAt: string
        startDate: string
        status: MemberCouponStatusEnumType
        promotion: {
          id: number
          conditionDetailsEn: string
          conditionDetailsTh: string
          descriptionEn: string
          descriptionTh: string
          extPromotionID: string
          nameEn: string
          nameTh: string
          images: Array<{ resizeableURL: string }>
        }
      }
    }>
    autoPromotions: Array<{
      id: number
      code: string
      descriptionEn: string
      descriptionTh: string
      nameEn: string
      nameTh: string
      memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
      targetEntity: AdjustmentTargetEntityTypeEnum
    }>
    manualPromotions: Array<{
      id: number
      code: string
      descriptionEn: string
      descriptionTh: string
      nameEn: string
      nameTh: string
      memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
      targetEntity: AdjustmentTargetEntityTypeEnum
    }>
  }
}

export type CheckPaymentStatusQueryVariables = Exact<{
  orderID: Scalars['Int']
}>

export type CheckPaymentStatusQuery = {
  checkPaymentStatus: { id: number; status: PaymentStatusEnum; failureReason: string; failureMessage: string }
}

export type CurrentCartQueryVariables = Exact<{
  vendorID: Scalars['Int']
}>

export type CurrentCartQuery = {
  currentCart: {
    adjustments: Array<{
      id: number
      labelEn: string
      labelTh: string
      fullAmountSatangs: number
      adjustmentType: AdjustmentTypeEnumType
      amountSatangs: number
      promotion: { type: PromotionTypeEnum }
    }>
  }
}

export type CurrentMembershipQueryVariables = Exact<{
  refresh?: InputMaybe<Scalars['Boolean']>
}>

export type CurrentMembershipQuery = {
  currentMembership: {
    firstName: string
    id: number
    lastName: string
    memberID: string
    memberTier: string
    provider: MembershipProviderEnum
    totalPoints: number
    userID: number
    dateOfBirth: string
    gender: MembershipGenderEnum
    phone: string
    allowPromoMsg: boolean
    isSubscriptionActive: boolean
    subscriptionEndDate: string
    subscriptionPlan: string
    legacyCardIDs: Array<string>
    pointExpirations: Array<{ id: number; expirationDate: string; points: number }>
    currentMembershipSubscription: {
      membershipProgramSubscriptionOption: { coverImage: { originalURL: string; resizableURL: string } }
    }
  }
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  currentUser: {
    id: number
    email: string
    defaultPaymentMethod: CustomerPaymentTypeEnum
    defaultPaymentPhone: string
    language: string
    omiseCardID: string
    omiseCardBrand: string
    omiseCardLastDigits: string
    lineEmail: string
    extMembershipPhone: string
  }
}

export type CurrentUserCampaignDataPackQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserCampaignDataPackQuery = {
  currentUser: {
    id: number
    linePicture: string
    email: string
    defaultPaymentMethod: CustomerPaymentTypeEnum
    defaultPaymentPhone: string
    language: string
    omiseCardID: string
    omiseCardBrand: string
    omiseCardLastDigits: string
    omiseCardholderName: string
    lineEmail: string
    extMembershipPhone: string
  }
  currentMembership: {
    firstName: string
    id: number
    lastName: string
    memberID: string
    memberTier: string
    provider: MembershipProviderEnum
    totalPoints: number
    userID: number
    dateOfBirth: string
    gender: MembershipGenderEnum
    phone: string
    allowPromoMsg: boolean
    isSubscriptionActive: boolean
    subscriptionEndDate: string
    subscriptionPlan: string
    legacyCardIDs: Array<string>
    pointExpirations: Array<{ id: number; expirationDate: string; points: number }>
    currentMembershipSubscription: {
      membershipProgramSubscriptionOption: { coverImage: { originalURL: string; resizableURL: string } }
    }
  }
}

export type DeliveryFeeAndTimeByDistanceQueryVariables = Exact<{
  lineItemQuantity: Scalars['Int']
  locationID: Scalars['Int']
  deliveryGeolocation: GeolocationInput
  isPickUp?: InputMaybe<Scalars['Boolean']>
}>

export type DeliveryFeeAndTimeByDistanceQuery = {
  deliveryFeeAndTimeByDistance: { deliverableDays: Array<{ date: string; timeslots: Array<string> }> }
}

export type FeaturedPromotionHhQueryVariables = Exact<{
  vendorID: Scalars['Int']
}>

export type FeaturedPromotionHhQuery = {
  cartAndHHMemberCoupons: {
    featuredPromotion: {
      promotionID: number
      promotionNameEn: string
      promotionNameTh: string
      promotionDescriptionEn: string
      promotionDescriptionTh: string
      targetEntity: AdjustmentTargetEntityTypeEnum
      memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
      isApplied: boolean
      adjustmentInvalidReason: AdjustmentInvalidReasonEnumType
      adjustmentInvalidData: { remainingMinimumSatangs: number; minimumSatangs: number }
    }
  }
}

export type ListCreditCardsQueryVariables = Exact<{
  vendorID: Scalars['Int']
}>

export type ListCreditCardsQuery = {
  listCreditCards: Array<{ cardID: string; cardBrand: string; cardLastDigits: string; cardholderName: string }>
}

export type ListLocationsForVendorQueryVariables = Exact<{
  vendorID: Scalars['Int']
  deliveryGeolocation?: InputMaybe<GeolocationInput>
  size?: InputMaybe<Scalars['Int']>
  afterLocationID?: InputMaybe<Scalars['Int']>
  keyword?: InputMaybe<Scalars['String']>
  useRouteDistance?: InputMaybe<Scalars['Boolean']>
  includeOutOfDeliveryArea?: InputMaybe<Scalars['Boolean']>
}>

export type ListLocationsForVendorQuery = {
  listLocationsForVendor: Array<{
    id: number
    nameEn: string
    nameTh: string
    approxDeliveryDistanceM: number
    geocodedDistrictEn: string
    geocodedDistrictTh: string
    latitude: number
    longitude: number
    vendor: { id: number; logoImage: { id: number; thumbnailResizableURL: string; resizableURL: string } }
  }>
}

export type ListMemberCouponsQueryVariables = Exact<{
  statuses: Array<InputMaybe<MemberCouponStatusEnumType>> | InputMaybe<MemberCouponStatusEnumType>
  pagination?: InputMaybe<PaginationInputType>
}>

export type ListMemberCouponsQuery = {
  listMemberCoupons: {
    memberCoupons: Array<{
      endDate: string
      id: number
      redeemedAt: string
      startDate: string
      status: MemberCouponStatusEnumType
      code: string
      promotion: {
        conditionDetailsEn: string
        conditionDetailsTh: string
        descriptionEn: string
        descriptionTh: string
        extPromotionID: string
        id: number
        nameEn: string
        nameTh: string
        isSelfRedeemableCoupon: boolean
        images: Array<{ resizeableURL: string }>
      }
    }>
    pagination: { count: number; limit: number; offset: number; total: number }
  }
}

export type ListMembershipPromotionsQueryVariables = Exact<{
  limit: Scalars['Int']
  type?: InputMaybe<MembershipPromotionTypeEnum>
  offset: Scalars['Int']
}>

export type ListMembershipPromotionsQuery = {
  listMembershipPromotions: {
    membershipPromotions: Array<{
      id: number
      membershipProgramID: number
      active: boolean
      nameEn: string
      nameTh: string
      descriptionEn: string
      descriptionTh: string
      conditionDetailsEn: string
      conditionDetailsTh: string
      endTime: string
      ineligibleReasons: Array<IneligibleReasonEnumType>
      points: number
      type: MembershipPromotionTypeEnum
      hashedID: string
      images: Array<{ resizableURL: string }>
    }>
    pagination: { count: number; limit: number; offset: number; total: number }
  }
}

export type ListOrdersQueryVariables = Exact<{
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  filter?: InputMaybe<CustomerOrderFilter>
}>

export type ListOrdersQuery = {
  listOrders: {
    orders: Array<{
      id: number
      deliveryDate: string
      lineItemTotalSatangs: number
      lineItems: Array<{
        id: number
        quantity: number
        totalSatangs: number
        product: { id: number; nameEn: string; nameTh: string; images: Array<{ id: number; resizableURL: string }> }
      }>
      location: { id: number; vendorID: number }
    }>
    pagination: { count: number; limit: number; offset: number; total: number }
  }
}

export type ListPointRedemptionCampaignsQueryVariables = Exact<{
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
}>

export type ListPointRedemptionCampaignsQuery = {
  listPointRedemptionCampaigns: Array<{
    id: number
    active: boolean
    nameEn: string
    nameTh: string
    points: number
    startTime: string
    endTime: string
    descriptionEn: string
    descriptionTh: string
    conditionDetailsEn: string
    conditionDetailsTh: string
    conditionTimeOfDayStart: string
    conditionTimeOfDayEnd: string
    invalidConditions: Array<PointRedemptionCampaignConditionTypeEnum>
    images: Array<{ resizableURL: string; originalURL: string; id: number }>
    vendor: { id: number; nameEn: string; nameTh: string }
  }>
}

export type ListProductsV2QueryVariables = Exact<{
  vendorID?: InputMaybe<Scalars['Int']>
  locationID?: InputMaybe<Scalars['Int']>
  categoryID?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  keyword?: InputMaybe<Scalars['String']>
}>

export type ListProductsV2Query = {
  listProductsV2: {
    pagination: { count: number; limit: number; offset: number; total: number }
    products: Array<{
      sku: string
      id: number
      active: boolean
      derivedColorScheme: ProductColorSchemeEnum
      discountSatangs: number
      priceSatangs: number
      totalPriceSatangs: number
      nameTh: string
      nameEn: string
      customNotesLabelTh: string
      customNotesLabelEn: string
      skipCustomNotesScreen: boolean
      eligibleContainerType: ProductContainerTypeEnum
      excludedFromPromotion: boolean
      categoryID: number
      maxSpicyLevel: SpicyLevelEnum
      maxQuantity: number
      tags: Array<ProductTagEnum>
      memberTierRankConditions: Array<ProductMemberTierRankConditionsEnum>
      memberIneligibleReason: MemberProductIneligibleReasonEnum
      promotionBadge: PromotionStrategyTypeEnum
      locationProducts: Array<{ id: number; inStockQuantity: number }>
      category: { id: number; nameTh: string; nameEn: string }
      images: Array<{ id: number; resizableURL: string; thumbnailResizableURL: string; originalURL: string }>
      vendor: { id: number; nameTh: string; nameEn: string; logoImage: { id: number; resizableURL: string } }
      modifierGroups: Array<{ id: number; active: boolean; position: number }>
      promotionProduct: {
        productGroupRewards: Array<{ productIDs: Array<number> }>
        promotion: { id: number; strategyType: PromotionStrategyTypeEnum }
      }
    }>
  }
}

export type ListScheduledContentsQueryVariables = Exact<{
  offset: Scalars['Int']
  limit: Scalars['Int']
  filter: ScheduledContentFilter
}>

export type ListScheduledContentsQuery = {
  listScheduledContents: Array<{
    id: number
    titleEn: string
    titleTh: string
    descriptionEn: string
    descriptionTh: string
    placement: ScheduledContentPlacementEnum
    type: ScheduledContentTypeEnum
    uri: string
    vendorID: number
    categoryID: number
    images: Array<{ id: number; originalURL: string; resizableURL: string; type: ImageTypeEnum }>
    products: Array<{
      sku: string
      id: number
      active: boolean
      derivedColorScheme: ProductColorSchemeEnum
      discountSatangs: number
      priceSatangs: number
      totalPriceSatangs: number
      nameTh: string
      nameEn: string
      customNotesLabelTh: string
      customNotesLabelEn: string
      skipCustomNotesScreen: boolean
      eligibleContainerType: ProductContainerTypeEnum
      categoryID: number
      maxSpicyLevel: SpicyLevelEnum
      tags: Array<ProductTagEnum>
      category: { id: number; nameTh: string; nameEn: string }
      images: Array<{ id: number; resizableURL: string; originalURL: string; type: ImageTypeEnum }>
    }>
  }>
}

export type LocationQueryVariables = Exact<{
  id: Scalars['Int']
  deliveryGeolocation?: InputMaybe<GeolocationInput>
  useRouteDistance?: InputMaybe<Scalars['Boolean']>
}>

export type LocationQuery = {
  location: {
    id: number
    active: boolean
    nameEn: string
    nameTh: string
    isInDeliveryArea: boolean
    approxDeliveryDistanceM: number
    geocodedAddressEn: string
    geocodedAddressTh: string
    deliveryContactPhone: string
    address: { id: number; address: string; detail: string; locationLabel: string; name: string }
    businessHours: Array<{ id: number; active: boolean; dayOfWeek: number; startTime: string; endTime: string }>
    vendor: {
      id: number
      nameEn: string
      nameTh: string
      quoteEn: string
      quoteTh: string
      descriptionTh: string
      descriptionEn: string
      canPickUp: boolean
      logoImage: { id: number; originalURL: string; resizableURL: string; type: ImageTypeEnum }
      images: Array<{
        id: number
        originalURL: string
        resizableURL: string
        thumbnailResizableURL: string
        type: ImageTypeEnum
      }>
    }
  }
}

export type LookupUnlinkedMembershipByCardIdQueryVariables = Exact<{
  vendorID: Scalars['Int']
  cardID: Scalars['String']
}>

export type LookupUnlinkedMembershipByCardIdQuery = {
  lookupUnlinkedMembershipByCardID: {
    status: MembershipLookUpEnumType
    membership: { firstName: string; lastName: string; memberID: string }
  }
}

export type MemberCouponQueryVariables = Exact<{
  couponID: Scalars['Int']
}>

export type MemberCouponQuery = {
  memberCoupon: {
    id: number
    createdAt: string
    endDate: string
    redeemedAt: string
    status: MemberCouponStatusEnumType
    promotion: {
      nameTh: string
      nameEn: string
      descriptionTh: string
      descriptionEn: string
      conditionDetailsTh: string
      conditionDetailsEn: string
      isSelfRedeemableCoupon: boolean
    }
  }
}

export type MembershipProgramQueryVariables = Exact<{
  vendorID: Scalars['Int']
}>

export type MembershipProgramQuery = {
  membershipProgram: {
    id: number
    membershipProvider: MembershipProviderEnum
    nameEn: string
    nameTh: string
    tiers: Array<{
      id: number
      rank: number
      refName: string
      cardImage: { type: ImageTypeEnum; originalURL: string; resizableURL: string }
    }>
    logoImage: { type: ImageTypeEnum; originalURL: string; resizableURL: string }
    images: Array<{ type: ImageTypeEnum; originalURL: string; resizableURL: string }>
  }
}

export type GetMembershipTierProgressQueryVariables = Exact<{ [key: string]: never }>

export type GetMembershipTierProgressQuery = {
  getMembershipTierProgress: {
    keepTier: { endDate: string; pointEarned: number; pointRequired: number; rank: number; tier: string }
    upTier: { endDate: string; pointEarned: number; pointRequired: number; rank: number; tier: string }
  }
}

export type ProductModifiersQueryVariables = Exact<{
  id: Scalars['Int']
  locationID?: InputMaybe<Scalars['Int']>
}>

export type ProductModifiersQuery = {
  product: {
    id: number
    modifierGroups: Array<{
      id: number
      active: boolean
      position: number
      maximumQuantity: number
      minimumQuantity: number
      modifierGroupID: number
      productID: number
      modifierGroup: {
        id: number
        nameEn: string
        nameTh: string
        vendorID: number
        multipleQuantity: boolean
        modifiers: Array<{
          id: number
          active: boolean
          nameEn: string
          nameTh: string
          position: number
          priceSatangs: number
          invalids: Array<InvalidModifierEnum>
          images: Array<{ id: number; alt: string; resizableURL: string }>
          product: { id: number; nameEn: string; nameTh: string }
        }>
      }
    }>
    promotionProduct: {
      productGroupRewards: Array<{
        rewardType: PromotionRewardTypeEnum
        rewardValue: number
        products: Array<{
          id: number
          priceSatangs: number
          totalPriceSatangs: number
          discountSatangs: number
          excludedFromPromotion: boolean
          nameTh: string
          nameEn: string
          categoryID: number
          tags: Array<ProductTagEnum>
          memberTierRankConditions: Array<ProductMemberTierRankConditionsEnum>
          memberIneligibleReason: MemberProductIneligibleReasonEnum
          skipCustomNotesScreen: boolean
          images: Array<{ id: number; resizableURL: string }>
          modifierGroups: Array<{
            id: number
            active: boolean
            position: number
            maximumQuantity: number
            minimumQuantity: number
            modifierGroupID: number
            productID: number
            modifierGroup: {
              id: number
              nameEn: string
              nameTh: string
              vendorID: number
              multipleQuantity: boolean
              modifiers: Array<{
                id: number
                active: boolean
                nameEn: string
                nameTh: string
                position: number
                priceSatangs: number
                invalids: Array<InvalidModifierEnum>
                images: Array<{ id: number; alt: string; resizableURL: string }>
              }>
            }
          }>
        }>
      }>
      promotion: { id: number; strategyType: PromotionStrategyTypeEnum; nameEn: string; nameTh: string }
    }
  }
}

export type RemoveCartAdjustmentMutationVariables = Exact<{
  promotionID?: InputMaybe<Scalars['Int']>
  externalCouponID?: InputMaybe<Scalars['Int']>
  cartID: Scalars['Int']
  hatoHeartMemberCouponID?: InputMaybe<Scalars['Int']>
}>

export type RemoveCartAdjustmentMutation = {
  removeCartAdjustment: {
    cartInvalids: Array<CartInvalidEnum>
    corrections: Array<CartCorrectionEnum>
    promotionInvalids: Array<CartPromotionInvalidEnum>
    cart: {
      id: number
      adjustments: Array<{
        id: number
        externalCouponID: number
        adjustmentType: AdjustmentTypeEnumType
        source: AdjustmentSourceEnumType
        invalidReason: AdjustmentInvalidReasonEnumType
        externalCoupon: { id: number; isAvailable: boolean; endTime: string; amount: number; serialNumber: string }
        promotion: { id: number; type: PromotionTypeEnum }
      }>
    }
  }
}

export type UserMembershipDataPackQueryVariables = Exact<{
  refresh?: InputMaybe<Scalars['Boolean']>
}>

export type UserMembershipDataPackQuery = {
  currentUser: {
    id: number
    email: string
    defaultPaymentMethod: CustomerPaymentTypeEnum
    defaultPaymentPhone: string
    language: string
    omiseCardID: string
    omiseCardBrand: string
    omiseCardLastDigits: string
    omiseCardholderName: string
    lineEmail: string
    extMembershipPhone: string
  }
  address: Array<{
    id: number
    name: string
    phone: string
    detail: string
    note: string
    locationLabel: string
    latitude: number
    longitude: number
    address: string
    buildingType: BuildingTypeEnum
    deliveryMethod: DeliveryMethodEnum
    callOnArrival: boolean
    lastUsed: boolean
    nickname: string
    temporary: boolean
    googlePlaceID: string
  }>
  currentMembership: {
    firstName: string
    id: number
    lastName: string
    memberID: string
    memberTier: string
    provider: MembershipProviderEnum
    totalPoints: number
    userID: number
    dateOfBirth: string
    gender: MembershipGenderEnum
    phone: string
    allowPromoMsg: boolean
    isSubscriptionActive: boolean
    subscriptionEndDate: string
    subscriptionPlan: string
    legacyCardIDs: Array<string>
    pointExpirations: Array<{ id: number; expirationDate: string; points: number }>
    currentMembershipSubscription: {
      membershipProgramSubscriptionOption: { coverImage: { originalURL: string; resizableURL: string } }
    }
  }
}

export type VendorQueryVariables = Exact<{
  id: Scalars['Int']
  deliveryGeolocation?: InputMaybe<GeolocationInput>
  useRouteDistance?: InputMaybe<Scalars['Boolean']>
}>

export type VendorQuery = {
  vendor: {
    id: number
    nameEn: string
    nameTh: string
    quoteEn: string
    quoteTh: string
    descriptionTh: string
    descriptionEn: string
    canPickUp: boolean
    logoImage: { id: number; originalURL: string; resizableURL: string; type: ImageTypeEnum }
    images: Array<{
      id: number
      originalURL: string
      resizableURL: string
      thumbnailResizableURL: string
      type: ImageTypeEnum
    }>
  }
}

export type VendorDetailDataPackQueryVariables = Exact<{
  vendorID: Scalars['Int']
  deliveryGeolocation?: InputMaybe<GeolocationInput>
  onlyActiveProduct?: InputMaybe<Scalars['Boolean']>
  locationID?: InputMaybe<Scalars['Int']>
}>

export type VendorDetailDataPackQuery = {
  listCategoriesForVendor: Array<{
    id: number
    nameEn: string
    nameTh: string
    position: number
    productColorScheme: ProductColorSchemeEnum
    images: Array<{
      id: number
      originalURL: string
      resizableURL: string
      thumbnailResizableURL: string
      type: ImageTypeEnum
    }>
  }>
  vendor: {
    id: number
    nameEn: string
    nameTh: string
    quoteEn: string
    quoteTh: string
    descriptionTh: string
    descriptionEn: string
    canPickUp: boolean
    logoImage: { id: number; originalURL: string; resizableURL: string; type: ImageTypeEnum }
    images: Array<{
      id: number
      originalURL: string
      resizableURL: string
      thumbnailResizableURL: string
      type: ImageTypeEnum
    }>
  }
}

export type VendorDetailDataPackWithNearestLocationQueryVariables = Exact<{
  vendorID: Scalars['Int']
  deliveryGeolocation?: InputMaybe<GeolocationInput>
  onlyActiveProduct?: InputMaybe<Scalars['Boolean']>
  locationID?: InputMaybe<Scalars['Int']>
}>

export type VendorDetailDataPackWithNearestLocationQuery = {
  listCategoriesForVendor: Array<{
    id: number
    nameEn: string
    nameTh: string
    position: number
    productColorScheme: ProductColorSchemeEnum
    images: Array<{
      id: number
      originalURL: string
      resizableURL: string
      thumbnailResizableURL: string
      type: ImageTypeEnum
    }>
  }>
  vendor: {
    id: number
    nameEn: string
    nameTh: string
    quoteEn: string
    quoteTh: string
    descriptionTh: string
    descriptionEn: string
    canPickUp: boolean
    logoImage: { id: number; originalURL: string; resizableURL: string; type: ImageTypeEnum }
    images: Array<{
      id: number
      originalURL: string
      resizableURL: string
      thumbnailResizableURL: string
      type: ImageTypeEnum
    }>
    nearestLocation: {
      id: number
      active: boolean
      nameEn: string
      nameTh: string
      isInDeliveryArea: boolean
      approxDeliveryDistanceM: number
      geocodedAddressEn: string
      geocodedAddressTh: string
      deliveryContactPhone: string
      address: { id: number; address: string; detail: string; locationLabel: string; name: string }
      businessHours: Array<{ id: number; active: boolean; dayOfWeek: number; startTime: string; endTime: string }>
      vendor: {
        id: number
        nameEn: string
        nameTh: string
        quoteEn: string
        quoteTh: string
        descriptionTh: string
        descriptionEn: string
        canPickUp: boolean
        logoImage: { id: number; originalURL: string; resizableURL: string; type: ImageTypeEnum }
        images: Array<{
          id: number
          originalURL: string
          resizableURL: string
          thumbnailResizableURL: string
          type: ImageTypeEnum
        }>
      }
    }
  }
}

export type VendorSettingQueryVariables = Exact<{
  vendorID: Scalars['Int']
}>

export type VendorSettingQuery = {
  vendorSetting: {
    id: number
    paymentMethods: Array<CustomerPaymentTypeEnum>
    vendorID: number
    orderMinimumLineItemTotalSatangs: number
    orderMinimumTotalSatangs: number
    excludingVat: boolean
    bankAccounts: Array<{ brand: BankBrandTypeEnum; name: string; number: string }>
  }
}

export declare const addCreditCard: import('graphql').DocumentNode
export declare const checkout: import('graphql').DocumentNode
export declare const feedback: import('graphql').DocumentNode
export declare const linkMembership: import('graphql').DocumentNode
export declare const linkMembershipByCardID: import('graphql').DocumentNode
export declare const redeemHHMemberCoupon: import('graphql').DocumentNode
export declare const redeemPoints: import('graphql').DocumentNode
export declare const removeCreditCard: import('graphql').DocumentNode
export declare const requestSmsOtp: import('graphql').DocumentNode
export declare const Address: import('graphql').DocumentNode
export declare const updateCartPaymentMethod: import('graphql').DocumentNode
export declare const updateCurrentUser: import('graphql').DocumentNode
export declare const updateLineEmailCurrentUser: import('graphql').DocumentNode
export declare const updateMembership: import('graphql').DocumentNode
export declare const applyPromoToCart: import('graphql').DocumentNode
export declare const cartAndFPMemberCoupons: import('graphql').DocumentNode
export declare const cartAndHHMemberCoupons: import('graphql').DocumentNode
export declare const checkPaymentStatus: import('graphql').DocumentNode
export declare const currentCart: import('graphql').DocumentNode
export declare const currentMembership: import('graphql').DocumentNode
export declare const currentUser: import('graphql').DocumentNode
export declare const currentUserCampaignDataPack: import('graphql').DocumentNode
export declare const deliveryFeeAndTimeByDistance: import('graphql').DocumentNode
export declare const featuredPromotionHH: import('graphql').DocumentNode
export declare const listCreditCards: import('graphql').DocumentNode
export declare const listLocationsForVendor: import('graphql').DocumentNode
export declare const listMemberCoupons: import('graphql').DocumentNode
export declare const listMembershipPromotions: import('graphql').DocumentNode
export declare const listOrders: import('graphql').DocumentNode
export declare const listPointRedemptionCampaigns: import('graphql').DocumentNode
export declare const listProductsV2: import('graphql').DocumentNode
export declare const listScheduledContents: import('graphql').DocumentNode
export declare const location: import('graphql').DocumentNode
export declare const lookupUnlinkedMembershipByCardID: import('graphql').DocumentNode
export declare const memberCoupon: import('graphql').DocumentNode
export declare const membershipProgram: import('graphql').DocumentNode
export declare const getMembershipTierProgress: import('graphql').DocumentNode
export declare const productModifiers: import('graphql').DocumentNode
export declare const removeCartAdjustment: import('graphql').DocumentNode
export declare const userMembershipDataPack: import('graphql').DocumentNode
export declare const vendor: import('graphql').DocumentNode
export declare const vendorDetailDataPack: import('graphql').DocumentNode
export declare const vendorDetailDataPackWithNearestLocation: import('graphql').DocumentNode
export declare const vendorSetting: import('graphql').DocumentNode

export const AddCreditCardDocument = gql`
  mutation addCreditCard($vendorID: Int!, $token: String!) {
    addCreditCard(vendorID: $vendorID, token: $token) {
      cardID
      cardBrand
      cardLastDigits
      cardholderName
    }
  }
`
export type AddCreditCardMutationFn = Apollo.MutationFunction<AddCreditCardMutation, AddCreditCardMutationVariables>

/**
 * __useAddCreditCardMutation__
 *
 * To run a mutation, you first call `useAddCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCreditCardMutation, { data, loading, error }] = useAddCreditCardMutation({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAddCreditCardMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCreditCardMutation, AddCreditCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddCreditCardMutation, AddCreditCardMutationVariables>(AddCreditCardDocument, options)
}
export type AddCreditCardMutationHookResult = ReturnType<typeof useAddCreditCardMutation>
export type AddCreditCardMutationResult = Apollo.MutationResult<AddCreditCardMutation>
export type AddCreditCardMutationOptions = Apollo.BaseMutationOptions<
  AddCreditCardMutation,
  AddCreditCardMutationVariables
>
export const CheckoutDocument = gql`
  mutation checkout($cartID: Int!, $paymentType: CustomerPaymentTypeEnum!, $phoneNumber: String, $returnURI: String) {
    checkout(cartID: $cartID, payment: { type: $paymentType, phoneNumber: $phoneNumber, returnURI: $returnURI }) {
      KPaymentResult {
        failureCode
        failureMessage
        phone
        status
      }
      corrections
      cartInvalids
      chargeResult {
        authorizeURI
        chargeID
        failureCode
        failureMessage
        status
      }
      order {
        id
        deliveryDate
        deliveryTimeslot
        payments {
          id
          type
        }
      }
    }
  }
`
export type CheckoutMutationFn = Apollo.MutationFunction<CheckoutMutation, CheckoutMutationVariables>

/**
 * __useCheckoutMutation__
 *
 * To run a mutation, you first call `useCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutMutation, { data, loading, error }] = useCheckoutMutation({
 *   variables: {
 *      cartID: // value for 'cartID'
 *      paymentType: // value for 'paymentType'
 *      phoneNumber: // value for 'phoneNumber'
 *      returnURI: // value for 'returnURI'
 *   },
 * });
 */
export function useCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<CheckoutMutation, CheckoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CheckoutMutation, CheckoutMutationVariables>(CheckoutDocument, options)
}
export type CheckoutMutationHookResult = ReturnType<typeof useCheckoutMutation>
export type CheckoutMutationResult = Apollo.MutationResult<CheckoutMutation>
export type CheckoutMutationOptions = Apollo.BaseMutationOptions<CheckoutMutation, CheckoutMutationVariables>
export const FeedbackDocument = gql`
  mutation feedback($id: Int, $orderID: Int, $rating: Int!, $message: String!, $issueTypes: [FeedbackIssueTypeEnum]) {
    feedback(feedback: { id: $id, orderID: $orderID, rating: $rating, message: $message, issueTypes: $issueTypes }) {
      id
      userID
      orderID
      rating
      message
      issueTypes
    }
  }
`
export type FeedbackMutationFn = Apollo.MutationFunction<FeedbackMutation, FeedbackMutationVariables>

/**
 * __useFeedbackMutation__
 *
 * To run a mutation, you first call `useFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [feedbackMutation, { data, loading, error }] = useFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orderID: // value for 'orderID'
 *      rating: // value for 'rating'
 *      message: // value for 'message'
 *      issueTypes: // value for 'issueTypes'
 *   },
 * });
 */
export function useFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<FeedbackMutation, FeedbackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FeedbackMutation, FeedbackMutationVariables>(FeedbackDocument, options)
}
export type FeedbackMutationHookResult = ReturnType<typeof useFeedbackMutation>
export type FeedbackMutationResult = Apollo.MutationResult<FeedbackMutation>
export type FeedbackMutationOptions = Apollo.BaseMutationOptions<FeedbackMutation, FeedbackMutationVariables>
export const LinkMembershipDocument = gql`
  mutation linkMembership(
    $otpPasscode: String!
    $vendorID: Int!
    $dateOfBirth: String
    $otpToken: String!
    $allowPromoMsg: Boolean
  ) {
    linkMembership(
      otpPasscode: $otpPasscode
      vendorID: $vendorID
      dateOfBirth: $dateOfBirth
      otpToken: $otpToken
      allowPromoMsg: $allowPromoMsg
    ) {
      membership {
        pointExpirations {
          id
          expirationDate
          points
        }
        firstName
        id
        lastName
        memberID
        memberTier
        provider
        totalPoints
        userID
        dateOfBirth
        gender
        phone
        allowPromoMsg
        isSubscriptionActive
        subscriptionEndDate
        subscriptionPlan
        legacyCardIDs
        currentMembershipSubscription {
          membershipProgramSubscriptionOption {
            coverImage {
              originalURL
              resizableURL
            }
          }
        }
      }
      status
    }
  }
`
export type LinkMembershipMutationFn = Apollo.MutationFunction<LinkMembershipMutation, LinkMembershipMutationVariables>

/**
 * __useLinkMembershipMutation__
 *
 * To run a mutation, you first call `useLinkMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkMembershipMutation, { data, loading, error }] = useLinkMembershipMutation({
 *   variables: {
 *      otpPasscode: // value for 'otpPasscode'
 *      vendorID: // value for 'vendorID'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      otpToken: // value for 'otpToken'
 *      allowPromoMsg: // value for 'allowPromoMsg'
 *   },
 * });
 */
export function useLinkMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkMembershipMutation, LinkMembershipMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LinkMembershipMutation, LinkMembershipMutationVariables>(LinkMembershipDocument, options)
}
export type LinkMembershipMutationHookResult = ReturnType<typeof useLinkMembershipMutation>
export type LinkMembershipMutationResult = Apollo.MutationResult<LinkMembershipMutation>
export type LinkMembershipMutationOptions = Apollo.BaseMutationOptions<
  LinkMembershipMutation,
  LinkMembershipMutationVariables
>
export const LinkMembershipByCardIdDocument = gql`
  mutation linkMembershipByCardID(
    $cardID: String!
    $firstName: String
    $lastName: String
    $otpToken: String!
    $otpPasscode: String!
    $vendorID: Int!
    $gender: MembershipGenderEnum
    $dateOfBirth: String
    $allowPromoMsg: Boolean
  ) {
    linkMembershipByCardID(
      cardID: $cardID
      vendorID: $vendorID
      firstName: $firstName
      lastName: $lastName
      otpToken: $otpToken
      otpPasscode: $otpPasscode
      gender: $gender
      dateOfBirth: $dateOfBirth
      allowPromoMsg: $allowPromoMsg
    ) {
      membership {
        pointExpirations {
          id
          expirationDate
          points
        }
        firstName
        id
        lastName
        memberID
        memberTier
        provider
        totalPoints
        userID
        dateOfBirth
        gender
        phone
        allowPromoMsg
        isSubscriptionActive
        subscriptionEndDate
        legacyCardIDs
        currentMembershipSubscription {
          membershipProgramSubscriptionOption {
            coverImage {
              originalURL
              resizableURL
            }
          }
        }
      }
      status
    }
  }
`
export type LinkMembershipByCardIdMutationFn = Apollo.MutationFunction<
  LinkMembershipByCardIdMutation,
  LinkMembershipByCardIdMutationVariables
>

/**
 * __useLinkMembershipByCardIdMutation__
 *
 * To run a mutation, you first call `useLinkMembershipByCardIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkMembershipByCardIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkMembershipByCardIdMutation, { data, loading, error }] = useLinkMembershipByCardIdMutation({
 *   variables: {
 *      cardID: // value for 'cardID'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      otpToken: // value for 'otpToken'
 *      otpPasscode: // value for 'otpPasscode'
 *      vendorID: // value for 'vendorID'
 *      gender: // value for 'gender'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      allowPromoMsg: // value for 'allowPromoMsg'
 *   },
 * });
 */
export function useLinkMembershipByCardIdMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkMembershipByCardIdMutation, LinkMembershipByCardIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LinkMembershipByCardIdMutation, LinkMembershipByCardIdMutationVariables>(
    LinkMembershipByCardIdDocument,
    options
  )
}
export type LinkMembershipByCardIdMutationHookResult = ReturnType<typeof useLinkMembershipByCardIdMutation>
export type LinkMembershipByCardIdMutationResult = Apollo.MutationResult<LinkMembershipByCardIdMutation>
export type LinkMembershipByCardIdMutationOptions = Apollo.BaseMutationOptions<
  LinkMembershipByCardIdMutation,
  LinkMembershipByCardIdMutationVariables
>
export const RedeemHhMemberCouponDocument = gql`
  mutation redeemHHMemberCoupon($couponID: Int!) {
    redeemHHMemberCoupon(couponID: $couponID) {
      error
      success
    }
  }
`
export type RedeemHhMemberCouponMutationFn = Apollo.MutationFunction<
  RedeemHhMemberCouponMutation,
  RedeemHhMemberCouponMutationVariables
>

/**
 * __useRedeemHhMemberCouponMutation__
 *
 * To run a mutation, you first call `useRedeemHhMemberCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemHhMemberCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemHhMemberCouponMutation, { data, loading, error }] = useRedeemHhMemberCouponMutation({
 *   variables: {
 *      couponID: // value for 'couponID'
 *   },
 * });
 */
export function useRedeemHhMemberCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<RedeemHhMemberCouponMutation, RedeemHhMemberCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RedeemHhMemberCouponMutation, RedeemHhMemberCouponMutationVariables>(
    RedeemHhMemberCouponDocument,
    options
  )
}
export type RedeemHhMemberCouponMutationHookResult = ReturnType<typeof useRedeemHhMemberCouponMutation>
export type RedeemHhMemberCouponMutationResult = Apollo.MutationResult<RedeemHhMemberCouponMutation>
export type RedeemHhMemberCouponMutationOptions = Apollo.BaseMutationOptions<
  RedeemHhMemberCouponMutation,
  RedeemHhMemberCouponMutationVariables
>
export const RedeemPointsDocument = gql`
  mutation redeemPoints($pointRedemptionCampaignID: Int!, $quantity: Int!) {
    redeemPoints(pointRedemptionCampaignID: $pointRedemptionCampaignID, quantity: $quantity) {
      failureReason
      success
      invalidConditions
      membership {
        pointExpirations {
          id
          expirationDate
          points
        }
        firstName
        id
        lastName
        memberID
        memberTier
        provider
        totalPoints
        userID
      }
    }
  }
`
export type RedeemPointsMutationFn = Apollo.MutationFunction<RedeemPointsMutation, RedeemPointsMutationVariables>

/**
 * __useRedeemPointsMutation__
 *
 * To run a mutation, you first call `useRedeemPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPointsMutation, { data, loading, error }] = useRedeemPointsMutation({
 *   variables: {
 *      pointRedemptionCampaignID: // value for 'pointRedemptionCampaignID'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useRedeemPointsMutation(
  baseOptions?: Apollo.MutationHookOptions<RedeemPointsMutation, RedeemPointsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RedeemPointsMutation, RedeemPointsMutationVariables>(RedeemPointsDocument, options)
}
export type RedeemPointsMutationHookResult = ReturnType<typeof useRedeemPointsMutation>
export type RedeemPointsMutationResult = Apollo.MutationResult<RedeemPointsMutation>
export type RedeemPointsMutationOptions = Apollo.BaseMutationOptions<
  RedeemPointsMutation,
  RedeemPointsMutationVariables
>
export const RemoveCreditCardDocument = gql`
  mutation removeCreditCard($vendorID: Int!, $cardID: String!) {
    removeCreditCard(vendorID: $vendorID, cardID: $cardID) {
      cardID
    }
  }
`
export type RemoveCreditCardMutationFn = Apollo.MutationFunction<
  RemoveCreditCardMutation,
  RemoveCreditCardMutationVariables
>

/**
 * __useRemoveCreditCardMutation__
 *
 * To run a mutation, you first call `useRemoveCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCreditCardMutation, { data, loading, error }] = useRemoveCreditCardMutation({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *      cardID: // value for 'cardID'
 *   },
 * });
 */
export function useRemoveCreditCardMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCreditCardMutation, RemoveCreditCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveCreditCardMutation, RemoveCreditCardMutationVariables>(
    RemoveCreditCardDocument,
    options
  )
}
export type RemoveCreditCardMutationHookResult = ReturnType<typeof useRemoveCreditCardMutation>
export type RemoveCreditCardMutationResult = Apollo.MutationResult<RemoveCreditCardMutation>
export type RemoveCreditCardMutationOptions = Apollo.BaseMutationOptions<
  RemoveCreditCardMutation,
  RemoveCreditCardMutationVariables
>
export const RequestSmsOtpDocument = gql`
  mutation requestSmsOtp($phone: String!, $vendorID: Int!) {
    requestSmsOtp(phone: $phone, vendorID: $vendorID) {
      phone
      token
      invalidReason
    }
  }
`
export type RequestSmsOtpMutationFn = Apollo.MutationFunction<RequestSmsOtpMutation, RequestSmsOtpMutationVariables>

/**
 * __useRequestSmsOtpMutation__
 *
 * To run a mutation, you first call `useRequestSmsOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSmsOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSmsOtpMutation, { data, loading, error }] = useRequestSmsOtpMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useRequestSmsOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestSmsOtpMutation, RequestSmsOtpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RequestSmsOtpMutation, RequestSmsOtpMutationVariables>(RequestSmsOtpDocument, options)
}
export type RequestSmsOtpMutationHookResult = ReturnType<typeof useRequestSmsOtpMutation>
export type RequestSmsOtpMutationResult = Apollo.MutationResult<RequestSmsOtpMutation>
export type RequestSmsOtpMutationOptions = Apollo.BaseMutationOptions<
  RequestSmsOtpMutation,
  RequestSmsOtpMutationVariables
>
export const AddressDocument = gql`
  mutation Address(
    $id: Int
    $name: String
    $phone: String
    $address: String
    $locationLabel: String
    $latitude: Float
    $longitude: Float
    $detail: String
    $note: String
    $buildingType: BuildingTypeEnum
    $deliveryMethod: DeliveryMethodEnum
    $callOnArrival: Boolean
    $skipAreaCheck: Boolean
    $nickname: String
    $temporary: Boolean
    $googlePlaceID: String
  ) {
    address(
      clearExistingAddresses: false
      skipAreaCheck: $skipAreaCheck
      address: {
        id: $id
        name: $name
        phone: $phone
        address: $address
        locationLabel: $locationLabel
        latitude: $latitude
        longitude: $longitude
        detail: $detail
        note: $note
        buildingType: $buildingType
        deliveryMethod: $deliveryMethod
        callOnArrival: $callOnArrival
        nickname: $nickname
        temporary: $temporary
        googlePlaceID: $googlePlaceID
      }
    ) {
      id
      name
      phone
      detail
      note
      locationLabel
      latitude
      longitude
      address
      buildingType
      deliveryMethod
      callOnArrival
      lastUsed
      nickname
      temporary
      googlePlaceID
    }
  }
`
export type AddressMutationFn = Apollo.MutationFunction<AddressMutation, AddressMutationVariables>

/**
 * __useAddressMutation__
 *
 * To run a mutation, you first call `useAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressMutation, { data, loading, error }] = useAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      address: // value for 'address'
 *      locationLabel: // value for 'locationLabel'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      detail: // value for 'detail'
 *      note: // value for 'note'
 *      buildingType: // value for 'buildingType'
 *      deliveryMethod: // value for 'deliveryMethod'
 *      callOnArrival: // value for 'callOnArrival'
 *      skipAreaCheck: // value for 'skipAreaCheck'
 *      nickname: // value for 'nickname'
 *      temporary: // value for 'temporary'
 *      googlePlaceID: // value for 'googlePlaceID'
 *   },
 * });
 */
export function useAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<AddressMutation, AddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddressMutation, AddressMutationVariables>(AddressDocument, options)
}
export type AddressMutationHookResult = ReturnType<typeof useAddressMutation>
export type AddressMutationResult = Apollo.MutationResult<AddressMutation>
export type AddressMutationOptions = Apollo.BaseMutationOptions<AddressMutation, AddressMutationVariables>
export const UpdateCartPaymentMethodDocument = gql`
  mutation updateCartPaymentMethod(
    $cartID: Int!
    $type: CustomerPaymentTypeEnum!
    $cardID: String
    $phoneNumber: String
  ) {
    updateCartPaymentMethod(cartID: $cartID, type: $type, cardID: $cardID, phoneNumber: $phoneNumber) {
      id
      paymentType
      paymentPhone
      omiseCardID
    }
  }
`
export type UpdateCartPaymentMethodMutationFn = Apollo.MutationFunction<
  UpdateCartPaymentMethodMutation,
  UpdateCartPaymentMethodMutationVariables
>

/**
 * __useUpdateCartPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateCartPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartPaymentMethodMutation, { data, loading, error }] = useUpdateCartPaymentMethodMutation({
 *   variables: {
 *      cartID: // value for 'cartID'
 *      type: // value for 'type'
 *      cardID: // value for 'cardID'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateCartPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCartPaymentMethodMutation, UpdateCartPaymentMethodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCartPaymentMethodMutation, UpdateCartPaymentMethodMutationVariables>(
    UpdateCartPaymentMethodDocument,
    options
  )
}
export type UpdateCartPaymentMethodMutationHookResult = ReturnType<typeof useUpdateCartPaymentMethodMutation>
export type UpdateCartPaymentMethodMutationResult = Apollo.MutationResult<UpdateCartPaymentMethodMutation>
export type UpdateCartPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  UpdateCartPaymentMethodMutation,
  UpdateCartPaymentMethodMutationVariables
>
export const UpdateCurrentUserDocument = gql`
  mutation updateCurrentUser($lineEmail: String, $extMembershipPhone: String) {
    updateCurrentUser(lineEmail: $lineEmail, extMembershipPhone: $extMembershipPhone) {
      id
      email
      defaultPaymentMethod
      defaultPaymentPhone
      language
      omiseCardID
      omiseCardBrand
      omiseCardLastDigits
      lineEmail
      extMembershipPhone
    }
  }
`
export type UpdateCurrentUserMutationFn = Apollo.MutationFunction<
  UpdateCurrentUserMutation,
  UpdateCurrentUserMutationVariables
>

/**
 * __useUpdateCurrentUserMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserMutation, { data, loading, error }] = useUpdateCurrentUserMutation({
 *   variables: {
 *      lineEmail: // value for 'lineEmail'
 *      extMembershipPhone: // value for 'extMembershipPhone'
 *   },
 * });
 */
export function useUpdateCurrentUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>(
    UpdateCurrentUserDocument,
    options
  )
}
export type UpdateCurrentUserMutationHookResult = ReturnType<typeof useUpdateCurrentUserMutation>
export type UpdateCurrentUserMutationResult = Apollo.MutationResult<UpdateCurrentUserMutation>
export type UpdateCurrentUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentUserMutation,
  UpdateCurrentUserMutationVariables
>
export const UpdateLineEmailCurrentUserDocument = gql`
  mutation updateLineEmailCurrentUser($lineEmail: String) {
    updateCurrentUser(lineEmail: $lineEmail) {
      id
      email
      defaultPaymentMethod
      defaultPaymentPhone
      language
      omiseCardID
      omiseCardBrand
      omiseCardLastDigits
      lineEmail
    }
  }
`
export type UpdateLineEmailCurrentUserMutationFn = Apollo.MutationFunction<
  UpdateLineEmailCurrentUserMutation,
  UpdateLineEmailCurrentUserMutationVariables
>

/**
 * __useUpdateLineEmailCurrentUserMutation__
 *
 * To run a mutation, you first call `useUpdateLineEmailCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLineEmailCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLineEmailCurrentUserMutation, { data, loading, error }] = useUpdateLineEmailCurrentUserMutation({
 *   variables: {
 *      lineEmail: // value for 'lineEmail'
 *   },
 * });
 */
export function useUpdateLineEmailCurrentUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLineEmailCurrentUserMutation,
    UpdateLineEmailCurrentUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLineEmailCurrentUserMutation, UpdateLineEmailCurrentUserMutationVariables>(
    UpdateLineEmailCurrentUserDocument,
    options
  )
}
export type UpdateLineEmailCurrentUserMutationHookResult = ReturnType<typeof useUpdateLineEmailCurrentUserMutation>
export type UpdateLineEmailCurrentUserMutationResult = Apollo.MutationResult<UpdateLineEmailCurrentUserMutation>
export type UpdateLineEmailCurrentUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateLineEmailCurrentUserMutation,
  UpdateLineEmailCurrentUserMutationVariables
>
export const UpdateMembershipDocument = gql`
  mutation updateMembership(
    $firstName: String
    $lastName: String
    $otpToken: String
    $otpPasscode: String
    $allowPromoMsg: Boolean
    $gender: MembershipGenderEnum
    $dateOfBirth: String
  ) {
    updateMembership(
      firstName: $firstName
      lastName: $lastName
      otpToken: $otpToken
      otpPasscode: $otpPasscode
      allowPromoMsg: $allowPromoMsg
      gender: $gender
      dateOfBirth: $dateOfBirth
    ) {
      membership {
        pointExpirations {
          id
          expirationDate
          points
        }
        firstName
        id
        lastName
        memberID
        memberTier
        provider
        totalPoints
        userID
        dateOfBirth
        gender
        phone
        allowPromoMsg
        isSubscriptionActive
        subscriptionEndDate
        subscriptionPlan
        legacyCardIDs
        currentMembershipSubscription {
          membershipProgramSubscriptionOption {
            coverImage {
              originalURL
              resizableURL
            }
          }
        }
      }
      status
    }
  }
`
export type UpdateMembershipMutationFn = Apollo.MutationFunction<
  UpdateMembershipMutation,
  UpdateMembershipMutationVariables
>

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      otpToken: // value for 'otpToken'
 *      otpPasscode: // value for 'otpPasscode'
 *      allowPromoMsg: // value for 'allowPromoMsg'
 *      gender: // value for 'gender'
 *      dateOfBirth: // value for 'dateOfBirth'
 *   },
 * });
 */
export function useUpdateMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(
    UpdateMembershipDocument,
    options
  )
}
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembershipMutation,
  UpdateMembershipMutationVariables
>
export const ApplyPromoToCartDocument = gql`
  mutation applyPromoToCart(
    $cartID: Int!
    $promoCode: String
    $externalMemberCouponRefID: String
    $hatoHeartMemberCouponID: Int
  ) {
    applyPromoToCart(
      cartID: $cartID
      promoCode: $promoCode
      externalMemberCouponRefID: $externalMemberCouponRefID
      hatoHeartMemberCouponID: $hatoHeartMemberCouponID
    ) {
      cart {
        id
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          promotionID
          externalCouponID
          externalCoupon {
            id
            isAvailable
            endTime
            amount
            serialNumber
            rewardType
            thumbnailImageURL
            name
          }
          adjustmentType
          source
          invalidReason
        }
      }
      cartInvalids
      corrections
      promotionInvalids
    }
  }
`
export type ApplyPromoToCartMutationFn = Apollo.MutationFunction<
  ApplyPromoToCartMutation,
  ApplyPromoToCartMutationVariables
>

/**
 * __useApplyPromoToCartMutation__
 *
 * To run a mutation, you first call `useApplyPromoToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPromoToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPromoToCartMutation, { data, loading, error }] = useApplyPromoToCartMutation({
 *   variables: {
 *      cartID: // value for 'cartID'
 *      promoCode: // value for 'promoCode'
 *      externalMemberCouponRefID: // value for 'externalMemberCouponRefID'
 *      hatoHeartMemberCouponID: // value for 'hatoHeartMemberCouponID'
 *   },
 * });
 */
export function useApplyPromoToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<ApplyPromoToCartMutation, ApplyPromoToCartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ApplyPromoToCartMutation, ApplyPromoToCartMutationVariables>(
    ApplyPromoToCartDocument,
    options
  )
}
export type ApplyPromoToCartMutationHookResult = ReturnType<typeof useApplyPromoToCartMutation>
export type ApplyPromoToCartMutationResult = Apollo.MutationResult<ApplyPromoToCartMutation>
export type ApplyPromoToCartMutationOptions = Apollo.BaseMutationOptions<
  ApplyPromoToCartMutation,
  ApplyPromoToCartMutationVariables
>
export const CartAndFpMemberCouponsDocument = gql`
  query cartAndFPMemberCoupons {
    cartAndFPMemberCoupons {
      cart {
        id
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          promotionID
          promotion {
            descriptionEn
            descriptionTh
            id
            nameEn
            nameTh
            type
            memberTierRankConditions
            targetEntity
          }
          memberCouponID
          memberCoupon {
            id
            endDate
            redeemedAt
            startDate
            status
            promotion {
              id
              conditionDetailsEn
              conditionDetailsTh
              descriptionEn
              descriptionTh
              extPromotionID
              nameEn
              nameTh
              images {
                resizeableURL
              }
            }
          }
          externalCouponID
          externalCoupon {
            id
            isAvailable
            endTime
            amount
            serialNumber
            rewardType
            thumbnailImageURL
            name
            description
            memberCouponRefID
          }
          adjustmentType
          source
          invalidReason
          invalidData {
            minimumSatangs
            remainingMinimumSatangs
          }
        }
      }
      coupons {
        id
        isAvailable
        endTime
        amount
        serialNumber
        rewardType
        thumbnailImageURL
        name
        memberCouponRefID
      }
      autoPromotions {
        id
        code
        descriptionEn
        descriptionTh
        nameEn
        nameTh
        memberTierRankConditions
        targetEntity
      }
      manualPromotions {
        id
        code
        descriptionEn
        descriptionTh
        nameEn
        nameTh
        memberTierRankConditions
        targetEntity
      }
    }
  }
`

/**
 * __useCartAndFpMemberCouponsQuery__
 *
 * To run a query within a React component, call `useCartAndFpMemberCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartAndFpMemberCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartAndFpMemberCouponsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartAndFpMemberCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<CartAndFpMemberCouponsQuery, CartAndFpMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CartAndFpMemberCouponsQuery, CartAndFpMemberCouponsQueryVariables>(
    CartAndFpMemberCouponsDocument,
    options
  )
}
export function useCartAndFpMemberCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartAndFpMemberCouponsQuery, CartAndFpMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CartAndFpMemberCouponsQuery, CartAndFpMemberCouponsQueryVariables>(
    CartAndFpMemberCouponsDocument,
    options
  )
}
export type CartAndFpMemberCouponsQueryHookResult = ReturnType<typeof useCartAndFpMemberCouponsQuery>
export type CartAndFpMemberCouponsLazyQueryHookResult = ReturnType<typeof useCartAndFpMemberCouponsLazyQuery>
export type CartAndFpMemberCouponsQueryResult = Apollo.QueryResult<
  CartAndFpMemberCouponsQuery,
  CartAndFpMemberCouponsQueryVariables
>
export const CartAndHhMemberCouponsDocument = gql`
  query cartAndHHMemberCoupons {
    cartAndHHMemberCoupons {
      cart {
        id
        adjustments {
          labelEn
          labelTh
          amountSatangs
          promotionID
          promotion {
            descriptionEn
            descriptionTh
            id
            nameEn
            nameTh
            type
            memberTierRankConditions
            targetEntity
          }
          memberCouponID
          memberCoupon {
            id
            endDate
            redeemedAt
            startDate
            status
            promotion {
              id
              conditionDetailsEn
              conditionDetailsTh
              descriptionEn
              descriptionTh
              extPromotionID
              nameEn
              nameTh
              images {
                resizeableURL
              }
            }
          }
          externalCouponID
          externalCoupon {
            id
            isAvailable
            endTime
            amount
            serialNumber
            rewardType
            thumbnailImageURL
            name
            description
          }
          adjustmentType
          source
          invalidReason
          invalidData {
            minimumSatangs
            remainingMinimumSatangs
          }
        }
      }
      coupons {
        isAvailable
        coupon {
          id
          endDate
          redeemedAt
          startDate
          status
          promotion {
            id
            conditionDetailsEn
            conditionDetailsTh
            descriptionEn
            descriptionTh
            extPromotionID
            nameEn
            nameTh
            images {
              resizeableURL
            }
          }
        }
      }
      autoPromotions {
        id
        code
        descriptionEn
        descriptionTh
        nameEn
        nameTh
        memberTierRankConditions
        targetEntity
      }
      manualPromotions {
        id
        code
        descriptionEn
        descriptionTh
        nameEn
        nameTh
        memberTierRankConditions
        targetEntity
      }
    }
  }
`

/**
 * __useCartAndHhMemberCouponsQuery__
 *
 * To run a query within a React component, call `useCartAndHhMemberCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartAndHhMemberCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartAndHhMemberCouponsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartAndHhMemberCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<CartAndHhMemberCouponsQuery, CartAndHhMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CartAndHhMemberCouponsQuery, CartAndHhMemberCouponsQueryVariables>(
    CartAndHhMemberCouponsDocument,
    options
  )
}
export function useCartAndHhMemberCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartAndHhMemberCouponsQuery, CartAndHhMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CartAndHhMemberCouponsQuery, CartAndHhMemberCouponsQueryVariables>(
    CartAndHhMemberCouponsDocument,
    options
  )
}
export type CartAndHhMemberCouponsQueryHookResult = ReturnType<typeof useCartAndHhMemberCouponsQuery>
export type CartAndHhMemberCouponsLazyQueryHookResult = ReturnType<typeof useCartAndHhMemberCouponsLazyQuery>
export type CartAndHhMemberCouponsQueryResult = Apollo.QueryResult<
  CartAndHhMemberCouponsQuery,
  CartAndHhMemberCouponsQueryVariables
>
export const CheckPaymentStatusDocument = gql`
  query checkPaymentStatus($orderID: Int!) {
    checkPaymentStatus(orderID: $orderID) {
      id
      status
      failureReason
      failureMessage
    }
  }
`

/**
 * __useCheckPaymentStatusQuery__
 *
 * To run a query within a React component, call `useCheckPaymentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPaymentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPaymentStatusQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useCheckPaymentStatusQuery(
  baseOptions: Apollo.QueryHookOptions<CheckPaymentStatusQuery, CheckPaymentStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckPaymentStatusQuery, CheckPaymentStatusQueryVariables>(CheckPaymentStatusDocument, options)
}
export function useCheckPaymentStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckPaymentStatusQuery, CheckPaymentStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckPaymentStatusQuery, CheckPaymentStatusQueryVariables>(
    CheckPaymentStatusDocument,
    options
  )
}
export type CheckPaymentStatusQueryHookResult = ReturnType<typeof useCheckPaymentStatusQuery>
export type CheckPaymentStatusLazyQueryHookResult = ReturnType<typeof useCheckPaymentStatusLazyQuery>
export type CheckPaymentStatusQueryResult = Apollo.QueryResult<
  CheckPaymentStatusQuery,
  CheckPaymentStatusQueryVariables
>
export const CurrentCartDocument = gql`
  query currentCart($vendorID: Int!) {
    currentCart(vendorID: $vendorID) {
      adjustments {
        id
        labelEn
        labelTh
        fullAmountSatangs
        adjustmentType
        amountSatangs
        promotion {
          type
        }
      }
    }
  }
`

/**
 * __useCurrentCartQuery__
 *
 * To run a query within a React component, call `useCurrentCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCartQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useCurrentCartQuery(baseOptions: Apollo.QueryHookOptions<CurrentCartQuery, CurrentCartQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentCartQuery, CurrentCartQueryVariables>(CurrentCartDocument, options)
}
export function useCurrentCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentCartQuery, CurrentCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentCartQuery, CurrentCartQueryVariables>(CurrentCartDocument, options)
}
export type CurrentCartQueryHookResult = ReturnType<typeof useCurrentCartQuery>
export type CurrentCartLazyQueryHookResult = ReturnType<typeof useCurrentCartLazyQuery>
export type CurrentCartQueryResult = Apollo.QueryResult<CurrentCartQuery, CurrentCartQueryVariables>
export const CurrentMembershipDocument = gql`
  query currentMembership($refresh: Boolean) {
    currentMembership(refresh: $refresh) {
      pointExpirations {
        id
        expirationDate
        points
      }
      firstName
      id
      lastName
      memberID
      memberTier
      provider
      totalPoints
      userID
      dateOfBirth
      gender
      phone
      allowPromoMsg
      isSubscriptionActive
      subscriptionEndDate
      subscriptionPlan
      legacyCardIDs
      currentMembershipSubscription {
        membershipProgramSubscriptionOption {
          coverImage {
            originalURL
            resizableURL
          }
        }
      }
    }
  }
`

/**
 * __useCurrentMembershipQuery__
 *
 * To run a query within a React component, call `useCurrentMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentMembershipQuery({
 *   variables: {
 *      refresh: // value for 'refresh'
 *   },
 * });
 */
export function useCurrentMembershipQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentMembershipQuery, CurrentMembershipQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentMembershipQuery, CurrentMembershipQueryVariables>(CurrentMembershipDocument, options)
}
export function useCurrentMembershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentMembershipQuery, CurrentMembershipQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentMembershipQuery, CurrentMembershipQueryVariables>(
    CurrentMembershipDocument,
    options
  )
}
export type CurrentMembershipQueryHookResult = ReturnType<typeof useCurrentMembershipQuery>
export type CurrentMembershipLazyQueryHookResult = ReturnType<typeof useCurrentMembershipLazyQuery>
export type CurrentMembershipQueryResult = Apollo.QueryResult<CurrentMembershipQuery, CurrentMembershipQueryVariables>
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      id
      email
      defaultPaymentMethod
      defaultPaymentPhone
      language
      omiseCardID
      omiseCardBrand
      omiseCardLastDigits
      lineEmail
      extMembershipPhone
    }
  }
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>
export const CurrentUserCampaignDataPackDocument = gql`
  query currentUserCampaignDataPack {
    currentUser {
      id
      linePicture
      email
      defaultPaymentMethod
      defaultPaymentPhone
      language
      omiseCardID
      omiseCardBrand
      omiseCardLastDigits
      omiseCardholderName
      lineEmail
      extMembershipPhone
    }
    currentMembership {
      pointExpirations {
        id
        expirationDate
        points
      }
      firstName
      id
      lastName
      memberID
      memberTier
      provider
      totalPoints
      userID
      dateOfBirth
      gender
      phone
      allowPromoMsg
      isSubscriptionActive
      subscriptionEndDate
      subscriptionPlan
      legacyCardIDs
      currentMembershipSubscription {
        membershipProgramSubscriptionOption {
          coverImage {
            originalURL
            resizableURL
          }
        }
      }
    }
  }
`

/**
 * __useCurrentUserCampaignDataPackQuery__
 *
 * To run a query within a React component, call `useCurrentUserCampaignDataPackQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserCampaignDataPackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserCampaignDataPackQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserCampaignDataPackQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserCampaignDataPackQuery, CurrentUserCampaignDataPackQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserCampaignDataPackQuery, CurrentUserCampaignDataPackQueryVariables>(
    CurrentUserCampaignDataPackDocument,
    options
  )
}
export function useCurrentUserCampaignDataPackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserCampaignDataPackQuery, CurrentUserCampaignDataPackQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserCampaignDataPackQuery, CurrentUserCampaignDataPackQueryVariables>(
    CurrentUserCampaignDataPackDocument,
    options
  )
}
export type CurrentUserCampaignDataPackQueryHookResult = ReturnType<typeof useCurrentUserCampaignDataPackQuery>
export type CurrentUserCampaignDataPackLazyQueryHookResult = ReturnType<typeof useCurrentUserCampaignDataPackLazyQuery>
export type CurrentUserCampaignDataPackQueryResult = Apollo.QueryResult<
  CurrentUserCampaignDataPackQuery,
  CurrentUserCampaignDataPackQueryVariables
>
export const DeliveryFeeAndTimeByDistanceDocument = gql`
  query deliveryFeeAndTimeByDistance(
    $lineItemQuantity: Int!
    $locationID: Int!
    $deliveryGeolocation: GeolocationInput!
    $isPickUp: Boolean
  ) {
    deliveryFeeAndTimeByDistance(
      lineItemQuantity: $lineItemQuantity
      locationID: $locationID
      deliveryGeolocation: $deliveryGeolocation
      isPickUp: $isPickUp
    ) {
      deliverableDays {
        date
        timeslots
      }
    }
  }
`

/**
 * __useDeliveryFeeAndTimeByDistanceQuery__
 *
 * To run a query within a React component, call `useDeliveryFeeAndTimeByDistanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryFeeAndTimeByDistanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryFeeAndTimeByDistanceQuery({
 *   variables: {
 *      lineItemQuantity: // value for 'lineItemQuantity'
 *      locationID: // value for 'locationID'
 *      deliveryGeolocation: // value for 'deliveryGeolocation'
 *      isPickUp: // value for 'isPickUp'
 *   },
 * });
 */
export function useDeliveryFeeAndTimeByDistanceQuery(
  baseOptions: Apollo.QueryHookOptions<DeliveryFeeAndTimeByDistanceQuery, DeliveryFeeAndTimeByDistanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DeliveryFeeAndTimeByDistanceQuery, DeliveryFeeAndTimeByDistanceQueryVariables>(
    DeliveryFeeAndTimeByDistanceDocument,
    options
  )
}
export function useDeliveryFeeAndTimeByDistanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeliveryFeeAndTimeByDistanceQuery,
    DeliveryFeeAndTimeByDistanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DeliveryFeeAndTimeByDistanceQuery, DeliveryFeeAndTimeByDistanceQueryVariables>(
    DeliveryFeeAndTimeByDistanceDocument,
    options
  )
}
export type DeliveryFeeAndTimeByDistanceQueryHookResult = ReturnType<typeof useDeliveryFeeAndTimeByDistanceQuery>
export type DeliveryFeeAndTimeByDistanceLazyQueryHookResult = ReturnType<
  typeof useDeliveryFeeAndTimeByDistanceLazyQuery
>
export type DeliveryFeeAndTimeByDistanceQueryResult = Apollo.QueryResult<
  DeliveryFeeAndTimeByDistanceQuery,
  DeliveryFeeAndTimeByDistanceQueryVariables
>
export const FeaturedPromotionHhDocument = gql`
  query featuredPromotionHH($vendorID: Int!) {
    cartAndHHMemberCoupons(vendorID: $vendorID) {
      featuredPromotion {
        promotionID
        promotionNameEn
        promotionNameTh
        promotionDescriptionEn
        promotionDescriptionTh
        targetEntity
        memberTierRankConditions
        isApplied
        adjustmentInvalidReason
        adjustmentInvalidData {
          remainingMinimumSatangs
          minimumSatangs
        }
      }
    }
  }
`

/**
 * __useFeaturedPromotionHhQuery__
 *
 * To run a query within a React component, call `useFeaturedPromotionHhQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedPromotionHhQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedPromotionHhQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useFeaturedPromotionHhQuery(
  baseOptions: Apollo.QueryHookOptions<FeaturedPromotionHhQuery, FeaturedPromotionHhQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FeaturedPromotionHhQuery, FeaturedPromotionHhQueryVariables>(
    FeaturedPromotionHhDocument,
    options
  )
}
export function useFeaturedPromotionHhLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FeaturedPromotionHhQuery, FeaturedPromotionHhQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FeaturedPromotionHhQuery, FeaturedPromotionHhQueryVariables>(
    FeaturedPromotionHhDocument,
    options
  )
}
export type FeaturedPromotionHhQueryHookResult = ReturnType<typeof useFeaturedPromotionHhQuery>
export type FeaturedPromotionHhLazyQueryHookResult = ReturnType<typeof useFeaturedPromotionHhLazyQuery>
export type FeaturedPromotionHhQueryResult = Apollo.QueryResult<
  FeaturedPromotionHhQuery,
  FeaturedPromotionHhQueryVariables
>
export const ListCreditCardsDocument = gql`
  query listCreditCards($vendorID: Int!) {
    listCreditCards(vendorID: $vendorID) {
      cardID
      cardBrand
      cardLastDigits
      cardholderName
    }
  }
`

/**
 * __useListCreditCardsQuery__
 *
 * To run a query within a React component, call `useListCreditCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCreditCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCreditCardsQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useListCreditCardsQuery(
  baseOptions: Apollo.QueryHookOptions<ListCreditCardsQuery, ListCreditCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListCreditCardsQuery, ListCreditCardsQueryVariables>(ListCreditCardsDocument, options)
}
export function useListCreditCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCreditCardsQuery, ListCreditCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListCreditCardsQuery, ListCreditCardsQueryVariables>(ListCreditCardsDocument, options)
}
export type ListCreditCardsQueryHookResult = ReturnType<typeof useListCreditCardsQuery>
export type ListCreditCardsLazyQueryHookResult = ReturnType<typeof useListCreditCardsLazyQuery>
export type ListCreditCardsQueryResult = Apollo.QueryResult<ListCreditCardsQuery, ListCreditCardsQueryVariables>
export const ListLocationsForVendorDocument = gql`
  query listLocationsForVendor(
    $vendorID: Int!
    $deliveryGeolocation: GeolocationInput
    $size: Int
    $afterLocationID: Int
    $keyword: String
    $useRouteDistance: Boolean
    $includeOutOfDeliveryArea: Boolean
  ) {
    listLocationsForVendor(
      vendorID: $vendorID
      deliveryGeolocation: $deliveryGeolocation
      size: $size
      afterLocationID: $afterLocationID
      keyword: $keyword
      useRouteDistance: $useRouteDistance
      includeOutOfDeliveryArea: $includeOutOfDeliveryArea
    ) {
      id
      nameEn
      nameTh
      approxDeliveryDistanceM
      geocodedDistrictEn
      geocodedDistrictTh
      latitude
      longitude
      vendor {
        id
        logoImage {
          id
          thumbnailResizableURL
          resizableURL
        }
      }
    }
  }
`

/**
 * __useListLocationsForVendorQuery__
 *
 * To run a query within a React component, call `useListLocationsForVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationsForVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationsForVendorQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *      deliveryGeolocation: // value for 'deliveryGeolocation'
 *      size: // value for 'size'
 *      afterLocationID: // value for 'afterLocationID'
 *      keyword: // value for 'keyword'
 *      useRouteDistance: // value for 'useRouteDistance'
 *      includeOutOfDeliveryArea: // value for 'includeOutOfDeliveryArea'
 *   },
 * });
 */
export function useListLocationsForVendorQuery(
  baseOptions: Apollo.QueryHookOptions<ListLocationsForVendorQuery, ListLocationsForVendorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListLocationsForVendorQuery, ListLocationsForVendorQueryVariables>(
    ListLocationsForVendorDocument,
    options
  )
}
export function useListLocationsForVendorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListLocationsForVendorQuery, ListLocationsForVendorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListLocationsForVendorQuery, ListLocationsForVendorQueryVariables>(
    ListLocationsForVendorDocument,
    options
  )
}
export type ListLocationsForVendorQueryHookResult = ReturnType<typeof useListLocationsForVendorQuery>
export type ListLocationsForVendorLazyQueryHookResult = ReturnType<typeof useListLocationsForVendorLazyQuery>
export type ListLocationsForVendorQueryResult = Apollo.QueryResult<
  ListLocationsForVendorQuery,
  ListLocationsForVendorQueryVariables
>
export const ListMemberCouponsDocument = gql`
  query listMemberCoupons($statuses: [MemberCouponStatusEnumType]!, $pagination: PaginationInputType) {
    listMemberCoupons(statuses: $statuses, pagination: $pagination) {
      memberCoupons {
        endDate
        id
        promotion {
          conditionDetailsEn
          conditionDetailsTh
          descriptionEn
          descriptionTh
          extPromotionID
          id
          images {
            resizeableURL
          }
          nameEn
          nameTh
          isSelfRedeemableCoupon
        }
        redeemedAt
        startDate
        status
        code
      }
      pagination {
        count
        limit
        offset
        total
      }
    }
  }
`

/**
 * __useListMemberCouponsQuery__
 *
 * To run a query within a React component, call `useListMemberCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMemberCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMemberCouponsQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListMemberCouponsQuery(
  baseOptions: Apollo.QueryHookOptions<ListMemberCouponsQuery, ListMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListMemberCouponsQuery, ListMemberCouponsQueryVariables>(ListMemberCouponsDocument, options)
}
export function useListMemberCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMemberCouponsQuery, ListMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListMemberCouponsQuery, ListMemberCouponsQueryVariables>(
    ListMemberCouponsDocument,
    options
  )
}
export type ListMemberCouponsQueryHookResult = ReturnType<typeof useListMemberCouponsQuery>
export type ListMemberCouponsLazyQueryHookResult = ReturnType<typeof useListMemberCouponsLazyQuery>
export type ListMemberCouponsQueryResult = Apollo.QueryResult<ListMemberCouponsQuery, ListMemberCouponsQueryVariables>
export const ListMembershipPromotionsDocument = gql`
  query listMembershipPromotions($limit: Int!, $type: MembershipPromotionTypeEnum, $offset: Int!) {
    listMembershipPromotions(limit: $limit, type: $type, offset: $offset) {
      membershipPromotions {
        id
        membershipProgramID
        active
        nameEn
        nameTh
        descriptionEn
        descriptionTh
        conditionDetailsEn
        conditionDetailsTh
        endTime
        images {
          resizableURL
        }
        ineligibleReasons
        points
        type
        hashedID
      }
      pagination {
        count
        limit
        offset
        total
      }
    }
  }
`

/**
 * __useListMembershipPromotionsQuery__
 *
 * To run a query within a React component, call `useListMembershipPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMembershipPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMembershipPromotionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      type: // value for 'type'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListMembershipPromotionsQuery(
  baseOptions: Apollo.QueryHookOptions<ListMembershipPromotionsQuery, ListMembershipPromotionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListMembershipPromotionsQuery, ListMembershipPromotionsQueryVariables>(
    ListMembershipPromotionsDocument,
    options
  )
}
export function useListMembershipPromotionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMembershipPromotionsQuery, ListMembershipPromotionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListMembershipPromotionsQuery, ListMembershipPromotionsQueryVariables>(
    ListMembershipPromotionsDocument,
    options
  )
}
export type ListMembershipPromotionsQueryHookResult = ReturnType<typeof useListMembershipPromotionsQuery>
export type ListMembershipPromotionsLazyQueryHookResult = ReturnType<typeof useListMembershipPromotionsLazyQuery>
export type ListMembershipPromotionsQueryResult = Apollo.QueryResult<
  ListMembershipPromotionsQuery,
  ListMembershipPromotionsQueryVariables
>
export const ListOrdersDocument = gql`
  query listOrders($limit: Int!, $offset: Int, $filter: CustomerOrderFilter) {
    listOrders(limit: $limit, offset: $offset, filter: $filter) {
      orders {
        id
        deliveryDate
        lineItemTotalSatangs
        lineItems {
          id
          quantity
          totalSatangs
          product {
            id
            nameEn
            nameTh
            images {
              id
              resizableURL
            }
          }
        }
        location {
          id
          vendorID
        }
      }
      pagination {
        count
        limit
        offset
        total
      }
    }
  }
`

/**
 * __useListOrdersQuery__
 *
 * To run a query within a React component, call `useListOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrdersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListOrdersQuery(baseOptions: Apollo.QueryHookOptions<ListOrdersQuery, ListOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListOrdersQuery, ListOrdersQueryVariables>(ListOrdersDocument, options)
}
export function useListOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListOrdersQuery, ListOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListOrdersQuery, ListOrdersQueryVariables>(ListOrdersDocument, options)
}
export type ListOrdersQueryHookResult = ReturnType<typeof useListOrdersQuery>
export type ListOrdersLazyQueryHookResult = ReturnType<typeof useListOrdersLazyQuery>
export type ListOrdersQueryResult = Apollo.QueryResult<ListOrdersQuery, ListOrdersQueryVariables>
export const ListPointRedemptionCampaignsDocument = gql`
  query listPointRedemptionCampaigns($limit: Int!, $offset: Int) {
    listPointRedemptionCampaigns(limit: $limit, offset: $offset) {
      id
      active
      images {
        resizableURL
        originalURL
        id
      }
      nameEn
      nameTh
      points
      startTime
      endTime
      descriptionEn
      descriptionTh
      conditionDetailsEn
      conditionDetailsTh
      conditionTimeOfDayStart
      conditionTimeOfDayEnd
      vendor {
        id
        nameEn
        nameTh
      }
      invalidConditions
    }
  }
`

/**
 * __useListPointRedemptionCampaignsQuery__
 *
 * To run a query within a React component, call `useListPointRedemptionCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPointRedemptionCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPointRedemptionCampaignsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPointRedemptionCampaignsQuery(
  baseOptions: Apollo.QueryHookOptions<ListPointRedemptionCampaignsQuery, ListPointRedemptionCampaignsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListPointRedemptionCampaignsQuery, ListPointRedemptionCampaignsQueryVariables>(
    ListPointRedemptionCampaignsDocument,
    options
  )
}
export function useListPointRedemptionCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPointRedemptionCampaignsQuery,
    ListPointRedemptionCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListPointRedemptionCampaignsQuery, ListPointRedemptionCampaignsQueryVariables>(
    ListPointRedemptionCampaignsDocument,
    options
  )
}
export type ListPointRedemptionCampaignsQueryHookResult = ReturnType<typeof useListPointRedemptionCampaignsQuery>
export type ListPointRedemptionCampaignsLazyQueryHookResult = ReturnType<
  typeof useListPointRedemptionCampaignsLazyQuery
>
export type ListPointRedemptionCampaignsQueryResult = Apollo.QueryResult<
  ListPointRedemptionCampaignsQuery,
  ListPointRedemptionCampaignsQueryVariables
>
export const ListProductsV2Document = gql`
  query listProductsV2(
    $vendorID: Int
    $locationID: Int
    $categoryID: Int
    $limit: Int
    $offset: Int
    $keyword: String
  ) {
    listProductsV2(
      vendorID: $vendorID
      locationID: $locationID
      categoryID: $categoryID
      limit: $limit
      offset: $offset
      keyword: $keyword
    ) {
      pagination {
        count
        limit
        offset
        total
      }
      products {
        sku
        id
        active
        locationProducts {
          id
          inStockQuantity
        }
        derivedColorScheme
        discountSatangs
        priceSatangs
        totalPriceSatangs
        nameTh
        nameEn
        customNotesLabelTh
        customNotesLabelEn
        skipCustomNotesScreen
        eligibleContainerType
        excludedFromPromotion
        category {
          id
          nameTh
          nameEn
        }
        categoryID
        maxSpicyLevel
        maxQuantity
        images {
          id
          resizableURL
          thumbnailResizableURL
          originalURL
        }
        tags
        vendor {
          id
          nameTh
          nameEn
          logoImage {
            id
            resizableURL
          }
        }
        modifierGroups {
          id
          active
          position
        }
        memberTierRankConditions
        memberIneligibleReason
        promotionProduct {
          productGroupRewards {
            productIDs
          }
          promotion {
            id
            strategyType
          }
        }
        promotionBadge
      }
    }
  }
`

/**
 * __useListProductsV2Query__
 *
 * To run a query within a React component, call `useListProductsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useListProductsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProductsV2Query({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *      locationID: // value for 'locationID'
 *      categoryID: // value for 'categoryID'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useListProductsV2Query(
  baseOptions?: Apollo.QueryHookOptions<ListProductsV2Query, ListProductsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListProductsV2Query, ListProductsV2QueryVariables>(ListProductsV2Document, options)
}
export function useListProductsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListProductsV2Query, ListProductsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListProductsV2Query, ListProductsV2QueryVariables>(ListProductsV2Document, options)
}
export type ListProductsV2QueryHookResult = ReturnType<typeof useListProductsV2Query>
export type ListProductsV2LazyQueryHookResult = ReturnType<typeof useListProductsV2LazyQuery>
export type ListProductsV2QueryResult = Apollo.QueryResult<ListProductsV2Query, ListProductsV2QueryVariables>
export const ListScheduledContentsDocument = gql`
  query listScheduledContents($offset: Int!, $limit: Int!, $filter: ScheduledContentFilter!) {
    listScheduledContents(offset: $offset, limit: $limit, filter: $filter) {
      id
      titleEn
      titleTh
      descriptionEn
      descriptionTh
      placement
      type
      uri
      vendorID
      categoryID
      images {
        id
        originalURL
        resizableURL
        type
      }
      products {
        sku
        id
        active
        derivedColorScheme
        discountSatangs
        priceSatangs
        totalPriceSatangs
        nameTh
        nameEn
        customNotesLabelTh
        customNotesLabelEn
        skipCustomNotesScreen
        eligibleContainerType
        category {
          id
          nameTh
          nameEn
        }
        categoryID
        maxSpicyLevel
        images {
          id
          resizableURL
          originalURL
          type
        }
        tags
      }
    }
  }
`

/**
 * __useListScheduledContentsQuery__
 *
 * To run a query within a React component, call `useListScheduledContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListScheduledContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListScheduledContentsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListScheduledContentsQuery(
  baseOptions: Apollo.QueryHookOptions<ListScheduledContentsQuery, ListScheduledContentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListScheduledContentsQuery, ListScheduledContentsQueryVariables>(
    ListScheduledContentsDocument,
    options
  )
}
export function useListScheduledContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListScheduledContentsQuery, ListScheduledContentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListScheduledContentsQuery, ListScheduledContentsQueryVariables>(
    ListScheduledContentsDocument,
    options
  )
}
export type ListScheduledContentsQueryHookResult = ReturnType<typeof useListScheduledContentsQuery>
export type ListScheduledContentsLazyQueryHookResult = ReturnType<typeof useListScheduledContentsLazyQuery>
export type ListScheduledContentsQueryResult = Apollo.QueryResult<
  ListScheduledContentsQuery,
  ListScheduledContentsQueryVariables
>
export const LocationDocument = gql`
  query location($id: Int!, $deliveryGeolocation: GeolocationInput, $useRouteDistance: Boolean) {
    location(id: $id, deliveryGeolocation: $deliveryGeolocation, useRouteDistance: $useRouteDistance) {
      id
      active
      nameEn
      nameTh
      isInDeliveryArea
      approxDeliveryDistanceM
      geocodedAddressEn
      geocodedAddressTh
      address {
        id
        address
        detail
        locationLabel
        name
      }
      businessHours {
        id
        active
        dayOfWeek
        startTime
        endTime
      }
      deliveryContactPhone
      vendor {
        id
        nameEn
        nameTh
        quoteEn
        quoteTh
        descriptionTh
        descriptionEn
        logoImage {
          id
          originalURL
          resizableURL
          type
        }
        images {
          id
          originalURL
          resizableURL
          thumbnailResizableURL
          type
        }
        canPickUp
      }
    }
  }
`

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      deliveryGeolocation: // value for 'deliveryGeolocation'
 *      useRouteDistance: // value for 'useRouteDistance'
 *   },
 * });
 */
export function useLocationQuery(baseOptions: Apollo.QueryHookOptions<LocationQuery, LocationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options)
}
export function useLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationQuery, LocationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options)
}
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>
export type LocationQueryResult = Apollo.QueryResult<LocationQuery, LocationQueryVariables>
export const LookupUnlinkedMembershipByCardIdDocument = gql`
  query lookupUnlinkedMembershipByCardID($vendorID: Int!, $cardID: String!) {
    lookupUnlinkedMembershipByCardID(vendorID: $vendorID, cardID: $cardID) {
      membership {
        firstName
        lastName
        memberID
      }
      status
    }
  }
`

/**
 * __useLookupUnlinkedMembershipByCardIdQuery__
 *
 * To run a query within a React component, call `useLookupUnlinkedMembershipByCardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupUnlinkedMembershipByCardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupUnlinkedMembershipByCardIdQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *      cardID: // value for 'cardID'
 *   },
 * });
 */
export function useLookupUnlinkedMembershipByCardIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    LookupUnlinkedMembershipByCardIdQuery,
    LookupUnlinkedMembershipByCardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LookupUnlinkedMembershipByCardIdQuery, LookupUnlinkedMembershipByCardIdQueryVariables>(
    LookupUnlinkedMembershipByCardIdDocument,
    options
  )
}
export function useLookupUnlinkedMembershipByCardIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LookupUnlinkedMembershipByCardIdQuery,
    LookupUnlinkedMembershipByCardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LookupUnlinkedMembershipByCardIdQuery, LookupUnlinkedMembershipByCardIdQueryVariables>(
    LookupUnlinkedMembershipByCardIdDocument,
    options
  )
}
export type LookupUnlinkedMembershipByCardIdQueryHookResult = ReturnType<
  typeof useLookupUnlinkedMembershipByCardIdQuery
>
export type LookupUnlinkedMembershipByCardIdLazyQueryHookResult = ReturnType<
  typeof useLookupUnlinkedMembershipByCardIdLazyQuery
>
export type LookupUnlinkedMembershipByCardIdQueryResult = Apollo.QueryResult<
  LookupUnlinkedMembershipByCardIdQuery,
  LookupUnlinkedMembershipByCardIdQueryVariables
>
export const MemberCouponDocument = gql`
  query memberCoupon($couponID: Int!) {
    memberCoupon(couponID: $couponID) {
      id
      createdAt
      endDate
      redeemedAt
      status
      promotion {
        nameTh
        nameEn
        descriptionTh
        descriptionEn
        conditionDetailsTh
        conditionDetailsEn
        isSelfRedeemableCoupon
      }
    }
  }
`

/**
 * __useMemberCouponQuery__
 *
 * To run a query within a React component, call `useMemberCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberCouponQuery({
 *   variables: {
 *      couponID: // value for 'couponID'
 *   },
 * });
 */
export function useMemberCouponQuery(
  baseOptions: Apollo.QueryHookOptions<MemberCouponQuery, MemberCouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MemberCouponQuery, MemberCouponQueryVariables>(MemberCouponDocument, options)
}
export function useMemberCouponLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberCouponQuery, MemberCouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MemberCouponQuery, MemberCouponQueryVariables>(MemberCouponDocument, options)
}
export type MemberCouponQueryHookResult = ReturnType<typeof useMemberCouponQuery>
export type MemberCouponLazyQueryHookResult = ReturnType<typeof useMemberCouponLazyQuery>
export type MemberCouponQueryResult = Apollo.QueryResult<MemberCouponQuery, MemberCouponQueryVariables>
export const MembershipProgramDocument = gql`
  query membershipProgram($vendorID: Int!) {
    membershipProgram(vendorID: $vendorID) {
      id
      membershipProvider
      nameEn
      nameTh
      tiers {
        id
        rank
        refName
        cardImage {
          type
          originalURL
          resizableURL
        }
      }
      logoImage {
        type
        originalURL
        resizableURL
      }
      images {
        type
        originalURL
        resizableURL
      }
    }
  }
`

/**
 * __useMembershipProgramQuery__
 *
 * To run a query within a React component, call `useMembershipProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipProgramQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useMembershipProgramQuery(
  baseOptions: Apollo.QueryHookOptions<MembershipProgramQuery, MembershipProgramQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MembershipProgramQuery, MembershipProgramQueryVariables>(MembershipProgramDocument, options)
}
export function useMembershipProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MembershipProgramQuery, MembershipProgramQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MembershipProgramQuery, MembershipProgramQueryVariables>(
    MembershipProgramDocument,
    options
  )
}
export type MembershipProgramQueryHookResult = ReturnType<typeof useMembershipProgramQuery>
export type MembershipProgramLazyQueryHookResult = ReturnType<typeof useMembershipProgramLazyQuery>
export type MembershipProgramQueryResult = Apollo.QueryResult<MembershipProgramQuery, MembershipProgramQueryVariables>
export const GetMembershipTierProgressDocument = gql`
  query getMembershipTierProgress {
    getMembershipTierProgress {
      keepTier {
        endDate
        pointEarned
        pointRequired
        rank
        tier
      }
      upTier {
        endDate
        pointEarned
        pointRequired
        rank
        tier
      }
    }
  }
`

/**
 * __useGetMembershipTierProgressQuery__
 *
 * To run a query within a React component, call `useGetMembershipTierProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipTierProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipTierProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembershipTierProgressQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMembershipTierProgressQuery, GetMembershipTierProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMembershipTierProgressQuery, GetMembershipTierProgressQueryVariables>(
    GetMembershipTierProgressDocument,
    options
  )
}
export function useGetMembershipTierProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMembershipTierProgressQuery, GetMembershipTierProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMembershipTierProgressQuery, GetMembershipTierProgressQueryVariables>(
    GetMembershipTierProgressDocument,
    options
  )
}
export type GetMembershipTierProgressQueryHookResult = ReturnType<typeof useGetMembershipTierProgressQuery>
export type GetMembershipTierProgressLazyQueryHookResult = ReturnType<typeof useGetMembershipTierProgressLazyQuery>
export type GetMembershipTierProgressQueryResult = Apollo.QueryResult<
  GetMembershipTierProgressQuery,
  GetMembershipTierProgressQueryVariables
>
export const ProductModifiersDocument = gql`
  query productModifiers($id: Int!, $locationID: Int) {
    product(id: $id, locationID: $locationID) {
      id
      modifierGroups {
        id
        active
        position
        maximumQuantity
        minimumQuantity
        modifierGroupID
        productID
        modifierGroup {
          id
          nameEn
          nameTh
          vendorID
          multipleQuantity
          modifiers {
            id
            active
            nameEn
            nameTh
            position
            images {
              id
              alt
              resizableURL
            }
            priceSatangs
            invalids
            product {
              id
              nameEn
              nameTh
            }
          }
        }
      }
      promotionProduct {
        productGroupRewards {
          products {
            id
            priceSatangs
            totalPriceSatangs
            discountSatangs
            excludedFromPromotion
            nameTh
            nameEn
            categoryID
            tags
            memberTierRankConditions
            memberIneligibleReason
            skipCustomNotesScreen
            excludedFromPromotion
            images {
              id
              resizableURL
            }
            skipCustomNotesScreen
            modifierGroups {
              id
              active
              position
              maximumQuantity
              minimumQuantity
              modifierGroupID
              productID
              modifierGroup {
                id
                nameEn
                nameTh
                vendorID
                multipleQuantity
                modifiers {
                  id
                  active
                  nameEn
                  nameTh
                  position
                  images {
                    id
                    alt
                    resizableURL
                  }
                  priceSatangs
                  invalids
                }
              }
            }
          }
          rewardType
          rewardValue
        }
        promotion {
          id
          strategyType
          nameEn
          nameTh
        }
      }
    }
  }
`

/**
 * __useProductModifiersQuery__
 *
 * To run a query within a React component, call `useProductModifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductModifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductModifiersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locationID: // value for 'locationID'
 *   },
 * });
 */
export function useProductModifiersQuery(
  baseOptions: Apollo.QueryHookOptions<ProductModifiersQuery, ProductModifiersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductModifiersQuery, ProductModifiersQueryVariables>(ProductModifiersDocument, options)
}
export function useProductModifiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductModifiersQuery, ProductModifiersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductModifiersQuery, ProductModifiersQueryVariables>(ProductModifiersDocument, options)
}
export type ProductModifiersQueryHookResult = ReturnType<typeof useProductModifiersQuery>
export type ProductModifiersLazyQueryHookResult = ReturnType<typeof useProductModifiersLazyQuery>
export type ProductModifiersQueryResult = Apollo.QueryResult<ProductModifiersQuery, ProductModifiersQueryVariables>
export const RemoveCartAdjustmentDocument = gql`
  mutation removeCartAdjustment(
    $promotionID: Int
    $externalCouponID: Int
    $cartID: Int!
    $hatoHeartMemberCouponID: Int
  ) {
    removeCartAdjustment(
      promotionID: $promotionID
      externalCouponID: $externalCouponID
      cartID: $cartID
      hatoHeartMemberCouponID: $hatoHeartMemberCouponID
    ) {
      cart {
        id
        adjustments {
          id
          externalCouponID
          externalCoupon {
            id
            isAvailable
            endTime
            amount
            serialNumber
          }
          promotion {
            id
            type
          }
          adjustmentType
          source
          invalidReason
        }
      }
      cartInvalids
      corrections
      promotionInvalids
    }
  }
`
export type RemoveCartAdjustmentMutationFn = Apollo.MutationFunction<
  RemoveCartAdjustmentMutation,
  RemoveCartAdjustmentMutationVariables
>

/**
 * __useRemoveCartAdjustmentMutation__
 *
 * To run a mutation, you first call `useRemoveCartAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCartAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCartAdjustmentMutation, { data, loading, error }] = useRemoveCartAdjustmentMutation({
 *   variables: {
 *      promotionID: // value for 'promotionID'
 *      externalCouponID: // value for 'externalCouponID'
 *      cartID: // value for 'cartID'
 *      hatoHeartMemberCouponID: // value for 'hatoHeartMemberCouponID'
 *   },
 * });
 */
export function useRemoveCartAdjustmentMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCartAdjustmentMutation, RemoveCartAdjustmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveCartAdjustmentMutation, RemoveCartAdjustmentMutationVariables>(
    RemoveCartAdjustmentDocument,
    options
  )
}
export type RemoveCartAdjustmentMutationHookResult = ReturnType<typeof useRemoveCartAdjustmentMutation>
export type RemoveCartAdjustmentMutationResult = Apollo.MutationResult<RemoveCartAdjustmentMutation>
export type RemoveCartAdjustmentMutationOptions = Apollo.BaseMutationOptions<
  RemoveCartAdjustmentMutation,
  RemoveCartAdjustmentMutationVariables
>
export const UserMembershipDataPackDocument = gql`
  query userMembershipDataPack($refresh: Boolean) {
    currentUser {
      id
      email
      defaultPaymentMethod
      defaultPaymentPhone
      language
      omiseCardID
      omiseCardBrand
      omiseCardLastDigits
      omiseCardholderName
      lineEmail
      extMembershipPhone
    }
    address {
      id
      name
      phone
      detail
      note
      locationLabel
      latitude
      longitude
      address
      buildingType
      deliveryMethod
      callOnArrival
      lastUsed
      nickname
      temporary
      googlePlaceID
    }
    currentMembership(refresh: $refresh) {
      pointExpirations {
        id
        expirationDate
        points
      }
      firstName
      id
      lastName
      memberID
      memberTier
      provider
      totalPoints
      userID
      dateOfBirth
      gender
      phone
      allowPromoMsg
      isSubscriptionActive
      subscriptionEndDate
      subscriptionPlan
      legacyCardIDs
      currentMembershipSubscription {
        membershipProgramSubscriptionOption {
          coverImage {
            originalURL
            resizableURL
          }
        }
      }
    }
  }
`

/**
 * __useUserMembershipDataPackQuery__
 *
 * To run a query within a React component, call `useUserMembershipDataPackQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMembershipDataPackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMembershipDataPackQuery({
 *   variables: {
 *      refresh: // value for 'refresh'
 *   },
 * });
 */
export function useUserMembershipDataPackQuery(
  baseOptions?: Apollo.QueryHookOptions<UserMembershipDataPackQuery, UserMembershipDataPackQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserMembershipDataPackQuery, UserMembershipDataPackQueryVariables>(
    UserMembershipDataPackDocument,
    options
  )
}
export function useUserMembershipDataPackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserMembershipDataPackQuery, UserMembershipDataPackQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserMembershipDataPackQuery, UserMembershipDataPackQueryVariables>(
    UserMembershipDataPackDocument,
    options
  )
}
export type UserMembershipDataPackQueryHookResult = ReturnType<typeof useUserMembershipDataPackQuery>
export type UserMembershipDataPackLazyQueryHookResult = ReturnType<typeof useUserMembershipDataPackLazyQuery>
export type UserMembershipDataPackQueryResult = Apollo.QueryResult<
  UserMembershipDataPackQuery,
  UserMembershipDataPackQueryVariables
>
export const VendorDocument = gql`
  query vendor($id: Int!, $deliveryGeolocation: GeolocationInput, $useRouteDistance: Boolean) {
    vendor(id: $id, deliveryGeolocation: $deliveryGeolocation, useRouteDistance: $useRouteDistance) {
      id
      nameEn
      nameTh
      quoteEn
      quoteTh
      descriptionTh
      descriptionEn
      logoImage {
        id
        originalURL
        resizableURL
        type
      }
      images {
        id
        originalURL
        resizableURL
        thumbnailResizableURL
        type
      }
      canPickUp
    }
  }
`

/**
 * __useVendorQuery__
 *
 * To run a query within a React component, call `useVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorQuery({
 *   variables: {
 *      id: // value for 'id'
 *      deliveryGeolocation: // value for 'deliveryGeolocation'
 *      useRouteDistance: // value for 'useRouteDistance'
 *   },
 * });
 */
export function useVendorQuery(baseOptions: Apollo.QueryHookOptions<VendorQuery, VendorQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VendorQuery, VendorQueryVariables>(VendorDocument, options)
}
export function useVendorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorQuery, VendorQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VendorQuery, VendorQueryVariables>(VendorDocument, options)
}
export type VendorQueryHookResult = ReturnType<typeof useVendorQuery>
export type VendorLazyQueryHookResult = ReturnType<typeof useVendorLazyQuery>
export type VendorQueryResult = Apollo.QueryResult<VendorQuery, VendorQueryVariables>
export const VendorDetailDataPackDocument = gql`
  query vendorDetailDataPack(
    $vendorID: Int!
    $deliveryGeolocation: GeolocationInput
    $onlyActiveProduct: Boolean
    $locationID: Int
  ) {
    listCategoriesForVendor(vendorID: $vendorID, onlyActiveProduct: $onlyActiveProduct, locationID: $locationID) {
      id
      nameEn
      nameTh
      position
      images {
        id
        originalURL
        resizableURL
        thumbnailResizableURL
        type
      }
      productColorScheme
    }
    vendor(id: $vendorID, deliveryGeolocation: $deliveryGeolocation) {
      id
      nameEn
      nameTh
      quoteEn
      quoteTh
      descriptionTh
      descriptionEn
      logoImage {
        id
        originalURL
        resizableURL
        type
      }
      images {
        id
        originalURL
        resizableURL
        thumbnailResizableURL
        type
      }
      canPickUp
    }
  }
`

/**
 * __useVendorDetailDataPackQuery__
 *
 * To run a query within a React component, call `useVendorDetailDataPackQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorDetailDataPackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorDetailDataPackQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *      deliveryGeolocation: // value for 'deliveryGeolocation'
 *      onlyActiveProduct: // value for 'onlyActiveProduct'
 *      locationID: // value for 'locationID'
 *   },
 * });
 */
export function useVendorDetailDataPackQuery(
  baseOptions: Apollo.QueryHookOptions<VendorDetailDataPackQuery, VendorDetailDataPackQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VendorDetailDataPackQuery, VendorDetailDataPackQueryVariables>(
    VendorDetailDataPackDocument,
    options
  )
}
export function useVendorDetailDataPackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VendorDetailDataPackQuery, VendorDetailDataPackQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VendorDetailDataPackQuery, VendorDetailDataPackQueryVariables>(
    VendorDetailDataPackDocument,
    options
  )
}
export type VendorDetailDataPackQueryHookResult = ReturnType<typeof useVendorDetailDataPackQuery>
export type VendorDetailDataPackLazyQueryHookResult = ReturnType<typeof useVendorDetailDataPackLazyQuery>
export type VendorDetailDataPackQueryResult = Apollo.QueryResult<
  VendorDetailDataPackQuery,
  VendorDetailDataPackQueryVariables
>
export const VendorDetailDataPackWithNearestLocationDocument = gql`
  query vendorDetailDataPackWithNearestLocation(
    $vendorID: Int!
    $deliveryGeolocation: GeolocationInput
    $onlyActiveProduct: Boolean
    $locationID: Int
  ) {
    listCategoriesForVendor(vendorID: $vendorID, onlyActiveProduct: $onlyActiveProduct, locationID: $locationID) {
      id
      nameEn
      nameTh
      position
      images {
        id
        originalURL
        resizableURL
        thumbnailResizableURL
        type
      }
      productColorScheme
    }
    vendor(id: $vendorID, deliveryGeolocation: $deliveryGeolocation) {
      id
      nameEn
      nameTh
      quoteEn
      quoteTh
      descriptionTh
      descriptionEn
      logoImage {
        id
        originalURL
        resizableURL
        type
      }
      images {
        id
        originalURL
        resizableURL
        thumbnailResizableURL
        type
      }
      canPickUp
      nearestLocation(deliveryGeolocation: $deliveryGeolocation) {
        id
        active
        nameEn
        nameTh
        isInDeliveryArea
        approxDeliveryDistanceM
        geocodedAddressEn
        geocodedAddressTh
        address {
          id
          address
          detail
          locationLabel
          name
        }
        businessHours {
          id
          active
          dayOfWeek
          startTime
          endTime
        }
        deliveryContactPhone
        vendor {
          id
          nameEn
          nameTh
          quoteEn
          quoteTh
          descriptionTh
          descriptionEn
          logoImage {
            id
            originalURL
            resizableURL
            type
          }
          images {
            id
            originalURL
            resizableURL
            thumbnailResizableURL
            type
          }
          canPickUp
        }
      }
    }
  }
`

/**
 * __useVendorDetailDataPackWithNearestLocationQuery__
 *
 * To run a query within a React component, call `useVendorDetailDataPackWithNearestLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorDetailDataPackWithNearestLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorDetailDataPackWithNearestLocationQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *      deliveryGeolocation: // value for 'deliveryGeolocation'
 *      onlyActiveProduct: // value for 'onlyActiveProduct'
 *      locationID: // value for 'locationID'
 *   },
 * });
 */
export function useVendorDetailDataPackWithNearestLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    VendorDetailDataPackWithNearestLocationQuery,
    VendorDetailDataPackWithNearestLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VendorDetailDataPackWithNearestLocationQuery,
    VendorDetailDataPackWithNearestLocationQueryVariables
  >(VendorDetailDataPackWithNearestLocationDocument, options)
}
export function useVendorDetailDataPackWithNearestLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VendorDetailDataPackWithNearestLocationQuery,
    VendorDetailDataPackWithNearestLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VendorDetailDataPackWithNearestLocationQuery,
    VendorDetailDataPackWithNearestLocationQueryVariables
  >(VendorDetailDataPackWithNearestLocationDocument, options)
}
export type VendorDetailDataPackWithNearestLocationQueryHookResult = ReturnType<
  typeof useVendorDetailDataPackWithNearestLocationQuery
>
export type VendorDetailDataPackWithNearestLocationLazyQueryHookResult = ReturnType<
  typeof useVendorDetailDataPackWithNearestLocationLazyQuery
>
export type VendorDetailDataPackWithNearestLocationQueryResult = Apollo.QueryResult<
  VendorDetailDataPackWithNearestLocationQuery,
  VendorDetailDataPackWithNearestLocationQueryVariables
>
export const VendorSettingDocument = gql`
  query vendorSetting($vendorID: Int!) {
    vendorSetting(vendorID: $vendorID) {
      id
      paymentMethods
      vendorID
      orderMinimumLineItemTotalSatangs
      orderMinimumTotalSatangs
      excludingVat
      bankAccounts {
        brand
        name
        number
      }
    }
  }
`

/**
 * __useVendorSettingQuery__
 *
 * To run a query within a React component, call `useVendorSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorSettingQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useVendorSettingQuery(
  baseOptions: Apollo.QueryHookOptions<VendorSettingQuery, VendorSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VendorSettingQuery, VendorSettingQueryVariables>(VendorSettingDocument, options)
}
export function useVendorSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VendorSettingQuery, VendorSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VendorSettingQuery, VendorSettingQueryVariables>(VendorSettingDocument, options)
}
export type VendorSettingQueryHookResult = ReturnType<typeof useVendorSettingQuery>
export type VendorSettingLazyQueryHookResult = ReturnType<typeof useVendorSettingLazyQuery>
export type VendorSettingQueryResult = Apollo.QueryResult<VendorSettingQuery, VendorSettingQueryVariables>
