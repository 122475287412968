const border = {
  width: {
    sm: 1,
    md: 2,
  },
  radius: {
    sm: 4,
    md: 6,
    md2: 8,
    lg: 10,
    xlg: 12,
  },
}

export default border
