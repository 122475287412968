import { ValidateCurrentOrderResult, OrderInvalidEnum } from './useValidateCurrentOrder'
import { ModalEnum } from 'components/buffet/BuffetModals'
import { ScreenEnum } from 'components/buffet/BuffetOverlayPage'
import { useAppDispatch } from 'store'
import {
  BuffetCurrentOrder,
  onCurrentPackageChange,
  resetBuffetModal,
  resetBuffetScreen,
  setBuffetModal,
  setBuffetScreen,
  setIsTimeUp,
} from 'store/slices/buffetSlice'
import moment from 'moment'

type Props = {
  skipModal?: string[]
  skipScreen?: string[]
}

const useHandleBuffetOrder = ({ skipModal = [], skipScreen = [] }: Props) => {
  const dispatch = useAppDispatch()
  const mappingInvalid = (invalid: string) => {
    switch (invalid) {
      case OrderInvalidEnum.TableIsNotAvailable:
        return ScreenEnum.TableIsNotAvailable
      case OrderInvalidEnum.PaymentPaid:
        return ScreenEnum.PaymentSuccess
      case OrderInvalidEnum.PaymentIsNotCompleted:
        return ScreenEnum.ResubmitPayment
      case OrderInvalidEnum.PendingQRPayment:
        return ScreenEnum.PaymentQR
      case OrderInvalidEnum.CreditCardFailed:
        return ScreenEnum.CreditCardFailed
      case OrderInvalidEnum.PendingPayment:
        return ModalEnum.PendingPayment
      case OrderInvalidEnum.OwnerNeedToCheckout:
        return ModalEnum.ContinueCheckout
      case OrderInvalidEnum.AnotherHasOccupied:
        return ModalEnum.ConfirmOverrideCheckout
      case OrderInvalidEnum.OrderIsTimeup:
        return ModalEnum.TimeUp
      case OrderInvalidEnum.ReadyToCheckout:
        return ModalEnum.ReadyToCheckout
      case OrderInvalidEnum.PackageHasChanged:
        return ModalEnum.UpgradePackage
      case OrderInvalidEnum.CallingStaff:
        return ModalEnum.CallingStaff
      case OrderInvalidEnum.StaffInitiateCheckout:
        return OrderInvalidEnum.StaffInitiateCheckout
      default:
        return ''
    }
  }

  const handleValidateResult = (validateResult: ValidateCurrentOrderResult, order: BuffetCurrentOrder) => {
    if (validateResult.invalid === OrderInvalidEnum.PackageHasChanged) {
      if (order) {
        dispatch(onCurrentPackageChange(order?.buffetPackage))
      }
    } else {
      const resultFromMapping = mappingInvalid(validateResult.invalid)
      const modalsKeys = Object.keys(ModalEnum)
      const screenKeys = Object.keys(ScreenEnum)
      if (modalsKeys.includes(resultFromMapping) && !skipModal.includes(resultFromMapping)) {
        dispatch(setBuffetModal({ type: resultFromMapping as ModalEnum, isProcessing: false }))
        dispatch(resetBuffetScreen())
      } else if (screenKeys.includes(resultFromMapping) && !skipScreen.includes(resultFromMapping)) {
        dispatch(setBuffetScreen({ type: resultFromMapping as ScreenEnum, isProcessing: false }))
        dispatch(resetBuffetModal())
      } else {
        dispatch(resetBuffetScreen())
        dispatch(resetBuffetModal())
      }
    }
  }

  const handleBuffetEndTime = (buffetEndTime?: string) => {
    if (buffetEndTime) {
      const now = moment()
      const endTime = moment(buffetEndTime)
      dispatch(setIsTimeUp(now.isAfter(endTime)))
    } else {
      dispatch(setIsTimeUp(false))
    }
  }

  return { handleValidateResult, handleBuffetEndTime }
}

export default useHandleBuffetOrder
