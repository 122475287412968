import { baseWidth, baseHeight } from 'styles/media'

interface SpacingStyle {
  verticalSpacingXS: string
  verticalSpacingS: string
  verticalSpacingM: string
  verticalSpacingL: string
  verticalSpacingXL: string
  verticalSpacing4XL: string
  horizontalSpacingXS: string
  horizontalSpacingS: string
  horizontalSpacingM: string
  horizontalSpacingL: string
  horizontalSpacingXL: string
  horizontalSpacing4XL: string
  horizontalPadding: string
  modalPaddingSide: string
  modalPaddingTopWithUpperButton: string
  modalPaddingBottom: string
}

const spacing: SpacingStyle = {
  verticalSpacingXS: `calc(${4 / baseHeight} * 100vh)`,
  verticalSpacingS: `calc(${8 / baseHeight} * 100vh)`,
  verticalSpacingM: `calc(${12 / baseHeight} * 100vh)`,
  verticalSpacingL: `calc(${16 / baseHeight} * 100vh)`,
  verticalSpacingXL: `calc(${20 / baseHeight} * 100vh)`,
  verticalSpacing4XL: `calc(${32 / baseHeight} * 100vh)`,
  horizontalSpacingXS: `calc(${4 / baseWidth} * 100vw)`,
  horizontalSpacingS: `calc(${8 / baseWidth} * 100vw)`,
  horizontalSpacingM: `calc(${12 / baseWidth} * 100vw)`,
  horizontalSpacingL: `calc(${16 / baseWidth} * 100vw)`,
  horizontalSpacingXL: `calc(${20 / baseWidth} * 100vw)`,
  horizontalSpacing4XL: `calc(${32 / baseWidth} * 100vw)`,
  horizontalPadding: `calc(${16 / baseWidth} * 100vw)`,
  modalPaddingSide: `calc(${20 / baseWidth} * 100vw)`,
  modalPaddingTopWithUpperButton: `calc(${16 / baseHeight} * 100vh)`,
  modalPaddingBottom: `calc(${40 / baseHeight} * 100vh)`,
}

export default spacing
