import React from 'react'
import styled from 'styled-components'
import { Fonts } from 'components/base-ui'

enum tagColorEnum {
  success = 'success',
  info = 'info',
}

interface TagProps {
  text: string
  color: keyof typeof tagColorEnum
}

const Tag = ({ text, color }: TagProps) => {
  return (
    <TagStyled color={color}>
      <TagText>{text}</TagText>
    </TagStyled>
  )
}

export default Tag

const TagStyled = styled.div<{ color: keyof typeof tagColorEnum }>`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 5px;

  ${({ color, theme }) =>
    color === tagColorEnum.success &&
    `
    background-color: ${theme.colors.successLightWhite};
    color: ${theme.colors.successDark};
  `}

  ${({ color, theme }) =>
    color === tagColorEnum.info &&
    `
    background-color: ${theme.colors.infoLightWhite};
    color: ${theme.colors.infoDark};
  `}
`

const TagText = styled(Fonts.SmallTitleRegular)`
  white-space: nowrap;
`
