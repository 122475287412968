import React, { useState, useEffect, Fragment, ReactNode, FC } from 'react'
import styled from 'styled-components'

import shadow from 'styles/shadow'
import { Fonts } from 'components/base-ui'
import { onClick } from 'helpers/fastClick'

enum SwitchTypeEnum {
  normal = 'normal',
  overlapButton = 'overlapButton',
}
type SwitchTypeEnumStr = keyof typeof SwitchTypeEnum

enum SwitchLayoutEnum {
  normal = 'normal',
  right = 'right',
}
type SwitchLayoutEnumStr = keyof typeof SwitchLayoutEnum

interface Props {
  asyncDefaultValue?: boolean
  defaultValue?: boolean
  children?: ReactNode
  type?: SwitchTypeEnumStr
  layout?: SwitchLayoutEnumStr
  onChange: (val) => void
  enableFastClick?: boolean
  showIcons?: boolean
}

const SwitchComponent: FC<Props> = ({
  asyncDefaultValue = false,
  defaultValue = false,
  children,
  type = 'normal',
  layout = 'normal',
  onChange = (val) => {},
  enableFastClick = true,
  showIcons = false,
}) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(defaultValue)
  }, [])

  useEffect(() => {
    setIsChecked(asyncDefaultValue)
  }, [asyncDefaultValue])

  const clickObj = enableFastClick
    ? {
        ...onClick(() => {
          onChange(!isChecked)
          if (asyncDefaultValue === null || asyncDefaultValue === undefined) {
            setIsChecked(!isChecked)
          }
        }),
      }
    : {
        onClick: () => {
          onChange(!isChecked)
          if (asyncDefaultValue === null || asyncDefaultValue === undefined) {
            setIsChecked(!isChecked)
          }
        },
      }

  return (
    <Label layout={layout} {...clickObj}>
      {
        // Layout: Normal
        layout === 'normal' && (
          <Fragment>
            <Switch active={isChecked} layout={layout} type={type} showIcons={showIcons} />
            <SwitchText>{children}</SwitchText>
          </Fragment>
        )
      }

      {
        // Layout: Right
        layout === 'right' && (
          <Fragment>
            <SwitchText>{children}</SwitchText>
            <Switch active={isChecked} layout={layout} type={type} showIcons={showIcons} />
          </Fragment>
        )
      }
    </Label>
  )
}

export default SwitchComponent

const Label = styled.div<{ layout: SwitchLayoutEnumStr }>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGrey};

  ${({ layout }) =>
    layout === 'right'
      ? `
    display: flex;
    justify-content: space-between;
  `
      : ''}
`

const SwitchText = styled(Fonts.BaseTitleRegular)`
  color: ${({ theme }) => theme.colors.darkGrey};
`

const Switch = styled.div<{
  active: boolean
  layout: SwitchLayoutEnumStr
  type: SwitchTypeEnumStr
  showIcons: boolean
}>`
  margin-right: 14px;
  position: relative;
  width: 36px;
  height: 16px;
  border-radius: 26px;
  background-color: ${({ theme }) => theme.colors.gray400};
  transition: 0.3s;
  left: 1px;

  &::after {
    ${({ showIcons }) =>
      showIcons
        ? `
      content: '\\e90d';
    `
        : 'content: "";'}

    font-family: 'icons-indiedish' !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    font-size: 9px;
    height: 20px;
    border-radius: 50%;
    box-shadow: ${shadow.floatingButton};
    background-color: white;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 0;
    transition: 0.3s;
    transform: translate3d(-3px, 0, 0);
  }

  ${({ layout }) =>
    layout === 'right'
      ? `
    margin-right: 0;
    margin-left: 14px;
  `
      : ''}

  ${({ theme, active, showIcons }) =>
    active
      ? `
    background-color: ${theme.colors.primary};

    &::after {
      ${showIcons ? `content: '\\e913';` : ''}
      color: ${theme.colors.primary};
      transform: translate3d(19px, 0, 0);
    }
    `
      : ''}

  ${({ type }) =>
    type === 'overlapButton'
      ? `
    margin-top: 5px;
    height: 16px;
    width: 36px;

    &::after {
      transform: translate3d(-3px, 0, 0);
      box-shadow: 0 2px 8px rgba(0, 0, 0, .24);
    }
  `
      : ''}

  ${({ theme, active, type, showIcons }) =>
    active && type === 'overlapButton'
      ? `

    &::after {
      ${showIcons ? `content: '\\e913';` : ''}
      color: ${theme.colors.primary};
      transform: translate3d(15px, 0, 0);
    }
    `
      : ''}
`
