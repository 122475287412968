export enum MemberProductIneligibleReasonEnum {
  MEMBERSHIP_REQUIRED = 'MembershipRequired',
  TIER_BIRTHMONTH_REQUIRED = 'TierBirthMonthRequired',
  TIER_INELIGIBLE = 'TierIneligible',
}

export enum ProductMemberTierRankConditionsEnum {
  ELIGIBLE = 'Eligible',
  NOT_ELIGIBLE = 'NotEligible',
  BIRTHMONTH = 'BirthMonth',
}

export enum ProductLimitQuantityModeEnum {
  none = 'none',
  maxQuantity = 'maxQuantity',
  stock = 'stock',
}
