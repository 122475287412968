import React, { FC } from 'react'
import styled from 'styled-components'
import ModalConfirm from 'components/base-ui/ModalConfirm'
import { useTranslation } from 'react-i18next'
import { Fonts } from 'components/base-ui'

type Props = {
  isOpen: boolean
  isLoading: boolean
  onConfirm: () => void
  onRequestClose: () => void
}

const ModalCallingStaff: FC<Props> = ({ isOpen, isLoading, onConfirm, onRequestClose }) => {
  const { t } = useTranslation()
  return (
    <ModalConfirm
      testId="callingStaffModalConfirm"
      isOpen={isOpen}
      isLoading={isLoading}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      hideCloseButton
      btnAccept={{
        onClick: onConfirm,
        content: t('buffet.modal.callingStaff.cancel'),
        isProcessing: isLoading,
        outline: true,
      }}
    >
      <ModalContent>
        <img src="/assets/dine-in/staff.png" alt="" style={{ paddingBottom: 15 }} />
        <Fonts.H2>{t('buffet.modal.callingStaff.title')}</Fonts.H2>
        <Fonts.BaseTitleRegular>{t('buffet.modal.callingStaff.description')}</Fonts.BaseTitleRegular>
      </ModalContent>
    </ModalConfirm>
  )
}

export default ModalCallingStaff

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 7px;
  padding-bottom: 25px;
  padding-top: 30px;
  text-align: center;
`
