import React, { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { bounceIn } from 'react-animations'

import shadow from 'styles/shadow'
import border from 'styles/border'
import spacing from 'styles/spacing'
import { form, control, button } from 'react-validation'

import { Button, Select, Icon } from 'components/base-ui'
import Fonts from 'components/base-ui/Fonts'
const { weight } = Fonts

const Form = form(
  ({ getValues, validate, validateAll, showError, hideError, children, onSubmit = () => {}, ...props }) => (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(e)
      }}
      {...props}
    >
      {children}
    </StyledForm>
  )
)

Form.Checkbox = ({ label, name, radioStyled, size, radius, iconSize, color, ...rest }) => {
  return (
    <InputCheckboxWrapper className="button">
      <InputStyledCheckbox
        className="button"
        type="checkbox"
        id={name}
        name={name}
        onChange={() => {}}
        radioStyled={radioStyled}
        {...rest}
      />
      <CheckboxLabel className="button" htmlFor={name}>
        <CircleCheckedWrapper>
          {rest.checked ? (
            <CircleChecked size={size} radius={radius} iconSize={iconSize} color={color} />
          ) : (
            <CircleUnChecked size={size} radius={radius} />
          )}
        </CircleCheckedWrapper>
        <div className="label">{label}</div>
      </CheckboxLabel>
    </InputCheckboxWrapper>
  )
}

Form.Radio = ({ label, name, size, radius, iconSize, color, ...rest }) => {
  return (
    <InputCheckboxWrapper className="button">
      <InputStyledCheckbox className="button" type="radio" id={name} name={name} onChange={() => {}} {...rest} />
      <CheckboxLabel className="button" htmlFor={name}>
        <CircleCheckedWrapper>
          {rest.checked ? (
            <CircleChecked size={size} radius={radius} iconSize={iconSize} color={color} />
          ) : (
            <CircleUnChecked size={size} radius={radius} />
          )}
        </CircleCheckedWrapper>
        <div className="label">{label}</div>
      </CheckboxLabel>
    </InputCheckboxWrapper>
  )
}

Form.Input = control(
  ({
    placeholder,
    error,
    isChanged,
    isUsed,
    floatError,
    hideError = false,
    hideTextError = false,
    placeholderSm = false,
    isPlaceholderAlwaysFloat = false,
    ...props
  }) => {
    const [isOnFocus, setIsOnFocus] = useState(false)
    const isFloat = isOnFocus || props.value !== '' || isPlaceholderAlwaysFloat

    const handleOnFocus = (e) => {
      if (props.onFocus) {
        props.onFocus(e)
      }
      setIsOnFocus(true)
    }

    const handleOnBlur = (e) => {
      if (props.onBlur) {
        props.onBlur(e)
      }
      setIsOnFocus(false)
    }

    const showError = isUsed && error && !hideError

    return (
      <InputWrapper>
        <Placeholder float={isFloat} search={props.search} sm={placeholderSm} error={showError}>
          {placeholder}
          {props.required && <RequiredStar>*</RequiredStar>}
        </Placeholder>
        <InputStyled {...props} error={showError} onFocus={handleOnFocus} onBlur={handleOnBlur} />
        {showError && !hideTextError ? (
          <ErrorMsg floatError={floatError}>
            <Icon name="warning-triangle" />
            <Fonts.SmallTitleBold>{error}</Fonts.SmallTitleBold>
          </ErrorMsg>
        ) : null}
      </InputWrapper>
    )
  }
)

const InputManual = ({
  placeholder,
  error,
  isChanged,
  floatError,
  hideError = false,
  hideTextError = false,
  placeholderSm = false,
  showBothPlaceholder = false,
  placeholderFloat,
  placeholderPrimaryColor,
  placeholderPrimaryColorOnFocus,
  ...props
}) => {
  const [isOnFocus, setIsOnFocus] = useState(false)
  const isFloat = isOnFocus || props.value !== '' || showBothPlaceholder

  const handleOnFocus = (e) => {
    if (props.onFocus) {
      props.onFocus(e)
    }
    setIsOnFocus(true)
  }

  const handleOnBlur = (e) => {
    if (props.onBlur) {
      props.onBlur(e)
    }
    setIsOnFocus(false)
  }

  const showError = error && !hideError
  let _placeholderFloat = placeholderFloat ? placeholderFloat : placeholder

  return (
    <InputWrapper>
      <Placeholder
        float={isFloat}
        search={props.search}
        sm={placeholderSm}
        md2={props.md2}
        error={showError}
        placeholderPrimaryColor={placeholderPrimaryColor}
        placeholderPrimaryColorOnFocus={placeholderPrimaryColorOnFocus}
        isActive={isOnFocus}
      >
        {_placeholderFloat}
        {props.required && <RequiredStar>*</RequiredStar>}
      </Placeholder>
      <InputStyled
        {...props}
        placeholder={showBothPlaceholder ? placeholder : ''}
        error={showError}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {showError && !hideTextError ? (
        <ErrorMsg floatError={floatError}>
          <Icon name="warning-triangle" />
          <Fonts.SmallTitleBold>{error}</Fonts.SmallTitleBold>
        </ErrorMsg>
      ) : null}
    </InputWrapper>
  )
}

Form.InputManual = InputManual

Form.Textarea = control(
  ({
    placeholder,
    notFloatPlaceholder,
    error,
    isChanged,
    isUsed,
    floatError,
    hideError = false,
    hideTextError = false,
    placeholderSm = false,
    rows = 1,
    ...props
  }) => {
    const [isOnFocus, setIsOnFocus] = useState(false)
    const isFloat = isOnFocus || props.value !== ''

    const handleOnFocus = (e) => {
      if (props.onFocus) {
        props.onFocus(e)
      }
      setIsOnFocus(true)
    }

    const handleOnBlur = (e) => {
      if (props.onBlur) {
        props.onBlur(e)
      }
      setIsOnFocus(false)
    }

    const showError = isUsed && error && !hideError

    return (
      <InputWrapper>
        {!notFloatPlaceholder && (
          <PlaceholderTextArea
            float={isFloat || placeholderSm}
            search={props.search}
            sm={placeholderSm}
            error={showError}
          >
            {placeholder}
            {props.required && <RequiredStar>*</RequiredStar>}
          </PlaceholderTextArea>
        )}
        <TextAreaStyled
          {...props}
          rows={rows}
          error={showError}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          placeholder={notFloatPlaceholder ? placeholder : ''}
        />
        {showError && !hideTextError ? (
          <ErrorMsg floatError={floatError}>
            <Icon name="warning-triangle" />
            <Fonts.SmallTitleBold>{error}</Fonts.SmallTitleBold>
          </ErrorMsg>
        ) : null}
      </InputWrapper>
    )
  }
)

Form.Select = control(
  ({ error, isChanged, isUsed, floatError, hideError = false, hideTextError = false, ...props }) => {
    const showError = isUsed && error && !hideError

    return (
      <InputWrapper>
        <Select {...props} error={showError} />
        {showError && !hideTextError ? (
          <ErrorMsg floatError={floatError}>
            <Icon name="warning-triangle" />
            <Fonts.SmallTitleBold>{error}</Fonts.SmallTitleBold>
          </ErrorMsg>
        ) : null}
      </InputWrapper>
    )
  }
)

Form.Button = button(({ hasErrors, disabled, ...props }) => {
  return <Button {...props} disabled={hasErrors || disabled} />
})

const BounceAnimation = keyframes`${bounceIn}`

Form.InputNotice = styled(Fonts.SmallTitleBold)`
  display: flex;
  color: ${(props) => (props.color ? props.color : props.theme.colors.gray400)};

  i {
    margin-right: 10px;
    position: relative;
    top: 2px;
  }

  ${(props) =>
    props.orange
      ? `
    color: ${props.theme.colors.orange};
    `
      : ''}
`

const RequiredStar = styled.div`
  color: ${(props) => props.theme.colors.red};

  &::before {
    content: '';
    margin-left: 4px;
  }
`

const StyledForm = styled.form`
  margin: 0;
`

const InputCheckboxWrapper = styled.div`
  white-space: nowrap;
  position: relative;

  label {
    font-family: ${weight.thaiRegular};
    font-size: 16px;
    color: ${(props) => props.theme.colors.textDark};
  }
`

const CircleCheckedWrapper = styled.div``

const CircleUnChecked = styled.div`
  width: ${({ size }) => (size ? size : '24px')};
  height: ${({ size }) => (size ? size : '24px')};
  border-radius: ${({ radius }) => (radius ? radius : `${border.radius.md}px`)};

  box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.gray200};
  animation: ${BounceAnimation} 0.6s linear forwards;
`
const CircleChecked = styled.div`
  width: ${({ size }) => (size ? size : '24px')};
  height: ${({ size }) => (size ? size : '24px')};
  border-radius: ${({ radius }) => (radius ? radius : `${border.radius.md}px`)};
  background-color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
  box-shadow: inset 0 0 0 2px ${({ theme, color }) => (color ? color : theme.colors.primary)};
  animation: ${BounceAnimation} 0.6s linear forwards;

  &::before {
    content: '\\e913';
    font-family: 'icons-indiedish' !important;
    display: block;
    color: white;
    font-size: ${({ iconSize }) => (iconSize ? iconSize : '9px')};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const CheckboxLabel = styled.label`
  display: flex;
  column-gap: 10px;
`

const InputStyledCheckbox = styled.input`
  display: inline-flex;
  appearance: none;
  -webkit-appearance: none;
  display: none;
`

// Input
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const Placeholder = styled.div`
  pointer-events: none;
  position: absolute;
  top: ${(props) => (props.top ? props.top : '0')};
  left: 0;
  padding: 5px 15px;
  min-height: 58px;
  font-size: 16px;
  color: ${(props) => (props.placeholderPrimaryColor ? props.theme.colors.primary : props.theme.colors.darkGrey)};
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: 0.3s;

  ${({ theme, isActive, placeholderPrimaryColorOnFocus }) =>
    isActive &&
    placeholderPrimaryColorOnFocus &&
    `
    color: ${theme.colors.primary};
  `}

  ${(props) =>
    props.float
      ? `
    transform: translate3d(0, -12px, 0);
    font-size: 12px;
    font-weight: ${weight.medium};
  `
      : ''}

  ${(props) =>
    props.float && props.md2
      ? `
    transform: translate3d(0, -11px, 0);
    `
      : ''}

  ${(props) =>
    props.error
      ? `
    color: ${props.theme.colors.errorLight} !important;
    `
      : ''}

  ${(props) =>
    props.search
      ? `
    min-height: 50px;
    font-size: 16px;
    `
      : ''}

   ${(props) =>
    props.sm
      ? `
     font-size: 12px;
     `
      : ''}
`

const PlaceholderTextArea = styled(Placeholder)`
  width: calc(100% - 2px);
  top: 13px;
  left: 1px;
  padding: 7px 0 2px 14px;
  background: #ffffff;
  z-index: 99;
  min-height: 0;
  border-radius: 6px;
`

const InputTheme = css`
  margin-bottom: 12px;
  display: block;
  width: 100%;
  padding: 5px 15px;
  padding-top: 22px;
  border: 1px solid ${(props) => props.theme.colors.gray150};
  box-shadow: ${shadow.subtle};
  outline: none;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '58px')};
  border-radius: ${border.radius.md}px;
  font-family: 'Bai Jamjuree', sans-serif;
  font-weight: normal;
  color: ${(props) => props.theme.colors.gray800};
  background-color: transparent;

  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.darkGrey};
  }

  &:-ms-input-placeholder {
    color: ${(props) => props.theme.colors.darkGrey};
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.darkGrey};
  }

  &:-webkit-autofill {
    background-color: red;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
  }

  &:read-only {
    border-color: ${(props) => props.theme.colors.gray150};
    background-color: ${(props) => props.theme.colors.gray150};
  }

  ${(props) =>
    props.md2
      ? `
    min-height: 62px;
    `
      : ''}

  ${(props) =>
    props.search
      ? `
    min-height: 50px;
    font-size: 16px;
    `
      : ''}

  ${(props) =>
    props.primary
      ? `
    border-color: ${props.theme.colors.primary};
    `
      : ''}

  ${(props) =>
    props.error
      ? `
    border-color: ${props.theme.colors.errorLight} !important;
  `
      : ''}
`

const InputStyled = styled.input`
  ${InputTheme}
`

const TextAreaStyled = styled.textarea`
  ${InputTheme}
  padding-top: 28px;
  resize: none;

  ${(props) =>
    props.placeholder
      ? `
    padding-top: 10px;
    padding-bottom: 10px;
  `
      : ''}
`

Form.PureInput = styled.input`
  margin-bottom: 12px;
  display: block;
  width: 100%;
  padding: 5px 15px;
  border: 1px solid ${(props) => props.theme.colors.gray150};
  box-shadow: ${shadow.subtle};
  outline: none;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '58px')};
  border-radius: ${border.radius.md}px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.gray800};
  background-color: white;

  &:-webkit-autofill {
    background-color: red;
  }

  ${(props) =>
    props.search
      ? `
    min-height: 50px;
    font-size: 16px;
    `
      : ''}

  ${(props) =>
    props.primary
      ? `
    border-color: ${props.theme.colors.primary};
    `
      : ''}

  ${(props) =>
    props.warning
      ? `
    border-color: ${props.theme.colors.yellow500};
    `
      : ''}

  ${(props) =>
    props.success
      ? `
    border-color: ${props.theme.colors.green500};
    `
      : ''}
`

Form.InputLikeNormalText = styled.input`
  outline: none;
  border: 0;
  width: 180px;
`

const ErrorMsg = styled.div`
  display: flex;
  margin-top: -4px;
  margin-bottom: ${spacing.md}px;

  i {
    color: ${(props) => props.theme.colors.errorLight};
    margin-right: 8px;
  }

  ${(props) =>
    props.floatError
      ? `
    position: absolute;
    top: 100%;
    `
      : null}
`

export default Form
