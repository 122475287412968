export enum SubPageName {
  Home = '',
  ProductDetail = 'product-detail',
  PrivateCart = 'private-cart',
  GlobalCart = 'global-cart',
  Checkout = 'checkout',
  PaymentMethods = 'payment-methods',
  Promotions = 'promotions',
  ShareCoupon = 'share-coupon',
  InviteFriends = 'invite-friends',
  ConfirmShareCoupon = 'confirm-share-coupon',
}

export enum TableStatus {
  AVAILABLE = 'Available',
  OCCUPIED = 'Occupied',
  CANCELLED = 'Cancelled',
}
