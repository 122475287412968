import { AdjustmentTargetEntityTypeEnum } from 'graphQL/dinein/generate/operations'

export enum ImageTypeEnum {
  Horizontal = 'Horizontal',
  HorizontalThumbnail = 'HorizontalThumbnail',
  Vertical = 'Vertical',
  VerticalThumbnail = 'VerticalThumbnail',
  Cover = 'Cover',
  Thumbnail = 'Thumbnail',
}

export type ImageTypeEnumStr = keyof ImageTypeEnum

export enum ProductColorSchemeEnum {
  MangoSmoothie = 'MangoSmoothie',
  BlueLagoon = 'BlueLagoon',
  Wheatgrass = 'Wheatgrass',
  Matcha = 'Matcha',
  Ovaltine = 'Ovaltine',
  WheatgrassSmoothie = 'WheatgrassSmoothie',
  MatchaLatte = 'MatchaLatte',
  ThaiTea = 'ThaiTea',
  ThaiTeaLatte = 'ThaiTeaLatte',
  Mojito = 'Mojito',
  SoyMilk = 'SoyMilk',
  TomatoJuice = 'TomatoJuice',
  Latte = 'Latte',
  ButterflyPeaLatte = 'ButterflyPeaLatte',
  StrawberrySmoothie = 'StrawberrySmoothie',
}

export type ProductColorSchemeEnumStr = keyof ProductColorSchemeEnum

export enum LanguageEnum {
  en = 'en',
  th = 'th',
}

export type LanguageEnumStr = keyof typeof LanguageEnum

export enum AdjustmentTypeEnumType {
  Cash = 'Cash',
  Discount = 'Discount',
  Concession = 'Concession',
}
export type AdjustmentTypeEnumTypeStr = keyof typeof AdjustmentTypeEnumType

export enum AdjustmentInvalidReasonEnumType {
  CodeHasBeenUsed = 'CodeHasBeenUsed',
  CouponHasBeenUsed = 'CouponHasBeenUsed',
  Expired = 'Expired',
  NotExisted = 'NotExisted',
  NotCashVoucher = 'NotCashVoucher',
  InvalidConditions = 'InvalidConditions',
  InsufficientTotalDue = 'InsufficientTotalDue',
}
export type AdjustmentInvalidReasonEnumTypeStr = keyof AdjustmentInvalidReasonEnumType

export enum AdjustmentSourceEnumType {
  ExternalCoupon = 'ExternalCoupon',
  ExternalMemberCoupon = 'ExternalMemberCoupon',
  MemberCoupon = 'MemberCoupon',
  Promotion = 'Promotion',
}
export type AdjustmentSourceEnumTypeStr = keyof AdjustmentSourceEnumType

export enum CartInvalidEnum {
  InvalidPromotion = 'InvalidPromotion',
  InvalidContainerReturnDate = 'InvalidContainerReturnDate',
  InvalidShippingAddress = 'InvalidShippingAddress',
  InvalidLineItem = 'InvalidLineItem',
  InvalidMinimumTotal = 'InvalidMinimumTotal',
  InvalidMinimumLineItemTotal = 'InvalidMinimumLineItemTotal',
  InvalidPickUpNotAvailable = 'InvalidPickUpNotAvailable',
  InvalidLineItemModifierQuantity = 'InvalidLineItemModifierQuantity',
  InvalidDeliveryDateTime = 'InvalidDeliveryDateTime',
  InvalidExternalCoupon = 'InvalidExternalCoupon',
  InvalidExceedLineItemMaxQuantity = 'InvalidExceedLineItemMaxQuantity',
}
export type CartInvalidEnumStr = keyof CartInvalidEnum

export enum CartCorrectionEnum {
  InactiveLineItemsRemoved = 'InactiveLineItemsRemoved',
}
export type CartCorrectionEnumStr = keyof CartCorrectionEnum

export enum CartPromotionInvalidEnum {
  InvalidConditions = 'InvalidConditions',
  Duplicate = 'Duplicate',
  NotExistedOrExpired = 'NotExistedOrExpired',
  Used = 'Used',
  Conflicting = 'Conflicting',
}
export type CartPromotionInvalidEnumStr = keyof typeof CartPromotionInvalidEnum

export enum MembershipTierEnum {
  FoodPassionFriend = 'FoodPassionFriend',
  FoodPassionBuddy = 'FoodPassionBuddy',
  FoodPassionFamily = 'FoodPassionFamily',
  Friend = 'Friend',
  Family = 'Family',
}
export type MembershipTierEnumStr = keyof MembershipTierEnum

export enum MembershipProviderEnum {
  FoodPassion = 'FoodPassion',
  HatoHeart = 'HatoHeart',
}
export type MembershipProviderEnumStr = keyof MembershipProviderEnum

export enum MembershipGenderEnum {
  Unknown = 'Unknown',
  Male = 'Male',
  Female = 'Female',
}
export type MembershipGenderEnumStr = keyof MembershipGenderEnum

export enum BuildingTypeEnum {
  House = 'House',
  CondoOffice = 'CondoOffice',
}
export type BuildingTypeEnumStr = keyof BuildingTypeEnum

export enum DeliveryMethodEnum {
  AtGuard = 'AtGuard',
  AtDoor = 'AtDoor',
  ToPerson = 'ToPerson',
  AtLobby = 'AtLobby',
  AtReception = 'AtReception',
}
export type DeliveryMethodEnumStr = keyof DeliveryMethodEnum

export enum ServiceMethodEnum {
  delivery = 'delivery',
  pickup = 'pickup',
  both = 'both',
}
export type ServiceMethodEnumStr = keyof typeof ServiceMethodEnum

export enum InvalidModifierEnum {
  TierIneligible = 'TierIneligible',
  TierBirthMonthRequired = 'TierBirthMonthRequired',
  MembershipRequired = 'MembershipRequired',
  ProductInactive = 'ProductInactive',
  OutOfStock = 'OutOfStock',
  Inactive = 'Inactive',
  InsufficientStock = 'InsufficientStock',
}
export type InvalidModifierEnumStr = keyof typeof InvalidModifierEnum

export enum OutOfDeliveryURLParamEnum {
  ChangeAddressLocation = 'ChangeAddressLocation',
  SelectPickUpStore = 'SelectPickUpStore',
}

export enum PromotionTypeEnum {
  Auto = 'Auto',
  Manual = 'Manual',
  ManualExternal = 'ManualExternal',
}

export type PromotionTypeEnumStr = keyof typeof PromotionTypeEnum

export enum categoryMenuTypeEnum {
  ScrollHorizontal = 'ScrollHorizontal',
  SearchDropdown = 'SearchDropdown',
}

export type categoryMenuTypeEnumStr = keyof typeof categoryMenuTypeEnum

export enum OrderStatusEnum {
  Placed = 'Placed',
  Confirmed = 'Confirmed',
  Cooking = 'Cooking',
  Refunded = 'Refunded',
  PartiallyRefunded = 'PartiallyRefunded',
  ReadyToCook = 'ReadyToCook',
  ReadyForDelivery = 'ReadyForDelivery',
  OutForDelivery = 'OutForDelivery',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
}

export type OrderStatusEnumStr = keyof typeof OrderStatusEnum

export enum MemberCouponStatusEnumType {
  Expired = 'Expired',
  PendingStart = 'PendingStart',
  Redeemed = 'Redeemed',
  Active = 'Active',
}

export type MemberCouponStatusEnumTypeStr = keyof typeof MemberCouponStatusEnumType

export enum OrderInvalidEnum {
  InvalidMemberCoupon = 'InvalidMemberCoupon',
  InvalidLineItem = 'InvalidLineItem',
  InvalidMinimumLineItemTotal = 'InvalidMinimumLineItemTotal',
  InvalidMinimumTotal = 'InvalidMinimumTotal',
  InvalidBeingCheckedOut = 'InvalidBeingCheckedOut',
  InvalidPromotion = 'InvalidPromotion',
  InvalidExternalCoupon = 'InvalidExternalCoupon',
  InvalidPaymentIsPending = 'InvalidPaymentIsPending',
}

export type OrderInvalidEnumStr = keyof typeof OrderInvalidEnum

export enum LineItemInvalidEnum {
  Inactive = 'Inactive',
  InsufficientStock = 'InsufficientStock',
  InvalidModifiers = 'InvalidModifiers',
  TierIneligible = 'TierIneligible',
  TierBirthMonthRequired = 'TierBirthMonthRequired',
  ExceedMaxQuantity = 'ExceedMaxQuantity',
  ServiceNotAllowed = 'ServiceNotAllowed',
  OutOfStock = 'OutOfStock',
  MembershipRequired = 'MembershipRequired',
  ModifierExceedMaximumQuantity = 'ModifierExceedMaximumQuantity',
  ModifierRequired = 'ModifierRequired',
}

export type LineItemInvalidEnumStr = keyof typeof LineItemInvalidEnum

export enum DineInOrderStatusEnum {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Placed = 'Placed',
}

export type DineInOrderStatusEnumStr = keyof typeof DineInOrderStatusEnum

export enum DineInLineItemStatusEnum {
  Cooking = 'Cooking',
  Serving = 'Serving',
  Served = 'Served',
}

export type DineInLineItemStatusEnumStr = keyof typeof DineInLineItemStatusEnum

export enum PaymentStatusEnum {
  PendingPayment = 'PendingPayment',
  PendingVerification = 'PendingVerification',
  Failed = 'Failed',
  Refunded = 'Refunded',
  PartiallyRefunded = 'PartiallyRefunded',
  Paid = 'Paid',
}

export enum SpicyLevelEnum {
  NotSpicy = 'NotSpicy',
  Mild = 'Mild',
  Medium = 'Medium',
  Hot = 'Hot',
}

export type AdjustmentTargetEntityTypeEnumStr = keyof typeof AdjustmentTargetEntityTypeEnum

export enum PromotionMemberTierRankConditionEnumType {
  Eligible = 'Eligible',
  NotEligible = 'NotEligible',
  BirthMonth = 'BirthMonth',
}

export type PromotionMemberTierRankConditionEnumTypeStr = keyof typeof PromotionMemberTierRankConditionEnumType

export enum AnalyticsRedeemPointsPageEnum {
  PointRedeem = 'Point Redeem',
  CampaignDetails = '"Campaign Details"',
}

export enum AnalyticsCloseCouponDetailsStatusEnum {
  Active = 'Active',
  Used = 'Used',
}

export enum UseScriptStatusEnum {
  idle = 'idle',
  loading = 'loading',
  ready = 'ready',
  error = 'error',
}

export enum ScheduledContentPlacementEnum {
  Hero1 = 'Hero1',
  Hero2 = 'Hero2',
  Hero3 = 'Hero3',
  Hero4 = 'Hero4',
  Hero5 = 'Hero5',
  HeroHome1 = 'HeroHome1',
  HeroHome2 = 'HeroHome2',
}

export enum IneligibleReasonEnumType {
  InsufficientPoints = 'InsufficientPoints',
  MaxRedemption = 'MaxRedemption',
  MaxRedemptionPerCustomer = 'MaxRedemptionPerCustomer',
  IneligibleTierRank = 'IneligibleTierRank',
}

export enum HatoReactGA4GroupsEnum {
  hato_store = 'hato_store',
  hato_heart = 'hato_heart',
  dine_in = 'dine_in',
}

export type HatoReactGA4GroupsEnumStr = keyof typeof HatoReactGA4GroupsEnum

export enum HatoPageEnum {
  homepageGon = 'homepageGon',
  homepage = 'homepage',
  checkout = 'checkout',
}

export enum CustomerPaymentTypeEnum {
  LinePay = 'LinePay',
  OmiseTrueMoneyWallet = 'OmiseTrueMoneyWallet',
  KBankThaiQR = 'KBankThaiQR',
  None = 'None',
  CashOnDelivery = 'CashOnDelivery',
  OmiseCreditCard = 'OmiseCreditCard',
  OmisePromptPay = 'OmisePromptPay',
  KPaymentKPlusNumber = 'KPaymentKPlusNumber',
  KPaymentPromptPayNumber = 'KPaymentPromptPayNumber',
  BankTransfer = 'BankTransfer',
  SCBPromptPay = 'SCBPromptPay',
}

export type CustomerPaymentTypeEnumStr = keyof typeof CustomerPaymentTypeEnum

export enum CreditCardBrandEnum {
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  JCB = 'JCB',
}

export type CreditCardBrandEnumStr = keyof typeof CreditCardBrandEnum

export enum BrandTypeEnum {
  SINGLE_BRAND = 'single-brand',
  MULTI_BRAND = 'multi-brand',
  THOUSAND_PRODUCTS = 'thousand-products',
  MALL = 'mall',
  MALL_WITHOUT_GROCERY = 'mall-without-grocery',
}
