import React, { Suspense, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter, useHistory } from 'react-router-dom'
import PrivateRoute from 'components/PrivateRoute'

import { routes, getPageLevelByPath, getTransitionNameByPath } from 'routes'
import { removeSession } from 'helpers/localStorage'
import { isDevelopment, isProduction } from 'variables/environment'
import initI18n from 'helpers/i18next'
import { Provider } from 'react-redux'
import { store } from 'store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import 'sanitize.css'
import 'styles/global-styles.css' // will deprecated

import * as Sentry from '@sentry/browser'
import reportWebVitals from './reportWebVitals'
import MainContainer from 'components/MainContainer'
import LoadingBrand from 'components/LoadingBrand'

// Custom Hooks
import usePageTransition from 'hooks/usePageTransition'

// Use React Context API
import AppProvider from 'contexts/AppProvider'
import LoadingPageProvider, { LoadingPageContext } from 'contexts/LoadingPageProvider'
import { ApolloProvider } from '@apollo/client'
import { liffApiClient } from 'variables/services'

// Polyfill array prototype find for IE
import 'core-js/features/array/find'
import 'core-js/features/object/assign'
import 'core-js/web/url-search-params'
import 'globalthis/polyfill'

const EmptyState = () => null

if (!isDevelopment) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: isProduction ? 'Production' : 'Staging',
    release: 'hatohub-web@' + process.env.REACT_APP_VERSION,
    // ignoreErrors: ['ChunkLoadError'],
  })
}

const ResetLocalStorage = () => {
  const history = useHistory()

  useEffect(() => {
    removeSession()
    history.push('/')
  }, [history])

  return null
}

initI18n()
let persistor = persistStore(store)

const Content = withRouter(({ location }) => {
  const { prevPageLevel, setPrevPageLevel, prevPageTransition, setPrevPageTransition } = usePageTransition()

  // Fixed for hashRouting
  // ---------------------------------------------------------------
  const pageLevelOflocation = location.state?.pageLevelOverWrite
    ? location.state.pageLevelOverWrite
    : getPageLevelByPath(location.pathname)
  const transitionNameOflocation = getTransitionNameByPath(location.pathname)
  // ---------------------------------------------------------------

  const pageLevel = pageLevelOflocation ?? 0
  const isBack = prevPageLevel > pageLevel
  const originTransitionName = transitionNameOflocation ?? 'fade'
  const timeout = pageLevel === prevPageLevel ? 0 : 400
  const transitionName = timeout === 0 ? '' : isBack ? prevPageTransition : originTransitionName

  return (
    <TransitionGroup
      childFactory={(child) =>
        React.cloneElement(child, {
          classNames: isBack ? `${transitionName}-reverse` : transitionName,
          timeout: timeout,
        })
      }
    >
      <CSSTransition
        key={location.pathname}
        timeout={{ enter: timeout, exit: timeout }}
        classNames={isBack ? `${transitionName}-reverse` : transitionName}
        onEntered={() => {
          setPrevPageLevel(pageLevel)
          setPrevPageTransition(originTransitionName)
          window.dispatchEvent(new Event('resize'))
        }}
      >
        <div className="page-transition-wrapper">
          <Suspense fallback={<EmptyState />}>
            {/* Liff Multi Store */}
            <Switch location={location}>
              {routes.map((route) => {
                if (route.private) {
                  return (
                    <PrivateRoute key={route.path} exact={route.exact} path={route.path}>
                      <route.component />
                    </PrivateRoute>
                  )
                } else {
                  return (
                    <Route key={route.path} exact={route.exact} path={route.path}>
                      <route.component />
                    </Route>
                  )
                }
              })}

              <Route path="/clear-localstorage" component={ResetLocalStorage} />
              <Redirect exact to="/" />
            </Switch>
          </Suspense>
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
})

ReactDOM.render(
  <ApolloProvider client={liffApiClient}>
    <AppProvider>
      <LoadingPageProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <MainContainer>
                <Content />
              </MainContainer>
            </Router>
            <LoadingPageContext.Consumer>
              {({ isLoadingPage }) => <LoadingBrand show={isLoadingPage} />}
            </LoadingPageContext.Consumer>
          </PersistGate>
        </Provider>
      </LoadingPageProvider>
    </AppProvider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
