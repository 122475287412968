import { useState, useEffect } from 'react'
import { getPageLevelByPath, getTransitionNameByPath } from 'routes'

const usePageTransition = () => {
  const [prevPageLevel, setPrevPageLevel] = useState(0)
  const [prevPageTransition, setPrevPageTransition] = useState<string | 0>('')

  useEffect(() => {
    const path = window.location.href.replace(window.location.origin, '').replace('/#', '')
    setPrevPageLevel(getPageLevelByPath(path))
    setPrevPageTransition(getTransitionNameByPath(path))
  }, [])

  return {
    prevPageLevel,
    setPrevPageLevel,
    prevPageTransition,
    setPrevPageTransition,
  }
}

export default usePageTransition
