import React, { CSSProperties, FC, MouseEvent, ReactNode } from 'react'
import styled from 'styled-components'
import { family, weight } from 'components/base-ui/Fonts'
import shadow from 'styles/shadow'
import { onClick } from 'helpers/fastClick'

interface ButtonStyledProps {
  padding?: string
  minWidth?: string
  float?: boolean
  minHeight?: boolean
  noBorderRadius?: boolean
  xtra_sssm?: boolean
  xtra_ssm?: boolean
  xtra_sm?: boolean
  sm?: boolean
  md?: boolean
  md2?: boolean
  md3?: boolean
  lg?: boolean
  lgRequired?: boolean
  inputSize?: boolean
  inputSizeSearch?: boolean
  inputBorderRadius?: boolean
  onlyIcon?: boolean
  flexWrap?: boolean
  alignTop?: boolean
  fullContent?: boolean
  left?: boolean
  isProcessing?: boolean
  disabled?: boolean
  outline?: boolean
  gray?: boolean
  darkGrey?: boolean
  secondary?: boolean
  warning?: boolean
  outlineGreen?: boolean
  outlineWhite?: boolean
  lgWarning?: boolean
  noOutLine?: boolean
  noOutLineWhite?: boolean
  outlineError?: boolean
  error?: boolean
}

interface Props extends ButtonStyledProps {
  children: ReactNode
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  enableFastClick?: boolean
  id?: string
  className?: string
  type?: string
  title?: boolean
  style?: CSSProperties
  bold?: boolean
  testId?: string
}

const Button: FC<Props> = ({
  children,
  isProcessing = false,
  noBorderRadius = false,
  onlyIcon = false,
  fullContent = false,
  onClick: btnOnClick = () => {},
  enableFastClick = false,
  disabled = false,
  noOutLine = false,
  type,
  title,
  testId,
  ...rest
}) => {
  let clickObj = {}
  if (btnOnClick) {
    clickObj = enableFastClick ? { ...onClick((e) => btnOnClick(e)) } : { onClick: btnOnClick }
  }

  return (
    <StyledButton
      data-testid={testId}
      {...rest}
      {...clickObj}
      noBorderRadius={noBorderRadius}
      onlyIcon={onlyIcon}
      disabled={disabled}
      isProcessing={isProcessing}
      fullContent={fullContent}
      noOutLine={noOutLine}
    >
      <PreloadImage src={`${process.env.PUBLIC_URL}/assets/loading/loading-white.svg`} />
      {isProcessing ? (
        noOutLine ? (
          <LoadingWrapper>
            <svg
              width="30px"
              height="30px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
              className="lds-rolling"
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                ng-attr-stroke="{{config.color}}"
                ng-attr-stroke-width="{{config.width}}"
                ng-attr-r="{{config.radius}}"
                ng-attr-stroke-dasharray="{{config.dasharray}}"
                stroke="#fff"
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
                transform="rotate(162 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="linear"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                  dur="400ms"
                  begin="0s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </svg>
          </LoadingWrapper>
        ) : (
          <img src={`${process.env.PUBLIC_URL}/assets/loading/loading-white.svg`} width="30px" height="28px" alt="" />
        )
      ) : (
        <div className="content-wrapper">{children}</div>
      )}
    </StyledButton>
  )
}

const PreloadImage = styled.img`
  display: none;
`

const StyledButton = styled.button<ButtonStyledProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => (padding ? padding : '0 20px')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '280px')};
  ${({ float }) => (float ? `width :100%;` : '')}
  ${({ minHeight }) => (minHeight ? 'min-height: 40px;' : 'height: 40px;')}
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: ${weight.semiBold};
  ${({ noBorderRadius }) => (noBorderRadius ? '' : 'border-radius: 20px;')}
  transition: .3s;

  &:focus {
    outline: none;
  }

  ${({ title }) =>
    title
      ? `
    font-family: ${family.brand};
    `
      : ''}

  ${({ xtra_sssm }) =>
    xtra_sssm
      ? `
    min-width: 88px;
    min-height: 25px;
    height: 25px;
    font-weight: ${weight.medium};
    font-size: 12px;
    letter-spacing: 0px;
    padding: 0 10px;

    i {
      font-size: 10px;
    }

    img {
      width: 20px;
      height: 20px;
    }
    `
      : ''}

  ${({ xtra_ssm }) =>
    xtra_ssm
      ? `
    min-width: 108px;
    min-height: 28px;
    height: 28px;
    font-weight: ${weight.medium};
    font-size: 12px;
    letter-spacing: 0px;
    `
      : ''}

  ${({ xtra_sm }) =>
    xtra_sm
      ? `
    min-width: 80px;
    min-height: 34px;
    height: 34px;
    font-size: 14px;
    letter-spacing: .5px;
    `
      : ''}

  ${({ sm }) =>
    sm
      ? `
    min-width: 93px;
    min-height: 38px;
    height: 38px;
    font-size: 14px;
    `
      : ''}

   ${({ md }) =>
    md
      ? `
     min-height: 47px;
     height: 47px;
     border-radius: 24px;
     font-size: 18px;
     `
      : ''}

  ${({ md2 }) =>
    md2
      ? `
    min-height: 54px;
    height: 54px;
    border-radius: 27px;
    font-size: 18px;
    `
      : ''}

  ${({ md3 }) =>
    md3
      ? `
    min-height: 62px;
    height: 62px;
    border-radius: 31px;
    font-size: 18px;
    `
      : ''}

  ${({ lg }) =>
    lg
      ? `
    min-height: 66px;
    height: 66px;
    border-radius: 33px;
    font-size: 18px;
    `
      : ''}

  ${({ lgRequired }) =>
    lgRequired
      ? `
    min-height: 62px;
    height: 62px;
    border-radius: 31px;
    `
      : ''}

  ${({ inputSize }) =>
    inputSize
      ? `
    min-height: 58px;
    height: 58px;
    `
      : ''}

  ${({ inputSizeSearch }) =>
    inputSizeSearch
      ? `
    min-height: 50px;
    height: 50px;
    `
      : ''}

  ${({ inputBorderRadius }) =>
    inputBorderRadius
      ? `
    border-radius: 8px;
    border-width: 1px !important;
    `
      : ''}

  i {
    transition: 0.3s;
    ${({ onlyIcon }) => (onlyIcon ? '' : 'margin-right: 6px;')}
  }

  div {
    transition: 0.3s;
  }

  .touch &.touch,
  .touch &:active {
    transition: 0s !important;

    i {
      transition: 0s !important;
    }

    div {
      transition: 0s !important;
    }
  }

  .content-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ flexWrap }) => (flexWrap ? `flex-wrap: wrap;` : '')}
    ${({ alignTop }) => (alignTop ? 'align-items: flex-start;' : '')}
    ${({ fullContent }) => (fullContent ? 'width: 100%;' : '')}
    ${({ left }) => (left ? 'justify-content: flex-start;' : '')}
  }

  .no-touch &:hover,
  .touch &:active {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }

  ${({ isProcessing, theme }) =>
    isProcessing
      ? `
    background-color: ${theme.colors.primaryHover} !important;
  `
      : ``}

  ${({ disabled, isProcessing, theme }) =>
    disabled && isProcessing === false
      ? `
    background-color: ${theme.colors.baseGrey} !important;
    color: ${theme.colors.white} !important;
    cursor: default !important;
    box-shadow: none;
    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.baseGrey} !important;
      color: ${theme.colors.white} !important;
      box-shadow: none;
    }
  `
      : ''}

  ${({ outline, theme }) =>
    outline
      ? `

    div, i {
      transition: 0s;
    }

    box-shadow: none;
    border: 1px solid ${theme.colors.primary};
    background-color:${theme.colors.white};
    color: ${theme.colors.primary};

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.primary};
      color: white;

      div {
        color: white;
      }
    }
  `
      : ''}

  ${({ outline, gray, theme }) =>
    outline && gray
      ? `

    div, i {
      transition: 0s;
    }

    box-shadow: none;
    border: 1px solid ${theme.colors.baseGrey};
    background-color: transparent;
    color: ${theme.colors.baseGrey};

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.baseGrey};
      color: white;

      div {
        color: white;
      }
    }
  `
      : ''}
  
  ${({ outline, darkGrey, theme }) =>
    outline && darkGrey
      ? `

    div, i {
      transition: 0s;
    }

    box-shadow: none;
    border: 2px solid ${theme.colors.darkGrey};
    background-color: transparent;
    color: ${theme.colors.darkGrey};

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.darkGrey};
      color: white;

      div {
        color: white;
      }
    }
  `
      : ''}

  ${({ outline, secondary, theme }) =>
    outline && secondary
      ? `

    div, i {
      transition: 0s;
    }

    box-shadow: none;
    border: 1px solid ${theme.colors.secondary};
    background-color: transparent;
    color: ${theme.colors.secondary};

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.secondary};
      color: white;

      div {
        color: white;
      }
    }
  `
      : ''}

  ${({ outline, warning, theme }) =>
    outline && warning
      ? `

    div, i {
      transition: 0s;
    }

    box-shadow: none;
    border: 1px solid ${theme.colors.yellow500};
    background-color: transparent;
    color: ${theme.colors.yellow500};

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.yellow500};
      color: white;

      div {
        color: white;
      }
    }
  `
      : ''}

  ${({ outline, disabled, theme }) =>
    outline && disabled
      ? `
    transition: 0s;
    opacity: .5;
    border: 1px solid ${theme.colors.primaryHover} !important;
    background-color: transparent !important;
    color: ${theme.colors.primaryHover} !important;
  `
      : ''}

  ${({ outline, isProcessing, theme }) =>
    outline && isProcessing
      ? `
    transition: .3s;
    opacity: 1;
    border: 1px solid ${theme.colors.primary} !important;
    background-color: ${theme.colors.primary} !important;
    color: white !important;
  `
      : ``}

  ${({ outline, md2 }) =>
    outline && md2
      ? `
    border-width: 2px;
  `
      : ''}

  ${({ outlineGreen, theme }) =>
    outlineGreen
      ? `
    box-shadow: none;
    justify-content: flex-start;
    padding-left: 18px;
    font-size: 16px;
    font-weight: ${weight.normal};
    border: 1px solid ${theme.colors.primary};
    background-color: transparent;
    color: ${theme.colors.primary};

    i {
      margin-right: 17px;
      color: ${theme.colors.primary};
    }

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.primary};
      color: white;

      i {
        color: white;
      }

      div {
        color: white;
      }
    }
  `
      : ''}


  ${({ outlineWhite, theme }) =>
    outlineWhite
      ? `
    justify-content: flex-start;
    padding-left: 18px;
    border: 0;
    font-size: 16px;
    font-weight: ${weight.normal};
    box-shadow: ${shadow.soft};
    border: 1px solid ${theme.colors.lightGrey};
    background-color: transparent;
    color: ${theme.colors.gray800};

    i {
      margin-right: 17px;
      color: ${theme.colors.primary};
    }

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.primary};
      color: white;

      i {
        color: white;
      }

      div {
        color: white;
      }
    }
  `
      : ''}

  ${({ outlineWhite, warning, theme }) =>
    outlineWhite && warning
      ? `
    justify-content: flex-start;
    padding-left: 18px;
    border: 0;
    font-size: 16px;
    font-weight: ${weight.normal};
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15), 0 0 0 1px ${theme.colors.yellow500};
    background-color: transparent;
    color: ${theme.colors.yellow500};

    i {
      margin-right: 17px;
      color: ${theme.colors.yellow500};
    }

    div {
      color: ${theme.colors.yellow500};
    }

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.yellow500};
      color: white;

      i {
        color: white;
      }

      div {
        color: white;
      }
    }
  `
      : ''}

  ${({ outlineWhite, lgWarning, theme }) =>
    outlineWhite && lgWarning
      ? `
    height: 62px;
    border-radius: 31px;
    justify-content: flex-start;
    padding-left: 27px;
    border: 0;
    font-size: 16px;
    font-weight: ${weight.medium};
    box-shadow: inset 0 0 0 2px ${theme.colors.yellow500}, 0px 1px 6px rgba(0, 0, 0, 0.15);
    background-color: transparent;
    color: ${theme.colors.gray800};

    i {
      margin-right: 12px;
      color: ${theme.colors.yellow500};
    }

    div {
      color: ${theme.colors.gray800};
    }

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.yellow500};
      color: white;

      i {
        color: white;
      }

      div {
        color: white;
      }
    }
  `
      : ''}

  ${({ noOutLine, theme }) =>
    noOutLine
      ? `
    height: 30px;
    min-height: 30px;
    border-radius: 31px;
    justify-content: flex-start;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 27px;
    border: 0;
    font-size: 16px;
    font-weight: ${weight.medium};
    box-shadow: none;
    background-color: transparent;
    color: ${theme.colors.primary};

    i {
      margin-right: 12px;
      color: ${theme.colors.primary};
    }

    div {
      color: ${theme.colors.primary};
    }

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.white};
      color: ${theme.colors.primary};

      i {
        color: ${theme.colors.primary};
      }

      div {
        color: ${theme.colors.primary};
      }
    }
  `
      : ''}

  ${({ sm, noOutLine }) =>
    noOutLine && sm
      ? `
    min-height: 38px;
    height: 38px;
    padding-left: 20px;
  `
      : ''}

  ${({ noOutLine, isProcessing, theme }) =>
    noOutLine && isProcessing
      ? `
    background-color: ${theme.colors.white} !important;
  `
      : ``}

  ${({ noOutLineWhite, theme }) =>
    noOutLineWhite
      ? `
    justify-content: flex-start;
    border: 0;
    font-size: 16px;
    font-weight: ${weight.medium};
    box-shadow: none;
    background-color: transparent;
    color: ${theme.colors.white};

    i {
      margin-right: 12px;
      color: ${theme.colors.white};
    }

    div {
      color: ${theme.colors.white};
    }

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.white};
      color: ${theme.colors.white};

      i {
        color: ${theme.colors.white};
      }

      div {
        color: ${theme.colors.white};
      }
    }
  `
      : ''}

  ${({ noOutLine, isProcessing, theme }) =>
    noOutLine && isProcessing
      ? `
    background-color: ${theme.colors.white} !important;
  `
      : ``}

  ${({ outlineError, theme }) =>
    outlineError
      ? `
    box-shadow: none;
    justify-content: flex-start;
    padding-left: 18px;
    font-weight: ${weight.normal};
    border: 2px solid ${theme.colors.errorLight};
    background-color: transparent;
    color: ${theme.colors.errorLight};

    i {
      margin-right: 8px;
      color: ${theme.colors.errorLight};
    }

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.errorLight};
      color: white;

      i {
        color: white;
      }

      div {
        color: white;
      }
    }
  `
      : ''}

  ${({ error, theme }) =>
    error
      ? `
    box-shadow: none;
    padding-left: 18px;
    font-weight: ${weight.normal};
    background-color: ${theme.colors.errorLight};
    color: white;

    i {
      margin-right: 8px;
      color: white;
    }

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.errorHover};
      color: white;

      i {
        color: white;
      }

      div {
        color: white;
      }
    }
  `
      : ''}

  ${({ error, isProcessing, theme }) =>
    error && isProcessing
      ? `
    box-shadow: none;
    padding-left: 18px;
    font-weight: ${weight.normal};
    background-color: ${theme.colors.errorHover} !important;
    color: white;

    i {
      margin-right: 8px;
      color: white;
    }

    .no-touch &:hover,
    .touch &:active {
      background-color: ${theme.colors.errorHover};
      color: white;

      i {
        color: white;
      }

      div {
        color: white;
      }
    }
  `
      : ''}
  
  ${({ error, isProcessing, disabled, theme }) =>
    error && isProcessing === false && disabled
      ? `
    color: ${theme.colors.gray200};

    .no-touch &:hover,
    .touch &:active {
      color: ${theme.colors.gray200};

      i {
        color: ${theme.colors.gray200};
      }

      div {
        color: ${theme.colors.gray200};
      }
    }
  `
      : ''}
`

const LoadingWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  circle {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`

export default Button
