import React, { FC } from 'react'
import { ModalFromBottom, Fonts, Button } from 'components/base-ui'
import styled from 'styled-components'
import { generateSrcSet } from 'helpers/image'

interface ModalConfirmBottomProps {
  onRequestClose: () => void
  shouldCloseOnOverlayClick?: boolean
  isOpen: boolean
  imagePath: string
  imageType: string
  isGenerateSrc: boolean
  title: string
  label?: string
  btnAccept: string
  btnCancel?: string
  onAccept: () => void
  onCancel?: () => void
}

const ModalConfirmBottom: FC<ModalConfirmBottomProps> = ({
  onRequestClose,
  shouldCloseOnOverlayClick = false,
  isOpen,
  imagePath,
  imageType,
  isGenerateSrc,
  title,
  label,
  btnAccept,
  btnCancel,
  onAccept,
  onCancel,
}) => {
  return (
    <ModalFromBottom
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      zindex={100}
      btnClose={null}
      hideCloseButton
      isLoading={false}
      isOpen={isOpen}
    >
      <ModalContent>
        {isGenerateSrc ? (
          <Image src={imagePath} srcSet={generateSrcSet(imagePath, imageType)} alt="" />
        ) : (
          <Image src={imagePath} alt="" />
        )}

        <Fonts.H3>{title}</Fonts.H3>
        {label && <Fonts.ActionBaseRegular>{label}</Fonts.ActionBaseRegular>}

        <ButtonWrapper>
          {btnAccept && (
            <Button onClick={onAccept} enableFastClick={false}>
              {btnAccept}
            </Button>
          )}
          {btnCancel && (
            <Button onClick={onCancel} outline enableFastClick={false}>
              {btnCancel}
            </Button>
          )}
        </ButtonWrapper>
      </ModalContent>
    </ModalFromBottom>
  )
}

export default ModalConfirmBottom

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 7px;
  padding-bottom: 20px;
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-top: 20px;

  button {
    min-width: 200px;
  }
`

const Image = styled.img`
  width: 225px;
  height: auto;
  padding-bottom: 40px;
`
