import React from 'react'
import styled from 'styled-components'
import spacing from 'styles/spacing'
import { Icon, Fonts } from 'components/base-ui'
import { FC } from 'react'

enum LabelTypeEnum {
  warning = 'warning',
  normal = 'normal',
  black = 'black',
  'black-inverse' = 'black-inverse',
}

type LabelTypeEnumStr = keyof typeof LabelTypeEnum

interface Props {
  type: LabelTypeEnumStr
  text: string
  hideIcon?: boolean
  center?: boolean
}
const LabelWarning: FC<Props> = ({ text, type = 'normal', hideIcon = false, center = false }) => {
  return (
    <Label type={type} center={center}>
      {!hideIcon && type !== 'black-inverse' && <Icon name="notice-circle" />}
      {type === 'black' && <Fonts.BaseTitleBold>{text}</Fonts.BaseTitleBold>}
      {type !== 'black' ? text : ''}
    </Label>
  )
}

export default LabelWarning

const Label = styled.div<{ type: LabelTypeEnumStr; center: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 69px;
  border-radius: 36px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2), inset 0 0 0 1px black;
  background-color: white;
  color: black;
  font-size: 16px;

  i {
    margin-right: 5px;
    font-size: 14px;
    color: black;
    transition: 0.6s;
  }

  ${({ center }) =>
    center
      ? `
    text-align: center;
    `
      : ''}

  ${({ type, theme }) =>
    type === 'warning'
      ? `
    box-shadow: inset 0 0 0 2px ${theme.colors.yellow500}, 0 5px 20px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    color: ${theme.colors.gray800};

    i {
      margin-right: 10px;
      color: ${theme.colors.yellow500};
    }
    `
      : ''}

  ${({ type, theme }) =>
    type === 'black'
      ? `
    height: auto;
    height: initial;
    border-radius: 300px;
    padding: ${spacing.md}px ${spacing.lg}px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    background-color: rgba(51, 51, 51, 0.8);
    color: ${theme.colors.bgColor};
    `
      : ''}

  ${({ type, theme }) =>
    type === 'black-inverse'
      ? `
    height: auto;
    height: initial;
    border-radius: 300px;
    padding: ${spacing.md}px ${spacing.lg}px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    background-color: rgba(51, 51, 51, 0.9);
    color: ${theme.colors.white};
    `
      : ''}
`
