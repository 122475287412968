import React from 'react'
import styled, { keyframes } from 'styled-components'
import { bounceIn } from 'react-animations'

interface CircleCheckBoxProps {
  checked: boolean
  onClick?: () => void
}

const CircleCheckBox = ({ checked, onClick = () => {} }: CircleCheckBoxProps) => {
  return checked ? <CircleChecked onClick={onClick} /> : <CircleUnChecked onClick={onClick} />
}

export default CircleCheckBox

const BounceAnimation = keyframes`${bounceIn}`

const CircleUnChecked = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.gray200};
  animation: ${BounceAnimation} 0.6s linear forwards;
`
const CircleChecked = styled.div`
  position: relative;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  box-shadow: inset 0 0 0 2px ${(props) => props.theme.colors.primary};
  animation: ${BounceAnimation} 0.6s linear forwards;

  &::before {
    content: '\\e913';
    font-family: 'icons-indiedish' !important;
    display: block;
    color: white;
    font-size: 10px;
    transform: scale(0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -7px;
    margin-left: -7px;
  }
`
