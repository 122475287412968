import React, { CSSProperties, FC, ReactNode } from 'react'
import styled from 'styled-components'

import spacing from 'styles/spacing'
import border from 'styles/border'

import { Icon, Fonts } from 'components/base-ui'

enum LabelBoxEnum {
  errorLight = 'errorLight',
  infoDark = 'infoDark',
  success = 'success',
  warning = 'warning',
  info = 'info',
  error = 'error',
  infoLight = 'infoLight',
}

export type LabelBoxEnumStr = keyof typeof LabelBoxEnum

enum LabelBoxSizeEnum {
  sm = 'm',
  md = 'd',
}

type LabelBoxSizeEnumStr = keyof typeof LabelBoxSizeEnum

interface Props {
  title?: string
  text: ReactNode | string
  type: LabelBoxEnumStr
  size?: LabelBoxSizeEnumStr
  style?: CSSProperties
}

const LabelBox: FC<Props> = ({ title, text, type, size = 'md', ...rest }) => {
  let styledText
  switch (type) {
    case 'errorLight':
      styledText = <DesSm>{text}</DesSm>
      break
    case 'infoDark':
      styledText = <Fonts.BaseTitleRegular>{text}</Fonts.BaseTitleRegular>
      break
    default:
      styledText = <Fonts.Base>{text}</Fonts.Base>
  }

  return (
    <LabelBoxWrapper type={type} {...rest}>
      {type && (
        <IconWrapper type={type}>
          {type === 'success' && <Icon name="check" />}
          {(type === 'warning' || type === 'error' || type === 'errorLight') && <Icon name="warning-triangle-redius" />}
          {(type === 'info' || type === 'infoDark') && (
            <Icon style={{ position: 'relative', top: '3px' }} name="notice-circle-2" />
          )}
          {type === 'infoLight' && <Icon style={{ position: 'relative', top: '2px' }} name="information" />}
        </IconWrapper>
      )}
      <div>
        {title && size === 'md' && <TitleMd type={type}>{title}</TitleMd>}
        {title && size === 'sm' && <TitleSm type={type}>{title}</TitleSm>}
        {text && styledText}
      </div>
    </LabelBoxWrapper>
  )
}

export default LabelBox

const TitleMd = styled(Fonts.BaseTitleBold)<{ type: LabelBoxEnumStr }>`
  font-size: 13px;
  ${({ type, theme }) =>
    type === 'infoLight' &&
    `
      font-weight: ${Fonts.weight.normal};
      color: ${theme.colors.infoDark}
    `}
  ${({ type, theme }) => type === 'errorLight' && ` color: ${theme.colors.errorLight} `}
  
  ${({ type, theme }) =>
    type === 'success' &&
    `
      font-weight: ${Fonts.weight.normal};
      color: ${theme.colors.success}
    `}
`

const TitleSm = styled(Fonts.SmallTitleBold)<{ type: LabelBoxEnumStr }>`
  ${({ type, theme }) =>
    type === 'infoLight'
      ? `
    font-weight: ${Fonts.weight.normal};
    color: ${theme.colors.infoDark}
    `
      : ''}

  ${({ type, theme }) =>
    type === 'errorLight'
      ? `
      color: ${theme.colors.errorLight}
      `
      : ''}
`

const DesSm = styled(Fonts.SmallTitleBold)`
  font-size: 10px;
`

const LabelBoxWrapper = styled.div<{ type: LabelBoxEnumStr }>`
  display: flex;
  color: ${({ theme }) => theme.colors.base};
  padding: ${spacing.sm}px ${spacing.md}px;
  border-radius: ${border.radius.md}px;
  border-style: solid;
  border-width: ${border.width.md}px;

  ${({ type, theme }) =>
    type === 'success'
      ? `
    border-color: ${theme.colors.success};
    background-color: ${theme.colors.successLightWhite};
    `
      : ''}

  ${({ type, theme }) =>
    type === 'warning'
      ? `
    border-color: ${theme.colors.warningLight};
    background-color: ${theme.colors.warningBg};
    `
      : ''}

  ${({ type, theme }) =>
    type === 'error'
      ? `
    border-color: ${theme.colors.errorLight};
    background-color: ${theme.colors.errorLightWhite};
    `
      : ''}
  
  ${({ type, theme }) =>
    type === 'errorLight'
      ? `
    border-color: ${theme.colors.errorLight};
    background-color: ${theme.colors.errorBgLight};
    `
      : ''}

  ${({ type, theme }) =>
    type === 'info'
      ? `
    border-color: ${theme.colors.infoDark};
    background-color: ${theme.colors.infoLightWhite};
    `
      : ''}

  ${({ type, theme }) =>
    type === 'infoLight'
      ? `
    border-color: ${theme.colors.infoDark};
    background-color: ${theme.colors.infoLightWhite};
    `
      : ''}
  
  ${({ type, theme }) =>
    type === 'infoDark'
      ? `
    border-color: ${theme.colors.infoDark};
    color: ${theme.colors.infoDark};
    background-color: ${theme.colors.infoLightWhite};
    `
      : ''}
`

const IconWrapper = styled.div<{ type: LabelBoxEnumStr }>`
  position: relative;
  top: 1px;
  line-height: 1em;

  i {
    font-size: 14px;
    margin-right: ${spacing.sm}px;

    ${({ type, theme }) =>
      type === 'success'
        ? `
      font-size: 11px;
      color: ${theme.colors.success}
      `
        : ''}

    ${({ type, theme }) =>
      type === 'warning'
        ? `
      font-size: 18px;
      color: ${theme.colors.warningLight}
      `
        : ''}

    ${({ type, theme }) =>
      type === 'error'
        ? `
      color: ${theme.colors.errorLight}
      `
        : ''}
      
    ${({ type, theme }) =>
      type === 'errorLight'
        ? `
      color: ${theme.colors.errorLight}
      `
        : ''}

    ${({ type, theme }) =>
      type === 'info' || type === 'infoLight'
        ? `
      color: ${theme.colors.infoDark}
      `
        : ''}
  }
`
