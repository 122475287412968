import { BrandTypeEnum } from 'brands'

export const parseLiffFromRedirectUrl = () => {
  const queryString = decodeURIComponent(window.location.search).replace('?liff.state=', '')
  return new URLSearchParams(queryString)
}

export const getSubDomains = () => {
  const urlStr = window.location.hostname
  const arrUrl = urlStr.split('.')
  return arrUrl[0]
}

export const generateQueriesStr = (queries: string[]) => {
  let queriesStr = queries.length > 0 ? `?${queries.join('&')}` : ''
  return queriesStr
}

export const generateObjectToQueryString = (params = {}) => {
  return Object.entries(params)
    .map((param) => `${param[0]}=${param[1]}`)
    .join('&')
}

interface GetBackPathFromProductDetailProps {
  brandType: string
  locationID: number
  categoryID: number
}

export const getBackPathFromProductDetail = ({
  brandType,
  locationID,
  categoryID,
}: GetBackPathFromProductDetailProps) => {
  switch (brandType) {
    case BrandTypeEnum.MULTI_BRAND:
      return `/store/${locationID}`
    case BrandTypeEnum.MALL_WITHOUT_GROCERY:
    case BrandTypeEnum.THOUSAND_PRODUCTS:
      return `/category/${categoryID}/${locationID}`
    default:
      return ''
  }
}

export const getLinkPoweredByHato = ({
  context,
  lang,
}: {
  context: 'delivery' | 'hatoheart' | 'dine-in'
  lang: 'th' | 'en'
}) => {
  const langPath = lang === 'th' ? 'th/home-page' : 'en/hato-hub'

  let utm_medium = ''
  switch (context) {
    case 'delivery':
      utm_medium = 'delivery'
      break
    case 'hatoheart':
      utm_medium = 'CRM'
      break
    case 'dine-in':
      utm_medium = 'dinein'
      break
  }

  return `https://www.hatohub.com/${langPath}/?utm_source=LIFF&utm_medium=${utm_medium}&utm_campaign=logo&openExternalBrowser=1`
}

export interface Params {
  [key: string]: string
}

export const createLIFFLinkWithParams = (basePath: string, params) => {
  const urlParams = new URLSearchParams(params).toString()
  return `https://liff.line.me/${basePath}?${urlParams}`
}
