import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { getSrcSetStr } from 'helpers/image'
import Fonts from 'components/base-ui/Fonts'
import { Button as BaseButton } from 'components/base-ui'

interface ScreenWithImageProps {
  id?: string
  imagePath: string
  imageSize: string
  isGenerateSrc?: boolean
  title: string
  description?: string
  isShowButton?: boolean
  buttonText?: string
  buttonOutline?: boolean
  onClick?: () => void
  children?: ReactNode
  maxWidth?: string
}

const ScreenWithImage: FC<ScreenWithImageProps> = ({
  id = '',
  imagePath,
  imageSize,
  isGenerateSrc = false,
  title,
  description = '',
  isShowButton = false,
  buttonText = '',
  buttonOutline = false,
  children = null,
  onClick = () => {},
  maxWidth = '252px',
}) => {
  return (
    <Wrapper className="screen-with-image" id={id}>
      {isGenerateSrc ? (
        <Image
          className="image-header"
          src={imagePath}
          srcSet={getSrcSetStr({ img: imagePath, hires2x: true, hires3x: true })}
          size={imageSize}
          alt=""
          maxWidth={maxWidth}
        />
      ) : (
        <Image className="image-header" src={imagePath} size={imageSize} alt="" />
      )}
      <TextWrapper>
        <Title className="title">{title}</Title>
        {description && <Description className="description">{description}</Description>}
      </TextWrapper>
      {isShowButton && buttonText && (
        <Button minWidth="250px" md outline={buttonOutline} onClick={onClick}>
          {buttonText}
        </Button>
      )}
      {children}
    </Wrapper>
  )
}

export default ScreenWithImage

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 25px;
  z-index: 100;
  padding: 0 60px;
  height: 100vh;
  width: 100vw;
`

const Image = styled.img<{ size: string; maxWidth?: string }>`
  width: ${({ size }) => size};
  max-width: ${({ maxWidth }) => maxWidth};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
`

const Title = styled(Fonts.H2)`
  text-align: center;
`

const Description = styled(Fonts.SmallTitleRegular)`
  text-align: center;
  font-size: 14px;
`

const Button = styled(BaseButton)`
  height: 54px;
  border-radius: 85px;
  padding: 0 58px;
`
