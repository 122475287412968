import styled, { keyframes } from 'styled-components'

const Spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const Spin1 = keyframes`
  20% { transform: rotate(150deg) }
  40% { transform: rotate(300deg) }
  80% { transform: rotate(300deg) }
  100% { transform: rotate(360deg) }
`
const Spin2 = keyframes`
  0% { transform:rotate(-30deg) }
  20% {
    transform:rotate(-30deg);
    border-color:transparent transparent ${(props) => props.theme.colors.bgGray} ${(props) =>
  props.theme.colors.bgGray};
  }
  21% { border-color:${(props) => props.theme.colors.primary} ${(props) =>
  props.theme.colors.primary} transparent transparent }
  40% { transform:rotate(-30deg) }
  60% {
    transform:rotate(120deg);
    border-color:${(props) => props.theme.colors.primary} ${(props) =>
  props.theme.colors.primary} transparent transparent
  }
  61% { border-color:transparent transparent ${(props) => props.theme.colors.bgGray} ${(props) =>
  props.theme.colors.bgGray} }
  80% { transform:rotate(270deg) }
  100% { transform:rotate(330deg) }
`

const GenericLoader = styled.div`
  position: fixed;
  width: 100px !important;
  height: 100px !important;
  margin: auto;
  box-shadow: inset 0 0 0 9px ${(props) => props.theme.colors.bgGray}, 0 0 0 1px ${(props) => props.theme.colors.bgGray};
  animation: ${Spin} 8s linear infinite;

  &,
  &::before,
  &::after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    position: absolute;
    border-radius: 50%;
  }

  &::before {
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    border: 6px solid ${(props) => props.theme.colors.bgGray};
    border-bottom: 6px solid ${(props) => props.theme.colors.primary};
    border-left: 6px solid ${(props) => props.theme.colors.primary};
    animation: ${Spin1} 2s linear infinite;
  }

  &::after {
    border: 8px solid ${(props) => props.theme.colors.bgGray};
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    animation: ${Spin2} 2s linear infinite;
  }
`

export default GenericLoader
