export interface FontSize {
  xs: string
  sm: string
  md: string
  lg: string
  base: string
  xl: string
  xxl: string
  xxxl: string
  xxxxl: string
}

const fontSize: FontSize = {
  xs: '0.5rem', // 8px
  sm: '0.625rem', // 10px
  md: '0.75rem', // 12px
  lg: '0.875rem', // 14px
  base: '1rem', // 16px
  xl: '1.125rem', // 18px
  xxl: '1.25rem', // 20px
  xxxl: '2rem', // 32px
  xxxxl: '3rem', // 48px
}

export default fontSize
