import React, { FC, CSSProperties, MouseEventHandler } from 'react'
import styled from 'styled-components'
import { ColorPalette } from 'styles/colors'

export interface IconProps {
  testId?: string
  className?: string
  name: string
  size?: string
  color?: keyof ColorPalette | string
  style?: CSSProperties
  onClick?: MouseEventHandler<HTMLDivElement>
}

const Icon: FC<IconProps> = ({ name, className, size = '16px', color, style, testId }) => {
  const _className = `${className ? `${className} ` : ''}icon-idd-${name}`
  return <IconStyles className={_className} size={size} color={color} style={style} data-testid={testId} />
}

export default Icon

const IconStyles = styled.i<{ size: string; color?: keyof ColorPalette | string }>`
  font-size: ${({ size }) => size};
  ${({ color, theme }) => color && `color: ${theme.colors[color] || color};`}
`
