import PropTypes from 'prop-types'
import styled from 'styled-components'
import spacing from 'styles/spacing'
import { Icon, Fonts } from 'components/base-ui'

const AlertContent = ({ text, type, ...rest }) => {
  return (
    <AlertContentWrapper {...rest}>
      {type && (
        <IconWrapper type={type}>
          {type === 'success' ? <Icon name="check" /> : <Icon name="notice-circle-2" />}
        </IconWrapper>
      )}
      <Fonts.SmallTitleBold>{text}</Fonts.SmallTitleBold>
    </AlertContentWrapper>
  )
}

AlertContent.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
}

export default AlertContent

const AlertContentWrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.base};
`

const IconWrapper = styled.div`
  position: relative;
  top: 1px;
  line-height: 1em;

  i {
    font-size: 14px;
    margin-right: ${spacing.sm}px;

    ${(props) =>
      props.type === 'success'
        ? `
      font-size: 11px;
      color: ${props.theme.colors.success}
      `
        : ''}

    ${(props) =>
      props.type === 'warning'
        ? `
      color: ${props.theme.colors.warningLight}
      `
        : ''}

    ${(props) =>
      props.type === 'error'
        ? `
      color: ${props.theme.colors.errorLight}
      `
        : ''}

    ${(props) =>
      props.type === 'info'
        ? `
      color: ${props.theme.colors.infoDark}
      `
        : ''}
  }
`
