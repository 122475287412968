import { useEffect, RefObject } from 'react'
import media from 'styles/media'

function useMouseDragHorizontalScroll(scrollRef: RefObject<HTMLDivElement>) {
  useEffect(() => {
    const slider = scrollRef.current
    let isDown = false
    let move = false
    let startX
    let scrollLeft
    let clearMoveTimer

    const handleMouseDown = (e) => {
      e.stopPropagation()
      isDown = true
      if (slider) {
        slider.classList.add('active')
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      }
    }

    const handleMouseLeave = () => {
      isDown = false
      if (slider) {
        slider.classList.remove('active')
      }

      clearTimeout(clearMoveTimer)
      clearMoveTimer = setTimeout(() => {
        move = false
      }, 100)
    }

    const handleMouseUp = (e) => {
      e.stopPropagation()
      isDown = false
      if (slider) {
        slider.classList.remove('active')
      }

      clearTimeout(clearMoveTimer)
      clearMoveTimer = setTimeout(() => {
        move = false
      }, 100)
    }

    const handleMouseMove = (e) => {
      if (!isDown) return
      e.preventDefault()
      if (slider) {
        const x = e.pageX - slider.offsetLeft
        const walk = x - startX //scroll-fast
        slider.scrollLeft = scrollLeft - walk
      }
      move = true
    }

    const handleMouseClick = (e) => {
      if (move) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    if (scrollRef.current && window.matchMedia(`(${media.screenMdMin})`).matches) {
      if (slider) {
        slider.addEventListener('mousedown', handleMouseDown)
        slider.addEventListener('mouseleave', handleMouseLeave)
        slider.addEventListener('mouseup', handleMouseUp)
        slider.addEventListener('mousemove', handleMouseMove)
        slider.addEventListener('click', handleMouseClick)
      }
    }

    return () => {
      if (scrollRef.current && window.matchMedia(`(${media.screenMdMin})`).matches) {
        if (slider) {
          slider.removeEventListener('mousedown', handleMouseDown)
          slider.removeEventListener('mouseleave', handleMouseLeave)
          slider.removeEventListener('mouseup', handleMouseUp)
          slider.removeEventListener('mousemove', handleMouseMove)
          slider.removeEventListener('click', handleMouseClick)
        }
      }
    }
  }, [scrollRef])
}

export default useMouseDragHorizontalScroll
