import React, { useRef } from 'react'
import Modal from 'react-modal'
import styled, { createGlobalStyle } from 'styled-components'
import border from 'styles/border'
import media from 'styles/media'
import { Icon, Button } from 'components/base-ui'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

function StyledModal({
  children,
  onRequestClose,
  zindex,
  isLoading,
  btnClose,
  hideCloseButton = true,
  shouldCloseOnOverlayClick = true,
  ...rest
}) {
  const { padding } = rest
  const modalRef = useRef(null)
  const defaultStyle = {
    overlay: {
      display: 'flex',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'transparent',
      padding: '30px',
      overflowY: 'auto',
      zIndex: zindex ? zindex : '100',
    },
    content: {
      position: 'relative',
      top: 'auto',
      right: 'auto',
      bottom: 'auto',
      left: 'auto',
      display: 'block',
      width: '100%',
      maxWidth: '327px',
      minHeight: '200px',
      overflow: 'initial',
      WebkitOverflowScrolling: 'touch',
      outline: 'none',
      padding: '0',
      margin: 'auto',
      border: '0',
      background: 'transparent',
      borderRadius: '0',
    },
  }

  return (
    <Modal
      ref={modalRef}
      {...rest}
      contentLabel="Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      closeTimeoutMS={300}
      onRequestClose={handleOnClose}
      onAfterOpen={() => {
        if (modalRef?.current?.node) {
          const DOMOverlay = modalRef?.current?.node.querySelector('.ReactModal__Overlay')
          disableBodyScroll(DOMOverlay)
        }
      }}
      shouldReturnFocusAfterClose={false}
      overlayRef={(item) => {
        if (item) {
          setTimeout(() => (item.scrollTop = 0))
        }
      }}
      style={defaultStyle}
    >
      {!hideCloseButton && (
        <CloseButton onClick={handleOnClose}>
          <Icon name="cancel-thin" />
        </CloseButton>
      )}
      <Container padding={padding} style={rest.style}>
        {children}
        <ButtonWrapper>
          {btnClose && (
            <Button md minWidth="160px" onClick={handleOnClose}>
              {btnClose}
            </Button>
          )}
        </ButtonWrapper>
      </Container>
      <GlobalStyled />
    </Modal>
  )

  function handleOnClose(event) {
    if (isLoading !== true) {
      if (modalRef?.current?.node) {
        const DOMOverlay = modalRef?.current?.node.querySelector('.ReactModal__Overlay')
        enableBodyScroll(DOMOverlay)
      }
      onRequestClose()
    }
  }
}

export default StyledModal

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 17px;
  right: 15px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
`

const Container = styled.div`
  padding: ${(props) => (props.padding ? '0' : '25px')};
  border: 1px solid #ccc;
  background: #fff;
  border-radius: ${border.radius.xlg}px;

  ${(props) =>
    props.padding
      ? `
    margin-bottom: 40px;
    `
      : ''}

  @media (${media.screenSmMax}) {
    ${(props) =>
      props.padding
        ? `
      margin-top: 20px;
      margin-bottom: 40px;
      `
        : ''}
  }
`

const GlobalStyled = createGlobalStyle`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.3s;

    @media (${media.screenSmMax}) {
      padding: 15px !important;
    }
  }

  .ReactModal__Overlay::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content {
    opacity: 0;
    transform: translate(0, 20px);
    transition: opacity 0.3s, transform 0.3s;
  }

  .ReactModal__Content--after-open {
    opacity: 1;
    transform: translate(0, 0);
  }

  .ReactModal__Content--before-close {
    opacity: 0;
    transform: translate(0, 20px);
  }

  .iphoneX {
    .ReactModal__Content {
      transform: translate(0, 0);
    }

    .ReactModal__Content--before-close {
      opacity: 0;
      transform: translate(0, 0);
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  text-align: center;

  div button {
    width: 200px;
  }
`
