import { Fonts, Modal } from 'components/base-ui'
import styled from 'styled-components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
}

const ModalTimeUp: FC<Props> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} shouldCloseOnOverlayClick={false} btnClose={t('btnOK')}>
      <ModalContent>
        <Fonts.H2>{t('buffet.warning.timeUp.title')}</Fonts.H2>
        <Fonts.BaseTitleRegular>{t('buffet.warning.timeUp.text')}</Fonts.BaseTitleRegular>
      </ModalContent>
    </Modal>
  )
}

export default ModalTimeUp

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 7px;
  padding-bottom: 25px;
  padding-top: 30px;
  text-align: center;
`
