import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationTH from './locales/translationTH.json'
import translationEN from './locales/translationEN.json'
import { LanguageEnum } from 'enums'

const resources = {
  [LanguageEnum.th]: { translation: translationTH },
  [LanguageEnum.en]: { translation: translationEN },
}

const initI18n = async () => {
  i18n.use(initReactI18next).init({
    resources,
    lng: LanguageEnum.th,
    fallbackLng: LanguageEnum.th,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })
}

const getPropertyByLang = ({ th, en }: { th: string; en: string }) => {
  switch (i18n.language) {
    case LanguageEnum.th:
      return th
    case LanguageEnum.en:
      return en
    default:
      return en ? en : th
  }
}

export default initI18n
export { i18n, getPropertyByLang }
