export const baseWidth = 375
export const baseHeight = 812

const media = {
  screenXsMax: 'max-width: 320px',
  screenSmMiniMax: 'max-width: 375px',
  screenSmMax: 'max-width: 767px',
  screenMdMax: 'max-width: 991px',
  screenLgMax: 'max-width: 1180px',
  screenSmMin: 'min-width: 768px',
  screenMdMin: 'min-width: 992px',
  screenLgMin: 'min-width: 1200px',
  screenXLgMin: 'min-width: 1400px',
  screenXXLgMin: 'min-width: 1600px',
  screenXXXLgMin: 'min-width: 1900px',
}

export default media
