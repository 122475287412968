import React from 'react'
import styled from 'styled-components'
import { Fonts, Icon } from 'components/base-ui'

enum LabelTextTypeEnum {
  normal = 'normal',
  warning = 'warning',
}

type LabelTextTypeEnumStr = keyof typeof LabelTextTypeEnum

interface LabelTextProps {
  text: string
  type: LabelTextTypeEnumStr
  onClose?: () => void
}

const LabelText = ({ type = LabelTextTypeEnum.normal, text, onClose }: LabelTextProps) => {
  return (
    <LabelContainer>
      {type === LabelTextTypeEnum.warning && <IconWarning name="warning-triangle" />}
      <Fonts.SmallTitleRegular>{text}</Fonts.SmallTitleRegular>
      {onClose && (
        <BtnClose onClick={onClose}>
          <IconClose name="close" />
        </BtnClose>
      )}
    </LabelContainer>
  )
}

export default LabelText

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 38px;
  padding: 8px 15px;
  border-radius: 6px;
  background-color: ${(p) => p.theme.colors.base};
  opacity: 0.9;
  box-shadow: 0px 4px 4px 0px #00000040;
  color: white;
`

const IconWarning = styled(Icon)`
  font-size: 12px;
  margin-right: 6px;
`

const BtnClose = styled.div`
  margin-left: auto;
  padding: 5px;
  margin-right: -5px;
`

const IconClose = styled(Icon)`
  font-size: 12px;
`
