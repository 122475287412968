import React, { useRef } from 'react'
import styled from 'styled-components'

import useMouseDragHorizontalScroll from 'hooks/useMouseDragHorizontalScroll'

const ScrollHWrapper = (props) => {
  const scrollRef = useRef(null)
  useMouseDragHorizontalScroll(scrollRef)

  return <ScrollHWrapperStyled {...props} ref={scrollRef} />
}

export default ScrollHWrapper

const ScrollHWrapperStyled = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 20px;

  &::scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`
