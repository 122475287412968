/**
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 * Config - color
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 */
import Color from 'color'

export interface ColorPalette {
  black: string
  gray: string
  gray100: string
  gray150: string
  gray200: string
  gray300: string
  gray400: string
  gray500: string
  gray600: string
  gray700: string
  gray800: string
  gray900: string
  gray1000: string
  orange: string
  orange100: string
  orange150: string
  orange300: string
  orange400: string
  darkGreen: string
  green: string
  green50: string
  green100: string
  green200: string
  green300: string
  green400: string
  green500: string
  gonGreen: string
  gonGreenHighlight: string
  yellow: string
  yellow100: string
  yellow500: string
  lightBlue: string
  lightBlueBg: string
  blue100: string
  blue300: string
  blue500: string
  brown100: string
  brown500: string
  red100: string
  red: string
  purple100: string
  purple500: string
  primaryHover: string
  second: string
  secondHover: string
  warning: string
  notice: string
  bgGray: string
  bgGrayLight: string
  errorBg: string
  primary: string
  secondary: string
  secondaryLight: string
  tertiary: string
  highlight: string
  price: string
  errorLight: string
  errorLightWhite: string
  errorDark: string
  errorHover: string
  errorBgLight: string
  warningLight: string
  warningLightWhite: string
  warningBg: string
  warningDark: string
  success: string
  white: string
  base: string
  darkGrey: string
  baseGrey: string
  infoDark: string
  infoLight: string
  infoLightWhite: string
  lightGrey: string
  veryLightGrey: string
  successLight: string
  successLightWhite: string
  successDark: string
  bgColor: string
  hatoHeartBackground: string
  nutritionBadge: string
  nutritionCalories: string
  nutritionCarbs: string
  nutritionFat: string
  nutritionFiber: string
  nutritionProtein: string
  overlay: string
  overlayDark: string
  textAndBackgroundDark: string
  textAndBackgroundDarkGrey: string
  textAndBackgroundGrey: string
  textAndBackgroundLightGrey: string
  textAndBackgroundWhite: string
  textAndBackgroundWhiteGrey: string
}

const base = {
  black: '#343434',
  gray: '#848484',
  gray100: '#F5F5F5',
  gray150: '#E8E8E8',
  gray200: '#D9DBD9',
  gray300: '#C0C2C0',
  gray400: '#A7A8A7',
  gray500: '#8D8F8D',
  gray600: '#727572',
  gray700: '#585C59',
  gray800: '#3E423F',
  gray900: '#252926',
  gray1000: '#39473F',
  orange: '#F26524',
  orange100: '#FEECE4',
  orange150: '#FDDDCE',
  orange300: '#F69273',
  orange400: '#F47535',
  darkGreen: '#6F9F32',
  green: '#3BAC8F',
  green50: '#F4FAEB',
  green100: '#ECF2E3',
  green200: '#DEE9CE',
  green300: '#B9D191',
  green400: '#96BC58',
  green500: '#7FB036',
  gonGreen: '#488541', // may be need to delete
  gonGreenHighlight: '#8DB64F', // may be need to delete
  yellow: '#F2AE0F',
  yellow100: '#FFF5E4',
  yellow500: '#FEBE10',
  lightBlue: '#9DD2E2',
  lightBlueBg: '#F1F8FB',
  blue100: '#E5F3F8',
  blue300: '#93C8D2',
  blue500: '#00416B',
  brown100: '#ECE5DF',
  brown500: '#AF7A3B',
  red100: '#FFEAEC',
  red: '#D92138',
  purple100: '#ECDDF1',
  purple500: '#85439A',
}

const colors: ColorPalette = {
  primaryHover: Color(base.gonGreen).lighten(0.2).hex(),
  second: base.gonGreenHighlight,
  secondHover: Color(base.gonGreenHighlight).lighten(0.2).hex(),
  warning: base.orange,
  notice: base.yellow500,
  bgGray: '#EAEAEA',
  bgGrayLight: '#F9F9F9',
  errorBg: '#FBE9EB',
  ...base,

  primary: '#0E9755',
  secondary: '#7FB036',
  secondaryLight: '#FDFFFB',
  tertiary: '#76BC21',
  highlight: '#F1F8E8',
  price: '#FA7C42',
  errorLight: '#EB5757',
  errorLightWhite: '#F8EAEB',
  errorDark: '#D82424',
  errorHover: Color('#EB5757').lighten(0.2).hex(),
  errorBgLight: '#FFFAFA',
  warningLight: '#FBBC19',
  warningLightWhite: '#FFEFA8',
  warningBg: '#FFFCF3',
  warningDark: '#E87100',
  success: '#4A9500',
  white: '#FFFFFF',
  base: '#333333',
  darkGrey: '#828282',
  baseGrey: '#B9B9B9',
  infoDark: '#1562A8',
  infoLight: '#2E8CE2',
  infoLightWhite: '#F2F9FF',
  lightGrey: '#E0E0E0',
  veryLightGrey: '#F6F6F6',
  successLight: '#67AE21',
  successLightWhite: '#F4FFEF',
  successDark: '#3E7D00',
  bgColor: '#FAFAFA',
  hatoHeartBackground: '',
  nutritionBadge: '#FEF5E4',
  nutritionCalories: '#FF5733',
  nutritionCarbs: '#AF7A3B',
  nutritionFat: '#FEBE10',
  nutritionFiber: '#C13E30',
  nutritionProtein: '#99D2E2',
  overlay: Color('#333333').alpha(0.56).string(),
  overlayDark: Color('#333333').alpha(0.88).string(),
  textAndBackgroundDark: '#333333',
  textAndBackgroundDarkGrey: '#828282',
  textAndBackgroundGrey: '#B9B9B9',
  textAndBackgroundLightGrey: '#EEEEEE',
  textAndBackgroundWhite: '#FFFFFF',
  textAndBackgroundWhiteGrey: '#F6F6F6',
}

export default colors
