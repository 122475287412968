import React, { FC } from 'react'
import styled from 'styled-components'
import { generateSrcSet } from 'helpers/image'
import { Fonts, Button } from 'components/base-ui'
import { useTranslation } from 'react-i18next'

const paymentFailedImg = `${process.env.PUBLIC_URL}/assets/dine-in/payment_failed.png`
interface ResubmitPaymentProps {
  onResubmitPayment: () => void
}
const ResubmitPayment: FC<ResubmitPaymentProps> = ({ onResubmitPayment }) => {
  const { t } = useTranslation()
  return (
    <Wrapper id="resubmit-payment-page">
      <ImageWrapper>
        <ImagePaymentStatus src={paymentFailedImg} srcSet={generateSrcSet(paymentFailedImg, 'png')} alt="" />
      </ImageWrapper>
      <TrueWalletFailedModal>
        <Fonts.H2 className="title">{t('dineInPaymentFailed')}</Fonts.H2>
        <Fonts.BaseTitleRegular className="description" style={{ textAlign: 'center' }}>
          {t('dineInRequestCancelPayment')}
        </Fonts.BaseTitleRegular>
        <ResubmitPaymentButton sm outline onClick={onResubmitPayment} enableFastClick={false}>
          {t('dineInCancelPayment')}
        </ResubmitPaymentButton>
      </TrueWalletFailedModal>
    </Wrapper>
  )
}

export default ResubmitPayment

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 100 auto;
  justify-content: space-between;
  position: fixed;
  height: 100vh;
  background-color: #fafafa;
`
const ImageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .show {
    animation: fadeIn 1s;
    display: block;
  }

  .hide {
    display: none;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      display: none;
    }
    to {
      opacity: 1;
      display: block;
    }
  }
`

const ImagePaymentStatus = styled.img`
  width: 250px;
  height: auto;
`

const ModalBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  padding: 50px 50px 120px 50px;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0px -2px 10px #f1f2f6;
`

const TrueWalletFailedModal = styled(ModalBottom)`
  padding-top: 85px;
  padding-bottom: 70px;
  column-gap: 8px;
  button {
    margin-top: 52px;
  }
`

const ResubmitPaymentButton = styled(Button)`
  padding: 0 25px;
`
