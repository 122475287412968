import React, { createContext, useState, useEffect, useRef } from 'react'
import { ThemeProvider } from 'styled-components'
import ReactGA from 'react-ga'
import colors from 'styles/colors'
import {
  getGonBrandChoices,
  getGonBrandChoicesListScheduledContent,
  getBrandBySubDomain,
  getLocationSettingByFixedLocationIDFromURL,
} from 'brands'
import { BrandTypeEnum } from 'brands'
import fontSize from 'styles/fontSize'
import { isStaging, isProduction } from 'variables/environment'
import i18next from 'i18next'
import { LanguageEnum } from 'enums'
import { getLineChannelIDSession } from 'helpers/sessionStorage'
import { getLocationIDSession } from 'helpers/localStorage'
import shadow from 'styles/shadowV2'
import spacing from 'styles/spacingV2'

export const AppContext = createContext()

function AppProvider({ children }) {
  // Brand
  const [BRANDCHOICES, setBRANDCHOICES] = useState([])
  const [BRANDCHOICES_LIST_SCHEDULED_CONTENT, setBRANDCHOICES_LIST_SCHEDULED_CONTENT] = useState([])
  const [brand, setBrand] = useState(null)
  const [brandType, setBrandType] = useState(BrandTypeEnum.SINGLE_BRAND)
  const [themeUI, setThemeUI] = useState(null)

  useEffect(() => {
    // Get brand from subdomain.
    getGonBrandChoices().then((BRANDCHOICES) => {
      setBRANDCHOICES(BRANDCHOICES)
    })
    getGonBrandChoicesListScheduledContent().then((BRANDCHOICES_LIST_SCHEDULED_CONTENT) => {
      setBRANDCHOICES_LIST_SCHEDULED_CONTENT(BRANDCHOICES_LIST_SCHEDULED_CONTENT)
    })
    getBrandBySubDomain().then((brandModule) => {
      let brandFromSubDomain = brandModule.default
      const locationSetting = getLocationSettingByFixedLocationIDFromURL(brandFromSubDomain)
      if (locationSetting) {
        brandFromSubDomain = { ...brandFromSubDomain, ...locationSetting }
      }
      setBrand(brandFromSubDomain)
      setThemeUI(brandFromSubDomain?.theme?.ui)
      document.title = brandFromSubDomain?.title
      document.querySelector('link[rel="icon"]').href = brandFromSubDomain?.favIcon

      switch (brandFromSubDomain.type) {
        case BrandTypeEnum.MULTI_BRAND:
          setBrandType(BrandTypeEnum.MULTI_BRAND)
          break
        case BrandTypeEnum.THOUSAND_PRODUCTS:
          setBrandType(BrandTypeEnum.THOUSAND_PRODUCTS)
          break
        case BrandTypeEnum.MALL:
          setBrandType(BrandTypeEnum.MALL)
          break
        case BrandTypeEnum.MALL_WITHOUT_GROCERY:
          setBrandType(BrandTypeEnum.MALL_WITHOUT_GROCERY)
          break
        default:
          setBrandType(BrandTypeEnum.SINGLE_BRAND)
      }

      if (brandFromSubDomain?.i18n) {
        for (let lngKey in brandFromSubDomain.i18n) {
          i18next.addResourceBundle(lngKey, 'translation', brandFromSubDomain.i18n[lngKey], true, true)
        }
      }

      // Initialed google analytics from brand data
      if (brandFromSubDomain?.googleAnalytics?.key) {
        ReactGA.initialize(brandFromSubDomain.googleAnalytics.key)
        ReactGA.ga('require', 'ec')
      }

      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_HATOHUB, {
        gaOptions: { name: 'hatohub' },
      })
      ReactGA.ga('hatohub.require', 'ec')

      if (isStaging || isProduction) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT, {
          gaOptions: { name: 'measurement' },
        })
      }
    })
  }, [])

  // Gtag only FoodPassion Brand
  useEffect(() => {
    if (BRANDCHOICES.find((_brand) => _brand.id === brand?.id)) {
      const script = document.createElement('script')

      script.src = `${process.env.PUBLIC_URL}/scripts/gtag-manager-for-foodpassion.js`
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [brand])

  // Loading Popup
  const [isLoadingPage, setIsLoadingPage] = useState(true)

  // Cache scroll pages
  const [scrollTopPages, setScrollTopPages] = useState({})

  // View state history
  const [referrerUrl, setReferrerUrl] = useState('')

  // User Facebook Profile
  const [isFacebookWebview, setIsFacebookWebview] = useState(false)
  const [isFacebookWebviewInit, setIsFacebookWebviewInit] = useState(true)

  // User Line Profile
  const [userProfile, setUserProfile] = useState('')
  const [userEmail, setUserEmail] = useState(null)
  const [accessToken, setAccessToken] = useState('')
  const [isLiffInit, setIsLiffInit] = useState(false)
  const [isLineFriend, setIsLineFriend] = useState(false)

  // User from IndyDish
  const [currentUser, setCurrentUser] = useState('')
  const [currentMembership, setCurrentMembership] = useState(null)
  const [isInitialedCurrentMembership, setIsInitialedCurrentMembership] = useState(false)

  // Current active menu on menu page ( vendorDetails )
  const [currentMenuIndex, setCurrentMenuIndex] = useState(0)
  // Cache current scroll poistion ( homepage )
  const [currentScrollYHomePage, setCurrentScrollYHomePage] = useState(0)

  // CurrentLocationID
  const [currentLocationID, setCurrentLocationID] = useState(null)
  const [isFixLocationID, setIsFixLocationID] = useState(false)

  // ServiceMethod
  const [fixServiceMethod, setFixServiceMethod] = useState('')

  // Cart
  const addToCartBusyState = useRef(false)
  const [cart, setCart] = useState(null) // Version APIs default is null
  const [autoPromotions, setAutoPromotions] = useState(null)
  const [initialCart, setInitialCart] = useState(false)
  const [statePaymentMethodCreditCard, setStatePaymentMethodCreditCard] = useState(null) // PaymentMethodType
  const [cartTimestampUpdated, setCartTimestampUpdated] = useState('')
  const [cartLocationID, setCartLocationID] = useState(null)
  const [cartCorrectionsStatus, setCartCorrectionsStatus] = useState([])
  const [flagTriggerFetchCurrentUser, setFlagTriggerFetchCurrentUser] = useState(false)
  const [flagTriggerFetchCart, setFlagTriggerFetchCart] = useState(false)
  const [flagTriggerGoToCheckoutAfterFetchCartSuccess, setFlagTriggerGoToCheckoutAfterFetchCartSuccess] =
    useState(false)
  const [flagTriggerFetchProductHomepage, setFlagTriggerFetchProductHomepage] = useState(false)
  const [flagTriggerFetchProductDetailsPage, setFlagTriggerFetchProductDetailsPage] = useState(false)
  const [flagTriggerFeaturedPromotionHH, setFlagTriggerFeaturedPromotionHH] = useState(false)
  const [isBackFromProductDetailsPage, setIsBackFromProductDetailsPage] = useState(false)
  const [isPickUpCart, setIsPickUpCart] = useState(false)

  const [flagTiggerFetchMembership, setFlagTiggerFetchMembership] = useState(false)
  const [flagTiggerFetchListRedemption, setFlagTiggerFetchListRedemption] = useState(false)
  const [flagTiggerFetchListCoupon, setFlagTiggerFetchListCoupon] = useState(false)

  const [nextTimeOpen, setNextTimeOpen] = useState('')

  // Credit Card (for first customer recure token when checkout was not completed charged and want to charged again).
  const [customerCreditCard, setCustomerCreditCard] = useState(null)

  // Re-Ordering
  const [isReOrderingCheckout, setIsReOrderingCheckout] = useState(false)

  // Language
  const [lang, setLang] = useState(null)
  const [initialLang, setInitialLang] = useState(false)

  // Modal: Handle case change store when add item to cart.
  const [isOpenModalChangeStore, setIsOpenModalChangeStore] = useState(false)
  const [currentItemModalChangeStore, setCurrentItemModalChangeStore] = useState(null)

  // Modal: Handle case api rate limit error 429
  const [isOpenModalApiRateLimit, setIsOpenModalApiRateLimit] = useState(false)

  // Modal: Handle case liff initialize failed and have to reopen liff.
  const [isOpenModalLIFFInitailedFailed, setIsOpenModalLIFFInitailedFailed] = useState(false)

  const [isOpenModalSystemError, setIsOpenModalSystemError] = useState(false)
  const [isOpenModalSessionExpired, setIsOpenModalSessionExpired] = useState(false)
  const [systemErrorRequestType, setSystemErrorRequestType] = useState('QUERY') // 'MUTATION'

  // Feature ModalNoticeNearestStore
  const [isOpenModalNoticeNearestStore, setIsOpenModalNoticeNearestStore] = useState(false)

  const [isOpenModalOnlyMemberMenu, setIsOpenModalOnlyMemberMenu] = useState(false)
  const [isOpenModalOnlyMemberMenuSpecificCondition, setIsOpenModalOnlyMemberMenuSpecificCondition] = useState(false)
  const [membershipProgram, setMembershipProgram] = useState(false)
  const [membershipProgramHH, setMembershipProgramHH] = useState(null)
  const [arrayOnlyMemberMenuSpecificConditions, setArrayOnlyMemberMenuSpecificConditions] = useState([])
  const [memberMenuIneligibleReason, setMemberMenuIneligibleReason] = useState('')
  const [vendorImageResizableURLForModalMemberMenu, setVendorImageResizableURLForModalMemberMenu] = useState('')
  const [dineInVendorID, setDineInVendorID] = useState(null)
  const [dineInPaymentMethods, setDineInPaymentMethods] = useState([])
  const [isThaiLang, setIsThaiLang] = useState(true)
  const [isUsedCurrentLocation, setIsUsedCurrentLocation] = useState(false)
  const [isSelectedCurrentLocation, setIsSelectedCurrentLocation] = useState(false)

  // ModalStoreIsClose
  const [isOpenModalStoreIsClose, setIsOpenModalStoreIsClose] = useState(false)

  // AIS
  const [AISParameter, setAISParameter] = useState('')

  // HatoHeart
  const [isHatoHeart, setIsHatoHeart] = useState(false)

  // Line Channel
  const [lineChannelID, setLineChannelID] = useState(getLineChannelIDSession())

  // Address
  const [googleMapPlaceData, setGoogleMapPlaceData] = useState(null)
  const [currentEditGoogleMapPlaceData, setCurrentEditGoogleMapPlaceData] = useState(null)
  const [currentPosition, setCurrentPosition] = useState(null)
  const [currentGoogleMapPlaceData, setCurrentGoogleMapPlaceData] = useState(null)
  const [isFlashOnRemoveAddressSuccess, setIsFlashOnRemoveAddressSuccess] = useState(false)
  const [isCanGetLocation, setIsCanGetLocation] = useState(false)
  const [flagTriggerSelectMainAddress, setFlagTriggerSelectMainAddress] = useState(false)

  useEffect(() => {
    setIsThaiLang(i18next.language === LanguageEnum.th)
  }, [i18next.language])

  const getLocationID = () => currentLocationID ?? cartLocationID ?? getLocationIDSession()

  const value = {
    referrerUrl,
    setReferrerUrl,
    currentMenuIndex,
    setCurrentMenuIndex,
    currentScrollYHomePage,
    setCurrentScrollYHomePage,
    isFacebookWebview,
    setIsFacebookWebview,
    isFacebookWebviewInit,
    setIsFacebookWebviewInit,
    isLiffInit,
    setIsLiffInit,
    isLineFriend,
    setIsLineFriend,
    cart,
    setCart,
    autoPromotions,
    setAutoPromotions,
    initialCart,
    setInitialCart,
    statePaymentMethodCreditCard,
    setStatePaymentMethodCreditCard,
    cartTimestampUpdated,
    setCartTimestampUpdated,
    cartLocationID,
    setCartLocationID,
    currentLocationID,
    setCurrentLocationID,
    isFixLocationID,
    setIsFixLocationID,
    fixServiceMethod,
    setFixServiceMethod,
    clearCart,
    googleMapPlaceData,
    setGoogleMapPlaceData,
    currentEditGoogleMapPlaceData,
    setCurrentEditGoogleMapPlaceData,
    currentPosition,
    setCurrentPosition,
    currentGoogleMapPlaceData,
    setCurrentGoogleMapPlaceData,
    isFlashOnRemoveAddressSuccess,
    setIsFlashOnRemoveAddressSuccess,
    userProfile,
    setUserProfile,
    userEmail,
    setUserEmail,
    accessToken,
    setAccessToken,
    isBackFromProductDetailsPage,
    setIsBackFromProductDetailsPage,
    currentUser,
    setCurrentUser,
    currentMembership,
    setCurrentMembership,
    isInitialedCurrentMembership,
    setIsInitialedCurrentMembership,
    isLoadingPage,
    setIsLoadingPage,
    flagTriggerFetchCurrentUser,
    setFlagTriggerFetchCurrentUser,
    flagTriggerFetchCart,
    setFlagTriggerFetchCart,
    flagTriggerGoToCheckoutAfterFetchCartSuccess,
    setFlagTriggerGoToCheckoutAfterFetchCartSuccess,
    flagTriggerFetchProductHomepage,
    setFlagTriggerFetchProductHomepage,
    flagTriggerFetchProductDetailsPage,
    setFlagTriggerFetchProductDetailsPage,
    flagTriggerFeaturedPromotionHH,
    setFlagTriggerFeaturedPromotionHH,
    flagTiggerFetchMembership,
    setFlagTiggerFetchMembership,
    flagTiggerFetchListRedemption,
    setFlagTiggerFetchListRedemption,
    flagTiggerFetchListCoupon,
    setFlagTiggerFetchListCoupon,
    cartCorrectionsStatus,
    setCartCorrectionsStatus,
    isPickUpCart,
    setIsPickUpCart,
    lang,
    setLang,
    initialLang,
    setInitialLang,
    isOpenModalSystemError,
    setIsOpenModalSystemError,
    isOpenModalSessionExpired,
    setIsOpenModalSessionExpired,
    systemErrorRequestType,
    setSystemErrorRequestType,
    isOpenModalStoreIsClose,
    setIsOpenModalStoreIsClose,
    customerCreditCard,
    setCustomerCreditCard,
    isReOrderingCheckout,
    setIsReOrderingCheckout,
    scrollTopPages,
    setScrollTopPages,
    isOpenModalNoticeNearestStore,
    setIsOpenModalNoticeNearestStore,
    BRANDCHOICES,
    BRANDCHOICES_LIST_SCHEDULED_CONTENT,
    brand,
    setBrand,
    brandType,
    themeUI,
    nextTimeOpen,
    setNextTimeOpen,
    isOpenModalOnlyMemberMenu,
    setIsOpenModalOnlyMemberMenu,
    isOpenModalOnlyMemberMenuSpecificCondition,
    setIsOpenModalOnlyMemberMenuSpecificCondition,
    membershipProgram,
    setMembershipProgram,
    membershipProgramHH,
    setMembershipProgramHH,
    arrayOnlyMemberMenuSpecificConditions,
    setArrayOnlyMemberMenuSpecificConditions,
    memberMenuIneligibleReason,
    setMemberMenuIneligibleReason,
    vendorImageResizableURLForModalMemberMenu,
    setVendorImageResizableURLForModalMemberMenu,
    AISParameter,
    setAISParameter,
    isHatoHeart,
    setIsHatoHeart,
    lineChannelID,
    setLineChannelID,
    isOpenModalApiRateLimit,
    setIsOpenModalApiRateLimit,
    isOpenModalLIFFInitailedFailed,
    setIsOpenModalLIFFInitailedFailed,
    dineInVendorID,
    setDineInVendorID,
    dineInPaymentMethods,
    setDineInPaymentMethods,
    isThaiLang,
    isUsedCurrentLocation,
    setIsUsedCurrentLocation,
    isSelectedCurrentLocation,
    setIsSelectedCurrentLocation,
    isCanGetLocation,
    setIsCanGetLocation,
    flagTriggerSelectMainAddress,
    setFlagTriggerSelectMainAddress,
    currentItemModalChangeStore,
    setCurrentItemModalChangeStore,
    setIsOpenModalChangeStore,
    isOpenModalChangeStore,
    addToCartBusyState,
    getBasicCartVariablesForUpdated,
    getLocationID,
  }

  let theme = {
    colors,
    fontSize,
    spacing,
    shadow,
  }
  if (brand) {
    const { theme: brandTheme } = brand
    const updatedTheme = {
      ...theme,
      colors: { ...colors, ...brandTheme.colors },
    }
    theme = updatedTheme
  }

  return (
    <AppContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AppContext.Provider>
  )

  function getBasicCartVariablesForUpdated({ cart, lineItems, address, locationID, pickUp }) {
    const _cart = cart

    let _locationID = _cart && _cart.locationID ? _cart.locationID : getLocationID()
    if (locationID) {
      _locationID = locationID
    }

    let _taxInvoice = null
    if (_cart && _cart.taxInvoice) {
      _taxInvoice = {
        id: _cart.taxInvoice?.id ?? null,
        companyName: _cart.taxInvoice?.companyName ?? '',
        address: _cart.taxInvoice?.address ?? '',
        taxID: _cart.taxInvoice?.taxID ?? '',
      }
    }

    let _pickUp = null
    if (typeof pickUp === 'undefined') {
      _pickUp = _cart ? _cart.pickUp : isPickUpCart
    } else {
      _pickUp = pickUp
    }

    let _containerReturnInfo = null
    if (_cart && _cart.containerReturnInfo) {
      _containerReturnInfo = {
        scheduledReturnDate: _cart.containerReturnInfo.scheduledReturnDate,
        scheduledReturnTimeslot: _cart.containerReturnInfo.scheduledReturnTimeslot,
      }
    }

    return {
      cartId: _cart && _cart.id ? _cart.id : null,
      lineItems,
      addressId: address && address.id ? address.id : null,
      deliveryDate: _cart && _cart.deliveryDate ? _cart.deliveryDate : null,
      deliveryTimeslot: _cart && _cart.deliveryTimeslot ? _cart.deliveryTimeslot : null,
      specialInstructions: _cart && _cart.specialInstructions ? _cart.specialInstructions : '',
      locationID: _locationID,
      returnContainer: false,
      requestUtensils: _cart && _cart.requestUtensils ? _cart.requestUtensils : false,
      taxInvoice: _taxInvoice,
      paymentType: _cart && _cart.paymentType ? _cart.paymentType : null,
      paymentPhone: _cart && _cart.paymentPhone ? _cart.paymentPhone : '',
      pickUp: _pickUp,
      lineChannelID,
      containerReturnInfo: _containerReturnInfo,
    }
  }

  function clearCart() {
    //Version Cart LocalStorage
    // setCart([])
    // setCartSession([])

    // Version API
    setCart(null)
  }
}

export default AppProvider
