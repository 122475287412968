import { useEffect } from 'react'

type Props = {
  onResize: ResizeObserverCallback
  element: HTMLDivElement
}

const useResizeObserver = ({ onResize, element }: Props) => {
  useEffect(() => {
    if (element) {
      const observer = new ResizeObserver(onResize)
      observer.observe(element)
      return () => {
        observer.unobserve(element)
      }
    }
  }, [element])
}

export default useResizeObserver
