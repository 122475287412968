import React, { FC, useContext } from 'react'
import styled from 'styled-components'
import { getLiff, redirectToChatScreen } from 'helpers/liff'
import { useTranslation } from 'react-i18next'
import ScreenWithImage from 'components/dine-in/ScreenWithImage'
import { Button as BaseButton } from 'components/base-ui'
import { AppContext } from 'contexts/AppProvider'

interface PaymentQRScreenProps {
  onChangePaymentMethod: () => void
}

const liff = getLiff()
const checkImg = `${process.env.PUBLIC_URL}/assets/payment/confirm-logo.png`

const PaymentQRScreen: FC<PaymentQRScreenProps> = ({ onChangePaymentMethod }) => {
  const { t } = useTranslation()
  const { brand } = useContext(AppContext)

  return (
    <ScreenWithImage imagePath={checkImg} imageSize="104px" isGenerateSrc title={t('dineInPendingQrPayment')}>
      <WrapperButton>
        {liff.isInClient() && (
          <Button minWidth="260px" md onClick={() => redirectToChatScreen(brand?.liffID.lineID)}>
            {t('btnOK')}
          </Button>
        )}
        <Button outline minWidth="260px" md onClick={onChangePaymentMethod}>
          {t('dineInButtonChangePaymentType')}
        </Button>
      </WrapperButton>
    </ScreenWithImage>
  )
}

export default PaymentQRScreen

const Button = styled(BaseButton)`
  height: 50px;
  padding-left: 0;
  padding-right: 0;
`

const WrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
