import React from 'react'
import styled from 'styled-components'
import { Icon, Fonts } from 'components/base-ui'

interface LabelInfoProps {
  text: string
}

const LabelInfo = ({ text }: LabelInfoProps) => {
  return (
    <InfoCustomerCanTypeQuantity>
      <Icon name="information" />
      <Fonts.SmallTitleRegular>{text}</Fonts.SmallTitleRegular>
    </InfoCustomerCanTypeQuantity>
  )
}

export default LabelInfo

const InfoCustomerCanTypeQuantity = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.infoDark};

  ${Fonts.SmallTitleRegular} {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.infoDark};
  }

  i {
    margin-right: 8px;
  }
`
