import React, { FC } from 'react'
import { ModalFromBottom } from 'components/base-ui'
import { Button, Fonts } from 'components/base-ui'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface ModalConfirmOverrideCheckoutProps {
  onOverrideCheckout: () => void
  onViewGlobalCart: () => void
  onRequestClose?: () => void
  isProcessing: boolean
  isOpen: boolean
}

const personLookingForImg = `${process.env.PUBLIC_URL}/assets/dine-in/person-payment.svg`

const ModalConfirmOverrideCheckout: FC<ModalConfirmOverrideCheckoutProps> = ({
  onOverrideCheckout,
  onViewGlobalCart,
  onRequestClose,
  isProcessing,
  isOpen,
}) => {
  const { t } = useTranslation()

  const handleOverrideCheckout = () => {
    if (isProcessing) return
    onOverrideCheckout()
  }
  const handleOnRequestClose = () => {
    onViewGlobalCart()
    onRequestClose && onRequestClose()
  }

  return (
    <ModalFromBottom
      id="modal-confirm-override-checkout"
      onRequestClose={handleOnRequestClose}
      shouldCloseOnOverlayClick={false}
      zindex={100}
      btnClose={null}
      hideCloseButton
      isLoading={false}
      isOpen={isOpen}
    >
      <ModalContent>
        <ImagePaymentStatus src={personLookingForImg} srcSet={personLookingForImg} alt="" />
        <Fonts.H3 id="override-title">{t('dineInConfirmOverride')}</Fonts.H3>
        <Fonts.ActionBaseRegular id="override-description">
          {t('dineInConfirmOverrideCheckoutDes')}
        </Fonts.ActionBaseRegular>
        <ButtonWrapper>
          <Button enableFastClick={false} onClick={onViewGlobalCart}>
            {t('btnOK')}
          </Button>
          <Button
            enableFastClick={false}
            onClick={handleOverrideCheckout}
            isProcessing={isProcessing}
            disabled={isProcessing}
            outline
          >
            {t('dineInOverrideCheckout')}
          </Button>
        </ButtonWrapper>
      </ModalContent>
    </ModalFromBottom>
  )
}

export default ModalConfirmOverrideCheckout

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 7px;
  padding-bottom: 20px;
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-top: 40px;

  button {
    min-width: 200px;
  }
`

const ImagePaymentStatus = styled.img`
  width: 225px;
  height: auto;
  padding-bottom: 40px;
`
