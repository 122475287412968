import React, { useContext } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { getLiff } from 'helpers/liff'
import { AppContext } from 'contexts/AppProvider'
import useGetLiffUser from 'services/hooks/useGetLiffUser'
const liff = getLiff()

const PrivateRoute = ({ children, ...rest }) => {
  const { brand, accessToken } = useContext(AppContext)
  const { pathname, search } = useLocation()

  let liffID = brand?.liffID?.home
  if (pathname === '/hato-heart' && brand?.liffID?.hatoheart) {
    liffID = brand.liffID.hatoheart
  }

  useGetLiffUser(liffID, () => {
    if (!liff.isLoggedIn()) {
      const redirectUri = window.location.origin + `${pathname}${search}`
      liff.login({ redirectUri })
    }
  })

  return <Route {...rest} render={() => accessToken && children} />
}

export default PrivateRoute
