import ScreenWithImage from 'components/dine-in/ScreenWithImage'
import React from 'react'
import { useTranslation } from 'react-i18next'

const paymentReceiptImage = '/assets/dine-in/payment-receipt.png'

const StaffPaymentScreen = () => {
  const { t } = useTranslation()
  return (
    <ScreenWithImage
      imageSize="100vw"
      maxWidth="400px"
      imagePath={paymentReceiptImage}
      title={t('dineInStaffCheckoutOrder')}
    ></ScreenWithImage>
  )
}

export default StaffPaymentScreen
