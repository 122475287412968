import { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Modal, Fonts } from 'components/base-ui'
import { getStrMemberCondition } from 'helpers/product'
import { MemberProductIneligibleReasonEnum, ProductMemberTierRankConditionsEnum } from 'enums/product'
import { THEMES_UI } from 'brands'
import { useTranslation } from 'react-i18next'

const ModalOnlyMemberMenuSpecificCondition = ({
  vendorImageResizableURLForModalMemberMenu,
  membershipProgram,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <Modal hideCloseButton={false} {...rest}>
      <ModalContainer>
        {rest.themeUI === THEMES_UI.GENERIC && vendorImageResizableURLForModalMemberMenu && (
          <img
            alt="icon-membership-vendor"
            src={vendorImageResizableURLForModalMemberMenu
              .replace('{width}', '130')
              .replace('{height}', '130_fmt-jpg_fit-contain')}
            style={{ marginTop: '15px', marginBottom: '20px' }}
          />
        )}

        {rest.themeUI === THEMES_UI.GON &&
          rest.memberMenuIneligibleReason === MemberProductIneligibleReasonEnum.TIER_BIRTHMONTH_REQUIRED && (
            <img
              alt="icon-gon-gang-club"
              src={`${process.env.PUBLIC_URL}/assets/gon/icon-birthmonth.png`}
              width="130px"
              style={{ marginTop: '15px', marginBottom: '20px' }}
            />
          )}

        {rest.themeUI === THEMES_UI.GON &&
          rest.memberMenuIneligibleReason !== MemberProductIneligibleReasonEnum.TIER_BIRTHMONTH_REQUIRED && (
            <Fragment>
              {rest.memberConditions.map((condition, conditionIndex) => {
                if (condition === ProductMemberTierRankConditionsEnum.NOT_ELIGIBLE) {
                  return null
                }

                switch (conditionIndex) {
                  case 1:
                    return (
                      <img
                        alt="icon-gon-gang-club"
                        src={`${process.env.PUBLIC_URL}/assets/gon/icon-gon-only-member-buddy.png`}
                        width="200px"
                        style={{ marginTop: '-30px', marginBottom: '-10px', marginLeft: '-25px' }}
                      />
                    )
                  case 2:
                    return (
                      <img
                        alt="icon-gon-gang-club"
                        src={`${process.env.PUBLIC_URL}/assets/gon/icon-gon-only-member-family.png`}
                        width="200px"
                        style={{ marginTop: '-30px', marginBottom: '-10px', marginLeft: '-25px' }}
                      />
                    )
                  default:
                    return (
                      <img
                        alt="icon-gon-gang-club"
                        src={`${process.env.PUBLIC_URL}/assets/gon/icon-gon-only-member.png`}
                        width="200px"
                        style={{ marginTop: '-30px', marginBottom: '-30px' }}
                      />
                    )
                }
              })}
            </Fragment>
          )}
        <Fonts.H3 style={{ marginBottom: '6px' }}>{t('eligibleTitleCondition')}</Fonts.H3>
        <Fonts.Base>
          {rest.memberMenuIneligibleReason === MemberProductIneligibleReasonEnum.TIER_INELIGIBLE && (
            <Fonts.Small>
              {getStrMemberCondition({ conditions: rest.memberConditions, lang: rest.lang, membershipProgram })}
            </Fonts.Small>
          )}

          {rest.memberMenuIneligibleReason === MemberProductIneligibleReasonEnum.TIER_BIRTHMONTH_REQUIRED && (
            <Fonts.Small style={{ maxWidth: '210px' }}>
              {rest.themeUI === THEMES_UI.GON ? t('eligibleTextBirthRequired') : t('eligibleTextBirthMenuRequired')}
            </Fonts.Small>
          )}
        </Fonts.Base>
      </ModalContainer>
    </Modal>
  )
}

ModalOnlyMemberMenuSpecificCondition.propTypes = {
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool,
  memberConditions: PropTypes.array,
}

ModalOnlyMemberMenuSpecificCondition.defaultProps = {
  memberConditions: [],
}

export default ModalOnlyMemberMenuSpecificCondition

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  text-align: center;
`
