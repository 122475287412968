import React, { useEffect, forwardRef, useRef, CSSProperties } from 'react'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { setElementCurrentScrollTop } from 'helpers/fastClick'
import useCombinedRefs from 'hooks/useCombinedRefs'

type Props = {
  children: React.ReactNode
  id?: string
  style?: CSSProperties
  onScroll?: VoidFunction
  useDisableBodyScroll?: boolean
}

export default forwardRef<HTMLElement, Props>((props, ref) => {
  const { useDisableBodyScroll = true } = props
  const innerRef = useRef<HTMLElement>(null)
  let scrollPageRef = useCombinedRefs(ref, innerRef)

  useEffect(() => {
    if (scrollPageRef.current && useDisableBodyScroll) {
      disableBodyScroll(scrollPageRef.current)
      setElementCurrentScrollTop(scrollPageRef.current)
    }

    const refCurrent = scrollPageRef.current

    return () => {
      if (refCurrent) {
        enableBodyScroll(refCurrent)
      }
    }
  }, [scrollPageRef, useDisableBodyScroll])

  return <ScrollPage ref={scrollPageRef} {...props} />
})

const ScrollPage = styled.div<{ flexColumn?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: inherit;

  ${({ flexColumn }) =>
    flexColumn
      ? `
    display: flex;
    flex-direction: column;
    `
      : ''}
`
