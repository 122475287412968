import numeral from 'numeral'

export const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export const displayPrice = (num) => (num != null ? numeral(num).format('0,0') : 'N/A')

export const displayPrice2Digit = (num) => (num != null ? numeral(num).format('0,0.00') : 'N/A')

export const displayPriceBothNoneAnd2Digit = (number: number) => {
  return Number.isInteger(number) ? displayPrice(number) : displayPrice2Digit(number)
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const cloneObject = <Type>(object: Type): Type => JSON.parse(JSON.stringify(object))

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms))
