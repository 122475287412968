import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { BuildingTypeEnum, DeliveryMethodEnum } from 'enums'
import { AddressFromServer, AddressPlaceData } from 'helpers/address'
import { RootState } from 'store'

export interface AddressState {
  main: AddressPlaceData
  isAvailable: boolean
  placeIsAvailable: boolean
  list: AddressFromServer[]
  isInitialed: boolean
  isAavilable: boolean
}

const initialState: AddressState = {
  main: {
    id: 0,
    name: '',
    tel: '',
    place: {
      name: '',
      formatted_address: '',
      geometry: {
        lat: 0,
        lng: 0,
      },
      googlePlaceID: '',
    },
    addressDetails: '',
    instructionDetails: '',
    buildingType: BuildingTypeEnum.House,
    deliveryMethod: DeliveryMethodEnum.ToPerson,
    callOnArrival: true,
    nickname: '',
    temporary: false,
  },
  isAvailable: false,
  placeIsAvailable: false,
  list: [],
  isInitialed: false,
  isAavilable: false,
}

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setMainAddress: (address: AddressState, { payload }: PayloadAction<AddressPlaceData>) => {
      address.main = payload
      address.isAvailable = payload.id !== 0
      address.placeIsAvailable = payload.place.geometry.lat !== 0 && payload.place.geometry.lng !== 0
    },
    setAddressList: (address: AddressState, { payload }: PayloadAction<AddressFromServer[]>) => {
      address.list = payload
    },
    setAddressIsInitialed: (address: AddressState, { payload }: PayloadAction<boolean>) => {
      address.isInitialed = payload
    },
  },
})

export const { setMainAddress, setAddressList, setAddressIsInitialed } = addressSlice.actions
export const addressSelector = (state: RootState) => state.address
export default addressSlice.reducer
