interface ShadowStyle {
  regular: string
  subtle: string
}

const shadow: ShadowStyle = {
  regular: '0px 3px 8px 0px rgba(0, 0, 0, 0.32)',
  subtle: '2px 2px 6px 0px rgba(0, 0, 0, 0.05)',
}

export default shadow
