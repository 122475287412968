import { CSSProperties, ReactNode, MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

interface FontFamilyHatoHeart {
  brandHH: string
  brand: string
  bodyText: string
  bodyTextHH: string
}

interface FontWeightHatoHeart {
  thin: number
  extraLight: number
  light: number
  normal: number
  medium: number
  semiBold: number
  bold: number
  extraBold: number
  black: number
}

type FontProps = ReturnType<
  typeof styled.div<{
    style?: CSSProperties
    onClick?: MouseEventHandler<HTMLDivElement>
    as?: string
    nowrap?: boolean
    children?: ReactNode
  }>
>

interface FontsType {
  family: FontFamilyHatoHeart
  weight: FontWeightHatoHeart
  H1: FontProps
  H2: FontProps
  H3: FontProps
  Large: FontProps
  Medium: FontProps
  MediumTitle: FontProps
  MediumTitleBold: FontProps
  MediumTitleRegular: FontProps
  BaseBold: FontProps
  Base: FontProps
  SmallBold: FontProps
  Small: FontProps
  BaseTitleBold: FontProps
  BaseTitleMedium: FontProps
  BaseTitleRegular: FontProps
  SmallTitleBold: FontProps
  SmallTitleMedium: FontProps
  SmallTitleRegular: FontProps
  ActionLarge: FontProps
  ActionBaseBold: FontProps
  ActionBaseRegular: FontProps
  PlaceholderLabelMini: FontProps
  Link: FontProps
  LinkTitle: FontProps
  LinkSmall: FontProps
  LinkSmallBold: FontProps
}

export const family: FontFamilyHatoHeart = {
  brandHH: "'Museo Sans Rounded', sans-serif",
  brand: "'Prompt', sans-serif",
  bodyText: "'Bai Jamjuree', sans-serif",
  bodyTextHH: "'Avenir Next', sans-serif",
}

export const weight: FontWeightHatoHeart = {
  thin: 100,
  extraLight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
}

const FontsStyles = css<{ nowrap?: boolean }>`
  white-space: pre-wrap;

  ${({ nowrap }) =>
    nowrap
      ? `
    white-space: nowrap;
    `
      : ''}
`

const Fonts: FontsType = { family, weight } as FontsType

Fonts.H1 = styled.h1`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.semiBold};
  font-size: 24px;
  line-height: 1.5em;
  margin: 0;
`

Fonts.H2 = styled.h2`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.semiBold};
  font-size: 20px;
  line-height: 1.5em;
  margin: 0;
`

Fonts.H3 = styled.h3`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.semiBold};
  font-size: 18px;
  line-height: 1.5em;
  margin: 0;
`

Fonts.Large = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.semiBold};
  font-size: 20px;
  line-height: 1.5em;
`

Fonts.Medium = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.semiBold};
  font-size: 18px;
  line-height: 1.53em;
`

Fonts.MediumTitle = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.normal};
  font-size: 18px;
  line-height: 1.53em;
`

Fonts.MediumTitleBold = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.bold};
  font-size: 18px;
  line-height: 1.53em;
`

Fonts.MediumTitleRegular = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.normal};
  font-size: 18px;
  line-height: 1.53em;
`

Fonts.BaseBold = styled.div`
  ${FontsStyles}
  font-family: ${family.bodyText};
  font-weight: ${weight.bold};
  font-size: 14px;
  line-height: 1.5em;
`

Fonts.Base = styled.div`
  ${FontsStyles}
  font-family: ${family.bodyText};
  font-weight: ${weight.normal};
  font-size: 14px;
  line-height: 1.5em;
`

Fonts.SmallBold = styled.div`
  ${FontsStyles}
  font-family: ${family.bodyText};
  font-weight: ${weight.bold};
  font-size: 12px;
  line-height: 1.4em;
`

Fonts.Small = styled.div`
  ${FontsStyles}
  font-family: ${family.bodyText};
  font-weight: ${weight.normal};
  font-size: 12px;
  line-height: 1.4em;
`

Fonts.BaseTitleBold = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.semiBold};
  font-size: 14px;
  line-height: 1.53em;
`

Fonts.BaseTitleMedium = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.medium};
  font-size: 14px;
  line-height: 1.53em;
`

Fonts.BaseTitleRegular = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.normal};
  font-size: 14px;
  line-height: 1.53em;
`

Fonts.SmallTitleBold = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.semiBold};
  font-size: 12px;
  line-height: 1.5em;
`

Fonts.SmallTitleMedium = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.normal};
  font-size: 12px;
  line-height: 1.5em;
`

Fonts.SmallTitleRegular = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.normal};
  font-size: 12px;
  line-height: 1.5em;
`

Fonts.ActionLarge = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.medium};
  font-size: 18px;
  line-height: 1.5em;
`

Fonts.ActionBaseBold = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.semiBold};
  font-size: 14px;
  line-height: 1.53em;
`

Fonts.ActionBaseRegular = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.normal};
  font-size: 14px;
  line-height: 1.53em;
`

Fonts.PlaceholderLabelMini = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.normal};
  font-size: 11px;
  line-height: 1.545em;
`

Fonts.Link = styled.div`
  ${FontsStyles}
  font-family: ${family.bodyText};
  font-weight: ${weight.normal};
  font-size: 14px;
  line-height: 1.745em;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.infoDark};
`

Fonts.LinkTitle = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.normal};
  font-size: 14px;
  line-height: 1.745em;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.infoDark};
`

Fonts.LinkSmall = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.normal};
  font-size: 12px;
  line-height: 1.5em;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.infoDark};
`

Fonts.LinkSmallBold = styled.div`
  ${FontsStyles}
  font-family: ${family.brand};
  font-weight: ${weight.bold};
  font-size: 12px;
  line-height: 1.5em;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.infoDark};
`

export default Fonts
