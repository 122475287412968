import useResizeObserver from 'hooks/useResizeObserver'
import React, { ReactNode, useRef, CSSProperties } from 'react'
import styled from 'styled-components'

type Props = {
  id?: string
  style?: CSSProperties
  children: ReactNode
  resizeCallback: (element: HTMLDivElement | null) => void
}

function ProductContentWrapper({ id, children, style, resizeCallback }: Props) {
  const productContentRef = useRef<HTMLDivElement>(null)

  useResizeObserver({
    onResize: () => resizeCallback(productContentRef.current!),
    element: productContentRef.current!,
  })

  return (
    <ContentWrapper id={id} ref={productContentRef} style={style}>
      {children}
    </ContentWrapper>
  )
}

export default ProductContentWrapper

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
