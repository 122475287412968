import React, { FC, ReactNode, MouseEvent } from 'react'
import styled from 'styled-components'
import { Icon } from 'components/base-ui'

interface ItemProps {
  testId?: string
  id?: string
  children: ReactNode
  mainIcon?: string
  rightIcon?: string
  highlight?: boolean
  border?: boolean
  singleBorder?: boolean
  iconSize?: string
  iconAlignTop?: boolean
  rightIconSize?: string
  disabled?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}

const Item: FC<ItemProps> = ({
  testId,
  id,
  children,
  mainIcon,
  highlight = false,
  rightIcon,
  border = false,
  singleBorder = false,
  iconSize = '20px',
  rightIconSize = '10px',
  iconAlignTop = false,
  disabled = false,
  onClick,
}) => {
  return (
    <Wrapper
      data-testid={testId}
      id={id}
      onClick={onClick}
      singleBorder={singleBorder}
      highlight={highlight}
      border={border}
      iconAlignTop={iconAlignTop}
      disabled={disabled}
    >
      {mainIcon && <Icon name={mainIcon} size={iconSize} />}
      <Content>{children}</Content>
      {rightIcon && <Icon name={rightIcon} size={rightIconSize} />}
    </Wrapper>
  )
}

export default Item

const Wrapper = styled.div<{
  id?: string
  singleBorder: boolean
  highlight: boolean
  border: boolean
  disabled: boolean
  iconAlignTop: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}>`
  display: flex;
  column-gap: 10px;
  align-items: center;
  width: 100%;
  padding: 16px;
  background-color: ${({ highlight, theme }) => (highlight ? '#FFF9E6' : theme.colors.white)};
  border-radius: 10px;
  ${({ border, singleBorder, theme }) =>
    border
      ? `border: 1px solid ${theme.colors.lightGrey};`
      : singleBorder && `border-bottom: 1px solid #F3F3F3; border-radius: 0;`}

  i {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    background-color: #fffbf0;
  }

  ${({ iconAlignTop }) =>
    iconAlignTop &&
    `
    align-items: baseline;
  `}

  ${({ theme, disabled }) =>
    disabled &&
    `
    color: ${theme.colors.darkGrey};
    background-color: ${theme.colors.veryLightGrey};

    &:hover {
      background-color: ${theme.colors.veryLightGrey};
    }

    i {
      color: ${theme.colors.darkGrey};
    }
  `}
`

const Content = styled.div`
  margin-right: auto;
`
