import _liff from '@line/liff'
import { LiffMockPlugin } from '@line/liff-mock'

declare module '@line/liff' {
  interface Liff {
    $mock: any
  }
}

_liff.use(new LiffMockPlugin())
_liff.$mock.set((p) => ({
  ...p,
  getProfile: {
    displayName: process.env.REACT_APP_LIFF_MOCK_DISPLAY_NAME,
    pictureUrl: process.env.REACT_APP_LIFF_MOCK_PICTURE_PROFILE,
    userId: process.env.REACT_APP_LIFF_MOCK_USER_ID,
  },
  getAccessToken: process.env.REACT_APP_LIFF_MOCK_ACCESSTOKEN,
  isLoggedIn: true,
  getDecodedIDToken: {
    email: process.env.REACT_APP_LIFF_MOCK_EMAIL,
  },
}))

export const getLiff = () => _liff

function versionCompare(v1, v2, options) {
  var lexicographical = options && options.lexicographical,
    zeroExtend = options && options.zeroExtend,
    v1parts = v1.split('.'),
    v2parts = v2.split('.')

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x)
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push('0')
    while (v2parts.length < v1parts.length) v2parts.push('0')
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number)
    v2parts = v2parts.map(Number)
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1
    }

    if (v1parts[i] === v2parts[i]) {
      continue
    } else if (v1parts[i] > v2parts[i]) {
      return 1
    } else {
      return -1
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1
  }

  return 0
}

export const isLineVersionHaveBackNavigator = () => {
  const liff = getLiff()

  if (liff) {
    const lineVersion = liff.getLineVersion()
    if (lineVersion === null) {
      return false
    }

    const resultCompare = versionCompare(lineVersion, '10.15.0', {})
    return resultCompare !== -1 && liff.getOS() === 'ios'
  } else {
    console.warn('HatoHub: Can not find LIFF SDK, That BackNavigator maybe working correctly on some line ios version.')
    return false
  }
}

export const redirectToChatScreen = (lineID) => {
  const liff = getLiff()
  const url = `http://line.me/ti/p/${lineID}`
  liff.openWindow({
    url,
    external: false,
  })
}
