export enum LANGUAGES {
  TH = 'th',
  EN = 'en',
}

export const getLangIDbyLineLang = (lineLang) => {
  let selectedLang = ''
  if (/^en\b/.test(lineLang)) {
    selectedLang = LANGUAGES.EN
  } else if (/^th\b/.test(lineLang)) {
    selectedLang = LANGUAGES.TH
  }

  return selectedLang
}
