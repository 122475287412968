import { Modal } from 'components/base-ui'
import React, { FC } from 'react'
import styled from 'styled-components'
import { Fonts } from 'components/base-ui'
import { THEMES_UI } from 'brands'
import { useTranslation } from 'react-i18next'
import { getSrcSetStr } from 'helpers/image'
type Props = {
  isOpen: boolean
  onRequestClose: () => void
  themeUI: string
  nextTimeOpen: string
}
const iconStoreClose = `${process.env.PUBLIC_URL}/assets/generic/modal/store-closed.png`
const fpIconStoreClose = `${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-store-close.png`

const ModalStoreClosed: FC<Props> = ({ isOpen, onRequestClose, themeUI, nextTimeOpen }) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zindex="9999" hideCloseButton={false}>
      {themeUI === THEMES_UI.GON && (
        <ModalContainerGeneric>
          <img
            alt="store-closed-food-passion"
            src={fpIconStoreClose}
            srcSet={getSrcSetStr({ img: fpIconStoreClose, hires2x: true, hires3x: true })}
          />
          <ModalTitleGeneric>{t('modalStoreIsCloseTitle')}</ModalTitleGeneric>
          <ModalTextGeneric>{t('modalStoreIsCloseDes')}</ModalTextGeneric>
        </ModalContainerGeneric>
      )}

      {themeUI === THEMES_UI.GENERIC && (
        <ModalContainerGeneric>
          <img
            alt="store-closed"
            src={iconStoreClose}
            srcSet={getSrcSetStr({ img: iconStoreClose, hires2x: true, hires3x: true })}
          />
          <ModalTitleGeneric>{t('modalGenericStoreIsCloseTitle')}</ModalTitleGeneric>
          {!nextTimeOpen && <ModalTextGeneric>{t('modalGenericStoreIsCloseDesA', { nextTimeOpen })}</ModalTextGeneric>}
          <ModalTextGeneric>{t('modalGenericStoreIsCloseDesB')}</ModalTextGeneric>
        </ModalContainerGeneric>
      )}
    </Modal>
  )
}

export default ModalStoreClosed

const ModalContainerGeneric = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 350px;
  margin-bottom: -10px;
`

const ModalTitleGeneric = styled(Fonts.H2)`
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
  color: black;
`

const ModalTextGeneric = styled(Fonts.Base)`
  text-align: center;
  color: black;
`
