import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from 'components/base-ui'

const BtnBack = ({ className, ...rest }) => {
  rest.className = `${rest.className ? `${rest.className} ` : ''} button`

  return (
    <BtnBackStyled {...rest}>
      <Icon name="plus-light" />
    </BtnBackStyled>
  )
}

BtnBack.propTypes = {
  lg: PropTypes.bool,
}

export default BtnBack

const BtnBackStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.24);
  background-color: white;
  transition: 0.6s;

  i {
    transform: rotate(45deg);
    font-size: 18px;
    color: ${(props) => props.theme.colors.primary};
    transition: 0.6s;
  }

  ${(props) =>
    props.sm
      ? `
    width: 40px;
    height: 40px;
    `
      : ''}

  ${(props) =>
    props.lg
      ? `
    width: 56px;
    height: 56px;
  `
      : ''}

  .touch &.touch {
    transition: 0s;
    background-color: ${(props) => props.theme.colors.primary};
    transform: scale(1.05);

    i {
      transition: 0s;
      color: white;
    }
  }
`
