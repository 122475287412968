import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { MODE_MODALCUSTOMERNOTES } from 'helpers/cart'
import { ProductCartType } from 'types/cart'
import { ProductModifier } from 'services/hooks/dine-in/useGetProductModifiers'
import { ScreenEnum } from 'components/buffet/BuffetOverlayPage'
import { ModalEnum } from 'components/buffet/BuffetModals'
import { AlertLabelBoxProps } from 'components/base-ui/AlertLabelBox'
import {
  PaymentTypeEnum,
  StaffCallingReasonEnum,
  LineItemServiceTypeForBuffetEnum,
  MemberProductIneligibleReasonEnum,
  PromotionStrategyTypeEnum,
  type BuffetOrderQuery,
  OrderStatusEnum,
} from 'graphQL/dinein/generate/operations'
import { PaymentMethod } from 'types/payment'
import { Coupon } from 'types/coupon'

const initPaymentMethod: PaymentMethod = {
  type: PaymentTypeEnum.None,
  phoneNumber: '',
  rememberCard: false,
  creditCard: {
    id: '',
    brand: '',
    lastDigits: '',
    holderName: '',
  },
}

const initialProductSelected: ProductCartType = {
  customNotes: '',
  modifiers: [],
  quantity: 1,
  skipCustomNotesScreen: false,
  item: {
    descriptionEn: '',
    descriptionTh: '',
    skipModifierScreen: false,
    active: false,
    availableServices: [],
    excludedFromListing: false,
    categoryID: 0,
    discountSatangs: 0,
    id: 0,
    excludedFromPromotion: false,
    skipCustomNotesScreen: false,
    images: [],
    memberTierRankConditions: [],
    modifierGroups: [],
    nameEn: '',
    nameTh: '',
    priceSatangs: 0,
    tags: [],
    totalPriceSatangs: 0,
    memberIneligibleReason: MemberProductIneligibleReasonEnum.MembershipRequired,
    promotionProduct: {
      productGroupRewards: [],
      promotion: {
        id: 0,
        strategyType: PromotionStrategyTypeEnum.BuyXGetYForZ,
      },
    },
    buffetPackageProducts: [],
    category: {
      id: 0,
      nameEn: '',
      nameTh: '',
      descriptionEn: '',
      descriptionTh: '',
      position: 0,
      images: [],
    },
    promotionBadge: PromotionStrategyTypeEnum.BuyXGetYForZ,
  },
}

export interface BuffetModalProductModifier {
  isOpen: boolean
  isLoading: boolean
  mode: string
  skipCustomNotesScreen: boolean
}

export interface BuffetTable {
  tableID: number
  orderID: number
}

export interface BuffetPackage {
  id: number
  nameEn: string
  nameTh: string
  level: number
}

export interface Screen {
  type: ScreenEnum
  isProcessing: boolean
}

export interface Modal {
  type: ModalEnum
  isProcessing: boolean
}

export interface BuffetCurrentOrder extends Omit<BuffetOrderQuery['currentOrder'], 'staffCallingReason'> {
  staffCallingReason: StaffCallingReasonEnum | null
}

export interface BuffetState {
  table: BuffetTable
  productSelected: ProductCartType
  isTimeUp: boolean
  endTime: null | string
  currentPackage: BuffetPackage
  modifiersSelected: ProductModifier[]
  modalProductModifier: BuffetModalProductModifier
  screen: Screen
  modal: Modal
  currentOrder: BuffetCurrentOrder
  paymentMethod: PaymentMethod
  serviceType?: LineItemServiceTypeForBuffetEnum
  isFixedServiceType: boolean
  allowedSharingCoupon: boolean
  totalUsersSharedCoupons: number
  alertLabelBox: AlertLabelBoxProps
  qrSharingCoupon: string
  memberCoupons: Coupon[]
}

export interface CurrentOrder {
  staffCallingReason: StaffCallingReasonEnum | null
}

const initiateModalProductModifier: BuffetModalProductModifier = {
  isOpen: false,
  isLoading: false,
  mode: MODE_MODALCUSTOMERNOTES.DEFAULT,
  skipCustomNotesScreen: false,
}

const initiateModal: Modal = { type: ModalEnum.None, isProcessing: false }
const initiateScreen: Screen = { type: ScreenEnum.None, isProcessing: false }

const initialState: BuffetState = {
  table: {
    tableID: 0,
    orderID: 0,
  },
  isTimeUp: false,
  endTime: null,
  currentOrder: {
    id: 0,
    status: OrderStatusEnum.Placed,
    userID: 0,
    buffetPackageID: 0,
    buffetEndTime: '',
    staffCallingReason: null,
    buffetPackage: { id: 0, nameEn: '', nameTh: '', level: 0 },
    payments: [],
    table: { id: 0, status: '' },
    tableGuests: [],
  },
  currentPackage: {
    id: 0,
    nameEn: '',
    nameTh: '',
    level: 0,
  },
  productSelected: initialProductSelected,
  modifiersSelected: [],
  modalProductModifier: initiateModalProductModifier,
  screen: initiateScreen,
  modal: initiateModal,
  paymentMethod: initPaymentMethod,
  isFixedServiceType: false,
  allowedSharingCoupon: false,
  totalUsersSharedCoupons: 0,
  alertLabelBox: {
    show: false,
    alertText: '',
    type: 'success',
  },
  qrSharingCoupon: '',
  memberCoupons: [],
}

export const buffetSlice = createSlice({
  name: 'buffet',
  initialState,
  reducers: {
    setBuffetTable: (buffet: BuffetState, { payload }: PayloadAction<BuffetTable>) => {
      buffet.table = payload
    },
    openModalProductModifier: (buffet: BuffetState, { payload }: PayloadAction<{ skipCustomNotesScreen: boolean }>) => {
      buffet.modalProductModifier = {
        isOpen: true,
        isLoading: true,
        mode: MODE_MODALCUSTOMERNOTES.DEFAULT,
        skipCustomNotesScreen: payload.skipCustomNotesScreen,
      }
    },
    openModalEditProductModifier: (
      buffet: BuffetState,
      { payload }: PayloadAction<{ skipCustomNotesScreen: boolean }>
    ) => {
      buffet.modalProductModifier = {
        isOpen: true,
        isLoading: true,
        mode: MODE_MODALCUSTOMERNOTES.EDITONLYNOTE,
        skipCustomNotesScreen: payload.skipCustomNotesScreen,
      }
    },
    closeModalProductModifier: (buffet: BuffetState) => {
      buffet.modalProductModifier = initiateModalProductModifier
    },
    setLoadingModifier: (buffet: BuffetState, { payload }: PayloadAction<boolean>) => {
      buffet.modalProductModifier.isLoading = payload
    },
    setProductSelected: (buffet: BuffetState, { payload }: PayloadAction<ProductCartType>) => {
      buffet.productSelected = payload
      buffet.modifiersSelected = payload.modifiers
    },
    setBuffetEndTime: (buffet: BuffetState, { payload }: PayloadAction<string>) => {
      buffet.endTime = payload
    },
    setIsTimeUp: (buffet: BuffetState, { payload }: PayloadAction<boolean>) => {
      buffet.isTimeUp = payload
    },
    onCurrentPackageChange: (buffet: BuffetState, { payload }: PayloadAction<BuffetPackage>) => {
      if (buffet.currentPackage.id !== 0 && buffet.currentPackage.id !== payload.id) {
        buffet.currentPackage = payload
        buffet.modal = { type: ModalEnum.UpgradePackage, isProcessing: false }
      } else if (buffet.currentPackage.id !== payload.id) {
        buffet.currentPackage = payload
      }
    },
    setBuffetScreen: (buffet: BuffetState, { payload }: PayloadAction<Screen>) => {
      buffet.screen = payload
    },
    setBuffetModal: (buffet: BuffetState, { payload }: PayloadAction<Modal>) => {
      buffet.modal = payload
    },
    resetBuffetModal: (buffet: BuffetState) => {
      buffet.modal = initiateModal
    },
    resetBuffetScreen: (buffet: BuffetState) => {
      buffet.screen = initiateScreen
    },
    setCurrentOrder: (buffet: BuffetState, { payload }: PayloadAction<BuffetCurrentOrder>) => {
      buffet.currentOrder = payload
    },
    setPaymentMethod: (buffet: BuffetState, { payload }: PayloadAction<PaymentMethod>) => {
      buffet.paymentMethod = payload
    },
    setServiceType: (buffet: BuffetState, { payload }: PayloadAction<LineItemServiceTypeForBuffetEnum>) => {
      buffet.serviceType = payload
    },
    resetServiceType: (buffet: BuffetState) => {
      delete buffet.serviceType
    },
    setIsFixedServiceType: (buffet: BuffetState, { payload }: PayloadAction<boolean>) => {
      buffet.isFixedServiceType = payload
    },
    setAllowedSharingCoupon: (buffet: BuffetState, { payload }: PayloadAction<boolean>) => {
      buffet.allowedSharingCoupon = payload
    },
    setTotalUsersSharedCoupons: (buffet: BuffetState, { payload }: PayloadAction<number>) => {
      buffet.totalUsersSharedCoupons = payload
    },
    setAlertLabelBox: (buffet: BuffetState, { payload }: PayloadAction<AlertLabelBoxProps>) => {
      buffet.alertLabelBox = payload
    },
    setQrSharingCoupon: (buffet: BuffetState, { payload }: PayloadAction<string>) => {
      buffet.qrSharingCoupon = payload
    },
    setMemberCoupons: (buffet: BuffetState, { payload }: PayloadAction<Coupon[]>) => {
      buffet.memberCoupons = payload
    },
  },
})

export const {
  setBuffetTable,
  openModalProductModifier,
  openModalEditProductModifier,
  setLoadingModifier,
  setProductSelected,
  closeModalProductModifier,
  setBuffetEndTime,
  setIsTimeUp,
  onCurrentPackageChange,
  setBuffetScreen,
  setBuffetModal,
  resetBuffetModal,
  resetBuffetScreen,
  setCurrentOrder,
  setPaymentMethod,
  setServiceType,
  resetServiceType,
  setIsFixedServiceType,
  setAllowedSharingCoupon,
  setTotalUsersSharedCoupons,
  setAlertLabelBox,
  setQrSharingCoupon,
  setMemberCoupons,
} = buffetSlice.actions
export const buffetSelector = (state: RootState) => state.buffet
export const serviceTypeSelector = (state: RootState) => state.buffet.serviceType
export const isFixedServiceTypeSelector = (state: RootState) => state.buffet.isFixedServiceType
export default buffetSlice.reducer
