import React, { FC } from 'react'
import { getLiff } from 'helpers/liff'
import { useTranslation } from 'react-i18next'
import ScreenWithImage from 'components/dine-in/ScreenWithImage'

const liff = getLiff()
const peopleWaitingImg = `${process.env.PUBLIC_URL}/assets/dine-in/people-waiting.png`

const TableNotAvailable: FC = () => {
  const { t } = useTranslation()

  const onClickBack = () => {
    if (liff.isInClient()) {
      liff.closeWindow()
    }
  }

  return (
    <ScreenWithImage
      imagePath={peopleWaitingImg}
      imageSize="100%"
      isGenerateSrc
      title={t('tableNotAvailable')}
      description={t('tableNotAvailableDesc')}
      isShowButton={liff.isInClient()}
      buttonText={t('backToLine')}
      buttonOutline={true}
      onClick={onClickBack}
    />
  )
}

export default TableNotAvailable
