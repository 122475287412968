import styled, { css } from 'styled-components'

export const family = {
  brand: "'Prompt', sans-serif",
  bodyText: "'Bai Jamjuree', sans-serif",
}

export const weight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
}

interface FontStyle {
  onClick?: (e: Event) => void
  nowrap?: boolean
}

const FontsStyles = css<FontStyle>`
  white-space: pre-wrap;

  ${({ nowrap }) =>
    nowrap
      ? `
    white-space: nowrap;
    `
      : ''}
`

const Fonts = {
  family,
  weight,
  H1: styled.h1<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.semiBold};
    font-size: 24px;
    line-height: 1.5em;
    margin: 0;
  `,

  H2: styled.h2<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.semiBold};
    font-size: 20px;
    line-height: 1.5em;
    margin: 0;
  `,

  H3: styled.h3<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.semiBold};
    font-size: 18px;
    line-height: 1.5em;
    margin: 0;
  `,

  H4: styled.h4<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.semiBold};
    font-size: 16px;
    line-height: 1.5em;
    margin: 0;
  `,

  Large: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.semiBold};
    font-size: 20px;
    line-height: 1.5em;
  `,

  Medium: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.semiBold};
    font-size: 17px;
    line-height: 1.53em;
  `,

  BaseBold: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.bodyText};
    font-weight: ${weight.bold};
    font-size: 14px;
    line-height: 1.745em;
  `,

  Base: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.bodyText};
    font-weight: ${weight.normal};
    font-size: 14px;
    line-height: 1.745em;
  `,

  SmallBold: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.bodyText};
    font-weight: ${weight.bold};
    font-size: 12px;
    line-height: 1.4em;
  `,

  Small: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.bodyText};
    font-weight: ${weight.normal};
    font-size: 12px;
    line-height: 1.4em;
  `,

  BaseTitleBold: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.semiBold};
    font-size: 15px;
    line-height: 1.53em;
  `,

  BaseTitleRegular: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.normal};
    font-size: 15px;
    line-height: 1.53em;
  `,

  SmallTitleBold: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.semiBold};
    font-size: 12px;
    line-height: 1.5em;
  `,

  SmallTitleMedium: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.medium};
    font-size: 12px;
    line-height: 1.5em;
  `,

  SmallTitleRegular: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.normal};
    font-size: 12px;
    line-height: 1.5em;
  `,

  ActionLarge: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.medium};
    font-size: 18px;
    line-height: 1.5em;
  `,

  ActionBaseBold: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.semiBold};
    font-size: 15px;
    line-height: 1.53em;
  `,

  ActionBaseRegular: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.normal};
    font-size: 15px;
    line-height: 1.53em;
  `,

  PlaceholderLabelMini: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.normal};
    font-size: 11px;
    line-height: 1.545em;
  `,

  LinkSmall: styled.div<FontStyle>`
    ${FontsStyles}
    font-family: ${family.brand};
    font-weight: ${weight.normal};
    font-size: 12px;
    line-height: 1.5em;
    text-decoration: underline;
  `,
}

export default Fonts
