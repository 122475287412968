import moment from 'moment'
import 'moment/locale/th'
import { LANGUAGES } from 'helpers/language'
import { MemberProductIneligibleReasonEnum } from 'enums/product'
import { t } from 'i18next'
import {
  DeliverableDayType,
  CustomerCartLineItemType,
  LineItemModifierType,
  InvalidModifierEnum,
  StaffPrivilegeTypeTypeEnum,
} from 'graphQL/hatostore/generate/operations'
import {
  TemporaryOrderWithPromotionQuery,
  AdjustmentTypeEnumType,
  PromotionTypeEnum,
} from 'graphQL/dinein/generate/operations'
import { Language } from 'hooks/useLanguage'

export const getFormatTextDeliveryDate = (date: string, lang: LANGUAGES = LANGUAGES.EN) => {
  return moment(date, 'YYYY-MM-DD')
    .locale(lang)
    .calendar(null, {
      sameDay: `[${t('today')}]`,
      nextDay: `[${t('tomorrow')}]`,
      nextWeek: lang === LANGUAGES.EN ? 'ddd, MMM DD' : 'ddd, DD MMM', //EN: Mon, Jul 05, TH: จันทร์, 05 ก.ค.
      lastDay: lang === LANGUAGES.EN ? 'ddd, MMM DD' : 'ddd, DD MMM',
      lastWeek: lang === LANGUAGES.EN ? 'ddd, MMM DD' : 'ddd, DD MMM',
      sameElse: lang === LANGUAGES.EN ? 'ddd, MMM DD' : 'ddd, DD MMM',
    })
}

export const getFormatTextDeliveryTimeslots = (time: string) => {
  if (time === 'ASAP') {
    return t('asap')
  } else {
    return time
  }
}

interface DeliveryDateAndTimeslot {
  deliverableDays: DeliverableDayType[]
  deliveryDate: string
  deliveryTimeslot: string
}

export const isValidDeliveryDateAndTimeslot = ({
  deliverableDays,
  deliveryDate,
  deliveryTimeslot,
}: DeliveryDateAndTimeslot) => {
  const selectedDate = deliverableDays.find((data) => data.date === deliveryDate)

  // if find match date go to find match timeslot
  if (selectedDate) {
    if (selectedDate.timeslots.indexOf(deliveryTimeslot) !== -1) {
      return true
    }
  }

  return false
}

export const distinct = (value: any, index: any, self: any) => self.indexOf(value) === index

interface Cart {
  productID: number
  quantity: number
  customNotes: string
  useContainer: boolean
  modifiers: {
    modifierID: number
    quantity: number
  }[]
}

export const convertLineItemsToCart = (productList: CustomerCartLineItemType[]) => {
  const _lineItemsCart: Cart[] = []
  productList.forEach((item) => {
    _lineItemsCart.push({
      productID: item.productID,
      quantity: item.quantity,
      customNotes: item.customNotes,
      useContainer: item.useContainer,
      modifiers: item.modifiers.map((modifier) => ({
        modifierID: modifier.modifierID,
        quantity: modifier.quantity,
      })),
    })
  })

  return _lineItemsCart
}

export enum MODE_MODALCUSTOMERNOTES {
  DEFAULT = 'default',
  EDITONLYNOTE = 'editOnlyNote',
}

export enum CART_CORRECTIONS {
  INACTIVE_LINE_ITEMS_REMOVED = 'InactiveLineItemsRemoved',
  INACTIVE_MODIFIERS_REMOVED = 'InactiveModifiersRemoved',
}

export const CART_LINEITEM_INVALIDS = {
  ...MemberProductIneligibleReasonEnum,
  INVALID_MODIFIERS: 'InvalidModifiers',
  OUT_OF_STOCK: 'OutOfStock',
  INACTIVE: 'Inactive',
  INSUFFICIENTSTOCK: 'InsufficientStock',
  MODIFIER_REQUIRED: 'ModifierRequired',
}

export const isStoreClose = ({ deliverableDays }: { deliverableDays: DeliverableDayType[] }) => {
  return deliverableDays.length > 0 ? false : true
}

export interface Modifier {
  modifierID: number
  quantity: number
}

export const isSameModifiers = (modifiersSetA: Modifier[] = [], modifiersSetB: Modifier[] = []) => {
  let isSame = true
  if (modifiersSetA.length !== modifiersSetB.length) {
    isSame = false
  } else if (!(modifiersSetA.length === 0 && modifiersSetB.length === 0)) {
    // If some modifierA can not find in modifierB that mean not the same modifiers.
    modifiersSetA.some((_modifierSetA) => {
      if (
        !modifiersSetB.some(
          (_modifierSetB) =>
            _modifierSetB.modifierID === _modifierSetA.modifierID && _modifierSetB.quantity === _modifierSetA.quantity
        )
      ) {
        isSame = false
        return true
      }
      return false
    })
  }

  return isSame
}

export const isSameCustomNotes = (lineItemA = { customNotes: '' }, lineItemB = { customNotes: '' }) =>
  lineItemA.customNotes === lineItemB.customNotes

export const isSameServiceType = (lineItemA, lineItemB) => lineItemA.serviceType === lineItemB.serviceType

export const checkInvalids = (invalids: string[]) => {
  const _invalids = invalids ?? []
  const isInsufficientStock = _invalids.includes(CART_LINEITEM_INVALIDS.INSUFFICIENTSTOCK)
  const isOutOfStock = _invalids.includes(CART_LINEITEM_INVALIDS.OUT_OF_STOCK)
  const isInactiveItem = _invalids.includes(CART_LINEITEM_INVALIDS.INACTIVE)
  const isInvalidModifiers = _invalids.includes(CART_LINEITEM_INVALIDS.INVALID_MODIFIERS)
  const isIneligibleMemberItem =
    _invalids.includes(CART_LINEITEM_INVALIDS.MEMBERSHIP_REQUIRED) ||
    _invalids.includes(CART_LINEITEM_INVALIDS.TIER_BIRTHMONTH_REQUIRED) ||
    _invalids.includes(CART_LINEITEM_INVALIDS.TIER_INELIGIBLE)
  const isModifierRequired = _invalids.includes(CART_LINEITEM_INVALIDS.MODIFIER_REQUIRED)
  return {
    isInsufficientStock,
    isOutOfStock,
    isInactiveItem,
    isInvalidModifiers,
    isIneligibleMemberItem,
    isModifierRequired,
  }
}

export const getInvalidModifiers = (
  modifiers: LineItemModifierType[],
  getPropertyByLang: (modifierName: Language) => string
) => {
  const listModifierOutOfStock: string[] = []
  const listModifierInsufficientStock: string[] = []
  const listModifierInactive: string[] = []

  modifiers.forEach((modifier) => {
    const { invalids, nameEn, nameTh } = modifier.modifier
    const isOutOfStock = invalids.indexOf(InvalidModifierEnum.OutOfStock) !== -1
    const isInsufficientStock = invalids.indexOf(InvalidModifierEnum.InsufficientStock) !== -1
    const isInactive =
      invalids.indexOf(InvalidModifierEnum.Inactive) !== -1 ||
      invalids.indexOf(InvalidModifierEnum.ProductInactive) !== -1
    const modifierName = getPropertyByLang({
      th: nameTh,
      en: nameEn,
    })

    if (isOutOfStock) {
      listModifierOutOfStock.push(modifierName)
    } else if (isInsufficientStock) {
      listModifierInsufficientStock.push(modifierName)
    } else if (isInactive) {
      listModifierInactive.push(modifierName)
    }
  })

  return {
    listModifierOutOfStock,
    listModifierInsufficientStock,
    listModifierInactive,
  }
}

export const filterAdjustmentsPromoDiscount = (
  adjustments: TemporaryOrderWithPromotionQuery['temporaryOrderWithPromotion']['adjustments']
) =>
  adjustments?.filter(
    ({ adjustmentType, amountSatangs, ...adjustment }) =>
      (adjustmentType === AdjustmentTypeEnumType.Discount ||
        adjustmentType === AdjustmentTypeEnumType.Concession ||
        adjustment.promotion?.type === PromotionTypeEnum.Auto) &&
      amountSatangs !== 0
  )

export const hasAdjustmentsExceedingCashVoucher = (
  adjustments: {
    adjustmentType: AdjustmentTypeEnumType
    fullAmountSatangs: number
    amountSatangs: number
    externalCoupon?: { staffPrivilegeType: StaffPrivilegeTypeTypeEnum }
  }[]
) => {
  return adjustments.some((adjustment) => {
    if (
      adjustment.adjustmentType === AdjustmentTypeEnumType.Cash &&
      adjustment.fullAmountSatangs !== adjustment.amountSatangs
    ) {
      if (adjustment.externalCoupon) {
        return adjustment.externalCoupon.staffPrivilegeType !== StaffPrivilegeTypeTypeEnum.Cash
      } else {
        return true
      }
    } else {
      return false
    }
  })
}
