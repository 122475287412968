import * as Sentry from '@sentry/browser'
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { getTokenSession } from 'helpers/localStorage'
import { isDevelopment } from 'variables/environment'

const hostName = process.env.REACT_APP_API_HOSTNAME

const serviceEndpoints = {
  liffAPI: `${hostName}${process.env.REACT_APP_API_GRAPHQL}`,
  liffAPILimit: `${hostName}${process.env.REACT_APP_API_GRAPHQL_LIMIT}`,
  dineIn: `${hostName}${process.env.REACT_APP_API_GRAPHQL_DINE_IN}`,
}

const listRetryOperationName = [
  'currentOrder',
  'memberCoupon',
  'orderFPWithPromotion',
  'temporaryOrderWithPromotion',
  'listPointRedemptionCampaigns',
  'getCartRecommendedProducts',
]
const MAX_RETRY = 1

const createClient = (endpoint: string) => {
  const retryLink = new RetryLink({
    attempts: (count, operation, error) => {
      operation.setContext({ isRetried: true })
      return !!error && count <= MAX_RETRY && listRetryOperationName.includes(operation.operationName)
    },
    delay: () => 500,
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const { variables, operationName } = operation
    const payload = JSON.stringify(variables)

    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => {
        const payload = JSON.stringify(variables)
        const errorMsg = `[GraphQL error]: Message: ${message}, Operation: ${operationName}, Payload: ${payload}`
        !isDevelopment && Sentry.captureException(new Error(errorMsg))
        return console.log(errorMsg)
      })
    }

    if (networkError && !!operation.getContext().isRetried) {
      let errorMsg = ''
      if (typeof networkError === 'string' && /ServerError/.test(networkError)) {
        errorMsg = `${networkError}, Operation: ${operationName}, Payload: ${payload}`
      } else {
        errorMsg = `[Network error]: ${networkError}, Operation: ${operationName}, Payload: ${payload}`
      }

      !isDevelopment && Sentry.captureException(new Error(errorMsg))
      console.log(errorMsg)
    }
  })

  const authLink = setContext((_, { headers }) => {
    const token = getTokenSession()
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const httpLink = createHttpLink({
    uri: endpoint,
  })

  return new ApolloClient({
    link: ApolloLink.from([authLink, retryLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      dataIdFromObject: (object) => object['key'] as string,
    }),
  })
}

const liffApiClient = createClient(serviceEndpoints.liffAPI)

const liffApiLimitClient = createClient(serviceEndpoints.liffAPILimit)

const dineInClient = createClient(serviceEndpoints.dineIn)

export { serviceEndpoints, liffApiClient, liffApiLimitClient, dineInClient }
