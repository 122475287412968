export enum MediaTypeEnum {
  IMAGE = 'Image',
  VIDEO = 'Video',
}

export const getTypeImageFromExtension = (path) => {
  let type = MediaTypeEnum.IMAGE
  var regexVdo = /\.mp4/
  if (regexVdo.test(path)) {
    type = MediaTypeEnum.VIDEO
  }

  return type
}

interface ImageSrcOption {
  img: string
  hires2x: boolean
  hires3x: boolean
}

export const getSrcSetStr = ({ img, hires2x, hires3x }: ImageSrcOption) => {
  let srcSetStr = ''
  let imgSplitData: string[] = []
  if (img && process.env.PUBLIC_URL) {
    imgSplitData = img.replace(process.env.PUBLIC_URL, '').split('.')
    const imgPath = imgSplitData[0]
    const imgExtension = imgSplitData[1]
    if (hires2x || hires3x) {
      srcSetStr = `${process.env.PUBLIC_URL}${imgPath}.${imgExtension} 1x`
    }

    if (hires2x) {
      srcSetStr += `, ${process.env.PUBLIC_URL}${imgPath}@2x.${imgExtension} 2x`
    }

    if (hires3x) {
      srcSetStr += `, ${process.env.PUBLIC_URL}${imgPath}@3x.${imgExtension} 3x`
    }
  }

  return srcSetStr
}

const getImageName = (imagePath: string, imageType: string, size: number) => {
  if (size === 1) {
    return `${imagePath} ${size}x`
  }
  return `${imagePath.replace(`.${imageType}`, `@${size}x.${imageType}`)} ${size}x`
}

export const generateSrcSet = (imagePath: string, imageType: string, size = '3x') => {
  if (size === '2x') return [1, 2].map((_size) => getImageName(imagePath, imageType, _size)).join(',')
  return [1, 2, 3].map((_size) => getImageName(imagePath, imageType, _size)).join(',')
}

interface ResizableURLOptions {
  width: string
  height: string
}

export const resizableURL = (url: string, { width, height }: ResizableURLOptions) =>
  url.replace('{width}', width).replace('{height}', height)
