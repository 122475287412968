import { useState } from 'react'

const useModal = () => {
  const [open, setOpen] = useState(false)
  const [processing, setProcessing] = useState(false)

  const reset = () => {
    setOpen(false)
    setProcessing(false)
  }

  return {
    open,
    setOpen,
    processing,
    setProcessing,
    reset,
  }
}

export default useModal
