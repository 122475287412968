import React from 'react'
import styled from 'styled-components'
import { Fonts } from 'components/base-ui'
import { onClick } from 'helpers/fastClick'

const Tab = ({ onClick: btnOnClick, ...rest }) => {
  const fastClickObj = btnOnClick ? { ...onClick(btnOnClick) } : {}

  return <TabStyled {...rest} {...fastClickObj} />
}

const TabStyled = styled(Fonts.ActionBaseBold)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
  white-space: nowrap;
  margin-bottom: 15px;
  padding: 18px 15px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.secondary};

  img,
  i {
    margin-right: 8px;
    color: ${(props) => props.theme.colors.secondary};
  }

  &.-active {
    z-index: 2;
    border-color: ${(props) => props.theme.colors.primary} !important;
    background-color: ${(props) => props.theme.colors.highlight};
    color: ${(props) => props.theme.colors.primary};

    i {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  ${(p) =>
    p.sm
      ? `
    padding: 10px;
  `
      : ''}
`

Tab.Group = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 5px;

  ${TabStyled} {
    margin-bottom: 0;
    box-shadow: none;
    border-left: 0;
    border-radius: 0;
    border-right: 1px solid ${(props) => props.theme.colors.primary};
  }

  ${TabStyled}:first-child {
    border-left: 1px solid ${(props) => props.theme.colors.primary};
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  ${TabStyled}:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`

export default Tab
