import React, { FC } from 'react'
import OverlayPage from 'components/dine-in/OverlayPage'
import TableNotAvailable from 'pages/dine-in/pages/TableNotAvailable'
import PaymentSuccess from 'pages/dine-in/pages/PaymentSuccess'
import ResubmitPayment from 'pages/dine-in/pages/ResubmitPayment'
import PaymentQRScreen from 'pages/dine-in/pages/PaymentQRScreen'
import CreditCardFailedScreen from 'pages/dine-in/pages/CreditCardFailedScreen'
import KplusNumberScreen from 'pages/dine-in/pages/KplusNumberScreen'
import { useAppDispatch } from 'store'
import { buffetSelector, resetBuffetScreen, setBuffetScreen } from 'store/slices/buffetSlice'
import { useCancelCheckoutMutation, useInitiateCheckoutMutation } from 'graphQL/dinein/generate/operations'
import { dineInClient } from 'variables/services'
import { useSelector } from 'react-redux'
import { SubPageName } from 'enums/buffet'
import { useHistory } from 'react-router-dom'
import StaffPaymentScreen from 'pages/dine-in/pages/StaffPaymentScreen'
import ModalConfirmWantToChangePaymentMethod from 'components/modals/ModalConfirmWantToChangePaymentMethod'
import useModal from 'hooks/useModal'

export enum ScreenEnum {
  None = '',
  TableIsNotAvailable = 'TableIsNotAvailable',
  PaymentSuccess = 'PaymentSuccess',
  ResubmitPayment = 'ResubmitPayment',
  PaymentQR = 'PaymentQR',
  CreditCardFailed = 'CreditCardFailed',
  KplusNumberScreen = 'KplusNumberScreen',
  StaffInitiateCheckout = 'StaffInitiateCheckout',
}

type Props = {
  screenType: ScreenEnum
  isProcessing: boolean
}

const BuffetOverlayPage: FC<Props> = ({ screenType, isProcessing }) => {
  const buffet = useSelector(buffetSelector)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const modalConfirmWantToChangePaymentMethod = useModal()

  const [initiateCheckout] = useInitiateCheckoutMutation({
    client: dineInClient,
  })

  const [cancelCheckout] = useCancelCheckoutMutation({
    client: dineInClient,
    variables: {
      orderID: buffet.table.orderID,
    },
  })

  const onChangePaymentMethod = async () => {
    dispatch(setBuffetScreen({ type: buffet.screen.type, isProcessing: true }))
    await cancelCheckout()
    await initiateCheckout({
      variables: {
        orderID: buffet.table.orderID,
        override: true,
      },
      onCompleted: () => {
        dispatch(resetBuffetScreen())
        modalConfirmWantToChangePaymentMethod.setOpen(false)
        history.push(SubPageName.SelectPaymentMethod)
      },
    })
  }

  return (
    <>
      <OverlayPage animation isShow={screenType !== ScreenEnum.None}>
        {screenType === ScreenEnum.TableIsNotAvailable && <TableNotAvailable />}
        {screenType === ScreenEnum.PaymentSuccess && <PaymentSuccess />}
        {screenType === ScreenEnum.ResubmitPayment && <ResubmitPayment onResubmitPayment={onChangePaymentMethod} />}
        {screenType === ScreenEnum.PaymentQR && (
          <PaymentQRScreen onChangePaymentMethod={() => modalConfirmWantToChangePaymentMethod.setOpen(true)} />
        )}
        {screenType === ScreenEnum.CreditCardFailed && (
          <CreditCardFailedScreen onChangePaymentMethod={onChangePaymentMethod} isProcessing={isProcessing} />
        )}
        {screenType === ScreenEnum.KplusNumberScreen && (
          <KplusNumberScreen onChangePaymentMethod={() => modalConfirmWantToChangePaymentMethod.setOpen(true)} />
        )}
        {screenType === ScreenEnum.StaffInitiateCheckout && <StaffPaymentScreen />}
      </OverlayPage>

      <ModalConfirmWantToChangePaymentMethod
        isOpen={modalConfirmWantToChangePaymentMethod.open}
        isProcessing={isProcessing}
        onRequestClose={() => modalConfirmWantToChangePaymentMethod.setOpen(false)}
        onClickConfirm={onChangePaymentMethod}
      />
    </>
  )
}

export default BuffetOverlayPage
