import { getSubDomains, parseLiffFromRedirectUrl } from 'helpers/url'
import { isDevelopment, isProduction } from 'variables/environment'
import { BrandConfigType } from 'types'
import Color from 'color'

// Domain names.
export const BRANDS = {}

export const BRANDCHOICES = isProduction ? [] : []
export const BRANDCHOICES_LIST_SCHEDULED_CONTENT = isProduction ? [] : []

const getMappingModuleNameBySubDomain = (subdomain) => {
  switch (subdomain) {
    case 'hatokitchen':
      return 'hato-kitchen'
    case 'gon-delivery':
    case 'passion-kitchen':
    case 'gon-gang-cloud-kitchen':
      return 'passion-kitchen'
    case 'lotushyper':
      return 'lotusmart'
    case 'rebornhealthyfood':
      return 'reborn-healthy'
    case 'single-restaurant-integ-test':
      return 'singlerestaurant'
    case 'baimiangdelivery':
      return 'baimiang'
    case 'magurogroup':
      return 'maguro'
    default:
      return subdomain
  }
}

export const getGonBrandChoices = async () => {
  let arrayPromiseGetBrands: any = []
  const arraySubdomains = ['gon-dine-in', 'gon-express']
  arraySubdomains.forEach((subdomain) => {
    arrayPromiseGetBrands.push(
      import(/* webpackChunkName: "print" */ `./${getMappingModuleNameBySubDomain(subdomain)}`)
    )
  })
  const results = await Promise.all(arrayPromiseGetBrands)
  return results.map((module) => module.default)
}

export const getGonBrandChoicesListScheduledContent = async () => {
  let arrayPromiseGetBrands: any = []
  const arraySubdomains = ['gon-dine-in', 'gon-express']
  arraySubdomains.forEach((subdomain) => {
    arrayPromiseGetBrands.push(
      import(/* webpackChunkName: "print" */ `./${getMappingModuleNameBySubDomain(subdomain)}`)
    )
  })
  const results = await Promise.all(arrayPromiseGetBrands)
  return results.map((module) => module.default)
}

export const getBrandBySubDomain = (subdomain) => {
  let _subdomain = subdomain ? subdomain : getSubDomains()
  if (isDevelopment) {
    _subdomain = process.env.REACT_APP_DEV_SUBDOMAIN
  }
  return import(/* webpackChunkName: "print" */ `./${getMappingModuleNameBySubDomain(_subdomain)}`)
}

export const getLocationSettingByFixedLocationIDFromURL = (brand) => {
  const params = parseLiffFromRedirectUrl()
  const fixLocationID = params.get('location') ?? ''

  if (fixLocationID && brand?.locations) {
    return brand.locations.find((location) => `${location.locationID}` === fixLocationID)
  } else {
    return null
  }
}

export enum THEMES_UI {
  GON = 'gon',
  GENERIC = 'generic',
}

export const BrandTypeEnum = {
  SINGLE_BRAND: 'single-brand',
  MULTI_BRAND: 'multi-brand',
  THOUSAND_PRODUCTS: 'thousand-products',
  MALL: 'mall',
  MALL_WITHOUT_GROCERY: 'mall-without-grocery',
}

export const isLotusBrand = (slug) => {
  const slugLotus = ['lotus-mart', 'lotuspreorder', 'lotusgofresh']
  return slugLotus.includes(slug)
}

const brandDefaultFeatures: BrandConfigType['features'] = {
  dineIn: {
    contactStaffToMakePayment: false,
  },
}

export const getBrandDefaultFeatures = (features?: BrandConfigType['features']): BrandConfigType['features'] => {
  return {
    hatostore: {
      ...brandDefaultFeatures.hatostore,
      ...features?.hatostore,
    },
    hatoheart: {
      ...brandDefaultFeatures.hatoheart,
      ...features?.hatoheart,
    },
    dineIn: {
      ...brandDefaultFeatures.dineIn,
      ...features?.dineIn,
    },
  }
}

type ThemeColors = Omit<BrandConfigType['theme']['colors'], 'primaryHover' | 'secondHover'>
export const createTheme = (ui: THEMES_UI, colors: ThemeColors): BrandConfigType['theme'] => {
  const { primary, secondary } = colors

  return {
    ui,
    colors: {
      ...colors,
      primaryHover: Color(primary).lighten(0.2).hex(),
      secondHover: Color(secondary).lighten(0.2).hex(),
    },
  }
}
