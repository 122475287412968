import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Modal, Fonts } from 'components/base-ui'
import { THEMES_UI } from 'brands'
import { useTranslation } from 'react-i18next'

const ModalOnlyMemberMenu = ({ vendorImageResizableURLForModalMemberMenu, hideRegisterButton = false, ...rest }) => {
  const { t } = useTranslation()
  return (
    <Modal hideCloseButton={false} {...rest}>
      <ModalContainer>
        {rest.themeUI === THEMES_UI.GENERIC && vendorImageResizableURLForModalMemberMenu && (
          <img
            alt="icon-membership-vendor"
            src={vendorImageResizableURLForModalMemberMenu
              .replace('{width}', '130')
              .replace('{height}', '130_fmt-jpg_fit-contain')}
            style={{ marginTop: '15px', marginBottom: '20px' }}
          />
        )}

        {rest.themeUI === THEMES_UI.GON && (
          <img
            alt="icon-gon-gang-club"
            src={`${process.env.PUBLIC_URL}/assets/gon/icon-gon-only-member.png`}
            width="200px"
            style={{ marginTop: '-30px', marginBottom: '-30px' }}
          />
        )}

        <Fonts.H2 style={{ marginBottom: '18px' }}>{t('eligibleTitleMemberReuired')}</Fonts.H2>
        <Fonts.Base style={{ marginBottom: '18px' }}>{t('eligibleTextMemberReuired')}</Fonts.Base>
        {!hideRegisterButton && (
          <>
            {rest.themeUI === THEMES_UI.GON ? (
              <a href="https://liff.line.me/1578956419-k3yNBDE8">
                <Button minWidth="160px" title md3>
                  {t('btnRegister')}
                </Button>
              </a>
            ) : (
              <a href="/hato-heart">
                <Button minWidth="160px" title md3>
                  {t('btnRegister')}
                </Button>
              </a>
            )}
          </>
        )}
      </ModalContainer>
    </Modal>
  )
}

ModalOnlyMemberMenu.propTypes = {
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool,
  phone: PropTypes.string,
}

ModalOnlyMemberMenu.defaultProps = {
  phone: '',
}

export default ModalOnlyMemberMenu

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 330px;
  text-align: center;
`
