import { useContext, useState } from 'react'
import { LoadingPageContext } from 'contexts/LoadingPageProvider'

interface useLoadingPageProps {
  initialIsLoadingPage: boolean
}

const useLoadingPage = ({ initialIsLoadingPage }: useLoadingPageProps) => {
  const { isLoadingPage: isGlobalLoadingPage, setIsLoadingPage: setIsGlobalLoadingPage } =
    useContext(LoadingPageContext)
  const [isLocalLoadingPage, setIsLocalLoadingPage] = useState<boolean>(initialIsLoadingPage)

  function handleChangeLoadingPage(isLoading: boolean) {
    setIsLocalLoadingPage(isLoading)
    isGlobalLoadingPage && !isLoading && setIsGlobalLoadingPage(false)
  }

  return {
    isLoadingPage: isLocalLoadingPage,
    setIsLoadingPage: handleChangeLoadingPage,
  }
}

export default useLoadingPage
