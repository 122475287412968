import { LANGUAGES } from 'helpers/language'
import { ProductLimitQuantityModeEnum } from 'enums/product'
import { t } from 'i18next'
import { ListProductsV2Query } from 'graphQL/hatostore/generate/operations'
import {
  ProductQuery,
  ListProductsQuery,
  PromotionStrategyTypeEnum,
  PromotionRewardTypeEnum,
  ProductMemberTierRankConditionsEnum,
} from 'graphQL/dinein/generate/operations'

interface LineItem {
  productID: number
  quantity: number
}

interface Cart {
  lineItems: LineItem[]
}

interface GetStrMemberCinditionParams {
  conditions: ProductMemberTierRankConditionsEnum[]
  lang: LANGUAGES
  membershipProgram: {
    nameEn: string
    nameTh: string
    tiers: {
      rank: number
      refName?: string
    }[]
  }
}

export const getStrMemberCondition = ({ conditions, lang, membershipProgram }: GetStrMemberCinditionParams) => {
  let str = ''
  let countMemberRequired = 0

  if (!membershipProgram) {
    return ''
  }

  conditions.forEach((condition, conditionIndex) => {
    if (condition === ProductMemberTierRankConditionsEnum.NotEligible) {
      return false
    }

    let tier = lang === LANGUAGES.EN ? `${membershipProgram.nameEn} ` : `${membershipProgram.nameTh} `
    const targetTier = membershipProgram.tiers.find((tier) => tier.rank === conditionIndex)
    tier += targetTier?.refName ?? ''

    if (
      condition === ProductMemberTierRankConditionsEnum.Eligible ||
      condition === ProductMemberTierRankConditionsEnum.BirthMonth
    ) {
      if (countMemberRequired === 0) {
        str += t('eligibleDetailsMemberFirstGeneric', { tier })
      } else {
        str += t('eligibleDetailsMemberFollowingGeneric', { tier })
      }
      countMemberRequired++
    }
  })

  return str
}

interface GetProductInStockQuantityParams {
  product: {
    locationProducts: { inStockQuantity: number }[]
  }
}
export const getProductInStockQuantity = ({ product }: GetProductInStockQuantityParams) => {
  return product?.locationProducts?.length > 0 ? product.locationProducts[0].inStockQuantity : null
}

interface GetProductLimitQuantityModeParams {
  product: {
    maxQuantity: number
    locationProducts: { inStockQuantity: number }[]
  }
}

export const getProductLimitQuantityMode = ({ product }: GetProductLimitQuantityModeParams) => {
  let mode = ProductLimitQuantityModeEnum.none

  if (!product) {
    return mode
  }

  const maxQuantity = product && product.maxQuantity ? product.maxQuantity : null
  const inStockQuantity = getProductInStockQuantity({ product })
  if (maxQuantity && inStockQuantity) {
    mode = maxQuantity < inStockQuantity ? ProductLimitQuantityModeEnum.maxQuantity : ProductLimitQuantityModeEnum.stock
  } else if (maxQuantity) {
    mode = ProductLimitQuantityModeEnum.maxQuantity
  } else if (inStockQuantity) {
    mode = ProductLimitQuantityModeEnum.stock
  }

  return mode
}

interface IsValidProductMaxQuantityToAddToCartParams {
  product: {
    id: number
    maxQuantity: number
    locationProducts: { inStockQuantity: number }[]
  }
  quantity?: number
  cart: Cart
}

export const isValidProductMaxQuantityToAddToCart = ({
  product,
  quantity = 0,
  cart,
}: IsValidProductMaxQuantityToAddToCartParams) => {
  if (!product) {
    return false
  }

  let limitQuantity: number | null = null
  const maxQuantity = product && product.maxQuantity ? product.maxQuantity : null
  const inStockQuantity = getProductInStockQuantity({ product })
  if (maxQuantity && inStockQuantity) {
    limitQuantity = maxQuantity < inStockQuantity ? maxQuantity : inStockQuantity
  } else if (maxQuantity) {
    limitQuantity = maxQuantity
  } else if (inStockQuantity) {
    limitQuantity = inStockQuantity
  }

  if (limitQuantity === null) {
    return true
  }

  if (cart) {
    const targetLineItem = cart.lineItems.find((lineItem) => lineItem.productID === product.id)
    if (targetLineItem?.quantity) {
      return targetLineItem.quantity + quantity < limitQuantity
    }
  }

  return quantity < limitQuantity
}

interface GetProductQuantityCanAddToCartParams {
  product: {
    id: number
    locationProducts: { inStockQuantity: number }[]
    maxQuantity: number
  }
  cart: Cart
}

export const getProductQuantityCanAddToCart = ({ product, cart }: GetProductQuantityCanAddToCartParams) => {
  if (!product) {
    return null
  }

  let limitQuantity: number | null = null
  const maxQuantity = product && product.maxQuantity ? product.maxQuantity : null
  const inStockQuantity = getProductInStockQuantity({ product })
  if (maxQuantity && inStockQuantity) {
    limitQuantity = maxQuantity < inStockQuantity ? maxQuantity : inStockQuantity
  } else if (maxQuantity) {
    limitQuantity = maxQuantity
  } else if (inStockQuantity) {
    limitQuantity = inStockQuantity
  }

  if (limitQuantity === null) {
    return null
  }

  if (cart) {
    const targetLineItem = cart.lineItems.find((lineItem) => lineItem.productID === product.id)
    if (targetLineItem?.quantity) {
      return limitQuantity - targetLineItem.quantity
    }
  }

  return limitQuantity
}

interface IsShowLabelExcludedFromProtmoionParams {
  product: {
    excludedFromPromotion?: boolean
  }
  cart: {
    adjustments: { id: number }[]
  }
}
export const isShowLabelExcludedFromProtmoion = ({ product, cart }: IsShowLabelExcludedFromProtmoionParams) => {
  const isEexcludedFromPromotion = product?.excludedFromPromotion ?? false
  return cart && cart.adjustments.length > 0 && isEexcludedFromPromotion
}

export const isValidToDisplayModalProductModifiers = (
  product:
    | ListProductsQuery['listProducts']['products'][number]
    | ProductQuery['product']
    | ListProductsV2Query['listProductsV2']['products'][number]
) => {
  const isHavePromotionProduct = product.promotionBadge === PromotionStrategyTypeEnum.BuyXGetYForZ
  return product.modifierGroups.length > 0 || !product.skipCustomNotesScreen || isHavePromotionProduct
}

interface getRewardTotalSatangsProps {
  rewardType: PromotionRewardTypeEnum
  rewardValue: number
  totalPriceSatangs: number
}

export const getRewardTotalSatangs = ({ rewardType, rewardValue, totalPriceSatangs }: getRewardTotalSatangsProps) => {
  switch (rewardType) {
    case PromotionRewardTypeEnum.FixedPercentage:
      return totalPriceSatangs - (totalPriceSatangs * rewardValue) / 100
    case PromotionRewardTypeEnum.FixedAmountSatangs:
      return totalPriceSatangs - rewardValue
  }
}
