import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import { isStaging, isProduction } from 'variables/environment'
import { HatoReactGA4GroupsEnum } from 'enums'
import { LANGUAGES } from './language'
import { LiffUser } from 'types'
import { PrivateCartAddItemType } from 'types/cart'
import { ListScheduledContentsQuery, OrderAdjustmentsQuery } from 'graphQL/dinein/generate/operations'
import { CategoryMenuType } from 'components/modals/ModalCategoryMenus'

interface Product {
  id: number
  nameTh: string
  vendor?: {
    nameTh: string
  }
  category?: {
    nameTh: string
  }
  discountSatangs: number
  priceSatangs: number
  totalPriceSatangs: number
}

interface ProductGA4 {
  item_id: number
  item_name: string
  item_brand: string
  item_category: string
  currency: string
  discount: number
  price: number
  quantity: number
}

interface Cart {
  id: number
  totalSatangs: number
  shippingTotalSatangs: number
  lineItemVatSatangs: number
  lineItems: {
    productID: number
    quantity: number
    product: Product
    totalSatangs: number
  }[]
}

const convertProductDataToItemDataGA4 = (
  product: Product | OrderAdjustmentsQuery['orderAndFPMemberCoupons']['order']['lineItems'][number]['product'],
  quantity: number
): ProductGA4 => {
  return {
    item_id: product.id,
    item_name: product.nameTh,
    item_brand: product.vendor && product.vendor?.nameTh ? product.vendor?.nameTh : '',
    item_category: product.category && product.category.nameTh ? product.category.nameTh : '',
    currency: 'THB',
    discount: product.discountSatangs / 100,
    price: product.priceSatangs / 100,
    quantity,
  }
}

export const filterQueryStringMenu = (search: string) => {
  const urlParams = new URLSearchParams(search)

  // Filter query menu
  urlParams.delete('menu')
  const queryString = urlParams.toString()

  return queryString ? `?${queryString}` : ''
}

export const addBrandToQueryString = (search: string, brand: string) => {
  const urlParams = new URLSearchParams(search)
  urlParams.append('brand', brand)
  const queryString = urlParams.toString()

  return queryString ? `?${queryString}` : ''
}

export const getAnalyticKeys = () => {
  const PixelKey = String(process.env.REACT_APP_FACEBOOK_PIXEL_ID_HATOHUB)

  return { PixelKey }
}

interface Profile {
  userId: string
}

interface SetUserIdAnalyticsPrams {
  userProfile: Profile
}

// Analytics set userID
export const setUserIdAnalytics = ({ userProfile }: SetUserIdAnalyticsPrams) => {
  ReactGA.ga('set', { userId: userProfile.userId })
  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.set', { userId: userProfile.userId })

  // GA4
  ReactGA4.set({ user_id: userProfile.userId })
}

// Analytics set user properties language
export const setUserProppertyLanguage = ({ lang }: { lang: LANGUAGES | LiffUser['language'] }) => {
  ReactGA4.gtag('set', 'user_properties', {
    language: lang,
  })
}

interface SendAnalyticsPageViewParams {
  pathname: string
  search: string
  brand?: string
}

export const sendAnalyticsPageView = ({ pathname, search, brand }: SendAnalyticsPageViewParams) => {
  const searchString = brand
    ? filterQueryStringMenu(addBrandToQueryString(search, brand))
    : filterQueryStringMenu(search)
  const pathString = pathname + searchString

  ReactGA.ga('send', 'pageview', pathString)

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.send', 'pageview', pathString)

  // Tracking for Measurement Loaded Times.
  if (isStaging || isProduction) {
    ReactGA.ga('measurement.send', 'pageview', pathString)
  }
}

export const sendAnalyticsIsNotInDeliveryArea = () => {
  ReactGA.ga('send', 'event', 'IsNotInDeliveryArea', 'Detection')

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.send', 'event', 'IsNotInDeliveryArea', 'Detection')
}

export const sendAnalyticsStoreIsClosed = () => {
  ReactGA.ga('send', 'event', 'StoreIsClosed', 'Detection')

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.send', 'event', 'StoreIsClosed', 'Detection')
}

// View Product Details
export const sendAnalyticsProductDetails = ({ userProfile, product }: { userProfile: Profile; product: Product }) => {
  ReactGA.ga('set', { userId: userProfile.userId, currencyCode: 'THB' })
  ReactGA.plugin.execute('ec', 'setAction', 'detail', {
    id: product.id,
    name: product.nameTh,
    brand: product.vendor?.nameTh,
    price: product.totalPriceSatangs / 100,
  })
  ReactGA.ga('ec:addProduct', {
    id: product.id,
    name: product.nameTh,
    brand: product.vendor?.nameTh,
    category: product.category && product.category.nameTh ? product.category.nameTh : '',
    price: product.totalPriceSatangs / 100,
  })
  ReactGA.ga('send', 'event', 'UX', 'click', 'view product detail')

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.set', {
    userId: userProfile.userId,
    currencyCode: 'THB',
  })
  ReactGA.plugin.execute('hatohub.ec', 'setAction', 'detail', {
    id: product.id,
    name: product.nameTh,
    brand: product.vendor?.nameTh,
    price: product.totalPriceSatangs / 100,
  })
  ReactGA.ga('hatohub.ec:addProduct', {
    id: product.id,
    name: product.nameTh,
    brand: product.vendor?.nameTh,
    category: product.category && product.category.nameTh ? product.category.nameTh : '',
    price: product.totalPriceSatangs / 100,
  })
  ReactGA.ga('hatohub.send', 'event', 'UX', 'click', 'view product detail')

  // GA4
  const items: ProductGA4[] = []
  items.push(convertProductDataToItemDataGA4(product, 1))
  ReactGA4.gtag('event', 'view_item', {
    currency: 'THB',
    value: product.totalPriceSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

// Add to Cart
export const sendAnalyticsAddToCart = ({
  userProfile,
  product,
  quantity,
}: {
  userProfile: Profile
  product: Product
  quantity: number
}) => {
  ReactGA.ga('set', { userId: userProfile.userId, currencyCode: 'THB' })
  ReactGA.plugin.execute('ec', 'setAction', 'add', {
    id: product.id,
    name: product.nameTh,
    brand: product.vendor?.nameTh,
    price: product.totalPriceSatangs / 100,
  })
  ReactGA.ga('ec:addProduct', {
    id: product.id,
    name: product.nameTh,
    brand: product.vendor?.nameTh,
    category: product.category && product.category.nameTh ? product.category.nameTh : '',
    price: product.totalPriceSatangs / 100,
    quantity,
  })
  ReactGA.ga('send', 'event', 'UX', 'click', 'add to cart')

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.set', {
    userId: userProfile.userId,
    currencyCode: 'THB',
  })
  ReactGA.plugin.execute('hatohub.ec', 'setAction', 'add', {
    id: product.id,
    name: product.nameTh,
    brand: product.vendor?.nameTh,
    price: product.totalPriceSatangs / 100,
  })
  ReactGA.ga('hatohub.ec:addProduct', {
    id: product.id,
    name: product.nameTh,
    brand: product.vendor?.nameTh,
    category: product.category && product.category.nameTh ? product.category.nameTh : '',
    price: product.totalPriceSatangs / 100,
    quantity,
  })
  ReactGA.ga('hatohub.send', 'event', 'UX', 'click', 'add to cart')

  // GA4
  const items: ProductGA4[] = []
  items.push(convertProductDataToItemDataGA4(product, quantity))
  ReactGA4.gtag('event', 'add_to_cart', {
    currency: 'THB',
    value: product.totalPriceSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

export const sendAnalyticsRemoveFromCart = ({ product, quantity }: { product: Product; quantity: number }) => {
  // GA4
  const items: ProductGA4[] = []
  items.push(convertProductDataToItemDataGA4(product, quantity))
  ReactGA4.gtag('event', 'remove_from_cart', {
    currency: 'THB',
    value: product.totalPriceSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

interface SendAnalyticsSelectPromotionParams {
  promotionID: string
  cart: {
    lineItems: { product: Product; quantity: number }[]
  }
}

export const sendAnalyticsSelectPromotion = ({ promotionID, cart }: SendAnalyticsSelectPromotionParams) => {
  // GA4
  const items: ProductGA4[] = []
  for (let i = 0; i < cart.lineItems.length; i++) {
    const lineItem = cart.lineItems[i]
    const product = lineItem.product
    items.push(convertProductDataToItemDataGA4(product, lineItem.quantity))
  }
  ReactGA4.gtag('event', 'select_promotion', {
    promotion_id: promotionID,
    items,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

interface SendAnalyticsAddToCartFromUpsaleParams {
  userProfile: Profile
  product: Product
  position: number
}
// Click Add to Cart from Upsale Component.
export const sendAnalyticsAddToCartFromUpsale = ({
  userProfile,
  product,
  position,
}: SendAnalyticsAddToCartFromUpsaleParams) => {
  ReactGA.ga('set', { userId: userProfile.userId, currencyCode: 'THB' })
  ReactGA.ga('ec:addPromo', {
    id: product.id,
    name: product.nameTh,
    brand: product.vendor?.nameTh,
    price: product.totalPriceSatangs / 100,
    creative: 'Duo Set',
    position,
  })

  ReactGA.ga('ec:setAction', 'promo_click')
  ReactGA.ga('send', 'event', 'Upsale Checkout Promotions', 'click', 'Duo Set')

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.set', {
    userId: userProfile.userId,
    currencyCode: 'THB',
  })
  ReactGA.ga('hatohub.ec:addPromo', {
    id: product.id,
    name: product.nameTh,
    brand: product.vendor?.nameTh,
    price: product.totalPriceSatangs / 100,
    creative: 'Duo Set',
    position,
  })

  ReactGA.ga('hatohub.ec:setAction', 'promo_click')
  ReactGA.ga('hatohub.send', 'event', 'Upsale Checkout Promotions', 'click', 'Duo Set')
}

interface SendAnalyticsViewCartParams {
  cart: {
    totalSatangs: number
    lineItems: { product: Product; quantity: number }[]
  }
}

export const sendAnalyticsViewCart = ({ cart }: SendAnalyticsViewCartParams) => {
  // GA4
  const items: ProductGA4[] = []
  for (let i = 0; i < cart.lineItems.length; i++) {
    const lineItem = cart.lineItems[i]
    const product = lineItem.product
    items.push(convertProductDataToItemDataGA4(product, lineItem.quantity))
  }
  ReactGA4.gtag('event', 'view_cart', {
    currency: 'THB',
    value: cart.totalSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

interface SendAnalyticsBeginCheckoutParmas {
  cart: {
    totalSatangs: number
    lineItems: { product: Product; quantity: number }[]
  }
}

export const sendAnalyticsBeginCheckout = ({ cart }: SendAnalyticsBeginCheckoutParmas) => {
  // GA4
  const items: ProductGA4[] = []
  for (let i = 0; i < cart.lineItems.length; i++) {
    const lineItem = cart.lineItems[i]
    const product = lineItem.product
    items.push(convertProductDataToItemDataGA4(product, lineItem.quantity))
  }
  ReactGA4.gtag('event', 'begin_checkout', {
    currency: 'THB',
    value: cart.totalSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

interface SendAnalyticsAddPaymentInfoParams {
  userProfile: Profile
  paymentInfo: { type: string }
  cart: Cart
}

// Add Payment Info ( when selected and confirm payment click )
export const sendAnalyticsAddPaymentInfo = ({ userProfile, paymentInfo, cart }: SendAnalyticsAddPaymentInfoParams) => {
  ReactGA.ga('set', { userId: userProfile.userId, currencyCode: 'THB' })
  ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
    step: 3,
    option: paymentInfo.type,
  })

  ReactGA.ga('send', 'event', 'Checkout', 'add payment info')

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.set', {
    userId: userProfile.userId,
    currencyCode: 'THB',
  })
  ReactGA.plugin.execute('hatohub.ec', 'setAction', 'checkout', {
    step: 3,
    option: paymentInfo.type,
  })

  ReactGA.ga('hatohub.send', 'event', 'Checkout', 'add payment info')

  // GA4
  const items: ProductGA4[] = []
  for (let i = 0; i < cart.lineItems.length; i++) {
    const lineItem = cart.lineItems[i]
    const product = lineItem.product
    items.push(convertProductDataToItemDataGA4(product, lineItem.quantity))
  }
  ReactGA4.gtag('event', 'add_payment_info', {
    currency: 'THB',
    value: cart.totalSatangs / 100,
    payment_type: paymentInfo.type,
    items,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

export const sendAnalyticsAddShippingInfo = ({ shippingTier, cart }: { shippingTier: string; cart: Cart }) => {
  // GA4
  const items: ProductGA4[] = []
  for (let i = 0; i < cart.lineItems.length; i++) {
    const lineItem = cart.lineItems[i]
    const product = lineItem.product
    items.push(convertProductDataToItemDataGA4(product, lineItem.quantity))
  }
  ReactGA4.gtag('event', 'add_shipping_info', {
    currency: 'THB',
    value: cart.shippingTotalSatangs / 100,
    shipping_tier: shippingTier,
    items,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

// Purchase
export const sendAnalyticsPurchase = ({ userProfile, cart }: { userProfile: Profile; cart: Cart }) => {
  ReactGA.ga('set', { userId: userProfile.userId, currencyCode: 'THB' })
  ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
    list: cart.lineItems,
    price: cart.totalSatangs / 100,
  })

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.set', {
    userId: userProfile.userId,
    currencyCode: 'THB',
  })
  ReactGA.plugin.execute('hatohub.ec', 'setAction', 'purchase', {
    list: cart.lineItems,
    price: cart.totalSatangs / 100,
  })

  for (var i = 0; i < cart.lineItems.length; i++) {
    var lineItem = cart.lineItems[i]
    ReactGA.ga('ec:addProduct', {
      id: lineItem.productID,
      name: lineItem.product.nameTh,
      brand: lineItem.product.vendor?.nameTh,
      category: lineItem.product.category && lineItem.product.category.nameTh ? lineItem.product.category.nameTh : '',
      price: lineItem.totalSatangs / 100,
      quantity: lineItem.quantity,
    })

    // Tracking Hatohub for all brands
    ReactGA.ga('hatohub.ec:addProduct', {
      id: lineItem.productID,
      name: lineItem.product.nameTh,
      brand: lineItem.product.vendor?.nameTh,
      category: lineItem.product.category && lineItem.product.category.nameTh ? lineItem.product.category.nameTh : '',
      price: lineItem.totalSatangs / 100,
      quantity: lineItem.quantity,
    })
  }

  ReactGA.ga('ec:setAction', 'purchase', {
    // Transaction details are provided in an actionFieldObject.
    id: cart.id, // (Required) Transaction id (string).
    affiliation: 'LINE LIFF', // Affiliation (string).
    revenue: cart.totalSatangs / 100, // Revenue (number).
    // 'tax': '2.85',                               // Tax (number).
    shipping: cart.shippingTotalSatangs / 100, // Shipping (number).
    // 'coupon': 'SUMMER2013'                       // Transaction coupon (string).
  })

  ReactGA.ga('send', 'event', 'Checkout', 'purchase')

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.ec:setAction', 'purchase', {
    // Transaction details are provided in an actionFieldObject.
    id: cart.id, // (Required) Transaction id (string).
    affiliation: 'LINE LIFF', // Affiliation (string).
    revenue: cart.totalSatangs / 100, // Revenue (number).
    // 'tax': '2.85',                               // Tax (number).
    shipping: cart.shippingTotalSatangs / 100, // Shipping (number).
    // 'coupon': 'SUMMER2013'                       // Transaction coupon (string).
  })

  ReactGA.ga('hatohub.send', 'event', 'Checkout', 'purchase')

  // GA4
  const items: ProductGA4[] = []
  for (let i = 0; i < cart.lineItems.length; i++) {
    const lineItem = cart.lineItems[i]
    const product = lineItem.product
    items.push(convertProductDataToItemDataGA4(product, lineItem.quantity))
  }
  ReactGA4.gtag('event', 'purchase', {
    currency: 'THB',
    transaction_id: cart.id,
    value: cart.totalSatangs / 100,
    shipping: cart.shippingTotalSatangs / 100,
    tax: cart.lineItemVatSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

export const sendAnalyticsSearchProducts = ({ search_term }: { search_term: string }) => {
  // GA4
  ReactGA4.gtag('event', 'search', {
    search_term,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

interface SendAnalyticsViewItemListParams {
  search_term: string
  categoryName: string
  products: Product[]
}

export const sendAnalyticsViewItemList = ({ search_term, categoryName, products }: SendAnalyticsViewItemListParams) => {
  // GA4
  const items: ProductGA4[] = []
  for (let i = 0; i < products.length; i++) {
    const product = products[i]
    items.push(convertProductDataToItemDataGA4(product, 1))
  }
  ReactGA4.gtag('event', 'view_item_list', {
    item_list_id: categoryName ? `category_${categoryName}` : '',
    item_list_name: search_term,
    items,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

export const sendAnalyticsClickToChangeAddress = ({
  page,
  is_first_time_customer,
}: {
  page: string
  is_first_time_customer: boolean
}) => {
  ReactGA4.gtag('event', 'Click', {
    click_target: 'Menu_Change_address',
    page, // HatoPageEnum
    is_first_time_customer,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

export const sendAnalyticsClickToChangeBranch = ({ page }: { page: string }) => {
  ReactGA4.gtag('event', 'Click', {
    click_target: 'Menu_Change_branch',
    page, // HatoPageEnum
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

export const sendAnalyticsSelectBranch = ({ branch_id, brand_name }: { branch_id: number; brand_name: string }) => {
  ReactGA4.gtag('event', 'select_branch', {
    branch_id,
    brand_name,
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

export const sendAnalyticsClickToSearchCategories = () => {
  ReactGA4.gtag('event', 'Click', {
    click_target: 'Search_Categories',
    send_to: HatoReactGA4GroupsEnum.hato_store,
  })
}

// Call login
export const sendAnalyticsLineLoginInitiated = ({ action }: { action: string }) => {
  ReactGA.ga('send', 'event', 'Login and Signup', 'LoginInitiated', 'LINE', {
    action,
    dimension3: 'LINE', //Dimension for Login/Signup Source
    metric6: '1', //Metric for Login Submits
  })

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.send', 'event', 'Login and Signup', 'LoginInitiated', 'LINE', {
    action,
    dimension3: 'LINE', //Dimension for Login/Signup Source
    metric6: '1', //Metric for Login Submits
  })
}

// Login Completed
export const sendAnalyticsLineLoginCompleted = ({ userProfile }: { userProfile: Profile }) => {
  ReactGA.ga('set', { '&uid': userProfile.userId })
  ReactGA.ga('send', 'event', 'Login and Signup', 'Login Success', 'LINE', {
    dimension1: userProfile.userId, //For seeing User-ID (If Privacy Policy Supports)
    dimension2: 'Yes', //Cohort for Registered User
    dimension3: 'LINE', //Dimension for Login/Signup Source
    metric4: '1', //Metric for Logins
  })

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.set', { '&uid': userProfile.userId })
  ReactGA.ga('hatohub.send', 'event', 'Login and Signup', 'Login Success', 'LINE', {
    dimension1: userProfile.userId, //For seeing User-ID (If Privacy Policy Supports)
    dimension2: 'Yes', //Cohort for Registered User
    dimension3: 'LINE', //Dimension for Login/Signup Source
    metric4: '1', //Metric for Logins
  })
}

// Add Friend
export const sendAnalyticsIsFriendAfterLineLogin = ({ userProfile }: { userProfile: Profile }) => {
  ReactGA.ga('set', { '&uid': userProfile.userId })
  ReactGA.ga('send', 'event', 'LINE Friend', 'IsFriendAfterLineLogin', 'LINE', {
    dimension1: userProfile.userId, //For seeing User-ID (If Privacy Policy Supports)
    dimension2: 'Yes', //Cohort for Registered User
    dimension3: 'LINE', //Dimension for Login/Signup Source
    metric4: '1', //Metric for Add Friend
  })

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.set', { '&uid': userProfile.userId })
  ReactGA.ga('hatohub.send', 'event', 'LINE Friend', 'IsFriendAfterLineLogin', 'LINE', {
    dimension1: userProfile.userId, //For seeing User-ID (If Privacy Policy Supports)
    dimension2: 'Yes', //Cohort for Registered User
    dimension3: 'LINE', //Dimension for Login/Signup Source
    metric4: '1', //Metric for Add Friend
  })
}

// Cancel Friend
export const sendAnalyticsIsNotFriendAfterLineLogin = ({ userProfile }: { userProfile: Profile }) => {
  ReactGA.ga('set', { '&uid': userProfile.userId })
  ReactGA.ga('send', 'event', 'LINE Friend', 'IsNotFriendAfterLineLogin', 'LINE', {
    dimension1: userProfile.userId, //For seeing User-ID (If Privacy Policy Supports)
    dimension3: 'LINE', //Dimension for Login/Signup Source
    metric5: '1', //Metric for Add Friend
  })

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.set', { '&uid': userProfile.userId })
  ReactGA.ga('hatohub.send', 'event', 'LINE Friend', 'IsNotFriendAfterLineLogin', 'LINE', {
    dimension1: userProfile.userId, //For seeing User-ID (If Privacy Policy Supports)
    dimension3: 'LINE', //Dimension for Login/Signup Source
    metric5: '1', //Metric for Add Friend
  })
}

// Already Friend
export const sendAnalyticsLineFriendExisting = ({ userProfile }: { userProfile: Profile }) => {
  ReactGA.ga('set', { '&uid': userProfile.userId })
  ReactGA.ga('send', 'event', 'LINE Friend', 'LineFriendExisting', 'LINE', {
    dimension1: userProfile.userId, //For seeing User-ID (If Privacy Policy Supports)
    dimension3: 'LINE', //Dimension for Login/Signup Source
    metric6: '1', //Metric for Add Friend
  })

  // Tracking Hatohub for all brands
  ReactGA.ga('hatohub.set', { '&uid': userProfile.userId })
  ReactGA.ga('hatohub.send', 'event', 'LINE Friend', 'LineFriendExisting', 'LINE', {
    dimension1: userProfile.userId, //For seeing User-ID (If Privacy Policy Supports)
    dimension3: 'LINE', //Dimension for Login/Signup Source
    metric6: '1', //Metric for Add Friend
  })
}

// Analytics for Campaigns
// 1. View campaign details. ( Like view product details )
// 2. Click to open modal to BurnPoint ( Like add product to cart )
// 3. Click burnPoint and burnPoint successfully ( Like purchase product )

interface Campaign {
  id: number
  nameTh: string
  points: number
  vendor: {
    nameTh: string
  }
}

export const sendAnalyticsViewCampaignDetails = ({
  userProfile,
  campaign,
}: {
  userProfile: Profile
  campaign: Campaign
}) => {
  // Test Tracking only on Staging
  if (isStaging) {
    ReactGA.ga('set', { userId: userProfile.userId, currencyCode: 'Point' })
    ReactGA.plugin.execute('ec', 'setAction', 'detail', {
      id: campaign.id,
      name: campaign.nameTh,
      price: campaign.points,
    })
    ReactGA.ga('ec:addProduct', {
      id: campaign.id,
      name: campaign.nameTh,
      brand: campaign.vendor.nameTh,
      category: campaign.vendor && campaign.vendor.nameTh ? campaign.vendor.nameTh : '',
      price: campaign.points,
    })
    ReactGA.ga('send', 'event', 'UX', 'click', 'view campaign detail')
  }
}

export const sendAnalyticsAddCampaignToCart = ({
  userProfile,
  campaign,
  quantity,
}: {
  userProfile: Profile
  campaign: Campaign
  quantity: number
}) => {
  // Test Tracking only on Staging
  if (isStaging) {
    ReactGA.ga('set', { userId: userProfile.userId, currencyCode: 'Point' })
    ReactGA.plugin.execute('ec', 'setAction', 'add', {
      id: campaign.id,
      name: campaign.nameTh,
      price: campaign.points,
    })
    ReactGA.ga('ec:addProduct', {
      id: campaign.id,
      name: campaign.nameTh,
      brand: campaign.vendor.nameTh,
      category: campaign.vendor && campaign.vendor.nameTh ? campaign.vendor.nameTh : '',
      price: campaign.points,
      quantity,
    })
    ReactGA.ga('send', 'event', 'UX', 'click', 'add campaign to cart')
  }
}

// Purchase
export const sendAnalyticsBurnPoint = ({
  userProfile,
  campaign,
  quantity,
}: {
  userProfile: Profile
  campaign: Campaign
  quantity: number
}) => {
  // Test Tracking only on Staging
  if (isStaging) {
    ReactGA.ga('set', { userId: userProfile.userId, currencyCode: 'Point' })
    ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
      list: [campaign],
      price: campaign.points * quantity,
    })

    ReactGA.ga('ec:addProduct', {
      id: campaign.id,
      name: campaign.nameTh,
      brand: campaign.vendor.nameTh,
      category: campaign.vendor && campaign.vendor.nameTh ? campaign.vendor.nameTh : '',
      price: campaign.points * quantity,
      quantity,
    })

    ReactGA.ga('ec:setAction', 'purchase', {
      // Transaction details are provided in an actionFieldObject.
      id: btoa(`${+new Date()}${campaign.id}`), // (Required) Transaction id (string).
      affiliation: 'LINE LIFF', // Affiliation (string).
      revenue: campaign.points * quantity, // Revenue (number).
      // 'tax': '2.85',                               // Tax (number).
      // shipping: cart.shippingTotalSatangs / 100, // Shipping (number).
      // 'coupon': 'SUMMER2013'                       // Transaction coupon (string).
    })

    ReactGA.ga('send', 'event', 'Checkout', 'purchase (BurnPoint)')
  }
}

// Measurement Load Time Page ( Manual )
export const sendAnalyticsMatricLoadTimePageFirstCustomer = (milliseconds: number) => {
  if (isStaging || isProduction) {
    ReactGA.ga('measurement.set', 'metric1', milliseconds)
    ReactGA.ga('send', 'event', 'Measurement', 'Load Time Initial First Customer')
  }
}

export const sendAnalyticsMatricLoadTimePageFullLoad = (milliseconds: number) => {
  if (isStaging || isProduction) {
    ReactGA.ga('measurement.set', 'metric2', milliseconds)
    ReactGA.ga('send', 'event', 'Measurement', 'Load Time Initial Page')
  }
}

// DineIn
export const sendAnalyticsDineInViewItem = ({ product }: { product: Product }) => {
  // GA4
  const items: ProductGA4[] = []
  items.push(convertProductDataToItemDataGA4(product, 1))
  ReactGA4.gtag('event', 'view_item', {
    currency: 'THB',
    value: product.totalPriceSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInAddToCart = ({ product, quantity }: { product: Product; quantity: number }) => {
  // GA4
  const items: ProductGA4[] = []
  items.push(convertProductDataToItemDataGA4(product, quantity))
  ReactGA4.gtag('event', 'add_to_cart', {
    currency: 'THB',
    value: product.totalPriceSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInRemoveFromCart = ({ product }: { product: Product }) => {
  // GA4
  const items: ProductGA4[] = []
  items.push(convertProductDataToItemDataGA4(product, 1))
  ReactGA4.gtag('event', 'remove_from_cart', {
    currency: 'THB',
    value: product.totalPriceSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInAddPaymentInfo = ({ paymentInfo }: { paymentInfo: { type: string } }) => {
  // GA4
  ReactGA4.gtag('event', 'add_payment_info', {
    payment_type: paymentInfo.type,
    items: [],
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInViewCart = ({
  currentPrivateCart,
}: {
  currentPrivateCart?: PrivateCartAddItemType
}) => {
  // GA4
  const items: ProductGA4[] = []
  let value = 0
  if (currentPrivateCart && currentPrivateCart?.products.length > 0) {
    for (let i = 0; i < currentPrivateCart.products.length; i++) {
      const lineItem = currentPrivateCart.products[i]
      const product = lineItem.item
      items.push(convertProductDataToItemDataGA4(product, lineItem.quantity))
      value += product.totalPriceSatangs * lineItem.quantity
    }
  }
  ReactGA4.gtag('event', 'view_cart', {
    currency: 'THB',
    value: value / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInBeginCheckout = ({
  order,
}: {
  order: OrderAdjustmentsQuery['orderAndFPMemberCoupons']['order']
}) => {
  // GA4
  const items: ProductGA4[] = []
  for (let i = 0; i < order.lineItems.length; i++) {
    const lineItem = order.lineItems[i]
    const product = lineItem.product
    items.push(convertProductDataToItemDataGA4(product, lineItem.quantity))
  }
  ReactGA4.gtag('event', 'begin_checkout', {
    currency: 'THB',
    value: order.totalSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInPurchase = ({
  order,
}: {
  order: OrderAdjustmentsQuery['orderAndFPMemberCoupons']['order']
}) => {
  // GA4
  const items: ProductGA4[] = []
  for (let i = 0; i < order.lineItems.length; i++) {
    const lineItem = order.lineItems[i]
    const product = lineItem.product
    items.push(convertProductDataToItemDataGA4(product, lineItem.quantity))
  }
  ReactGA4.gtag('event', 'purchase', {
    currency: 'THB',
    transaction_id: order.id,
    value: order.totalSatangs / 100,
    items,
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInSelectPromotion = ({ promotionID }: { promotionID: string }) => {
  // GA4
  ReactGA4.gtag('event', 'select_promotion', {
    promotion_id: promotionID,
    items: [],
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInClickBanner = ({
  banner,
  position,
}: {
  banner: ListScheduledContentsQuery['listScheduledContents'][number]
  position: number
}) => {
  // GA4
  ReactGA4.gtag('event', 'click_banner', {
    banner_id: banner.id,
    banner_title_th: banner.titleTh,
    banner_title_en: banner.titleEn,
    banner_type: banner.type,
    banner_position: position,
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInClickSearch = () => {
  // GA4
  ReactGA4.gtag('event', 'click_search', {
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInSearchProducts = ({ search_term }: { search_term: string }) => {
  // GA4
  ReactGA4.gtag('event', 'search', {
    search_term,
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInClickOpenCategory = () => {
  // GA4
  ReactGA4.gtag('event', 'click_category_menus', {
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

export const sendAnalyticsDineInSelectCategory = ({
  category,
  position,
}: {
  category: CategoryMenuType
  position: number
}) => {
  // GA4
  ReactGA4.gtag('event', 'select_category_menus', {
    category_term: category.label,
    category_position: position,
    send_to: HatoReactGA4GroupsEnum.dine_in,
  })
}

// HatoHeart GA4
export const sendAnalyticsHatoHeartSignUp = () => {
  ReactGA4.gtag('event', 'sign_up', {
    method: 'LINE',
    send_to: HatoReactGA4GroupsEnum.hato_heart,
  })
}

export const sendAnalyticsHatoHeartRedeemPoints = ({
  campaign_id,
  campaign_name,
  page,
}: {
  campaign_id: string
  campaign_name: string
  page: string
}) => {
  // page: AnalyticsRedeemPointsPageEnum
  ReactGA4.gtag('event', 'redeem_points', {
    campaign_id,
    campaign_name,
    page,
    send_to: HatoReactGA4GroupsEnum.hato_heart,
  })
}

export const sendAnalyticsHatoHeartViewCampaign = ({
  campaign_id,
  campaign_name,
}: {
  campaign_id: string
  campaign_name: string
}) => {
  ReactGA4.gtag('event', 'view_campaign', {
    campaign_id,
    campaign_name,
    send_to: HatoReactGA4GroupsEnum.hato_heart,
  })
}

export const sendAnalyticsHatoHeartViewCoupon = ({
  coupon_id,
  coupon_name,
}: {
  coupon_id: string
  coupon_name: string
}) => {
  ReactGA4.gtag('event', 'view_coupon', {
    coupon_id,
    coupon_name,
    send_to: HatoReactGA4GroupsEnum.hato_heart,
  })
}

export const sendAnalyticsHatoHeartApplyCoupon = ({
  coupon_id,
  coupon_name,
}: {
  coupon_id: string
  coupon_name: string
}) => {
  ReactGA4.gtag('event', 'apply_coupon', {
    coupon_id,
    coupon_name,
    send_to: HatoReactGA4GroupsEnum.hato_heart,
  })
}

export const sendAnalyticsHatoHeartCloseCouponDetails = ({
  coupon_id,
  coupon_name,
}: {
  coupon_id: number
  coupon_name: string
}) => {
  // status: AnalyticsCloseCouponDetailsStatusEnum
  ReactGA4.gtag('event', 'close_coupon_details', {
    coupon_id,
    coupon_name,
    send_to: HatoReactGA4GroupsEnum.hato_heart,
  })
}
