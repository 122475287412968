import { Fonts, Modal } from 'components/base-ui'
import styled from 'styled-components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
  packageName: string
}

const ModalUpgradePackage: FC<Props> = ({ isOpen, onRequestClose, packageName }) => {
  const { t } = useTranslation()
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      hideCloseButton={false}
      btnClose={t('buffet.warning.upgradePackage.button')}
    >
      <ModalContent>
        <Fonts.H2>
          {t('buffet.warning.upgradePackage.titleStart')}
          <br />
          <PackageName>{` ${packageName} `}</PackageName>
          <br />
          {t('buffet.warning.upgradePackage.titleEnd')}
        </Fonts.H2>
        <Fonts.BaseTitleRegular>{t('buffet.warning.upgradePackage.text')}</Fonts.BaseTitleRegular>
      </ModalContent>
    </Modal>
  )
}

export default ModalUpgradePackage

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 7px;
  padding-bottom: 25px;
  padding-top: 30px;
  text-align: center;
`

const PackageName = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`
