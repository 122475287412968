import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Fonts } from 'components/hato-heart/base-ui'
import { onClick } from 'helpers/fastClick'

const SwitchTabsComponent = ({
  asyncDefaultValue,
  defaultValue = '',
  options = [],
  onChange = (value) => {},
  enableFastClick = true,
  bgDark = false,
  sm,
  md,
}) => {
  const timeStamp = +new Date()
  const [selectedTab, setSelectedTab] = useState('')
  const [leftPosition, setLeftPosition] = useState(0)

  useEffect(() => {
    if (options.length > 0) {
      setSelectedTab(options[0].value)
    } else {
      setSelectedTab(defaultValue)
    }
  }, [])

  useEffect(() => {
    if (typeof asyncDefaultValue !== 'undefined') {
      setSelectedTab(asyncDefaultValue)
    }
  }, [asyncDefaultValue])

  const activeOption = useMemo(() => {
    return options.find((option) => option.value === selectedTab)
  }, [selectedTab, options])

  useEffect(() => {
    if (activeOption) {
      const domActiveTarget = document.body.querySelector(
        `#switch-${activeOption.label.replace(/\s/g, '')}-${timeStamp}`
      )
      setLeftPosition(domActiveTarget.offsetLeft)
    }
  }, [activeOption, timeStamp])

  return (
    <SwitchTabs {...{ bgDark }} sm={sm} md={md}>
      {options.map((option, i) => {
        const { value, label } = option

        const clickObj = enableFastClick
          ? {
              ...onClick(() => {
                onChange(value)
                if (asyncDefaultValue === null || asyncDefaultValue === undefined) {
                  setSelectedTab(value)
                }
              }),
            }
          : {
              onClick: () => {
                onChange(value)
                if (asyncDefaultValue === null || asyncDefaultValue === undefined) {
                  setSelectedTab(value)
                }
              },
            }

        return (
          <SwtichTab
            key={i}
            sm={sm}
            md={md}
            {...clickObj}
            data-testid={`switch-${label.replace(/\s/g, '')}`}
            id={`switch-${label.replace(/\s/g, '')}-${timeStamp}`}
            {...{ bgDark }}
          >
            {sm ? (
              <Fonts.SmallTitleRegular>{label}</Fonts.SmallTitleRegular>
            ) : md ? (
              <Fonts.BaseTitleRegular>{label}</Fonts.BaseTitleRegular>
            ) : (
              <Fonts.BaseTitleBold>{label}</Fonts.BaseTitleBold>
            )}
          </SwtichTab>
        )
      })}
      {activeOption && (
        <SwitchTabOverlay sm={sm} style={{ transform: `translate3d(${leftPosition}px, 0, 0)` }} active={true}>
          {sm ? (
            <Fonts.SmallTitleRegular>{activeOption.label}</Fonts.SmallTitleRegular>
          ) : md ? (
            <Fonts.BaseTitleRegular>{activeOption.label}</Fonts.BaseTitleRegular>
          ) : (
            <Fonts.BaseTitleBold>{activeOption.label}</Fonts.BaseTitleBold>
          )}
        </SwitchTabOverlay>
      )}
    </SwitchTabs>
  )
}

SwitchTabsComponent.propTypes = {
  asyncDefaultValue: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  bgDark: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
}

export default SwitchTabsComponent

const SwitchTabs = styled.div`
  position: relative;
  display: flex;
  border-radius: 100px;
  height: 38px;
  box-shadow: inset 0 0 0 1px #eaeaea;
  background-color: #f1f1f1;
  padding: 3px 4px;

  ${(p) =>
    p.sm
      ? `
    height: 25px;
  `
      : ''}

  ${(p) =>
    p.md
      ? `
    height: 30px;
  `
      : ''}

  ${(p) =>
    p.bgDark
      ? `
    overflow: hidden;
    box-shadow: inset 0 0 0 1px ${p.theme.colors.primary};
    background-color: ${p.theme.colors.primary};

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.3);
      pointer-events: none;
    }
  `
      : ''}
`

const SwtichTab = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 100px;
  color: ${(p) => p.theme.colors.baseGrey};

  ${Fonts.BaseTitleBold} {
    white-space: nowrap;
  }

  ${(p) =>
    p.sm
      ? `
    padding: 0 10px;
  `
      : ''}

  ${(p) =>
    p.md
      ? `
    padding: 0 15px;
  `
      : ''}

  ${(p) =>
    p.bgDark
      ? `
    color: white; 
  `
      : ''}
`

const SwitchTabOverlay = styled(SwtichTab)`
  position: absolute;
  top: 3px;
  bottom: 3px;
  transition: 0.3s;
  left: 0;
  pointer-events: none;

  ${(p) =>
    p.sm
      ? `
  `
      : ''}

  ${(p) =>
    p.active
      ? `
    color: white;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    background-color: ${p.theme.colors.primary};
  `
      : ''}
`
