// ***** THIS FILE IS GENERATED, DO NOT EDIT! *****
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T
export type InputMaybe<T> = T
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export enum ActivityLevelEnum {
  HighActivity = 'HighActivity',
  LowActivity = 'LowActivity',
  ModerateActivity = 'ModerateActivity',
}

export type Address = {
  /** Full address string (excluding province and zipcode) */
  address: Maybe<Scalars['String']>
  buildingType: Maybe<BuildingTypeEnum>
  callOnArrival: Maybe<Scalars['Boolean']>
  deliveryMethod: Maybe<DeliveryMethodEnum>
  detail: Maybe<Scalars['String']>
  /** Email of contact person */
  email: Maybe<Scalars['String']>
  /** Floors such as "30-34" */
  floors: Maybe<Scalars['String']>
  geocodedAddressEn: Maybe<Scalars['String']>
  geocodedAddressTh: Maybe<Scalars['String']>
  geocodedDistrictEn: Maybe<Scalars['String']>
  geocodedDistrictTh: Maybe<Scalars['String']>
  geocodedPostcode: Maybe<Scalars['String']>
  geocodedProvinceEn: Maybe<Scalars['String']>
  geocodedProvinceTh: Maybe<Scalars['String']>
  geocodedSubdistrictEn: Maybe<Scalars['String']>
  geocodedSubdistrictTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  lastUsed: Maybe<Scalars['Boolean']>
  latitude: Maybe<Scalars['Float']>
  /** Location or building name such as "AIA Capital Center Building" */
  locationLabel: Maybe<Scalars['String']>
  longitude: Maybe<Scalars['Float']>
  /** Fullname of contact person */
  name: Maybe<Scalars['String']>
  /** Nickname of address */
  nickname: Maybe<Scalars['String']>
  note: Maybe<Scalars['String']>
  /** Phone number of contact person */
  phone: Maybe<Scalars['String']>
  postcode: Maybe<Scalars['String']>
  province: Maybe<Scalars['String']>
  userID: Maybe<Scalars['Int']>
}

export enum AdjustmentInvalidReasonEnumType {
  CodeHasBeenUsed = 'CodeHasBeenUsed',
  CouponHasBeenUsed = 'CouponHasBeenUsed',
  Expired = 'Expired',
  InsufficientTotalDue = 'InsufficientTotalDue',
  InvalidConditions = 'InvalidConditions',
  NotCashVoucher = 'NotCashVoucher',
  NotExisted = 'NotExisted',
}

export enum AdjustmentSourceEnumType {
  ExternalCoupon = 'ExternalCoupon',
  ExternalMemberCoupon = 'ExternalMemberCoupon',
  MemberCoupon = 'MemberCoupon',
  Promotion = 'Promotion',
}

export enum AdjustmentTargetEntityTypeEnum {
  EveryItem = 'EveryItem',
  LineItem = 'LineItem',
  ProductLevel = 'ProductLevel',
  ShippingFee = 'ShippingFee',
}

export type AdjustmentType = {
  adjustmentType: Maybe<AdjustmentTypeEnumType>
  amountSatangs: Maybe<Scalars['Int']>
  externalCoupon: Maybe<ExternalCouponType>
  externalCouponID: Maybe<Scalars['Int']>
  fullAmountSatangs: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  invalidData: Maybe<CustomerAdjustmentInvalidDataType>
  invalidReason: Maybe<AdjustmentInvalidReasonEnumType>
  labelEn: Maybe<Scalars['String']>
  labelTh: Maybe<Scalars['String']>
  memberCoupon: Maybe<MemberCouponType>
  memberCouponID: Maybe<Scalars['Int']>
  promotion: Maybe<CustomerAdjustmentPromotion>
  promotionID: Maybe<Scalars['Int']>
  source: Maybe<AdjustmentSourceEnumType>
}

export enum AdjustmentTypeEnumType {
  Cash = 'Cash',
  Concession = 'Concession',
  Discount = 'Discount',
}

export type BankAccountType = {
  brand: Maybe<BankBrandTypeEnum>
  name: Maybe<Scalars['String']>
  number: Maybe<Scalars['String']>
}

export enum BankBrandTypeEnum {
  Bbl = 'BBL',
  Boa = 'BOA',
  Cimb = 'CIMB',
  Gsb = 'GSB',
  Icbc = 'ICBC',
  KBank = 'KBank',
  Ktb = 'KTB',
  Kiatnakin = 'Kiatnakin',
  Krungsri = 'Krungsri',
  PromptPay = 'PromptPay',
  Scb = 'SCB',
  StandardCharteredBank = 'StandardCharteredBank',
  TBank = 'TBank',
  Tmb = 'TMB',
  TiscoBank = 'TiscoBank',
  Uob = 'UOB',
}

export type BuffetItemType = {
  buffetPackageID: Maybe<Scalars['Int']>
  /** Must be sequent and should associate to other packages */
  buffetPersonTypeID: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  labelEn: Maybe<Scalars['String']>
  labelTh: Maybe<Scalars['String']>
  priceSatangs: Maybe<Scalars['Int']>
  sku: Maybe<Scalars['String']>
  type: Maybe<BuffetItemTypeEnum>
}

export enum BuffetItemTypeEnum {
  LeftOver = 'LeftOver',
  Overtime = 'Overtime',
  Person = 'Person',
}

export type BuffetPackageProductType = {
  buffetPackage: Maybe<BuffetPackageType>
  buffetPackageID: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  productID: Maybe<Scalars['Int']>
}

export type BuffetPackageType = {
  active: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  items: Maybe<Array<Maybe<BuffetItemType>>>
  level: Maybe<Scalars['Int']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  vendorID: Maybe<Scalars['Int']>
}

export enum BuildingTypeEnum {
  CondoOffice = 'CondoOffice',
  House = 'House',
}

export type BusinessHourType = {
  active: Maybe<Scalars['Boolean']>
  /** Day of week starting from 0 which is Sunday. */
  dayOfWeek: Maybe<Scalars['Int']>
  /** The time that the business closes in format HH:MM */
  endTime: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  /** The time that the business opens in format HH:MM */
  startTime: Maybe<Scalars['String']>
}

export type Category = {
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  productColorScheme: Maybe<ProductColorSchemeEnum>
  vendorID: Maybe<Scalars['Int']>
}

export type CouponImageType = {
  resizeableURL: Maybe<Scalars['String']>
}

export type CustomerAdjustmentInvalidDataType = {
  minimumSatangs: Maybe<Scalars['Int']>
  remainingMinimumSatangs: Maybe<Scalars['Int']>
}

export type CustomerAdjustmentPromotion = {
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  memberTierRankConditions: Maybe<Array<Maybe<PromotionMemberTierRankConditionEnumType>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  targetEntity: Maybe<AdjustmentTargetEntityTypeEnum>
  type: Maybe<PromotionTypeEnum>
}

export type CustomerLocationProduct = {
  active: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  /** Returns null if product doesn't track stock */
  inStockQuantity: Maybe<Scalars['Int']>
  locationID: Maybe<Scalars['Int']>
  productID: Maybe<Scalars['Int']>
}

export enum DeliveryMethodEnum {
  AtDoor = 'AtDoor',
  AtGuard = 'AtGuard',
  AtLobby = 'AtLobby',
  AtReception = 'AtReception',
  ToPerson = 'ToPerson',
}

export enum DietaryRestrictionEnum {
  Chicken = 'Chicken',
  Egg = 'Egg',
  Fish = 'Fish',
  Milk = 'Milk',
  Nut = 'Nut',
  Soy = 'Soy',
  Vegetarian = 'Vegetarian',
}

export type DineInCreditCardType = {
  cardBrand: Maybe<Scalars['String']>
  cardID: Maybe<Scalars['String']>
  cardLastDigits: Maybe<Scalars['String']>
  cardholderName: Maybe<Scalars['String']>
}

export enum ExternalCouponRewardTypeCodeEnum {
  Ca = 'CA',
  Co = 'CO',
  Dc = 'DC',
  Pv = 'PV',
  Rc = 'RC',
  Tk = 'TK',
  Vc = 'VC',
}

export enum ExternalCouponRewardTypeEnum {
  Cash = 'Cash',
  Discount = 'Discount',
}

export type ExternalCouponType = {
  amount: Maybe<Scalars['Int']>
  code: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  endTime: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  imageURL: Maybe<Scalars['String']>
  isAvailable: Maybe<Scalars['Boolean']>
  memberCouponRefID: Maybe<Scalars['String']>
  membership: Maybe<OrderMembershipType>
  membershipID: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  rewardType: Maybe<ExternalCouponRewardTypeEnum>
  rewardTypeCode: Maybe<ExternalCouponRewardTypeCodeEnum>
  serialNumber: Maybe<Scalars['String']>
  staffPrivilegeType: Maybe<StaffPrivilegeTypeTypeEnum>
  startTime: Maybe<Scalars['String']>
  status: Maybe<Scalars['String']>
  thumbnailImageURL: Maybe<Scalars['String']>
}

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male',
}

export type GeolocationInput = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export enum HealthGoalEnum {
  Balanced = 'Balanced',
  Excreting = 'Excreting',
  Muscle = 'Muscle',
  WeightLoss = 'WeightLoss',
}

export type ImageType = {
  alt: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  /** Original URL of the media */
  originalURL: Maybe<Scalars['String']>
  /** For sorting images in collection */
  position: Maybe<Scalars['Int']>
  /** Image URL with {width} and {height} placeholder. Just replace those with actual value in pixel and image will get resized automatically. */
  resizableURL: Maybe<Scalars['String']>
  /** Thumbnail original URL of the media */
  thumbnailOriginalURL: Maybe<Scalars['String']>
  /** Thumbnail image URL with {width} and {height} placeholder. Just replace those with actual value in pixel and image will get resized automatically. */
  thumbnailResizableURL: Maybe<Scalars['String']>
  /** Thumbnail Image path (for video) in S3 */
  thumbnailUploadFile: Maybe<Scalars['String']>
  type: Maybe<ImageTypeEnum>
  /** Image path in S3 */
  uploadFile: Maybe<Scalars['String']>
}

export enum ImageTypeEnum {
  Cover = 'Cover',
  Horizontal = 'Horizontal',
  HorizontalThumbnail = 'HorizontalThumbnail',
  Thumbnail = 'Thumbnail',
  Vertical = 'Vertical',
  VerticalThumbnail = 'VerticalThumbnail',
}

export type Ingredient = {
  id: Maybe<Scalars['Int']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
}

export type InitiateCheckoutResultType = {
  order: Maybe<OrderType>
  /** Invalids for order */
  orderInvalids: Maybe<Array<Maybe<OrderInvalidEnum>>>
  /** Warnings for order */
  orderWarnings: Maybe<Array<Maybe<OrderWarningEnum>>>
}

export enum InvalidModifierEnum {
  Inactive = 'Inactive',
  InsufficientStock = 'InsufficientStock',
  MembershipRequired = 'MembershipRequired',
  OutOfStock = 'OutOfStock',
  ProductInactive = 'ProductInactive',
  TierBirthMonthRequired = 'TierBirthMonthRequired',
  TierIneligible = 'TierIneligible',
}

export type InvoiceInput = {
  address?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  taxID?: InputMaybe<Scalars['String']>
}

export enum KPaymentResultFailureCodeEnum {
  InvalidPhone = 'InvalidPhone',
  ProcessingError = 'ProcessingError',
}

export enum KPaymentResultStatusEnum {
  Fail = 'fail',
  Success = 'success',
}

export type KPaymentResultType = {
  failureCode: Maybe<KPaymentResultFailureCodeEnum>
  failureMessage: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  status: Maybe<KPaymentResultStatusEnum>
}

export type LiffUser = {
  activityLevel: Maybe<ActivityLevelEnum>
  bmi: Maybe<Scalars['Float']>
  caloriesPerMeal: Maybe<Scalars['Float']>
  dateOfBirth: Maybe<Scalars['String']>
  defaultPaymentMethod: Maybe<PaymentTypeEnum>
  defaultPaymentPhone: Maybe<Scalars['String']>
  dietaryRestrictions: Maybe<Array<Maybe<DietaryRestrictionEnum>>>
  email: Maybe<Scalars['String']>
  faceImage: Maybe<ImageType>
  gender: Maybe<GenderEnum>
  hasPlacedOrder: Maybe<Scalars['Boolean']>
  healthGoal: Maybe<HealthGoalEnum>
  heightCm: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  language: Maybe<Scalars['String']>
  lineDisplayName: Maybe<Scalars['String']>
  /** Image URL for Line profile pic */
  linePicture: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  omiseCardBrand: Maybe<Scalars['String']>
  omiseCardID: Maybe<Scalars['String']>
  omiseCardLastDigits: Maybe<Scalars['String']>
  omiseCardholderName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  rememberCard: Maybe<Scalars['Boolean']>
  spicyLevel: Maybe<SpicyLevelEnum>
  weightKg: Maybe<Scalars['Int']>
}

export enum LineItemInvalidEnum {
  ExceedMaxQuantity = 'ExceedMaxQuantity',
  Inactive = 'Inactive',
  InsufficientStock = 'InsufficientStock',
  InvalidModifiers = 'InvalidModifiers',
  MembershipRequired = 'MembershipRequired',
  ModifierExceedMaximumQuantity = 'ModifierExceedMaximumQuantity',
  ModifierRequired = 'ModifierRequired',
  OutOfStock = 'OutOfStock',
  ServiceNotAllowed = 'ServiceNotAllowed',
  TierBirthMonthRequired = 'TierBirthMonthRequired',
  TierIneligible = 'TierIneligible',
}

export type LineItemModifierType = {
  id: Maybe<Scalars['Int']>
  modifier: Maybe<Modifier>
  modifierID: Maybe<Scalars['Int']>
  pricePerUnitSatangs: Maybe<Scalars['Int']>
  quantity: Maybe<Scalars['Int']>
  status: Maybe<ModifierStatusEnum>
  totalSatangs: Maybe<Scalars['Int']>
}

export enum LineItemServiceEnum {
  Buffet = 'Buffet',
  BuffetALaCarte = 'BuffetALaCarte',
  BuffetALaCarteTakeaway = 'BuffetALaCarteTakeaway',
  DineIn = 'DineIn',
  DineInTakeaway = 'DineInTakeaway',
}

export enum LineItemServiceTypeForBuffetEnum {
  BuffetALaCarteTakeaway = 'BuffetALaCarteTakeaway',
}

export enum LineItemServiceTypeForDineInEnum {
  DineIn = 'DineIn',
  DineInTakeaway = 'DineInTakeaway',
}

export enum LineItemSourceEnum {
  ProductRecommendation = 'ProductRecommendation',
}

export enum LineItemStatusEnum {
  Cooking = 'Cooking',
  Served = 'Served',
  Serving = 'Serving',
}

export type ListCustomerBuffetPackageProductsResult = {
  pagination: Maybe<PaginationType>
  products: Maybe<Array<Maybe<ProductBuffetPackageProductType>>>
}

export type ListFpMemberCouponsFilter = {
  userIDs?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export type ListProducts = {
  pagination: Maybe<PaginationType>
  products: Maybe<Array<Maybe<ProductType>>>
}

export type LocationType = {
  active: Maybe<Scalars['Boolean']>
  /** Deprecated. Use other fields such as name, latitude and longitude instead. */
  address: Maybe<Address>
  addressLabel: Maybe<Scalars['String']>
  /** The approximate distance (displacement) of location from specified lat/lng. This is available only in certain APIs */
  approxDeliveryDistanceM: Maybe<Scalars['Int']>
  businessHours: Maybe<Array<Maybe<BusinessHourType>>>
  deliveryContactPhone: Maybe<Scalars['String']>
  floors: Maybe<Scalars['String']>
  geocodedAddressEn: Maybe<Scalars['String']>
  geocodedAddressTh: Maybe<Scalars['String']>
  geocodedDistrictEn: Maybe<Scalars['String']>
  geocodedDistrictTh: Maybe<Scalars['String']>
  geocodedPostcode: Maybe<Scalars['String']>
  geocodedProvinceEn: Maybe<Scalars['String']>
  geocodedProvinceTh: Maybe<Scalars['String']>
  geocodedSubdistrictEn: Maybe<Scalars['String']>
  geocodedSubdistrictTh: Maybe<Scalars['String']>
  googlePlaceID: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  /** Is the specified lat/lng in delivery area? */
  isInDeliveryArea: Maybe<Scalars['Boolean']>
  latitude: Maybe<Scalars['Float']>
  longitude: Maybe<Scalars['Float']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  shippingFeePolicy: Maybe<ShippingFeePolicyEnum>
  vendor: Maybe<VendorType>
  vendorID: Maybe<Scalars['Int']>
}

export type MemberCouponPromotionType = {
  conditionDetailsEn: Maybe<Scalars['String']>
  conditionDetailsTh: Maybe<Scalars['String']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  extPromotionID: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<CouponImageType>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
}

export enum MemberCouponStatusEnumType {
  Active = 'Active',
  Expired = 'Expired',
  PendingStart = 'PendingStart',
  Redeemed = 'Redeemed',
}

export type MemberCouponType = {
  /** end date in "2006-01-02" format in Asia/Bangkok timezone */
  endDate: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  promotion: Maybe<MemberCouponPromotionType>
  /** redeemed at in RFC3339 format "2019-02-21T04:21:03+07:00" in Asia/Bangkok timezone */
  redeemedAt: Maybe<Scalars['String']>
  /** start date in "2006-01-02" format in Asia/Bangkok timezone */
  startDate: Maybe<Scalars['String']>
  status: Maybe<MemberCouponStatusEnumType>
}

export enum MemberProductIneligibleReasonEnum {
  MembershipRequired = 'MembershipRequired',
  TierBirthMonthRequired = 'TierBirthMonthRequired',
  TierIneligible = 'TierIneligible',
}

export enum MembershipProviderEnum {
  FoodPassion = 'FoodPassion',
  HatoHeart = 'HatoHeart',
  Maguro = 'Maguro',
}

export enum MembershipTierEnum {
  Family = 'Family',
  FoodPassionBuddy = 'FoodPassionBuddy',
  FoodPassionFamily = 'FoodPassionFamily',
  FoodPassionFriend = 'FoodPassionFriend',
  FoodPassionSoulmate = 'FoodPassionSoulmate',
  Friend = 'Friend',
}

export type Modifier = {
  active: Maybe<Scalars['Boolean']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  invalids: Maybe<Array<Maybe<InvalidModifierEnum>>>
  modifierGroup: Maybe<ModifierGroup>
  modifierGroupID: Maybe<Scalars['Int']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  priceSatangs: Maybe<Scalars['Int']>
  product: Maybe<ProductWithoutModifierType>
  productID: Maybe<Scalars['Int']>
}

export type ModifierGroup = {
  id: Maybe<Scalars['Int']>
  modifiers: Maybe<Array<Maybe<Modifier>>>
  multipleQuantity: Maybe<Scalars['Boolean']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  vendorID: Maybe<Scalars['Int']>
}

export type ModifierGroupWithProduct = {
  id: Maybe<Scalars['Int']>
  multipleQuantity: Maybe<Scalars['Boolean']>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  vendorID: Maybe<Scalars['Int']>
}

export enum ModifierStatusEnum {
  Cooking = 'Cooking',
  Served = 'Served',
  Serving = 'Serving',
}

export type NutritionType = {
  calories: Maybe<Scalars['Float']>
  caloriesFromFat: Maybe<Scalars['Float']>
  carbG: Maybe<Scalars['Float']>
  cholesterolMg: Maybe<Scalars['Float']>
  fatG: Maybe<Scalars['Float']>
  fiberG: Maybe<Scalars['Float']>
  id: Maybe<Scalars['Int']>
  proteinG: Maybe<Scalars['Float']>
  saturatedFatG: Maybe<Scalars['Float']>
  servingsPerItem: Maybe<Scalars['Float']>
  sodiumMg: Maybe<Scalars['Float']>
  sugarsG: Maybe<Scalars['Float']>
  transFatG: Maybe<Scalars['Float']>
}

export type OmiseChargeType = {
  /** Authorize URI for Omise TrueMoneyWallet payment type */
  authorizeURI: Maybe<Scalars['String']>
  /** Charge ID from Omise */
  chargeID: Maybe<Scalars['String']>
  /** Failure Code from Omise */
  failureCode: Maybe<Scalars['String']>
  /** Failure Message from Omise */
  failureMessage: Maybe<Scalars['String']>
  /** Charge Status from Omise (pending|successful|failed|reversed) */
  status: Maybe<Scalars['String']>
}

export type OrderAndFpMemberCouponsResultType = {
  autoPromotions: Maybe<Array<Maybe<OrderPromotionType>>>
  coupons: Maybe<Array<Maybe<ExternalCouponType>>>
  manualPromotions: Maybe<Array<Maybe<OrderPromotionType>>>
  order: Maybe<OrderType>
  /** Invalid states of the order */
  orderInvalids: Maybe<Array<Maybe<OrderInvalidEnum>>>
}

export type OrderAndHhMemberCouponsResultType = {
  autoPromotions: Maybe<Array<Maybe<OrderPromotionType>>>
  coupons: Maybe<Array<Maybe<OrderMemberCouponType>>>
  manualPromotions: Maybe<Array<Maybe<OrderPromotionType>>>
  order: Maybe<OrderType>
  /** Invalid states of the order */
  orderInvalids: Maybe<Array<Maybe<OrderInvalidEnum>>>
  /** @deprecated We change to use the manualPromotions field instead */
  promotions: Maybe<Array<Maybe<OrderPromotionType>>>
}

export type OrderBuffetItemType = {
  buffetItem: Maybe<BuffetItemType>
  buffetItemID: Maybe<Scalars['Int']>
  buffetItemType: Maybe<BuffetItemTypeEnum>
  id: Maybe<Scalars['Int']>
  orderId: Maybe<Scalars['Int']>
  pricePerUnitSatangs: Maybe<Scalars['Int']>
  quantity: Maybe<Scalars['Int']>
  totalSatangs: Maybe<Scalars['Int']>
}

export type OrderCheckoutType = {
  KPaymentResult: Maybe<KPaymentResultType>
  /** Charge Result for Omise payment method */
  chargeResult: Maybe<OmiseChargeType>
  order: Maybe<OrderType>
  /** Invalid states of the cart */
  orderInvalids: Maybe<Array<Maybe<OrderInvalidEnum>>>
}

export type OrderInput = {
  id?: InputMaybe<Scalars['Int']>
  paymentPhone?: InputMaybe<Scalars['String']>
  paymentType?: InputMaybe<PaymentTypeEnum>
  taxInvoice?: InputMaybe<InvoiceInput>
}

export enum OrderInvalidEnum {
  InvalidBeingCheckedOut = 'InvalidBeingCheckedOut',
  InvalidBuffetCheckout = 'InvalidBuffetCheckout',
  InvalidExternalCoupon = 'InvalidExternalCoupon',
  InvalidLineItem = 'InvalidLineItem',
  InvalidMaximumCouponSharing = 'InvalidMaximumCouponSharing',
  InvalidMemberCoupon = 'InvalidMemberCoupon',
  InvalidMinimumLineItemTotal = 'InvalidMinimumLineItemTotal',
  InvalidMinimumTotal = 'InvalidMinimumTotal',
  InvalidNoCoupon = 'InvalidNoCoupon',
  InvalidOvertime = 'InvalidOvertime',
  InvalidPaymentIsPending = 'InvalidPaymentIsPending',
  InvalidPromotion = 'InvalidPromotion',
}

export type OrderLineItemInput = {
  customNotes?: InputMaybe<Scalars['String']>
  modifiers?: InputMaybe<Array<InputMaybe<OrderLineItemModifierInput>>>
  productID: Scalars['Int']
  quantity: Scalars['Int']
  serviceType?: InputMaybe<LineItemServiceEnum>
  source?: InputMaybe<LineItemSourceEnum>
}

export type OrderLineItemModifierInput = {
  modifierID: Scalars['Int']
  quantity: Scalars['Int']
}

export type OrderLineItemType = {
  /** If the value more than 0, it means that is buffet item */
  buffetPackageID: Maybe<Scalars['Int']>
  /** Time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  createdAt: Maybe<Scalars['String']>
  /** This line item was created by this user */
  createdByUser: Maybe<LiffUser>
  customNotes: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  invalids: Maybe<Array<Maybe<LineItemInvalidEnum>>>
  /** Line items group by batch No. */
  lineItemBatchID: Maybe<Scalars['Int']>
  modifiers: Maybe<Array<Maybe<LineItemModifierType>>>
  pricePerUnitSatangs: Maybe<Scalars['Int']>
  product: Maybe<ProductType>
  productID: Maybe<Scalars['Int']>
  quantity: Maybe<Scalars['Int']>
  /** Service type indicates line item is order for dine-in, buffet or takeaway */
  serviceType: Maybe<LineItemServiceEnum>
  status: Maybe<LineItemStatusEnum>
  totalSatangs: Maybe<Scalars['Int']>
}

export type OrderMemberCouponType = {
  coupon: Maybe<MemberCouponType>
  isAvailable: Maybe<Scalars['Boolean']>
}

export type OrderMembershipType = {
  /** earned at time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  earnedAt: Maybe<Scalars['String']>
  earnedPoints: Maybe<Scalars['Int']>
  firstName: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  lastName: Maybe<Scalars['String']>
  memberID: Maybe<Scalars['String']>
  memberTier: Maybe<MembershipTierEnum>
  orderID: Maybe<Scalars['Int']>
  provider: Maybe<MembershipProviderEnum>
  totalPoints: Maybe<Scalars['Int']>
  user: Maybe<LiffUser>
  userID: Maybe<Scalars['Int']>
}

export enum OrderPromotionInvalidEnum {
  Conflicting = 'Conflicting',
  Duplicate = 'Duplicate',
  InvalidConditions = 'InvalidConditions',
  /** For Food Passion staff privilege */
  InvalidLineId = 'InvalidLineID',
  NotExistedOrExpired = 'NotExistedOrExpired',
  /** For Food Passion staff privilege */
  QrCodeNotFound = 'QRCodeNotFound',
  /** For Food Passion staff privilege */
  StaffPrivilegeAlreadyApplied = 'StaffPrivilegeAlreadyApplied',
  Used = 'Used',
}

export type OrderPromotionType = {
  code: Maybe<Scalars['String']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  memberTierRankConditions: Maybe<Array<Maybe<PromotionMemberTierRankConditionEnumType>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  strategyType: Maybe<PromotionStrategyTypeEnum>
  targetEntity: Maybe<AdjustmentTargetEntityTypeEnum>
}

export type OrderResultType = {
  order: Maybe<OrderType>
  /** Invalid states of the order */
  orderInvalids: Maybe<Array<Maybe<OrderInvalidEnum>>>
  /** Invalids when adding promotion to order */
  promotionInvalids: Maybe<Array<Maybe<OrderPromotionInvalidEnum>>>
}

export enum OrderStatusEnum {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Placed = 'Placed',
  ReadyToCheckout = 'ReadyToCheckout',
  StaffCheckout = 'StaffCheckout',
}

export type OrderTaxInvoiceType = {
  address: Maybe<Scalars['String']>
  companyName: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  taxID: Maybe<Scalars['String']>
}

export type OrderType = {
  /** Sum of cash voucher amount */
  adjustmentCashTotalSatangs: Maybe<Scalars['Int']>
  /** Sum of staff cash amount */
  adjustmentStaffCashTotalSatangs: Maybe<Scalars['Int']>
  /** Sum of discount amount */
  adjustmentTotalSatangs: Maybe<Scalars['Int']>
  /** Discounts and promotions */
  adjustments: Maybe<Array<Maybe<AdjustmentType>>>
  /** buffetEndTime time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  buffetEndTime: Maybe<Scalars['String']>
  buffetItems: Maybe<Array<Maybe<OrderBuffetItemType>>>
  buffetPackage: Maybe<BuffetPackageType>
  buffetPackageID: Maybe<Scalars['Int']>
  buffetTotalSatangs: Maybe<Scalars['Int']>
  /** Sum of deposit amount */
  depositTotalSatangs: Maybe<Scalars['Int']>
  /** If the order is excluding vat this field will be true */
  excludingVat: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  lineItemTotalExcludingVatSatangs: Maybe<Scalars['Int']>
  lineItemTotalSatangs: Maybe<Scalars['Int']>
  /** Value from VAT 7 percents from total. */
  lineItemVatSatangs: Maybe<Scalars['Int']>
  lineItems: Maybe<Array<Maybe<OrderLineItemType>>>
  location: Maybe<LocationType>
  locationID: Maybe<Scalars['Int']>
  membership: Maybe<OrderMembershipType>
  /** Order number */
  number: Maybe<Scalars['String']>
  omiseCardBrand: Maybe<Scalars['String']>
  omiseCardID: Maybe<Scalars['String']>
  omiseCardLastDigits: Maybe<Scalars['String']>
  omiseCardholderName: Maybe<Scalars['String']>
  paymentPhone: Maybe<Scalars['String']>
  paymentType: Maybe<PaymentTypeEnum>
  payments: Maybe<Array<Maybe<PaymentType>>>
  /** PlacedAt time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  placedAt: Maybe<Scalars['String']>
  specialInstructions: Maybe<Scalars['String']>
  /** A reason that customer called the staff */
  staffCallingReason: Maybe<StaffCallingReasonEnum>
  status: Maybe<OrderStatusEnum>
  /** Table info */
  table: Maybe<TableType>
  tableGuests: Maybe<Array<Maybe<TableGuestType>>>
  taxInvoice: Maybe<OrderTaxInvoiceType>
  /** Total amount after cash voucher */
  totalDueSatangs: Maybe<Scalars['Int']>
  totalQuantity: Maybe<Scalars['Int']>
  totalSatangs: Maybe<Scalars['Int']>
  /** Sum of everything plus deposit amount */
  totalWithDepositSatangs: Maybe<Scalars['Int']>
  user: Maybe<LiffUser>
  userID: Maybe<Scalars['Int']>
}

export enum OrderWarningEnum {
  WarningOverrideCheckout = 'WarningOverrideCheckout',
}

export type PaginationType = {
  count: Maybe<Scalars['Int']>
  limit: Maybe<Scalars['Int']>
  offset: Maybe<Scalars['Int']>
  total: Maybe<Scalars['Int']>
}

export type PaymentBankSlipImageType = {
  id: Maybe<Scalars['Int']>
  /** File path in S3 */
  uploadFile: Maybe<Scalars['String']>
  url: Maybe<Scalars['String']>
}

export type PaymentInput = {
  /** Phone Number (For Omise Truemoney Wallet or KPayment PayPlus. This is optional, required if payment type is OmiseTrueMoneyWallet, KPaymentKPlusNumber or KPaymentPromptPayNumber) */
  phoneNumber?: InputMaybe<Scalars['String']>
  /** Return URI after checking out (This is optional, default value is empty) */
  returnURI?: InputMaybe<Scalars['String']>
  /** Payment Type (BankTransfer|LinePay|PromptPay|OmiseCreditCard|SCBPromptPay|OmisePromptPay|OmiseTrueMoneyWallet|KPaymentKPlusNumber|KPaymentPromptPayNumber) */
  type: PaymentTypeEnum
}

export enum PaymentStatusEnum {
  Failed = 'Failed',
  Paid = 'Paid',
  PartiallyRefunded = 'PartiallyRefunded',
  PendingPayment = 'PendingPayment',
  PendingVerification = 'PendingVerification',
  Refunded = 'Refunded',
}

export type PaymentType = {
  amountSatangs: Maybe<Scalars['Int']>
  bankSlipImages: Maybe<Array<Maybe<PaymentBankSlipImageType>>>
  changeBackAmountSatangs: Maybe<Scalars['Int']>
  failureMessage: Maybe<Scalars['String']>
  failureReason: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  status: Maybe<PaymentStatusEnum>
  tenderedAmountSatangs: Maybe<Scalars['Int']>
  type: Maybe<PaymentTypeEnum>
}

export enum PaymentTypeEnum {
  BankTransfer = 'BankTransfer',
  Cash = 'Cash',
  ExternalEdc = 'ExternalEDC',
  ExternalQrPayment = 'ExternalQRPayment',
  KBankThaiQr = 'KBankThaiQR',
  KPaymentKPlusNumber = 'KPaymentKPlusNumber',
  KPaymentPromptPayNumber = 'KPaymentPromptPayNumber',
  LinePay = 'LinePay',
  None = 'None',
  OmiseCreditCard = 'OmiseCreditCard',
  OmisePromptPay = 'OmisePromptPay',
  OmiseTrueMoneyWallet = 'OmiseTrueMoneyWallet',
  ScbPromptPay = 'SCBPromptPay',
}

export enum ProductAvailableServiceEnum {
  Buffet = 'Buffet',
  BuffetALaCarte = 'BuffetALaCarte',
  BuffetALaCarteTakeaway = 'BuffetALaCarteTakeaway',
  Delivery = 'Delivery',
  DineIn = 'DineIn',
  DineInTakeaway = 'DineInTakeaway',
}

export type ProductBuffetPackageProductType = {
  active: Maybe<Scalars['Boolean']>
  availableServices: Maybe<Array<Maybe<ProductServiceEnum>>>
  brand: Maybe<Scalars['String']>
  buffetPackageProducts: Maybe<Array<Maybe<BuffetPackageProductType>>>
  category: Maybe<Category>
  categoryID: Maybe<Scalars['Int']>
  /** Color scheme set to this product. This does not consider its modifiers and category. For display, use derivedColorScheme. */
  colorScheme: Maybe<ProductColorSchemeEnum>
  /** Label or guide for users to input custom notes. */
  customNotesLabelEn: Maybe<Scalars['String']>
  /** Label or guide for users to input custom notes. */
  customNotesLabelTh: Maybe<Scalars['String']>
  /** Color scheme of product for display. The value is derived from the color scheme of product itself, its modifiers and its category. */
  derivedColorScheme: Maybe<ProductColorSchemeEnum>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  /** Amount to discount from actual total price. Note that this should be positive amount. */
  discountSatangs: Maybe<Scalars['Int']>
  /** End time (exclusive) in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  endTime: Maybe<Scalars['String']>
  /** Exclude products from listing in LIFF when this field is true. */
  excludedFromListing: Maybe<Scalars['Boolean']>
  /** Exclude product from promotion when this field is set to true */
  excludedFromPromotion: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  ingredientList: Maybe<Array<Maybe<Ingredient>>>
  /** Deprecated. Use ingredientList instead. */
  ingredients: Maybe<Scalars['String']>
  locationProducts: Maybe<Array<Maybe<CustomerLocationProduct>>>
  /** Max spicy level considering the spicy level of the product and modifiers */
  maxSpicyLevel: Maybe<SpicyLevelEnum>
  memberIneligibleReason: Maybe<MemberProductIneligibleReasonEnum>
  memberTierRankConditions: Maybe<Array<Maybe<ProductMemberTierRankConditionsEnum>>>
  /** Deprecated */
  modifierConfigs: Maybe<ProductModifierConfig>
  /** Modifier groups associated to product */
  modifierGroups: Maybe<Array<Maybe<ProductModifierGroup>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  /** Nutrition for the product excluding its modifiers */
  nutrition: Maybe<NutritionType>
  /** Nutrition for the product including all its modifiers */
  nutritionTotal: Maybe<NutritionType>
  onDemand: Maybe<Scalars['Boolean']>
  position: Maybe<Scalars['Int']>
  /** Price of products excluding its modifiers */
  priceSatangs: Maybe<Scalars['Int']>
  promotionBadge: Maybe<PromotionStrategyTypeEnum>
  shortName: Maybe<Scalars['String']>
  /** Skip the screen to add custom notes when adding to cart in LIFF . */
  skipCustomNotesScreen: Maybe<Scalars['Boolean']>
  /** Skip the screen to select modifiers when adding to cart in POS. */
  skipModifierScreen: Maybe<Scalars['Boolean']>
  sku: Maybe<Scalars['String']>
  /** Spicy level of the product excluding modifiers */
  spicyLevel: Maybe<SpicyLevelEnum>
  /** Start time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  startTime: Maybe<Scalars['String']>
  tags: Maybe<Array<Maybe<ProductTagEnum>>>
  tasteDescriptionEn: Maybe<Scalars['String']>
  tasteDescriptionTh: Maybe<Scalars['String']>
  /** Price of the product including its modifiers. Already taken into discount. */
  totalPriceSatangs: Maybe<Scalars['Int']>
  vendor: Maybe<VendorType>
  vendorID: Maybe<Scalars['Int']>
}

export enum ProductColorSchemeEnum {
  BlueLagoon = 'BlueLagoon',
  ButterflyPeaLatte = 'ButterflyPeaLatte',
  Latte = 'Latte',
  MangoSmoothie = 'MangoSmoothie',
  Matcha = 'Matcha',
  MatchaLatte = 'MatchaLatte',
  Mojito = 'Mojito',
  Ovaltine = 'Ovaltine',
  SoyMilk = 'SoyMilk',
  StrawberrySmoothie = 'StrawberrySmoothie',
  ThaiTea = 'ThaiTea',
  ThaiTeaLatte = 'ThaiTeaLatte',
  TomatoJuice = 'TomatoJuice',
  Wheatgrass = 'Wheatgrass',
  WheatgrassSmoothie = 'WheatgrassSmoothie',
}

export enum ProductMemberTierRankConditionsEnum {
  BirthMonth = 'BirthMonth',
  Eligible = 'Eligible',
  NotEligible = 'NotEligible',
}

export type ProductModifierConfig = {
  defaultQuantity: Maybe<Scalars['Int']>
  id: Maybe<Scalars['Int']>
  modifierID: Maybe<Scalars['Int']>
}

export type ProductModifierGroup = {
  active: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  maximumQuantity: Maybe<Scalars['Int']>
  minimumQuantity: Maybe<Scalars['Int']>
  modifierGroup: Maybe<ModifierGroup>
  modifierGroupID: Maybe<Scalars['Int']>
  position: Maybe<Scalars['Int']>
  productID: Maybe<Scalars['Int']>
}

export enum ProductRecommendationFeature {
  ProductRecommendationV2 = 'ProductRecommendationV2',
  ProductRecommendationV3 = 'ProductRecommendationV3',
}

export enum ProductServiceEnum {
  Buffet = 'Buffet',
  BuffetALaCarte = 'BuffetALaCarte',
  BuffetALaCarteTakeaway = 'BuffetALaCarteTakeaway',
  Delivery = 'Delivery',
  DineIn = 'DineIn',
  DineInTakeaway = 'DineInTakeaway',
}

export enum ProductTagEnum {
  BestSeller = 'BestSeller',
  Biodegradable = 'Biodegradable',
  BoostingImmuneSystem = 'BoostingImmuneSystem',
  CanBeGlutenFree = 'CanBeGlutenFree',
  CanBeJ = 'CanBeJ',
  CholesterolFree = 'CholesterolFree',
  Compostable = 'Compostable',
  ContainsEgg = 'ContainsEgg',
  ContainsFish = 'ContainsFish',
  ContainsProbiotic = 'ContainsProbiotic',
  ContainsShrimp = 'ContainsShrimp',
  DairyFree = 'DairyFree',
  DirectFromFarmers = 'DirectFromFarmers',
  Exclusive = 'Exclusive',
  FreeRange = 'FreeRange',
  FrequentlyPurchased = 'FrequentlyPurchased',
  GainingMuscle = 'GainingMuscle',
  GlutenFree = 'GlutenFree',
  GoodForBlood = 'GoodForBlood',
  GoodForBone = 'GoodForBone',
  GoodForBrain = 'GoodForBrain',
  GoodForEyes = 'GoodForEyes',
  GoodForSkin = 'GoodForSkin',
  HighAntioxidant = 'HighAntioxidant',
  HighCalcium = 'HighCalcium',
  HighFiber = 'HighFiber',
  HighIron = 'HighIron',
  HighOmegaFat = 'HighOmegaFat',
  HighProtein = 'HighProtein',
  HighVitaminA = 'HighVitaminA',
  HighVitaminB = 'HighVitaminB',
  HighVitaminC = 'HighVitaminC',
  HighVitaminD = 'HighVitaminD',
  Homemade = 'Homemade',
  ImprovingDigestion = 'ImprovingDigestion',
  JMenu = 'JMenu',
  Keto = 'Keto',
  LandSafe = 'LandSafe',
  Light = 'Light',
  LocallySourced = 'LocallySourced',
  LowCal = 'LowCal',
  LowCarb = 'LowCarb',
  LowFat = 'LowFat',
  LowGi = 'LowGI',
  LowSodium = 'LowSodium',
  LowSugar = 'LowSugar',
  LowUricAcid = 'LowUricAcid',
  New = 'New',
  NoAddedSugar = 'NoAddedSugar',
  NoMsg = 'NoMSG',
  NoPlastic = 'NoPlastic',
  NoPreservatives = 'NoPreservatives',
  NonGmo = 'NonGMO',
  OceanLifeSafe = 'OceanLifeSafe',
  OrganicIngredients = 'OrganicIngredients',
  Paleo = 'Paleo',
  PeanutFree = 'PeanutFree',
  PesticideFree = 'PesticideFree',
  PlantBased = 'PlantBased',
  RecentlyPurchased = 'RecentlyPurchased',
  Recommended = 'Recommended',
  RecycleablePackaging = 'RecycleablePackaging',
  Signature = 'Signature',
  SoyFree = 'SoyFree',
  SugarFree = 'SugarFree',
  SupportsLocalCommunity = 'SupportsLocalCommunity',
  Sustainable = 'Sustainable',
  SustainablyFarmed = 'SustainablyFarmed',
  Vegan = 'Vegan',
  Vegetarian = 'Vegetarian',
  WeightControl = 'WeightControl',
  YourFavorite = 'YourFavorite',
}

export type ProductType = {
  active: Maybe<Scalars['Boolean']>
  availableServices: Maybe<Array<Maybe<ProductServiceEnum>>>
  brand: Maybe<Scalars['String']>
  category: Maybe<Category>
  categoryID: Maybe<Scalars['Int']>
  /** Color scheme set to this product. This does not consider its modifiers and category. For display, use derivedColorScheme. */
  colorScheme: Maybe<ProductColorSchemeEnum>
  /** Label or guide for users to input custom notes. */
  customNotesLabelEn: Maybe<Scalars['String']>
  /** Label or guide for users to input custom notes. */
  customNotesLabelTh: Maybe<Scalars['String']>
  /** Color scheme of product for display. The value is derived from the color scheme of product itself, its modifiers and its category. */
  derivedColorScheme: Maybe<ProductColorSchemeEnum>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  /** Amount to discount from actual total price. Note that this should be positive amount. */
  discountSatangs: Maybe<Scalars['Int']>
  /** End time (exclusive) in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  endTime: Maybe<Scalars['String']>
  /** Exclude products from listing in LIFF when this field is true. */
  excludedFromListing: Maybe<Scalars['Boolean']>
  /** Exclude product from promotion when this field is set to true */
  excludedFromPromotion: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  ingredientList: Maybe<Array<Maybe<Ingredient>>>
  /** Deprecated. Use ingredientList instead. */
  ingredients: Maybe<Scalars['String']>
  locationProducts: Maybe<Array<Maybe<CustomerLocationProduct>>>
  /** Max spicy level considering the spicy level of the product and modifiers */
  maxSpicyLevel: Maybe<SpicyLevelEnum>
  memberIneligibleReason: Maybe<MemberProductIneligibleReasonEnum>
  memberTierRankConditions: Maybe<Array<Maybe<ProductMemberTierRankConditionsEnum>>>
  /** Deprecated */
  modifierConfigs: Maybe<ProductModifierConfig>
  /** Modifier groups associated to product */
  modifierGroups: Maybe<Array<Maybe<ProductModifierGroup>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  /** Nutrition for the product excluding its modifiers */
  nutrition: Maybe<NutritionType>
  /** Nutrition for the product including all its modifiers */
  nutritionTotal: Maybe<NutritionType>
  onDemand: Maybe<Scalars['Boolean']>
  position: Maybe<Scalars['Int']>
  /** Price of products excluding its modifiers */
  priceSatangs: Maybe<Scalars['Int']>
  promotionBadge: Maybe<PromotionStrategyTypeEnum>
  promotionProduct: Maybe<PromotionProductType>
  shortName: Maybe<Scalars['String']>
  /** Skip the screen to add custom notes when adding to cart in LIFF . */
  skipCustomNotesScreen: Maybe<Scalars['Boolean']>
  /** Skip the screen to select modifiers when adding to cart in POS. */
  skipModifierScreen: Maybe<Scalars['Boolean']>
  sku: Maybe<Scalars['String']>
  /** Spicy level of the product excluding modifiers */
  spicyLevel: Maybe<SpicyLevelEnum>
  /** Start time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  startTime: Maybe<Scalars['String']>
  tags: Maybe<Array<Maybe<ProductTagEnum>>>
  tasteDescriptionEn: Maybe<Scalars['String']>
  tasteDescriptionTh: Maybe<Scalars['String']>
  /** Price of the product including its modifiers. Already taken into discount. */
  totalPriceSatangs: Maybe<Scalars['Int']>
  vendor: Maybe<VendorType>
  vendorID: Maybe<Scalars['Int']>
}

export type ProductWithoutModifierType = {
  active: Maybe<Scalars['Boolean']>
  availableServices: Maybe<Array<Maybe<ProductServiceEnum>>>
  brand: Maybe<Scalars['String']>
  category: Maybe<Category>
  categoryID: Maybe<Scalars['Int']>
  /** Color scheme set to this product. This does not consider its modifiers and category. For display, use derivedColorScheme. */
  colorScheme: Maybe<ProductColorSchemeEnum>
  /** Label or guide for users to input custom notes. */
  customNotesLabelEn: Maybe<Scalars['String']>
  /** Label or guide for users to input custom notes. */
  customNotesLabelTh: Maybe<Scalars['String']>
  /** Color scheme of product for display. The value is derived from the color scheme of product itself, its modifiers and its category. */
  derivedColorScheme: Maybe<ProductColorSchemeEnum>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  /** Amount to discount from actual total price. Note that this should be positive amount. */
  discountSatangs: Maybe<Scalars['Int']>
  /** End time (exclusive) in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  endTime: Maybe<Scalars['String']>
  /** Exclude products from listing in LIFF when this field is true. */
  excludedFromListing: Maybe<Scalars['Boolean']>
  /** Exclude product from promotion when this field is set to true */
  excludedFromPromotion: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  ingredientList: Maybe<Array<Maybe<Ingredient>>>
  /** Deprecated. Use ingredientList instead. */
  ingredients: Maybe<Scalars['String']>
  locationProducts: Maybe<Array<Maybe<CustomerLocationProduct>>>
  /** Max spicy level considering the spicy level of the product and modifiers */
  maxSpicyLevel: Maybe<SpicyLevelEnum>
  memberIneligibleReason: Maybe<MemberProductIneligibleReasonEnum>
  memberTierRankConditions: Maybe<Array<Maybe<ProductMemberTierRankConditionsEnum>>>
  /** Deprecated */
  modifierConfigs: Maybe<ProductModifierConfig>
  /** Modifier groups associated to product */
  modifierGroups: Maybe<Array<Maybe<ModifierGroupWithProduct>>>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  /** Nutrition for the product excluding its modifiers */
  nutrition: Maybe<NutritionType>
  /** Nutrition for the product including all its modifiers */
  nutritionTotal: Maybe<NutritionType>
  onDemand: Maybe<Scalars['Boolean']>
  position: Maybe<Scalars['Int']>
  /** Price of products excluding its modifiers */
  priceSatangs: Maybe<Scalars['Int']>
  promotionBadge: Maybe<PromotionStrategyTypeEnum>
  shortName: Maybe<Scalars['String']>
  /** Skip the screen to add custom notes when adding to cart in LIFF . */
  skipCustomNotesScreen: Maybe<Scalars['Boolean']>
  /** Skip the screen to select modifiers when adding to cart in POS. */
  skipModifierScreen: Maybe<Scalars['Boolean']>
  sku: Maybe<Scalars['String']>
  /** Spicy level of the product excluding modifiers */
  spicyLevel: Maybe<SpicyLevelEnum>
  /** Start time in RFC3339 format "2019-02-21T04:21:03+07:00" which is in Bangkok (+7) timezone */
  startTime: Maybe<Scalars['String']>
  tags: Maybe<Array<Maybe<ProductTagEnum>>>
  tasteDescriptionEn: Maybe<Scalars['String']>
  tasteDescriptionTh: Maybe<Scalars['String']>
  /** Price of the product including its modifiers. Already taken into discount. */
  totalPriceSatangs: Maybe<Scalars['Int']>
  vendor: Maybe<VendorType>
  vendorID: Maybe<Scalars['Int']>
}

export enum PromotionMemberTierRankConditionEnumType {
  BirthMonth = 'BirthMonth',
  Eligible = 'Eligible',
  NotEligible = 'NotEligible',
}

export type PromotionProductGroupType = {
  minimumAmountSatangs: Maybe<Scalars['Int']>
  productIDs: Maybe<Array<Maybe<Scalars['Int']>>>
  products: Maybe<Array<Maybe<ProductType>>>
  quantity: Maybe<Scalars['Int']>
  rewardType: Maybe<PromotionRewardTypeEnum>
  rewardValue: Maybe<Scalars['Float']>
  type: Maybe<PromotionProductGroupTypeEnum>
}

export enum PromotionProductGroupTypeEnum {
  Condition = 'Condition',
  ConditionAndReward = 'ConditionAndReward',
}

export type PromotionProductType = {
  productGroupRewards: Maybe<Array<Maybe<PromotionProductGroupType>>>
  promotion: Maybe<OrderPromotionType>
}

export enum PromotionRewardTypeEnum {
  FixedAmountSatangs = 'FixedAmountSatangs',
  FixedPercentage = 'FixedPercentage',
}

export enum PromotionStrategyTypeEnum {
  BuyXGetYForZ = 'BuyXGetYForZ',
  ManualBuy1Get1 = 'ManualBuy1Get1',
  ManualBuy2ForDiscount = 'ManualBuy2ForDiscount',
  ManualBuy2Get1 = 'ManualBuy2Get1',
  ManualBuy3ForDiscount = 'ManualBuy3ForDiscount',
  ManualBuy3Get1 = 'ManualBuy3Get1',
  ManualBuy4ForDiscount = 'ManualBuy4ForDiscount',
  ManualBuy4Get1 = 'ManualBuy4Get1',
  ManualBuy5ForDiscount = 'ManualBuy5ForDiscount',
  ManualBuy5Get1 = 'ManualBuy5Get1',
  ManualBuy6ForDiscount = 'ManualBuy6ForDiscount',
  ManualBuy6Get1 = 'ManualBuy6Get1',
  ManualBuy7ForDiscount = 'ManualBuy7ForDiscount',
}

export enum PromotionTypeEnum {
  Auto = 'Auto',
  Manual = 'Manual',
  ManualExternal = 'ManualExternal',
}

export type RecommenedProducts = {
  products: Maybe<Array<Maybe<ProductType>>>
}

export type RemoveCreditCardType = {
  cardID: Maybe<Scalars['String']>
}

export type RootMutation = {
  addCreditCard: Maybe<DineInCreditCardType>
  /** Apply one of promotion types to order. */
  applyPromoToOrder: Maybe<OrderResultType>
  /** This will stamp a enum to call staff */
  callStaff: Maybe<OrderType>
  /** The cancelCheckout will unlock the order to add items more */
  cancelCheckout: Maybe<OrderType>
  /** Checkout the order using specified payments */
  checkout: Maybe<OrderCheckoutType>
  /** The initiateCheckout will lock the order not allow additional items more */
  initiateCheckout: Maybe<InitiateCheckoutResultType>
  removeCreditCard: Maybe<RemoveCreditCardType>
  /** Remove one adjustment which matching promotionID or externalCouponID. Provide either promotionID or externalCouponID associated to adjustment to remove */
  removeOrderAdjustment: Maybe<OrderResultType>
  /** Share my member coupons to the order */
  shareMyMemberCoupons: Maybe<ShareMyMemberCouponsResultType>
  /** Add line items to the current order */
  submitOrderLineItems: Maybe<OrderResultType>
  updateOrder: Maybe<OrderResultType>
  updateOrderPaymentMethod: Maybe<OrderResultType>
}

export type RootMutationAddCreditCardArgs = {
  token: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootMutationApplyPromoToOrderArgs = {
  externalMemberCouponRefID?: InputMaybe<Scalars['String']>
  hatoHeartMemberCouponID?: InputMaybe<Scalars['Int']>
  orderID: Scalars['Int']
  promoCode?: InputMaybe<Scalars['String']>
}

export type RootMutationCallStaffArgs = {
  orderID: Scalars['Int']
  reason?: InputMaybe<StaffCallingReasonEnum>
}

export type RootMutationCancelCheckoutArgs = {
  orderID: Scalars['Int']
}

export type RootMutationCheckoutArgs = {
  orderID: Scalars['Int']
  payment: PaymentInput
}

export type RootMutationInitiateCheckoutArgs = {
  orderID: Scalars['Int']
  override?: InputMaybe<Scalars['Boolean']>
}

export type RootMutationRemoveCreditCardArgs = {
  cardID: Scalars['String']
  vendorID: Scalars['Int']
}

export type RootMutationRemoveOrderAdjustmentArgs = {
  externalCouponID?: InputMaybe<Scalars['Int']>
  hatoHeartMemberCouponID?: InputMaybe<Scalars['Int']>
  orderID: Scalars['Int']
  promotionID?: InputMaybe<Scalars['Int']>
}

export type RootMutationShareMyMemberCouponsArgs = {
  orderID: Scalars['Int']
}

export type RootMutationSubmitOrderLineItemsArgs = {
  lineItems: Array<InputMaybe<OrderLineItemInput>>
  orderID: Scalars['Int']
}

export type RootMutationUpdateOrderArgs = {
  order: OrderInput
}

export type RootMutationUpdateOrderPaymentMethodArgs = {
  cardID?: InputMaybe<Scalars['String']>
  orderID: Scalars['Int']
  phoneNumber?: InputMaybe<Scalars['String']>
  type: PaymentTypeEnum
}

export type RootQuery = {
  checkPaymentStatus: Maybe<PaymentType>
  /** Get current order by a table and will create when the order not existing */
  currentOrder: Maybe<OrderType>
  /** Get current user based on access token */
  currentUser: Maybe<LiffUser>
  /** currently this API support only dine-in orders */
  getCartRecommendedProducts: Maybe<RecommenedProducts>
  listBuffetPackageProducts: Maybe<ListCustomerBuffetPackageProductsResult>
  /** List categories filters only product is active */
  listCategories: Maybe<Array<Maybe<Category>>>
  listCreditCards: Maybe<Array<Maybe<DineInCreditCardType>>>
  listFPMemberCoupons: Maybe<Array<Maybe<ExternalCouponType>>>
  /** List active products */
  listProducts: Maybe<ListProducts>
  /** List active scheduled contents with highest priority for each placement */
  listScheduledContents: Maybe<Array<Maybe<ScheduledContentType>>>
  /** Get location by TableID */
  location: Maybe<LocationType>
  orderAndFPMemberCoupons: Maybe<OrderAndFpMemberCouponsResultType>
  orderAndHHMemberCoupons: Maybe<OrderAndHhMemberCouponsResultType>
  /** Get a product */
  product: Maybe<ProductType>
  /** Get temporary order with promotion */
  temporaryOrderWithPromotion: Maybe<OrderType>
  /** Get vendor */
  vendor: Maybe<VendorType>
  /** Get vendor setting */
  vendorSetting: Maybe<VendorSettingType>
}

export type RootQueryCheckPaymentStatusArgs = {
  orderID: Scalars['Int']
}

export type RootQueryCurrentOrderArgs = {
  orderID: Scalars['Int']
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}

export type RootQueryGetCartRecommendedProductsArgs = {
  experimentFeature?: InputMaybe<ProductRecommendationFeature>
  orderID: Scalars['Int']
  privateCartLineItems: Array<InputMaybe<OrderLineItemInput>>
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}

export type RootQueryListBuffetPackageProductsArgs = {
  categoryID?: InputMaybe<Scalars['Int']>
  keyword?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderID: Scalars['Int']
  serviceType?: InputMaybe<LineItemServiceTypeForBuffetEnum>
  vendorID: Scalars['Int']
}

export type RootQueryListCategoriesArgs = {
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}

export type RootQueryListCreditCardsArgs = {
  vendorID: Scalars['Int']
}

export type RootQueryListFpMemberCouponsArgs = {
  filter?: InputMaybe<ListFpMemberCouponsFilter>
  orderID?: InputMaybe<Scalars['Int']>
}

export type RootQueryListProductsArgs = {
  categoryID?: InputMaybe<Scalars['Int']>
  keyword?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  serviceType?: InputMaybe<LineItemServiceTypeForDineInEnum>
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}

export type RootQueryListScheduledContentsArgs = {
  filter: ScheduledContentFilter
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type RootQueryLocationArgs = {
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}

export type RootQueryOrderAndFpMemberCouponsArgs = {
  orderID?: InputMaybe<Scalars['Int']>
}

export type RootQueryOrderAndHhMemberCouponsArgs = {
  orderID?: InputMaybe<Scalars['Int']>
}

export type RootQueryProductArgs = {
  availableService?: InputMaybe<ProductAvailableServiceEnum>
  id: Scalars['Int']
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}

export type RootQueryTemporaryOrderWithPromotionArgs = {
  lineItems: Array<InputMaybe<OrderLineItemInput>>
  tableID: Scalars['Int']
}

export type RootQueryVendorArgs = {
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}

export type RootQueryVendorSettingArgs = {
  vendorID: Scalars['Int']
}

export enum ScheduledContentChannelEnum {
  Buffet = 'Buffet',
  Delivery = 'Delivery',
  DineIn = 'DineIn',
}

export type ScheduledContentFilter = {
  channel: ScheduledContentChannelEnum
  placements: Array<InputMaybe<ScheduledContentPlacementEnum>>
  vendorID: Scalars['Int']
}

export enum ScheduledContentPlacementEnum {
  Hero1 = 'Hero1',
  Hero2 = 'Hero2',
  Hero3 = 'Hero3',
  Hero4 = 'Hero4',
  Hero5 = 'Hero5',
  HeroHome1 = 'HeroHome1',
  HeroHome2 = 'HeroHome2',
}

export type ScheduledContentType = {
  categoryID: Maybe<Scalars['Int']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  placement: Maybe<ScheduledContentPlacementEnum>
  priority: Maybe<Scalars['Int']>
  products: Maybe<Array<Maybe<ProductType>>>
  titleEn: Maybe<Scalars['String']>
  titleTh: Maybe<Scalars['String']>
  type: Maybe<ScheduledContentTypeEnum>
  uri: Maybe<Scalars['String']>
  vendorID: Maybe<Scalars['Int']>
}

export enum ScheduledContentTypeEnum {
  Category = 'Category',
  SingleMedia = 'SingleMedia',
  SingleProduct = 'SingleProduct',
}

export type ShareMyMemberCouponsResultType = {
  order: Maybe<OrderType>
  /** Invalid states of the order */
  orderInvalids: Maybe<Array<Maybe<OrderInvalidEnum>>>
}

export enum ShippingFeePolicyEnum {
  Distance = 'Distance',
  Zone = 'Zone',
}

export enum SpicyLevelEnum {
  Hot = 'Hot',
  Medium = 'Medium',
  Mild = 'Mild',
  NotSpicy = 'NotSpicy',
}

export enum StaffCallingReasonEnum {
  Checkout = 'Checkout',
}

export enum StaffPrivilegeTypeTypeEnum {
  Cash = 'Cash',
  DiscountPercentage = 'DiscountPercentage',
}

export type TableGuestType = {
  allowSharingCoupon: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  userID: Maybe<Scalars['Int']>
}

export type TableType = {
  id: Maybe<Scalars['Int']>
  locationID: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  status: Maybe<Scalars['String']>
}

export type VendorSettingType = {
  bankAccounts: Maybe<Array<Maybe<BankAccountType>>>
  excludingVat: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['Int']>
  orderMinimumLineItemTotalSatangs: Maybe<Scalars['Int']>
  orderMinimumTotalSatangs: Maybe<Scalars['Int']>
  paymentMethods: Maybe<Array<Maybe<PaymentTypeEnum>>>
  vendorID: Maybe<Scalars['Int']>
}

export type VendorType = {
  active: Maybe<Scalars['Boolean']>
  /** Flag to determine if vendor is available for pick up. */
  canPickUp: Maybe<Scalars['Boolean']>
  descriptionEn: Maybe<Scalars['String']>
  descriptionTh: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  images: Maybe<Array<Maybe<ImageType>>>
  locations: Maybe<Array<Maybe<LocationType>>>
  logoImage: Maybe<ImageType>
  nameEn: Maybe<Scalars['String']>
  nameTh: Maybe<Scalars['String']>
  nearestLocation: Maybe<LocationType>
  quoteEn: Maybe<Scalars['String']>
  quoteTh: Maybe<Scalars['String']>
}

export type VendorTypeNearestLocationArgs = {
  deliveryGeolocation?: InputMaybe<GeolocationInput>
}

export type ApplyPromoToOrderMutationVariables = Exact<{
  orderID: Scalars['Int']
  promoCode?: InputMaybe<Scalars['String']>
  externalMemberCouponRefID?: InputMaybe<Scalars['String']>
  hatoHeartMemberCouponID?: InputMaybe<Scalars['Int']>
}>

export type ApplyPromoToOrderMutation = {
  applyPromoToOrder: {
    orderInvalids: Array<OrderInvalidEnum>
    promotionInvalids: Array<OrderPromotionInvalidEnum>
    order: {
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        promotionID: number
        externalCouponID: number
        adjustmentType: AdjustmentTypeEnumType
        source: AdjustmentSourceEnumType
        invalidReason: AdjustmentInvalidReasonEnumType
        externalCoupon: {
          id: number
          isAvailable: boolean
          endTime: string
          amount: number
          serialNumber: string
          rewardType: ExternalCouponRewardTypeEnum
          thumbnailImageURL: string
          name: string
        }
      }>
    }
  }
}

export type CallStaffMutationVariables = Exact<{
  orderID: Scalars['Int']
  reason?: InputMaybe<StaffCallingReasonEnum>
}>

export type CallStaffMutation = { callStaff: { id: number; staffCallingReason: StaffCallingReasonEnum } }

export type CancelCheckoutMutationVariables = Exact<{
  orderID: Scalars['Int']
}>

export type CancelCheckoutMutation = { cancelCheckout: { id: number } }

export type InitiateCheckoutMutationVariables = Exact<{
  orderID: Scalars['Int']
  override?: InputMaybe<Scalars['Boolean']>
}>

export type InitiateCheckoutMutation = {
  initiateCheckout: {
    orderWarnings: Array<OrderWarningEnum>
    orderInvalids: Array<OrderInvalidEnum>
    order: {
      id: number
      lineItemVatSatangs: number
      status: OrderStatusEnum
      userID: number
      lineItems: Array<{ id: number }>
      table: { id: number; name: string; status: string }
      user: { id: number; lineDisplayName: string; linePicture: string }
      payments: Array<{ status: PaymentStatusEnum; type: PaymentTypeEnum }>
    }
  }
}

export type RemoveOrderAdjustmentMutationVariables = Exact<{
  promotionID?: InputMaybe<Scalars['Int']>
  externalCouponID?: InputMaybe<Scalars['Int']>
  orderID: Scalars['Int']
  hatoHeartMemberCouponID?: InputMaybe<Scalars['Int']>
}>

export type RemoveOrderAdjustmentMutation = {
  removeOrderAdjustment: {
    orderInvalids: Array<OrderInvalidEnum>
    promotionInvalids: Array<OrderPromotionInvalidEnum>
    order: {
      id: number
      adjustments: Array<{
        id: number
        externalCouponID: number
        adjustmentType: AdjustmentTypeEnumType
        source: AdjustmentSourceEnumType
        invalidReason: AdjustmentInvalidReasonEnumType
        externalCoupon: { id: number; isAvailable: boolean; endTime: string; amount: number; serialNumber: string }
      }>
    }
  }
}

export type ShareMyMemberCouponsMutationVariables = Exact<{
  orderID: Scalars['Int']
}>

export type ShareMyMemberCouponsMutation = {
  shareMyMemberCoupons: {
    orderInvalids: Array<OrderInvalidEnum>
    order: { id: number; tableGuests: Array<{ id: number; userID: number; allowSharingCoupon: boolean }> }
  }
}

export type SubmitOrderLineItemsMutationVariables = Exact<{
  orderID: Scalars['Int']
  lineItems: Array<InputMaybe<OrderLineItemInput>> | InputMaybe<OrderLineItemInput>
}>

export type SubmitOrderLineItemsMutation = {
  submitOrderLineItems: {
    orderInvalids: Array<OrderInvalidEnum>
    promotionInvalids: Array<OrderPromotionInvalidEnum>
    order: {
      id: number
      buffetEndTime: string
      lineItems: Array<{
        id: number
        productID: number
        invalids: Array<LineItemInvalidEnum>
        modifiers: Array<{ modifier: { nameEn: string; nameTh: string; invalids: Array<InvalidModifierEnum> } }>
      }>
    }
  }
}

export type UpdateOrderPaymentMethodMutationVariables = Exact<{
  orderID: Scalars['Int']
  type: PaymentTypeEnum
  cardID?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
}>

export type UpdateOrderPaymentMethodMutation = {
  updateOrderPaymentMethod: {
    orderInvalids: Array<OrderInvalidEnum>
    order: {
      id: number
      paymentType: PaymentTypeEnum
      paymentPhone: string
      omiseCardID: string
      omiseCardLastDigits: string
      omiseCardholderName: string
      omiseCardBrand: string
    }
  }
}

export type BuffetCheckoutQueryVariables = Exact<{
  vendorID: Scalars['Int']
  orderID: Scalars['Int']
  tableID: Scalars['Int']
}>

export type BuffetCheckoutQuery = {
  currentOrder: {
    id: number
    lineItemVatSatangs: number
    status: OrderStatusEnum
    adjustmentCashTotalSatangs: number
    adjustmentStaffCashTotalSatangs: number
    totalSatangs: number
    totalDueSatangs: number
    lineItemTotalSatangs: number
    userID: number
    paymentType: PaymentTypeEnum
    paymentPhone: string
    omiseCardID: string
    omiseCardLastDigits: string
    omiseCardholderName: string
    omiseCardBrand: string
    adjustments: Array<{
      id: number
      labelEn: string
      labelTh: string
      amountSatangs: number
      fullAmountSatangs: number
      adjustmentType: AdjustmentTypeEnumType
      invalidReason: AdjustmentInvalidReasonEnumType
      promotion: { descriptionEn: string; descriptionTh: string; type: PromotionTypeEnum }
      externalCoupon: { id: number; staffPrivilegeType: StaffPrivilegeTypeTypeEnum }
    }>
    buffetItems: Array<{ id: number; buffetItemType: BuffetItemTypeEnum; totalSatangs: number }>
    lineItems: Array<{ id: number }>
    table: { id: number; name: string; status: string }
    user: { id: number; lineDisplayName: string; linePicture: string }
    payments: Array<{ status: PaymentStatusEnum; type: PaymentTypeEnum }>
  }
  orderAndFPMemberCoupons: {
    orderInvalids: Array<OrderInvalidEnum>
    order: {
      id: number
      adjustmentCashTotalSatangs: number
      adjustmentStaffCashTotalSatangs: number
      totalSatangs: number
      totalDueSatangs: number
      lineItemTotalSatangs: number
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        fullAmountSatangs: number
        adjustmentType: AdjustmentTypeEnumType
        invalidReason: AdjustmentInvalidReasonEnumType
        promotion: { descriptionEn: string; descriptionTh: string; type: PromotionTypeEnum }
        externalCoupon: { id: number; staffPrivilegeType: StaffPrivilegeTypeTypeEnum }
      }>
    }
  }
  vendor: { id: number; nameEn: string; nameTh: string; logoImage: { resizableURL: string; originalURL: string } }
  vendorSetting: { paymentMethods: Array<PaymentTypeEnum> }
}

export type BuffetGlobalCartQueryVariables = Exact<{
  tableID: Scalars['Int']
  orderID: Scalars['Int']
  vendorID: Scalars['Int']
}>

export type BuffetGlobalCartQuery = {
  currentOrder: {
    id: number
    totalSatangs: number
    lineItemTotalSatangs: number
    userID: number
    status: OrderStatusEnum
    staffCallingReason: StaffCallingReasonEnum
    buffetEndTime: string
    buffetPackageID: number
    buffetItems: Array<{
      id: number
      buffetItemType: BuffetItemTypeEnum
      pricePerUnitSatangs: number
      quantity: number
      totalSatangs: number
      buffetItem: { labelEn: string; labelTh: string }
    }>
    buffetPackage: { id: number; nameEn: string; nameTh: string; level: number }
    lineItems: Array<{
      id: number
      buffetPackageID: number
      lineItemBatchID: number
      productID: number
      totalSatangs: number
      customNotes: string
      quantity: number
      invalids: Array<LineItemInvalidEnum>
      status: LineItemStatusEnum
      createdAt: string
      serviceType: LineItemServiceEnum
      product: {
        id: number
        nameEn: string
        nameTh: string
        excludedFromPromotion: boolean
        images: Array<{ id: number; resizableURL: string }>
      }
      modifiers: Array<{
        modifierID: number
        quantity: number
        modifier: {
          nameEn: string
          nameTh: string
          priceSatangs: number
          invalids: Array<InvalidModifierEnum>
          modifierGroup: { multipleQuantity: boolean }
        }
      }>
      createdByUser: { id: number; linePicture: string; lineDisplayName: string }
    }>
  }
}

export type BuffetOrderQueryVariables = Exact<{
  orderID: Scalars['Int']
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}>

export type BuffetOrderQuery = {
  currentOrder: {
    id: number
    status: OrderStatusEnum
    userID: number
    buffetPackageID: number
    buffetEndTime: string
    staffCallingReason: StaffCallingReasonEnum
    buffetPackage: { id: number; nameEn: string; nameTh: string; level: number }
    payments: Array<{
      id: number
      failureReason: string
      failureMessage: string
      type: PaymentTypeEnum
      status: PaymentStatusEnum
    }>
    table: { id: number; status: string }
    tableGuests: Array<{ userID: number; allowSharingCoupon: boolean }>
  }
}

export type BuffetOrderFpWithPromotionQueryVariables = Exact<{
  orderID?: InputMaybe<Scalars['Int']>
}>

export type BuffetOrderFpWithPromotionQuery = {
  orderAndFPMemberCoupons: {
    order: {
      id: number
      totalSatangs: number
      lineItemTotalSatangs: number
      userID: number
      status: OrderStatusEnum
      staffCallingReason: StaffCallingReasonEnum
      buffetEndTime: string
      buffetPackageID: number
      adjustmentTotalSatangs: number
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        adjustmentType: AdjustmentTypeEnumType
        promotion: { id: number; type: PromotionTypeEnum }
      }>
      buffetItems: Array<{
        id: number
        buffetItemType: BuffetItemTypeEnum
        pricePerUnitSatangs: number
        quantity: number
        totalSatangs: number
        buffetItem: { labelEn: string; labelTh: string }
      }>
      buffetPackage: { id: number; nameEn: string; nameTh: string; level: number }
      lineItems: Array<{
        id: number
        buffetPackageID: number
        lineItemBatchID: number
        productID: number
        totalSatangs: number
        customNotes: string
        quantity: number
        invalids: Array<LineItemInvalidEnum>
        status: LineItemStatusEnum
        createdAt: string
        serviceType: LineItemServiceEnum
        product: {
          id: number
          nameEn: string
          nameTh: string
          excludedFromPromotion: boolean
          images: Array<{ id: number; resizableURL: string }>
        }
        modifiers: Array<{
          modifierID: number
          quantity: number
          modifier: {
            nameEn: string
            nameTh: string
            priceSatangs: number
            invalids: Array<InvalidModifierEnum>
            modifierGroup: { multipleQuantity: boolean }
          }
        }>
        createdByUser: { id: number; linePicture: string; lineDisplayName: string }
      }>
    }
  }
}

export type BuffetOrderHhWithPromotionQueryVariables = Exact<{
  orderID?: InputMaybe<Scalars['Int']>
}>

export type BuffetOrderHhWithPromotionQuery = {
  orderAndHHMemberCoupons: {
    order: {
      id: number
      totalSatangs: number
      lineItemTotalSatangs: number
      userID: number
      status: OrderStatusEnum
      staffCallingReason: StaffCallingReasonEnum
      buffetEndTime: string
      buffetPackageID: number
      adjustmentTotalSatangs: number
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        adjustmentType: AdjustmentTypeEnumType
        promotion: { id: number; type: PromotionTypeEnum }
      }>
      buffetItems: Array<{
        id: number
        buffetItemType: BuffetItemTypeEnum
        pricePerUnitSatangs: number
        quantity: number
        totalSatangs: number
        buffetItem: { labelEn: string; labelTh: string }
      }>
      buffetPackage: { id: number; nameEn: string; nameTh: string; level: number }
      lineItems: Array<{
        id: number
        buffetPackageID: number
        lineItemBatchID: number
        productID: number
        totalSatangs: number
        customNotes: string
        quantity: number
        invalids: Array<LineItemInvalidEnum>
        status: LineItemStatusEnum
        createdAt: string
        serviceType: LineItemServiceEnum
        product: {
          id: number
          nameEn: string
          nameTh: string
          excludedFromPromotion: boolean
          images: Array<{ id: number; resizableURL: string }>
        }
        modifiers: Array<{
          modifierID: number
          quantity: number
          modifier: {
            nameEn: string
            nameTh: string
            priceSatangs: number
            invalids: Array<InvalidModifierEnum>
            modifierGroup: { multipleQuantity: boolean }
          }
        }>
        createdByUser: { id: number; linePicture: string; lineDisplayName: string }
      }>
    }
  }
}

export type BuffetOrderPackageQueryVariables = Exact<{
  tableID: Scalars['Int']
  orderID: Scalars['Int']
  vendorID: Scalars['Int']
}>

export type BuffetOrderPackageQuery = {
  currentOrder: { buffetPackage: { id: number; nameEn: string; nameTh: string; level: number } }
}

export type BuffetProductDetailQueryVariables = Exact<{
  id: Scalars['Int']
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
  orderID: Scalars['Int']
  categoryID: Scalars['Int']
  limit?: InputMaybe<Scalars['Int']>
  offset: Scalars['Int']
}>

export type BuffetProductDetailQuery = {
  vendor: { id: number; nameEn: string; nameTh: string; logoImage: { resizableURL: string; originalURL: string } }
  location: { id: number; nameEn: string; nameTh: string }
  currentOrder: { id: number; buffetPackageID: number; table: { id: number; name: string } }
  product: {
    id: number
    nameEn: string
    nameTh: string
    descriptionEn: string
    descriptionTh: string
    categoryID: number
    tasteDescriptionTh: string
    tasteDescriptionEn: string
    availableServices: Array<ProductServiceEnum>
    excludedFromPromotion: boolean
    discountSatangs: number
    priceSatangs: number
    totalPriceSatangs: number
    tags: Array<ProductTagEnum>
    memberTierRankConditions: Array<ProductMemberTierRankConditionsEnum>
    memberIneligibleReason: MemberProductIneligibleReasonEnum
    maxSpicyLevel: SpicyLevelEnum
    skipCustomNotesScreen: boolean
    excludedFromListing: boolean
    ingredientList: Array<{ id: number; nameTh: string; nameEn: string }>
    images: Array<{ id: number; resizableURL: string; thumbnailResizableURL: string; originalURL: string }>
    nutrition: {
      id: number
      calories: number
      caloriesFromFat: number
      carbG: number
      cholesterolMg: number
      fatG: number
      fiberG: number
      proteinG: number
      saturatedFatG: number
      servingsPerItem: number
      sodiumMg: number
      sugarsG: number
      transFatG: number
    }
  }
  listBuffetPackageProducts: {
    products: Array<{
      id: number
      nameEn: string
      nameTh: string
      descriptionEn: string
      descriptionTh: string
      priceSatangs: number
      skipModifierScreen: boolean
      active: boolean
      categoryID: number
      memberIneligibleReason: MemberProductIneligibleReasonEnum
      memberTierRankConditions: Array<ProductMemberTierRankConditionsEnum>
      totalPriceSatangs: number
      discountSatangs: number
      availableServices: Array<ProductServiceEnum>
      excludedFromPromotion: boolean
      excludedFromListing: boolean
      tags: Array<ProductTagEnum>
      skipCustomNotesScreen: boolean
      images: Array<{ id: number; resizableURL: string }>
      category: {
        id: number
        nameEn: string
        nameTh: string
        descriptionEn: string
        descriptionTh: string
        position: number
        images: Array<{ id: number; resizableURL: string; originalURL: string }>
      }
      buffetPackageProducts: Array<{
        id: number
        buffetPackageID: number
        buffetPackage: { nameEn: string; nameTh: string }
      }>
    }>
  }
}

export type BuffetProductListQueryVariables = Exact<{
  vendorID: Scalars['Int']
  tableID: Scalars['Int']
  orderID: Scalars['Int']
  keyword?: InputMaybe<Scalars['String']>
  categoryID?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  serviceType?: InputMaybe<LineItemServiceTypeForBuffetEnum>
}>

export type BuffetProductListQuery = {
  listBuffetPackageProducts: {
    products: Array<{
      id: number
      nameEn: string
      nameTh: string
      descriptionEn: string
      descriptionTh: string
      priceSatangs: number
      skipModifierScreen: boolean
      active: boolean
      availableServices: Array<ProductServiceEnum>
      categoryID: number
      memberIneligibleReason: MemberProductIneligibleReasonEnum
      memberTierRankConditions: Array<ProductMemberTierRankConditionsEnum>
      totalPriceSatangs: number
      discountSatangs: number
      excludedFromPromotion: boolean
      excludedFromListing: boolean
      tags: Array<ProductTagEnum>
      skipCustomNotesScreen: boolean
      images: Array<{ id: number; resizableURL: string }>
      category: {
        id: number
        nameEn: string
        nameTh: string
        descriptionEn: string
        descriptionTh: string
        position: number
        images: Array<{ id: number; resizableURL: string; originalURL: string }>
      }
      buffetPackageProducts: Array<{
        id: number
        buffetPackageID: number
        buffetPackage: { nameEn: string; nameTh: string }
      }>
    }>
  }
  listCategories: Array<{
    id: number
    nameTh: string
    nameEn: string
    images: Array<{ originalURL: string; resizableURL: string }>
  }>
  currentOrder: {
    id: number
    buffetPackageID: number
    table: { id: number; name: string; status: string }
    buffetPackage: { nameTh: string; nameEn: string }
  }
  vendor: { id: number; nameEn: string; nameTh: string; logoImage: { resizableURL: string; originalURL: string } }
}

export type CheckPaymentStatusQueryVariables = Exact<{
  orderID: Scalars['Int']
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}>

export type CheckPaymentStatusQuery = {
  checkPaymentStatus: {
    id: number
    status: PaymentStatusEnum
    failureReason: string
    failureMessage: string
    type: PaymentTypeEnum
  }
  currentOrder: { buffetPackageID: number }
}

export type CheckoutMutationVariables = Exact<{
  orderID: Scalars['Int']
  payment: PaymentInput
}>

export type CheckoutMutation = {
  checkout: {
    orderInvalids: Array<OrderInvalidEnum>
    KPaymentResult: {
      failureCode: KPaymentResultFailureCodeEnum
      failureMessage: string
      phone: string
      status: KPaymentResultStatusEnum
    }
    chargeResult: {
      authorizeURI: string
      chargeID: string
      failureCode: string
      failureMessage: string
      status: string
    }
    order: {
      id: number
      status: OrderStatusEnum
      userID: number
      table: { id: number; locationID: number; name: string; status: string }
      payments: Array<{ status: PaymentStatusEnum; type: PaymentTypeEnum }>
    }
  }
}

export type CurrentOrderQueryVariables = Exact<{
  tableID: Scalars['Int']
  orderID: Scalars['Int']
  vendorID: Scalars['Int']
}>

export type CurrentOrderQuery = {
  currentOrder: {
    id: number
    lineItemVatSatangs: number
    status: OrderStatusEnum
    totalSatangs: number
    userID: number
    paymentType: PaymentTypeEnum
    paymentPhone: string
    omiseCardID: string
    omiseCardLastDigits: string
    omiseCardBrand: string
    omiseCardholderName: string
    lineItems: Array<{ id: number }>
    table: { id: number; name: string; status: string }
    user: { id: number; lineDisplayName: string; linePicture: string }
    payments: Array<{ id: number; status: PaymentStatusEnum; type: PaymentTypeEnum }>
    tableGuests: Array<{ userID: number; allowSharingCoupon: boolean }>
  }
}

export type GetCartRecommendedProductsQueryVariables = Exact<{
  orderID: Scalars['Int']
  vendorID: Scalars['Int']
  tableID: Scalars['Int']
  privateCartLineItems: Array<InputMaybe<OrderLineItemInput>> | InputMaybe<OrderLineItemInput>
  experimentFeature?: InputMaybe<ProductRecommendationFeature>
}>

export type GetCartRecommendedProductsQuery = {
  getCartRecommendedProducts: {
    products: Array<{
      id: number
      priceSatangs: number
      totalPriceSatangs: number
      discountSatangs: number
      nameTh: string
      nameEn: string
      categoryID: number
      tags: Array<ProductTagEnum>
      memberTierRankConditions: Array<ProductMemberTierRankConditionsEnum>
      memberIneligibleReason: MemberProductIneligibleReasonEnum
      skipCustomNotesScreen: boolean
      excludedFromPromotion: boolean
      promotionBadge: PromotionStrategyTypeEnum
      modifierGroups: Array<{ id: number }>
      images: Array<{ id: number; resizableURL: string; thumbnailResizableURL: string; originalURL: string }>
      promotionProduct: {
        productGroupRewards: Array<{ productIDs: Array<number> }>
        promotion: { id: number; strategyType: PromotionStrategyTypeEnum }
      }
    }>
  }
}

export type GlobalCartQueryVariables = Exact<{
  tableID: Scalars['Int']
  orderID: Scalars['Int']
  vendorID: Scalars['Int']
}>

export type GlobalCartQuery = {
  currentOrder: {
    id: number
    totalSatangs: number
    lineItemTotalSatangs: number
    userID: number
    status: OrderStatusEnum
    lineItemVatSatangs: number
    paymentType: PaymentTypeEnum
    paymentPhone: string
    omiseCardID: string
    omiseCardLastDigits: string
    omiseCardBrand: string
    lineItems: Array<{
      id: number
      lineItemBatchID: number
      productID: number
      totalSatangs: number
      customNotes: string
      quantity: number
      invalids: Array<LineItemInvalidEnum>
      status: LineItemStatusEnum
      createdAt: string
      serviceType: LineItemServiceEnum
      product: {
        id: number
        nameEn: string
        nameTh: string
        excludedFromPromotion: boolean
        images: Array<{ id: number; resizableURL: string }>
      }
      modifiers: Array<{
        modifierID: number
        quantity: number
        modifier: {
          nameEn: string
          nameTh: string
          priceSatangs: number
          invalids: Array<InvalidModifierEnum>
          modifierGroup: { multipleQuantity: boolean }
        }
      }>
      createdByUser: { id: number; linePicture: string; lineDisplayName: string }
    }>
    table: { id: number; name: string; status: string }
    user: { id: number; lineDisplayName: string; linePicture: string }
    payments: Array<{ id: number; status: PaymentStatusEnum; type: PaymentTypeEnum }>
  }
}

export type ListFpMemberCouponsQueryVariables = Exact<{
  orderID?: InputMaybe<Scalars['Int']>
  filter?: InputMaybe<ListFpMemberCouponsFilter>
}>

export type ListFpMemberCouponsQuery = {
  listFPMemberCoupons: Array<{
    isAvailable: boolean
    startTime: string
    endTime: string
    serialNumber: string
    rewardType: ExternalCouponRewardTypeEnum
    imageURL: string
    name: string
    memberCouponRefID: string
    description: string
    membership: { id: number; user: { id: number; lineDisplayName: string; linePicture: string } }
  }>
}

export type ListProductsQueryVariables = Exact<{
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  tableID: Scalars['Int']
  keyword: Scalars['String']
  vendorID: Scalars['Int']
  serviceType?: InputMaybe<LineItemServiceTypeForDineInEnum>
}>

export type ListProductsQuery = {
  listProducts: {
    pagination: { count: number; limit: number; offset: number; total: number }
    products: Array<{
      id: number
      priceSatangs: number
      totalPriceSatangs: number
      discountSatangs: number
      nameTh: string
      nameEn: string
      categoryID: number
      tags: Array<ProductTagEnum>
      memberTierRankConditions: Array<ProductMemberTierRankConditionsEnum>
      memberIneligibleReason: MemberProductIneligibleReasonEnum
      skipCustomNotesScreen: boolean
      excludedFromPromotion: boolean
      promotionBadge: PromotionStrategyTypeEnum
      modifierGroups: Array<{ id: number }>
      images: Array<{ id: number; resizableURL: string; thumbnailResizableURL: string; originalURL: string }>
      promotionProduct: {
        productGroupRewards: Array<{ productIDs: Array<number> }>
        promotion: { id: number; strategyType: PromotionStrategyTypeEnum }
      }
    }>
  }
}

export type ListScheduledContentsQueryVariables = Exact<{
  offset: Scalars['Int']
  limit: Scalars['Int']
  filter: ScheduledContentFilter
}>

export type ListScheduledContentsQuery = {
  listScheduledContents: Array<{
    id: number
    titleEn: string
    titleTh: string
    descriptionEn: string
    descriptionTh: string
    placement: ScheduledContentPlacementEnum
    type: ScheduledContentTypeEnum
    uri: string
    vendorID: number
    categoryID: number
    images: Array<{ id: number; originalURL: string; resizableURL: string; type: ImageTypeEnum }>
    products: Array<{
      sku: string
      id: number
      active: boolean
      derivedColorScheme: ProductColorSchemeEnum
      discountSatangs: number
      priceSatangs: number
      totalPriceSatangs: number
      nameTh: string
      nameEn: string
      customNotesLabelTh: string
      customNotesLabelEn: string
      skipCustomNotesScreen: boolean
      categoryID: number
      maxSpicyLevel: SpicyLevelEnum
      tags: Array<ProductTagEnum>
      category: { id: number; nameTh: string; nameEn: string }
      images: Array<{ id: number; resizableURL: string; originalURL: string; type: ImageTypeEnum }>
    }>
  }>
}

export type LocationQueryVariables = Exact<{
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}>

export type LocationQuery = { location: { id: number; nameEn: string; nameTh: string } }

export type OrderAdjustmentsQueryVariables = Exact<{
  orderID?: InputMaybe<Scalars['Int']>
}>

export type OrderAdjustmentsQuery = {
  orderAndFPMemberCoupons: {
    orderInvalids: Array<OrderInvalidEnum>
    order: {
      id: number
      adjustmentCashTotalSatangs: number
      adjustmentStaffCashTotalSatangs: number
      totalSatangs: number
      totalDueSatangs: number
      lineItemTotalSatangs: number
      excludingVat: boolean
      lineItemVatSatangs: number
      lineItems: Array<{
        id: number
        quantity: number
        totalSatangs: number
        product: {
          id: number
          nameTh: string
          nameEn: string
          discountSatangs: number
          priceSatangs: number
          vendor: { id: number; nameTh: string }
          category: { id: number; nameTh: string }
        }
      }>
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        fullAmountSatangs: number
        adjustmentType: AdjustmentTypeEnumType
        invalidReason: AdjustmentInvalidReasonEnumType
        promotion: { descriptionEn: string; descriptionTh: string; type: PromotionTypeEnum }
        externalCoupon: { id: number; staffPrivilegeType: StaffPrivilegeTypeTypeEnum }
      }>
    }
  }
}

export type OrderAndFpMemberCouponsQueryVariables = Exact<{
  orderID?: InputMaybe<Scalars['Int']>
}>

export type OrderAndFpMemberCouponsQuery = {
  orderAndFPMemberCoupons: {
    order: {
      id: number
      userID: number
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        promotionID: number
        memberCouponID: number
        externalCouponID: number
        adjustmentType: AdjustmentTypeEnumType
        source: AdjustmentSourceEnumType
        invalidReason: AdjustmentInvalidReasonEnumType
        promotion: {
          descriptionEn: string
          descriptionTh: string
          id: number
          nameEn: string
          nameTh: string
          type: PromotionTypeEnum
          memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
          targetEntity: AdjustmentTargetEntityTypeEnum
        }
        memberCoupon: {
          id: number
          endDate: string
          redeemedAt: string
          startDate: string
          status: MemberCouponStatusEnumType
          promotion: {
            id: number
            conditionDetailsEn: string
            conditionDetailsTh: string
            descriptionEn: string
            descriptionTh: string
            extPromotionID: string
            nameEn: string
            nameTh: string
            images: Array<{ resizeableURL: string }>
          }
        }
        externalCoupon: {
          id: number
          isAvailable: boolean
          endTime: string
          amount: number
          serialNumber: string
          rewardType: ExternalCouponRewardTypeEnum
          thumbnailImageURL: string
          name: string
          description: string
          memberCouponRefID: string
          staffPrivilegeType: StaffPrivilegeTypeTypeEnum
        }
        invalidData: { minimumSatangs: number; remainingMinimumSatangs: number }
      }>
    }
    coupons: Array<{
      id: number
      isAvailable: boolean
      endTime: string
      amount: number
      serialNumber: string
      rewardType: ExternalCouponRewardTypeEnum
      thumbnailImageURL: string
      name: string
      memberCouponRefID: string
      description: string
      membership: { id: number; user: { id: number; lineDisplayName: string; linePicture: string } }
    }>
    autoPromotions: Array<{
      id: number
      code: string
      descriptionEn: string
      descriptionTh: string
      nameEn: string
      nameTh: string
      memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
      targetEntity: AdjustmentTargetEntityTypeEnum
    }>
    manualPromotions: Array<{
      id: number
      code: string
      descriptionEn: string
      descriptionTh: string
      nameEn: string
      nameTh: string
      memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
      targetEntity: AdjustmentTargetEntityTypeEnum
    }>
  }
}

export type OrderAndHhMemberCouponsQueryVariables = Exact<{
  orderID: Scalars['Int']
}>

export type OrderAndHhMemberCouponsQuery = {
  orderAndHHMemberCoupons: {
    order: {
      id: number
      userID: number
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        promotionID: number
        memberCouponID: number
        externalCouponID: number
        adjustmentType: AdjustmentTypeEnumType
        source: AdjustmentSourceEnumType
        invalidReason: AdjustmentInvalidReasonEnumType
        promotion: {
          descriptionEn: string
          descriptionTh: string
          id: number
          nameEn: string
          nameTh: string
          type: PromotionTypeEnum
          memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
          targetEntity: AdjustmentTargetEntityTypeEnum
        }
        memberCoupon: {
          id: number
          endDate: string
          redeemedAt: string
          startDate: string
          status: MemberCouponStatusEnumType
          promotion: {
            id: number
            conditionDetailsEn: string
            conditionDetailsTh: string
            descriptionEn: string
            descriptionTh: string
            extPromotionID: string
            nameEn: string
            nameTh: string
            images: Array<{ resizeableURL: string }>
          }
        }
        externalCoupon: {
          id: number
          isAvailable: boolean
          endTime: string
          amount: number
          serialNumber: string
          rewardType: ExternalCouponRewardTypeEnum
          thumbnailImageURL: string
          name: string
          description: string
          memberCouponRefID: string
          staffPrivilegeType: StaffPrivilegeTypeTypeEnum
        }
        invalidData: { minimumSatangs: number; remainingMinimumSatangs: number }
      }>
    }
    coupons: Array<{
      isAvailable: boolean
      coupon: {
        id: number
        endDate: string
        redeemedAt: string
        startDate: string
        status: MemberCouponStatusEnumType
        promotion: {
          id: number
          conditionDetailsEn: string
          conditionDetailsTh: string
          descriptionEn: string
          descriptionTh: string
          extPromotionID: string
          nameEn: string
          nameTh: string
          images: Array<{ resizeableURL: string }>
        }
      }
    }>
    autoPromotions: Array<{
      id: number
      code: string
      descriptionEn: string
      descriptionTh: string
      nameEn: string
      nameTh: string
      memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
      targetEntity: AdjustmentTargetEntityTypeEnum
    }>
    manualPromotions: Array<{
      id: number
      code: string
      descriptionEn: string
      descriptionTh: string
      nameEn: string
      nameTh: string
      memberTierRankConditions: Array<PromotionMemberTierRankConditionEnumType>
      targetEntity: AdjustmentTargetEntityTypeEnum
    }>
  }
}

export type OrderFpWithPromotionQueryVariables = Exact<{
  orderID?: InputMaybe<Scalars['Int']>
}>

export type OrderFpWithPromotionQuery = {
  orderAndFPMemberCoupons: {
    order: {
      id: number
      totalSatangs: number
      adjustmentTotalSatangs: number
      lineItemTotalSatangs: number
      userID: number
      status: OrderStatusEnum
      lineItemVatSatangs: number
      paymentType: PaymentTypeEnum
      paymentPhone: string
      omiseCardID: string
      omiseCardLastDigits: string
      omiseCardBrand: string
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        adjustmentType: AdjustmentTypeEnumType
        promotion: { id: number; type: PromotionTypeEnum }
      }>
      lineItems: Array<{
        id: number
        lineItemBatchID: number
        productID: number
        totalSatangs: number
        customNotes: string
        quantity: number
        invalids: Array<LineItemInvalidEnum>
        status: LineItemStatusEnum
        createdAt: string
        serviceType: LineItemServiceEnum
        product: {
          id: number
          nameEn: string
          nameTh: string
          excludedFromPromotion: boolean
          images: Array<{ id: number; resizableURL: string }>
        }
        modifiers: Array<{
          modifierID: number
          quantity: number
          modifier: {
            nameEn: string
            nameTh: string
            priceSatangs: number
            invalids: Array<InvalidModifierEnum>
            modifierGroup: { multipleQuantity: boolean }
          }
        }>
        createdByUser: { id: number; linePicture: string; lineDisplayName: string }
      }>
      table: { id: number; name: string; status: string }
      user: { id: number; lineDisplayName: string; linePicture: string }
      payments: Array<{ id: number; status: PaymentStatusEnum; type: PaymentTypeEnum }>
    }
  }
}

export type OrderHhWithPromotionQueryVariables = Exact<{
  orderID?: InputMaybe<Scalars['Int']>
}>

export type OrderHhWithPromotionQuery = {
  orderAndHHMemberCoupons: {
    order: {
      id: number
      totalSatangs: number
      adjustmentTotalSatangs: number
      lineItemTotalSatangs: number
      userID: number
      status: OrderStatusEnum
      lineItemVatSatangs: number
      paymentType: PaymentTypeEnum
      paymentPhone: string
      omiseCardID: string
      omiseCardLastDigits: string
      omiseCardBrand: string
      adjustments: Array<{
        id: number
        labelEn: string
        labelTh: string
        amountSatangs: number
        adjustmentType: AdjustmentTypeEnumType
        promotion: { id: number; type: PromotionTypeEnum }
      }>
      lineItems: Array<{
        id: number
        lineItemBatchID: number
        productID: number
        totalSatangs: number
        customNotes: string
        quantity: number
        invalids: Array<LineItemInvalidEnum>
        status: LineItemStatusEnum
        createdAt: string
        serviceType: LineItemServiceEnum
        product: {
          id: number
          nameEn: string
          nameTh: string
          excludedFromPromotion: boolean
          images: Array<{ id: number; resizableURL: string }>
        }
        modifiers: Array<{
          modifierID: number
          quantity: number
          modifier: {
            nameEn: string
            nameTh: string
            priceSatangs: number
            invalids: Array<InvalidModifierEnum>
            modifierGroup: { multipleQuantity: boolean }
          }
        }>
        createdByUser: { id: number; linePicture: string; lineDisplayName: string }
      }>
      table: { id: number; name: string; status: string }
      user: { id: number; lineDisplayName: string; linePicture: string }
      payments: Array<{ id: number; status: PaymentStatusEnum; type: PaymentTypeEnum }>
    }
  }
}

export type PaymentMethodQueryVariables = Exact<{
  vendorID: Scalars['Int']
  orderID: Scalars['Int']
  tableID: Scalars['Int']
}>

export type PaymentMethodQuery = {
  currentOrder: {
    id: number
    lineItemVatSatangs: number
    status: OrderStatusEnum
    adjustmentCashTotalSatangs: number
    adjustmentStaffCashTotalSatangs: number
    totalSatangs: number
    totalDueSatangs: number
    lineItemTotalSatangs: number
    userID: number
    paymentType: PaymentTypeEnum
    paymentPhone: string
    omiseCardID: string
    omiseCardLastDigits: string
    omiseCardholderName: string
    omiseCardBrand: string
    payments: Array<{ status: PaymentStatusEnum; type: PaymentTypeEnum }>
  }
  vendor: { id: number; nameEn: string; nameTh: string; logoImage: { resizableURL: string; originalURL: string } }
  vendorSetting: { paymentMethods: Array<PaymentTypeEnum> }
}

export type ProductQueryVariables = Exact<{
  id: Scalars['Int']
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
}>

export type ProductQuery = {
  product: {
    id: number
    nameEn: string
    nameTh: string
    descriptionEn: string
    descriptionTh: string
    tasteDescriptionTh: string
    tasteDescriptionEn: string
    excludedFromPromotion: boolean
    discountSatangs: number
    priceSatangs: number
    totalPriceSatangs: number
    categoryID: number
    tags: Array<ProductTagEnum>
    memberTierRankConditions: Array<ProductMemberTierRankConditionsEnum>
    memberIneligibleReason: MemberProductIneligibleReasonEnum
    maxSpicyLevel: SpicyLevelEnum
    skipCustomNotesScreen: boolean
    promotionBadge: PromotionStrategyTypeEnum
    modifierGroups: Array<{ id: number }>
    ingredientList: Array<{ id: number; nameTh: string; nameEn: string }>
    images: Array<{ id: number; resizableURL: string; thumbnailResizableURL: string; originalURL: string }>
    nutrition: {
      calories: number
      caloriesFromFat: number
      carbG: number
      cholesterolMg: number
      fatG: number
      fiberG: number
      proteinG: number
      saturatedFatG: number
      servingsPerItem: number
      sodiumMg: number
      sugarsG: number
      transFatG: number
    }
    promotionProduct: {
      productGroupRewards: Array<{ productIDs: Array<number> }>
      promotion: { id: number; strategyType: PromotionStrategyTypeEnum }
    }
  }
}

export type ProductWithModifierQueryVariables = Exact<{
  id: Scalars['Int']
  tableID: Scalars['Int']
  vendorID: Scalars['Int']
  availableService?: InputMaybe<ProductAvailableServiceEnum>
}>

export type ProductWithModifierQuery = {
  product: {
    id: number
    skipCustomNotesScreen: boolean
    modifierGroups: Array<{
      id: number
      active: boolean
      position: number
      maximumQuantity: number
      minimumQuantity: number
      modifierGroupID: number
      productID: number
      modifierGroup: {
        id: number
        nameEn: string
        nameTh: string
        vendorID: number
        multipleQuantity: boolean
        modifiers: Array<{
          id: number
          active: boolean
          nameEn: string
          nameTh: string
          position: number
          priceSatangs: number
          invalids: Array<InvalidModifierEnum>
          images: Array<{ id: number; alt: string; resizableURL: string }>
        }>
      }
    }>
    promotionProduct: {
      productGroupRewards: Array<{
        rewardType: PromotionRewardTypeEnum
        rewardValue: number
        products: Array<{
          id: number
          priceSatangs: number
          totalPriceSatangs: number
          discountSatangs: number
          excludedFromPromotion: boolean
          nameTh: string
          nameEn: string
          categoryID: number
          tags: Array<ProductTagEnum>
          memberTierRankConditions: Array<ProductMemberTierRankConditionsEnum>
          memberIneligibleReason: MemberProductIneligibleReasonEnum
          skipCustomNotesScreen: boolean
          images: Array<{ id: number; resizableURL: string }>
          modifierGroups: Array<{
            id: number
            active: boolean
            position: number
            maximumQuantity: number
            minimumQuantity: number
            modifierGroupID: number
            productID: number
            modifierGroup: {
              id: number
              nameEn: string
              nameTh: string
              vendorID: number
              multipleQuantity: boolean
              modifiers: Array<{
                id: number
                active: boolean
                nameEn: string
                nameTh: string
                position: number
                priceSatangs: number
                invalids: Array<InvalidModifierEnum>
                images: Array<{ id: number; alt: string; resizableURL: string }>
              }>
            }
          }>
        }>
      }>
      promotion: { id: number; strategyType: PromotionStrategyTypeEnum; nameEn: string; nameTh: string }
    }
  }
}

export type TemporaryOrderWithPromotionQueryVariables = Exact<{
  tableID: Scalars['Int']
  lineItems: Array<InputMaybe<OrderLineItemInput>> | InputMaybe<OrderLineItemInput>
}>

export type TemporaryOrderWithPromotionQuery = {
  temporaryOrderWithPromotion: {
    id: number
    totalSatangs: number
    adjustmentTotalSatangs: number
    lineItemTotalSatangs: number
    adjustments: Array<{
      id: number
      labelEn: string
      labelTh: string
      amountSatangs: number
      adjustmentType: AdjustmentTypeEnumType
      promotion: { id: number; type: PromotionTypeEnum }
    }>
  }
}

export declare const applyPromoToOrder: import('graphql').DocumentNode
export declare const callStaff: import('graphql').DocumentNode
export declare const cancelCheckout: import('graphql').DocumentNode
export declare const initiateCheckout: import('graphql').DocumentNode
export declare const removeOrderAdjustment: import('graphql').DocumentNode
export declare const shareMyMemberCoupons: import('graphql').DocumentNode
export declare const submitOrderLineItems: import('graphql').DocumentNode
export declare const updateOrderPaymentMethod: import('graphql').DocumentNode
export declare const buffetCheckout: import('graphql').DocumentNode
export declare const buffetGlobalCart: import('graphql').DocumentNode
export declare const buffetOrder: import('graphql').DocumentNode
export declare const buffetOrderFPWithPromotion: import('graphql').DocumentNode
export declare const buffetOrderHHWithPromotion: import('graphql').DocumentNode
export declare const buffetOrderPackage: import('graphql').DocumentNode
export declare const buffetProductDetail: import('graphql').DocumentNode
export declare const buffetProductList: import('graphql').DocumentNode
export declare const checkPaymentStatus: import('graphql').DocumentNode
export declare const checkout: import('graphql').DocumentNode
export declare const currentOrder: import('graphql').DocumentNode
export declare const getCartRecommendedProducts: import('graphql').DocumentNode
export declare const globalCart: import('graphql').DocumentNode
export declare const listFPMemberCoupons: import('graphql').DocumentNode
export declare const listProducts: import('graphql').DocumentNode
export declare const listScheduledContents: import('graphql').DocumentNode
export declare const location: import('graphql').DocumentNode
export declare const orderAdjustments: import('graphql').DocumentNode
export declare const orderAndFPMemberCoupons: import('graphql').DocumentNode
export declare const orderAndHHMemberCoupons: import('graphql').DocumentNode
export declare const orderFPWithPromotion: import('graphql').DocumentNode
export declare const orderHHWithPromotion: import('graphql').DocumentNode
export declare const paymentMethod: import('graphql').DocumentNode
export declare const product: import('graphql').DocumentNode
export declare const productWithModifier: import('graphql').DocumentNode
export declare const temporaryOrderWithPromotion: import('graphql').DocumentNode

export const ApplyPromoToOrderDocument = gql`
  mutation applyPromoToOrder(
    $orderID: Int!
    $promoCode: String
    $externalMemberCouponRefID: String
    $hatoHeartMemberCouponID: Int
  ) {
    applyPromoToOrder(
      orderID: $orderID
      promoCode: $promoCode
      externalMemberCouponRefID: $externalMemberCouponRefID
      hatoHeartMemberCouponID: $hatoHeartMemberCouponID
    ) {
      orderInvalids
      promotionInvalids
      order {
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          promotionID
          externalCouponID
          externalCoupon {
            id
            isAvailable
            endTime
            amount
            serialNumber
            rewardType
            thumbnailImageURL
            name
          }
          adjustmentType
          source
          invalidReason
        }
      }
    }
  }
`
export type ApplyPromoToOrderMutationFn = Apollo.MutationFunction<
  ApplyPromoToOrderMutation,
  ApplyPromoToOrderMutationVariables
>

/**
 * __useApplyPromoToOrderMutation__
 *
 * To run a mutation, you first call `useApplyPromoToOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPromoToOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPromoToOrderMutation, { data, loading, error }] = useApplyPromoToOrderMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      promoCode: // value for 'promoCode'
 *      externalMemberCouponRefID: // value for 'externalMemberCouponRefID'
 *      hatoHeartMemberCouponID: // value for 'hatoHeartMemberCouponID'
 *   },
 * });
 */
export function useApplyPromoToOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<ApplyPromoToOrderMutation, ApplyPromoToOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ApplyPromoToOrderMutation, ApplyPromoToOrderMutationVariables>(
    ApplyPromoToOrderDocument,
    options
  )
}
export type ApplyPromoToOrderMutationHookResult = ReturnType<typeof useApplyPromoToOrderMutation>
export type ApplyPromoToOrderMutationResult = Apollo.MutationResult<ApplyPromoToOrderMutation>
export type ApplyPromoToOrderMutationOptions = Apollo.BaseMutationOptions<
  ApplyPromoToOrderMutation,
  ApplyPromoToOrderMutationVariables
>
export const CallStaffDocument = gql`
  mutation callStaff($orderID: Int!, $reason: StaffCallingReasonEnum) {
    callStaff(orderID: $orderID, reason: $reason) {
      id
      staffCallingReason
    }
  }
`
export type CallStaffMutationFn = Apollo.MutationFunction<CallStaffMutation, CallStaffMutationVariables>

/**
 * __useCallStaffMutation__
 *
 * To run a mutation, you first call `useCallStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callStaffMutation, { data, loading, error }] = useCallStaffMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCallStaffMutation(
  baseOptions?: Apollo.MutationHookOptions<CallStaffMutation, CallStaffMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CallStaffMutation, CallStaffMutationVariables>(CallStaffDocument, options)
}
export type CallStaffMutationHookResult = ReturnType<typeof useCallStaffMutation>
export type CallStaffMutationResult = Apollo.MutationResult<CallStaffMutation>
export type CallStaffMutationOptions = Apollo.BaseMutationOptions<CallStaffMutation, CallStaffMutationVariables>
export const CancelCheckoutDocument = gql`
  mutation cancelCheckout($orderID: Int!) {
    cancelCheckout(orderID: $orderID) {
      id
    }
  }
`
export type CancelCheckoutMutationFn = Apollo.MutationFunction<CancelCheckoutMutation, CancelCheckoutMutationVariables>

/**
 * __useCancelCheckoutMutation__
 *
 * To run a mutation, you first call `useCancelCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCheckoutMutation, { data, loading, error }] = useCancelCheckoutMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useCancelCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelCheckoutMutation, CancelCheckoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelCheckoutMutation, CancelCheckoutMutationVariables>(CancelCheckoutDocument, options)
}
export type CancelCheckoutMutationHookResult = ReturnType<typeof useCancelCheckoutMutation>
export type CancelCheckoutMutationResult = Apollo.MutationResult<CancelCheckoutMutation>
export type CancelCheckoutMutationOptions = Apollo.BaseMutationOptions<
  CancelCheckoutMutation,
  CancelCheckoutMutationVariables
>
export const InitiateCheckoutDocument = gql`
  mutation initiateCheckout($orderID: Int!, $override: Boolean) {
    initiateCheckout(orderID: $orderID, override: $override) {
      order {
        id
        lineItemVatSatangs
        status
        lineItems {
          id
        }
        table {
          id
          name
          status
        }
        userID
        user {
          id
          lineDisplayName
          linePicture
        }
        payments {
          status
          type
        }
      }
      orderWarnings
      orderInvalids
    }
  }
`
export type InitiateCheckoutMutationFn = Apollo.MutationFunction<
  InitiateCheckoutMutation,
  InitiateCheckoutMutationVariables
>

/**
 * __useInitiateCheckoutMutation__
 *
 * To run a mutation, you first call `useInitiateCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateCheckoutMutation, { data, loading, error }] = useInitiateCheckoutMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      override: // value for 'override'
 *   },
 * });
 */
export function useInitiateCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<InitiateCheckoutMutation, InitiateCheckoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InitiateCheckoutMutation, InitiateCheckoutMutationVariables>(
    InitiateCheckoutDocument,
    options
  )
}
export type InitiateCheckoutMutationHookResult = ReturnType<typeof useInitiateCheckoutMutation>
export type InitiateCheckoutMutationResult = Apollo.MutationResult<InitiateCheckoutMutation>
export type InitiateCheckoutMutationOptions = Apollo.BaseMutationOptions<
  InitiateCheckoutMutation,
  InitiateCheckoutMutationVariables
>
export const RemoveOrderAdjustmentDocument = gql`
  mutation removeOrderAdjustment(
    $promotionID: Int
    $externalCouponID: Int
    $orderID: Int!
    $hatoHeartMemberCouponID: Int
  ) {
    removeOrderAdjustment(
      promotionID: $promotionID
      externalCouponID: $externalCouponID
      orderID: $orderID
      hatoHeartMemberCouponID: $hatoHeartMemberCouponID
    ) {
      order {
        id
        adjustments {
          id
          externalCouponID
          externalCoupon {
            id
            isAvailable
            endTime
            amount
            serialNumber
          }
          adjustmentType
          source
          invalidReason
        }
      }
      orderInvalids
      promotionInvalids
    }
  }
`
export type RemoveOrderAdjustmentMutationFn = Apollo.MutationFunction<
  RemoveOrderAdjustmentMutation,
  RemoveOrderAdjustmentMutationVariables
>

/**
 * __useRemoveOrderAdjustmentMutation__
 *
 * To run a mutation, you first call `useRemoveOrderAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderAdjustmentMutation, { data, loading, error }] = useRemoveOrderAdjustmentMutation({
 *   variables: {
 *      promotionID: // value for 'promotionID'
 *      externalCouponID: // value for 'externalCouponID'
 *      orderID: // value for 'orderID'
 *      hatoHeartMemberCouponID: // value for 'hatoHeartMemberCouponID'
 *   },
 * });
 */
export function useRemoveOrderAdjustmentMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveOrderAdjustmentMutation, RemoveOrderAdjustmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveOrderAdjustmentMutation, RemoveOrderAdjustmentMutationVariables>(
    RemoveOrderAdjustmentDocument,
    options
  )
}
export type RemoveOrderAdjustmentMutationHookResult = ReturnType<typeof useRemoveOrderAdjustmentMutation>
export type RemoveOrderAdjustmentMutationResult = Apollo.MutationResult<RemoveOrderAdjustmentMutation>
export type RemoveOrderAdjustmentMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrderAdjustmentMutation,
  RemoveOrderAdjustmentMutationVariables
>
export const ShareMyMemberCouponsDocument = gql`
  mutation shareMyMemberCoupons($orderID: Int!) {
    shareMyMemberCoupons(orderID: $orderID) {
      order {
        id
        tableGuests {
          id
          userID
          allowSharingCoupon
        }
      }
      orderInvalids
    }
  }
`
export type ShareMyMemberCouponsMutationFn = Apollo.MutationFunction<
  ShareMyMemberCouponsMutation,
  ShareMyMemberCouponsMutationVariables
>

/**
 * __useShareMyMemberCouponsMutation__
 *
 * To run a mutation, you first call `useShareMyMemberCouponsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareMyMemberCouponsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareMyMemberCouponsMutation, { data, loading, error }] = useShareMyMemberCouponsMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useShareMyMemberCouponsMutation(
  baseOptions?: Apollo.MutationHookOptions<ShareMyMemberCouponsMutation, ShareMyMemberCouponsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ShareMyMemberCouponsMutation, ShareMyMemberCouponsMutationVariables>(
    ShareMyMemberCouponsDocument,
    options
  )
}
export type ShareMyMemberCouponsMutationHookResult = ReturnType<typeof useShareMyMemberCouponsMutation>
export type ShareMyMemberCouponsMutationResult = Apollo.MutationResult<ShareMyMemberCouponsMutation>
export type ShareMyMemberCouponsMutationOptions = Apollo.BaseMutationOptions<
  ShareMyMemberCouponsMutation,
  ShareMyMemberCouponsMutationVariables
>
export const SubmitOrderLineItemsDocument = gql`
  mutation submitOrderLineItems($orderID: Int!, $lineItems: [OrderLineItemInput]!) {
    submitOrderLineItems(orderID: $orderID, lineItems: $lineItems) {
      orderInvalids
      promotionInvalids
      order {
        id
        buffetEndTime
        lineItems {
          id
          productID
          invalids
          modifiers {
            modifier {
              nameEn
              nameTh
              invalids
            }
          }
        }
      }
    }
  }
`
export type SubmitOrderLineItemsMutationFn = Apollo.MutationFunction<
  SubmitOrderLineItemsMutation,
  SubmitOrderLineItemsMutationVariables
>

/**
 * __useSubmitOrderLineItemsMutation__
 *
 * To run a mutation, you first call `useSubmitOrderLineItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOrderLineItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOrderLineItemsMutation, { data, loading, error }] = useSubmitOrderLineItemsMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      lineItems: // value for 'lineItems'
 *   },
 * });
 */
export function useSubmitOrderLineItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitOrderLineItemsMutation, SubmitOrderLineItemsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitOrderLineItemsMutation, SubmitOrderLineItemsMutationVariables>(
    SubmitOrderLineItemsDocument,
    options
  )
}
export type SubmitOrderLineItemsMutationHookResult = ReturnType<typeof useSubmitOrderLineItemsMutation>
export type SubmitOrderLineItemsMutationResult = Apollo.MutationResult<SubmitOrderLineItemsMutation>
export type SubmitOrderLineItemsMutationOptions = Apollo.BaseMutationOptions<
  SubmitOrderLineItemsMutation,
  SubmitOrderLineItemsMutationVariables
>
export const UpdateOrderPaymentMethodDocument = gql`
  mutation updateOrderPaymentMethod($orderID: Int!, $type: PaymentTypeEnum!, $cardID: String, $phoneNumber: String) {
    updateOrderPaymentMethod(orderID: $orderID, type: $type, cardID: $cardID, phoneNumber: $phoneNumber) {
      order {
        id
        paymentType
        paymentPhone
        omiseCardID
        omiseCardLastDigits
        omiseCardholderName
        omiseCardBrand
      }
      orderInvalids
    }
  }
`
export type UpdateOrderPaymentMethodMutationFn = Apollo.MutationFunction<
  UpdateOrderPaymentMethodMutation,
  UpdateOrderPaymentMethodMutationVariables
>

/**
 * __useUpdateOrderPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateOrderPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderPaymentMethodMutation, { data, loading, error }] = useUpdateOrderPaymentMethodMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      type: // value for 'type'
 *      cardID: // value for 'cardID'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateOrderPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrderPaymentMethodMutation, UpdateOrderPaymentMethodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateOrderPaymentMethodMutation, UpdateOrderPaymentMethodMutationVariables>(
    UpdateOrderPaymentMethodDocument,
    options
  )
}
export type UpdateOrderPaymentMethodMutationHookResult = ReturnType<typeof useUpdateOrderPaymentMethodMutation>
export type UpdateOrderPaymentMethodMutationResult = Apollo.MutationResult<UpdateOrderPaymentMethodMutation>
export type UpdateOrderPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderPaymentMethodMutation,
  UpdateOrderPaymentMethodMutationVariables
>
export const BuffetCheckoutDocument = gql`
  query buffetCheckout($vendorID: Int!, $orderID: Int!, $tableID: Int!) {
    currentOrder(tableID: $tableID, orderID: $orderID, vendorID: $vendorID) {
      id
      lineItemVatSatangs
      status
      adjustmentCashTotalSatangs
      adjustmentStaffCashTotalSatangs
      totalSatangs
      totalDueSatangs
      lineItemTotalSatangs
      adjustments {
        id
        labelEn
        labelTh
        amountSatangs
        fullAmountSatangs
        adjustmentType
        invalidReason
        promotion {
          descriptionEn
          descriptionTh
          type
        }
        externalCoupon {
          id
          staffPrivilegeType
        }
      }
      buffetItems {
        id
        buffetItemType
        totalSatangs
      }
      lineItems {
        id
      }
      table {
        id
        name
        status
      }
      userID
      user {
        id
        lineDisplayName
        linePicture
      }
      payments {
        status
        type
      }
      paymentType
      paymentPhone
      omiseCardID
      omiseCardLastDigits
      omiseCardholderName
      omiseCardBrand
    }
    orderAndFPMemberCoupons(orderID: $orderID) {
      order {
        id
        adjustmentCashTotalSatangs
        adjustmentStaffCashTotalSatangs
        totalSatangs
        totalDueSatangs
        lineItemTotalSatangs
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          fullAmountSatangs
          adjustmentType
          invalidReason
          promotion {
            descriptionEn
            descriptionTh
            type
          }
          externalCoupon {
            id
            staffPrivilegeType
          }
        }
      }
      orderInvalids
    }
    vendor(tableID: $tableID, vendorID: $vendorID) {
      id
      nameEn
      nameTh
      logoImage {
        resizableURL
        originalURL
      }
    }
    vendorSetting(vendorID: $vendorID) {
      paymentMethods
    }
  }
`

/**
 * __useBuffetCheckoutQuery__
 *
 * To run a query within a React component, call `useBuffetCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuffetCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuffetCheckoutQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *      orderID: // value for 'orderID'
 *      tableID: // value for 'tableID'
 *   },
 * });
 */
export function useBuffetCheckoutQuery(
  baseOptions: Apollo.QueryHookOptions<BuffetCheckoutQuery, BuffetCheckoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BuffetCheckoutQuery, BuffetCheckoutQueryVariables>(BuffetCheckoutDocument, options)
}
export function useBuffetCheckoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuffetCheckoutQuery, BuffetCheckoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BuffetCheckoutQuery, BuffetCheckoutQueryVariables>(BuffetCheckoutDocument, options)
}
export type BuffetCheckoutQueryHookResult = ReturnType<typeof useBuffetCheckoutQuery>
export type BuffetCheckoutLazyQueryHookResult = ReturnType<typeof useBuffetCheckoutLazyQuery>
export type BuffetCheckoutQueryResult = Apollo.QueryResult<BuffetCheckoutQuery, BuffetCheckoutQueryVariables>
export const BuffetGlobalCartDocument = gql`
  query buffetGlobalCart($tableID: Int!, $orderID: Int!, $vendorID: Int!) {
    currentOrder(tableID: $tableID, orderID: $orderID, vendorID: $vendorID) {
      id
      totalSatangs
      lineItemTotalSatangs
      userID
      status
      staffCallingReason
      buffetEndTime
      buffetPackageID
      buffetItems {
        id
        buffetItem {
          labelEn
          labelTh
        }
        buffetItemType
        pricePerUnitSatangs
        quantity
        totalSatangs
      }
      buffetPackage {
        id
        nameEn
        nameTh
        level
      }
      lineItems {
        id
        buffetPackageID
        lineItemBatchID
        productID
        totalSatangs
        customNotes
        quantity
        invalids
        status
        createdAt
        product {
          id
          nameEn
          nameTh
          excludedFromPromotion
          images {
            id
            resizableURL
          }
        }
        modifiers {
          modifierID
          quantity
          modifier {
            nameEn
            nameTh
            priceSatangs
            invalids
            modifierGroup {
              multipleQuantity
            }
          }
        }
        createdByUser {
          id
          linePicture
          lineDisplayName
        }
        serviceType
      }
    }
  }
`

/**
 * __useBuffetGlobalCartQuery__
 *
 * To run a query within a React component, call `useBuffetGlobalCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuffetGlobalCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuffetGlobalCartQuery({
 *   variables: {
 *      tableID: // value for 'tableID'
 *      orderID: // value for 'orderID'
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useBuffetGlobalCartQuery(
  baseOptions: Apollo.QueryHookOptions<BuffetGlobalCartQuery, BuffetGlobalCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BuffetGlobalCartQuery, BuffetGlobalCartQueryVariables>(BuffetGlobalCartDocument, options)
}
export function useBuffetGlobalCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuffetGlobalCartQuery, BuffetGlobalCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BuffetGlobalCartQuery, BuffetGlobalCartQueryVariables>(BuffetGlobalCartDocument, options)
}
export type BuffetGlobalCartQueryHookResult = ReturnType<typeof useBuffetGlobalCartQuery>
export type BuffetGlobalCartLazyQueryHookResult = ReturnType<typeof useBuffetGlobalCartLazyQuery>
export type BuffetGlobalCartQueryResult = Apollo.QueryResult<BuffetGlobalCartQuery, BuffetGlobalCartQueryVariables>
export const BuffetOrderDocument = gql`
  query buffetOrder($orderID: Int!, $tableID: Int!, $vendorID: Int!) {
    currentOrder(orderID: $orderID, tableID: $tableID, vendorID: $vendorID) {
      id
      status
      userID
      buffetPackageID
      buffetEndTime
      staffCallingReason
      buffetPackage {
        id
        nameEn
        nameTh
        level
      }
      payments {
        id
        failureReason
        failureMessage
        type
        status
      }
      table {
        id
        status
      }
      tableGuests {
        userID
        allowSharingCoupon
      }
    }
  }
`

/**
 * __useBuffetOrderQuery__
 *
 * To run a query within a React component, call `useBuffetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuffetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuffetOrderQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      tableID: // value for 'tableID'
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useBuffetOrderQuery(baseOptions: Apollo.QueryHookOptions<BuffetOrderQuery, BuffetOrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BuffetOrderQuery, BuffetOrderQueryVariables>(BuffetOrderDocument, options)
}
export function useBuffetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuffetOrderQuery, BuffetOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BuffetOrderQuery, BuffetOrderQueryVariables>(BuffetOrderDocument, options)
}
export type BuffetOrderQueryHookResult = ReturnType<typeof useBuffetOrderQuery>
export type BuffetOrderLazyQueryHookResult = ReturnType<typeof useBuffetOrderLazyQuery>
export type BuffetOrderQueryResult = Apollo.QueryResult<BuffetOrderQuery, BuffetOrderQueryVariables>
export const BuffetOrderFpWithPromotionDocument = gql`
  query buffetOrderFPWithPromotion($orderID: Int) {
    orderAndFPMemberCoupons(orderID: $orderID) {
      order {
        id
        totalSatangs
        lineItemTotalSatangs
        userID
        status
        staffCallingReason
        buffetEndTime
        buffetPackageID
        adjustmentTotalSatangs
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          adjustmentType
          promotion {
            id
            type
          }
        }
        buffetItems {
          id
          buffetItem {
            labelEn
            labelTh
          }
          buffetItemType
          pricePerUnitSatangs
          quantity
          totalSatangs
        }
        buffetPackage {
          id
          nameEn
          nameTh
          level
        }
        lineItems {
          id
          buffetPackageID
          lineItemBatchID
          productID
          totalSatangs
          customNotes
          quantity
          invalids
          status
          createdAt
          product {
            id
            nameEn
            nameTh
            excludedFromPromotion
            images {
              id
              resizableURL
            }
          }
          modifiers {
            modifierID
            quantity
            modifier {
              nameEn
              nameTh
              priceSatangs
              invalids
              modifierGroup {
                multipleQuantity
              }
            }
          }
          createdByUser {
            id
            linePicture
            lineDisplayName
          }
          serviceType
        }
      }
    }
  }
`

/**
 * __useBuffetOrderFpWithPromotionQuery__
 *
 * To run a query within a React component, call `useBuffetOrderFpWithPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuffetOrderFpWithPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuffetOrderFpWithPromotionQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useBuffetOrderFpWithPromotionQuery(
  baseOptions?: Apollo.QueryHookOptions<BuffetOrderFpWithPromotionQuery, BuffetOrderFpWithPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BuffetOrderFpWithPromotionQuery, BuffetOrderFpWithPromotionQueryVariables>(
    BuffetOrderFpWithPromotionDocument,
    options
  )
}
export function useBuffetOrderFpWithPromotionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuffetOrderFpWithPromotionQuery, BuffetOrderFpWithPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BuffetOrderFpWithPromotionQuery, BuffetOrderFpWithPromotionQueryVariables>(
    BuffetOrderFpWithPromotionDocument,
    options
  )
}
export type BuffetOrderFpWithPromotionQueryHookResult = ReturnType<typeof useBuffetOrderFpWithPromotionQuery>
export type BuffetOrderFpWithPromotionLazyQueryHookResult = ReturnType<typeof useBuffetOrderFpWithPromotionLazyQuery>
export type BuffetOrderFpWithPromotionQueryResult = Apollo.QueryResult<
  BuffetOrderFpWithPromotionQuery,
  BuffetOrderFpWithPromotionQueryVariables
>
export const BuffetOrderHhWithPromotionDocument = gql`
  query buffetOrderHHWithPromotion($orderID: Int) {
    orderAndHHMemberCoupons(orderID: $orderID) {
      order {
        id
        totalSatangs
        lineItemTotalSatangs
        userID
        status
        staffCallingReason
        buffetEndTime
        buffetPackageID
        adjustmentTotalSatangs
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          adjustmentType
          promotion {
            id
            type
          }
        }
        buffetItems {
          id
          buffetItem {
            labelEn
            labelTh
          }
          buffetItemType
          pricePerUnitSatangs
          quantity
          totalSatangs
        }
        buffetPackage {
          id
          nameEn
          nameTh
          level
        }
        lineItems {
          id
          buffetPackageID
          lineItemBatchID
          productID
          totalSatangs
          customNotes
          quantity
          invalids
          status
          createdAt
          product {
            id
            nameEn
            nameTh
            excludedFromPromotion
            images {
              id
              resizableURL
            }
          }
          modifiers {
            modifierID
            quantity
            modifier {
              nameEn
              nameTh
              priceSatangs
              invalids
              modifierGroup {
                multipleQuantity
              }
            }
          }
          createdByUser {
            id
            linePicture
            lineDisplayName
          }
          serviceType
        }
      }
    }
  }
`

/**
 * __useBuffetOrderHhWithPromotionQuery__
 *
 * To run a query within a React component, call `useBuffetOrderHhWithPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuffetOrderHhWithPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuffetOrderHhWithPromotionQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useBuffetOrderHhWithPromotionQuery(
  baseOptions?: Apollo.QueryHookOptions<BuffetOrderHhWithPromotionQuery, BuffetOrderHhWithPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BuffetOrderHhWithPromotionQuery, BuffetOrderHhWithPromotionQueryVariables>(
    BuffetOrderHhWithPromotionDocument,
    options
  )
}
export function useBuffetOrderHhWithPromotionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuffetOrderHhWithPromotionQuery, BuffetOrderHhWithPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BuffetOrderHhWithPromotionQuery, BuffetOrderHhWithPromotionQueryVariables>(
    BuffetOrderHhWithPromotionDocument,
    options
  )
}
export type BuffetOrderHhWithPromotionQueryHookResult = ReturnType<typeof useBuffetOrderHhWithPromotionQuery>
export type BuffetOrderHhWithPromotionLazyQueryHookResult = ReturnType<typeof useBuffetOrderHhWithPromotionLazyQuery>
export type BuffetOrderHhWithPromotionQueryResult = Apollo.QueryResult<
  BuffetOrderHhWithPromotionQuery,
  BuffetOrderHhWithPromotionQueryVariables
>
export const BuffetOrderPackageDocument = gql`
  query buffetOrderPackage($tableID: Int!, $orderID: Int!, $vendorID: Int!) {
    currentOrder(orderID: $orderID, tableID: $tableID, vendorID: $vendorID) {
      buffetPackage {
        id
        nameEn
        nameTh
        level
      }
    }
  }
`

/**
 * __useBuffetOrderPackageQuery__
 *
 * To run a query within a React component, call `useBuffetOrderPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuffetOrderPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuffetOrderPackageQuery({
 *   variables: {
 *      tableID: // value for 'tableID'
 *      orderID: // value for 'orderID'
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useBuffetOrderPackageQuery(
  baseOptions: Apollo.QueryHookOptions<BuffetOrderPackageQuery, BuffetOrderPackageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BuffetOrderPackageQuery, BuffetOrderPackageQueryVariables>(BuffetOrderPackageDocument, options)
}
export function useBuffetOrderPackageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuffetOrderPackageQuery, BuffetOrderPackageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BuffetOrderPackageQuery, BuffetOrderPackageQueryVariables>(
    BuffetOrderPackageDocument,
    options
  )
}
export type BuffetOrderPackageQueryHookResult = ReturnType<typeof useBuffetOrderPackageQuery>
export type BuffetOrderPackageLazyQueryHookResult = ReturnType<typeof useBuffetOrderPackageLazyQuery>
export type BuffetOrderPackageQueryResult = Apollo.QueryResult<
  BuffetOrderPackageQuery,
  BuffetOrderPackageQueryVariables
>
export const BuffetProductDetailDocument = gql`
  query buffetProductDetail(
    $id: Int!
    $tableID: Int!
    $vendorID: Int!
    $orderID: Int!
    $categoryID: Int!
    $limit: Int
    $offset: Int!
  ) {
    vendor(tableID: $tableID, vendorID: $vendorID) {
      id
      nameEn
      nameTh
      logoImage {
        resizableURL
        originalURL
      }
    }
    location(tableID: $tableID, vendorID: $vendorID) {
      id
      nameEn
      nameTh
    }
    currentOrder(tableID: $tableID, orderID: $orderID, vendorID: $vendorID) {
      id
      buffetPackageID
      table {
        id
        name
      }
    }
    product(id: $id, tableID: $tableID, vendorID: $vendorID) {
      id
      nameEn
      nameTh
      descriptionEn
      descriptionTh
      categoryID
      tasteDescriptionTh
      tasteDescriptionEn
      availableServices
      excludedFromPromotion
      discountSatangs
      priceSatangs
      totalPriceSatangs
      categoryID
      tags
      memberTierRankConditions
      memberIneligibleReason
      maxSpicyLevel
      skipCustomNotesScreen
      excludedFromListing
      ingredientList {
        id
        nameTh
        nameEn
      }
      images {
        id
        resizableURL
        thumbnailResizableURL
        originalURL
      }
      nutrition {
        id
        calories
        caloriesFromFat
        carbG
        cholesterolMg
        fatG
        fiberG
        proteinG
        saturatedFatG
        servingsPerItem
        sodiumMg
        sugarsG
        transFatG
      }
    }
    listBuffetPackageProducts(
      orderID: $orderID
      vendorID: $vendorID
      categoryID: $categoryID
      limit: $limit
      offset: $offset
    ) {
      products {
        id
        nameEn
        nameTh
        descriptionEn
        descriptionTh
        priceSatangs
        skipModifierScreen
        active
        categoryID
        memberIneligibleReason
        memberTierRankConditions
        totalPriceSatangs
        discountSatangs
        availableServices
        excludedFromPromotion
        excludedFromListing
        tags
        memberIneligibleReason
        skipCustomNotesScreen
        images {
          id
          resizableURL
        }
        category {
          id
          nameEn
          nameTh
          descriptionEn
          descriptionTh
          position
          images {
            id
            resizableURL
            originalURL
          }
        }
        buffetPackageProducts {
          id
          buffetPackageID
          buffetPackage {
            nameEn
            nameTh
          }
        }
      }
    }
  }
`

/**
 * __useBuffetProductDetailQuery__
 *
 * To run a query within a React component, call `useBuffetProductDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuffetProductDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuffetProductDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tableID: // value for 'tableID'
 *      vendorID: // value for 'vendorID'
 *      orderID: // value for 'orderID'
 *      categoryID: // value for 'categoryID'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useBuffetProductDetailQuery(
  baseOptions: Apollo.QueryHookOptions<BuffetProductDetailQuery, BuffetProductDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BuffetProductDetailQuery, BuffetProductDetailQueryVariables>(
    BuffetProductDetailDocument,
    options
  )
}
export function useBuffetProductDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuffetProductDetailQuery, BuffetProductDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BuffetProductDetailQuery, BuffetProductDetailQueryVariables>(
    BuffetProductDetailDocument,
    options
  )
}
export type BuffetProductDetailQueryHookResult = ReturnType<typeof useBuffetProductDetailQuery>
export type BuffetProductDetailLazyQueryHookResult = ReturnType<typeof useBuffetProductDetailLazyQuery>
export type BuffetProductDetailQueryResult = Apollo.QueryResult<
  BuffetProductDetailQuery,
  BuffetProductDetailQueryVariables
>
export const BuffetProductListDocument = gql`
  query buffetProductList(
    $vendorID: Int!
    $tableID: Int!
    $orderID: Int!
    $keyword: String
    $categoryID: Int
    $limit: Int
    $offset: Int
    $serviceType: LineItemServiceTypeForBuffetEnum
  ) {
    listBuffetPackageProducts(
      orderID: $orderID
      vendorID: $vendorID
      keyword: $keyword
      categoryID: $categoryID
      limit: $limit
      offset: $offset
      serviceType: $serviceType
    ) {
      products {
        id
        nameEn
        nameTh
        descriptionEn
        descriptionTh
        priceSatangs
        skipModifierScreen
        active
        availableServices
        categoryID
        memberIneligibleReason
        memberTierRankConditions
        totalPriceSatangs
        discountSatangs
        excludedFromPromotion
        excludedFromListing
        tags
        memberIneligibleReason
        skipCustomNotesScreen
        images {
          id
          resizableURL
        }
        category {
          id
          nameEn
          nameTh
          descriptionEn
          descriptionTh
          position
          images {
            id
            resizableURL
            originalURL
          }
        }
        buffetPackageProducts {
          id
          buffetPackageID
          buffetPackage {
            nameEn
            nameTh
          }
        }
      }
    }
    listCategories(tableID: $tableID, vendorID: $vendorID) {
      id
      nameTh
      nameEn
      images {
        originalURL
        resizableURL
      }
    }
    currentOrder(tableID: $tableID, orderID: $orderID, vendorID: $vendorID) {
      id
      buffetPackageID
      table {
        id
        name
        status
      }
      buffetPackage {
        nameTh
        nameEn
      }
    }
    vendor(tableID: $tableID, vendorID: $vendorID) {
      id
      nameEn
      nameTh
      logoImage {
        resizableURL
        originalURL
      }
    }
  }
`

/**
 * __useBuffetProductListQuery__
 *
 * To run a query within a React component, call `useBuffetProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuffetProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuffetProductListQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *      tableID: // value for 'tableID'
 *      orderID: // value for 'orderID'
 *      keyword: // value for 'keyword'
 *      categoryID: // value for 'categoryID'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      serviceType: // value for 'serviceType'
 *   },
 * });
 */
export function useBuffetProductListQuery(
  baseOptions: Apollo.QueryHookOptions<BuffetProductListQuery, BuffetProductListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BuffetProductListQuery, BuffetProductListQueryVariables>(BuffetProductListDocument, options)
}
export function useBuffetProductListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BuffetProductListQuery, BuffetProductListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BuffetProductListQuery, BuffetProductListQueryVariables>(
    BuffetProductListDocument,
    options
  )
}
export type BuffetProductListQueryHookResult = ReturnType<typeof useBuffetProductListQuery>
export type BuffetProductListLazyQueryHookResult = ReturnType<typeof useBuffetProductListLazyQuery>
export type BuffetProductListQueryResult = Apollo.QueryResult<BuffetProductListQuery, BuffetProductListQueryVariables>
export const CheckPaymentStatusDocument = gql`
  query checkPaymentStatus($orderID: Int!, $tableID: Int!, $vendorID: Int!) {
    checkPaymentStatus(orderID: $orderID) {
      id
      status
      failureReason
      failureMessage
      type
    }
    currentOrder(tableID: $tableID, orderID: $orderID, vendorID: $vendorID) {
      buffetPackageID
    }
  }
`

/**
 * __useCheckPaymentStatusQuery__
 *
 * To run a query within a React component, call `useCheckPaymentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPaymentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPaymentStatusQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      tableID: // value for 'tableID'
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useCheckPaymentStatusQuery(
  baseOptions: Apollo.QueryHookOptions<CheckPaymentStatusQuery, CheckPaymentStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckPaymentStatusQuery, CheckPaymentStatusQueryVariables>(CheckPaymentStatusDocument, options)
}
export function useCheckPaymentStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckPaymentStatusQuery, CheckPaymentStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckPaymentStatusQuery, CheckPaymentStatusQueryVariables>(
    CheckPaymentStatusDocument,
    options
  )
}
export type CheckPaymentStatusQueryHookResult = ReturnType<typeof useCheckPaymentStatusQuery>
export type CheckPaymentStatusLazyQueryHookResult = ReturnType<typeof useCheckPaymentStatusLazyQuery>
export type CheckPaymentStatusQueryResult = Apollo.QueryResult<
  CheckPaymentStatusQuery,
  CheckPaymentStatusQueryVariables
>
export const CheckoutDocument = gql`
  mutation checkout($orderID: Int!, $payment: PaymentInput!) {
    checkout(orderID: $orderID, payment: $payment) {
      KPaymentResult {
        failureCode
        failureMessage
        phone
        status
      }
      chargeResult {
        authorizeURI
        chargeID
        failureCode
        failureMessage
        status
      }
      order {
        id
        status
        table {
          id
          locationID
          name
          status
        }
        userID
        payments {
          status
          type
        }
      }
      orderInvalids
    }
  }
`
export type CheckoutMutationFn = Apollo.MutationFunction<CheckoutMutation, CheckoutMutationVariables>

/**
 * __useCheckoutMutation__
 *
 * To run a mutation, you first call `useCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutMutation, { data, loading, error }] = useCheckoutMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      payment: // value for 'payment'
 *   },
 * });
 */
export function useCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<CheckoutMutation, CheckoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CheckoutMutation, CheckoutMutationVariables>(CheckoutDocument, options)
}
export type CheckoutMutationHookResult = ReturnType<typeof useCheckoutMutation>
export type CheckoutMutationResult = Apollo.MutationResult<CheckoutMutation>
export type CheckoutMutationOptions = Apollo.BaseMutationOptions<CheckoutMutation, CheckoutMutationVariables>
export const CurrentOrderDocument = gql`
  query currentOrder($tableID: Int!, $orderID: Int!, $vendorID: Int!) {
    currentOrder(tableID: $tableID, orderID: $orderID, vendorID: $vendorID) {
      id
      lineItemVatSatangs
      status
      totalSatangs
      lineItems {
        id
      }
      table {
        id
        name
        status
      }
      userID
      user {
        id
        lineDisplayName
        linePicture
      }
      payments {
        id
        status
        type
      }
      tableGuests {
        userID
        allowSharingCoupon
      }
      paymentType
      paymentPhone
      omiseCardID
      omiseCardLastDigits
      omiseCardBrand
      omiseCardholderName
    }
  }
`

/**
 * __useCurrentOrderQuery__
 *
 * To run a query within a React component, call `useCurrentOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentOrderQuery({
 *   variables: {
 *      tableID: // value for 'tableID'
 *      orderID: // value for 'orderID'
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useCurrentOrderQuery(
  baseOptions: Apollo.QueryHookOptions<CurrentOrderQuery, CurrentOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentOrderQuery, CurrentOrderQueryVariables>(CurrentOrderDocument, options)
}
export function useCurrentOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentOrderQuery, CurrentOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentOrderQuery, CurrentOrderQueryVariables>(CurrentOrderDocument, options)
}
export type CurrentOrderQueryHookResult = ReturnType<typeof useCurrentOrderQuery>
export type CurrentOrderLazyQueryHookResult = ReturnType<typeof useCurrentOrderLazyQuery>
export type CurrentOrderQueryResult = Apollo.QueryResult<CurrentOrderQuery, CurrentOrderQueryVariables>
export const GetCartRecommendedProductsDocument = gql`
  query getCartRecommendedProducts(
    $orderID: Int!
    $vendorID: Int!
    $tableID: Int!
    $privateCartLineItems: [OrderLineItemInput]!
    $experimentFeature: ProductRecommendationFeature
  ) {
    getCartRecommendedProducts(
      orderID: $orderID
      vendorID: $vendorID
      tableID: $tableID
      privateCartLineItems: $privateCartLineItems
      experimentFeature: $experimentFeature
    ) {
      products {
        id
        priceSatangs
        totalPriceSatangs
        discountSatangs
        nameTh
        nameEn
        categoryID
        tags
        memberTierRankConditions
        memberIneligibleReason
        skipCustomNotesScreen
        excludedFromPromotion
        modifierGroups {
          id
        }
        images {
          id
          resizableURL
          thumbnailResizableURL
          originalURL
        }
        promotionProduct {
          productGroupRewards {
            productIDs
          }
          promotion {
            id
            strategyType
          }
        }
        promotionBadge
      }
    }
  }
`

/**
 * __useGetCartRecommendedProductsQuery__
 *
 * To run a query within a React component, call `useGetCartRecommendedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartRecommendedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartRecommendedProductsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      vendorID: // value for 'vendorID'
 *      tableID: // value for 'tableID'
 *      privateCartLineItems: // value for 'privateCartLineItems'
 *      experimentFeature: // value for 'experimentFeature'
 *   },
 * });
 */
export function useGetCartRecommendedProductsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCartRecommendedProductsQuery, GetCartRecommendedProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCartRecommendedProductsQuery, GetCartRecommendedProductsQueryVariables>(
    GetCartRecommendedProductsDocument,
    options
  )
}
export function useGetCartRecommendedProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCartRecommendedProductsQuery, GetCartRecommendedProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCartRecommendedProductsQuery, GetCartRecommendedProductsQueryVariables>(
    GetCartRecommendedProductsDocument,
    options
  )
}
export type GetCartRecommendedProductsQueryHookResult = ReturnType<typeof useGetCartRecommendedProductsQuery>
export type GetCartRecommendedProductsLazyQueryHookResult = ReturnType<typeof useGetCartRecommendedProductsLazyQuery>
export type GetCartRecommendedProductsQueryResult = Apollo.QueryResult<
  GetCartRecommendedProductsQuery,
  GetCartRecommendedProductsQueryVariables
>
export const GlobalCartDocument = gql`
  query globalCart($tableID: Int!, $orderID: Int!, $vendorID: Int!) {
    currentOrder(tableID: $tableID, orderID: $orderID, vendorID: $vendorID) {
      id
      totalSatangs
      lineItemTotalSatangs
      userID
      status
      lineItemVatSatangs
      lineItems {
        id
        lineItemBatchID
        productID
        totalSatangs
        customNotes
        quantity
        invalids
        status
        createdAt
        product {
          id
          nameEn
          nameTh
          excludedFromPromotion
          images {
            id
            resizableURL
          }
        }
        modifiers {
          modifierID
          quantity
          modifier {
            nameEn
            nameTh
            priceSatangs
            invalids
            modifierGroup {
              multipleQuantity
            }
          }
        }
        createdByUser {
          id
          linePicture
          lineDisplayName
        }
        serviceType
      }
      table {
        id
        name
        status
      }
      user {
        id
        lineDisplayName
        linePicture
      }
      payments {
        id
        status
        type
      }
      paymentType
      paymentPhone
      omiseCardID
      omiseCardLastDigits
      omiseCardBrand
    }
  }
`

/**
 * __useGlobalCartQuery__
 *
 * To run a query within a React component, call `useGlobalCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalCartQuery({
 *   variables: {
 *      tableID: // value for 'tableID'
 *      orderID: // value for 'orderID'
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useGlobalCartQuery(baseOptions: Apollo.QueryHookOptions<GlobalCartQuery, GlobalCartQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GlobalCartQuery, GlobalCartQueryVariables>(GlobalCartDocument, options)
}
export function useGlobalCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GlobalCartQuery, GlobalCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GlobalCartQuery, GlobalCartQueryVariables>(GlobalCartDocument, options)
}
export type GlobalCartQueryHookResult = ReturnType<typeof useGlobalCartQuery>
export type GlobalCartLazyQueryHookResult = ReturnType<typeof useGlobalCartLazyQuery>
export type GlobalCartQueryResult = Apollo.QueryResult<GlobalCartQuery, GlobalCartQueryVariables>
export const ListFpMemberCouponsDocument = gql`
  query listFPMemberCoupons($orderID: Int, $filter: ListFPMemberCouponsFilter) {
    listFPMemberCoupons(orderID: $orderID, filter: $filter) {
      isAvailable
      startTime
      endTime
      serialNumber
      rewardType
      imageURL
      name
      memberCouponRefID
      description
      membership {
        id
        user {
          id
          lineDisplayName
          linePicture
        }
      }
    }
  }
`

/**
 * __useListFpMemberCouponsQuery__
 *
 * To run a query within a React component, call `useListFpMemberCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFpMemberCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFpMemberCouponsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListFpMemberCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListFpMemberCouponsQuery, ListFpMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListFpMemberCouponsQuery, ListFpMemberCouponsQueryVariables>(
    ListFpMemberCouponsDocument,
    options
  )
}
export function useListFpMemberCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListFpMemberCouponsQuery, ListFpMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListFpMemberCouponsQuery, ListFpMemberCouponsQueryVariables>(
    ListFpMemberCouponsDocument,
    options
  )
}
export type ListFpMemberCouponsQueryHookResult = ReturnType<typeof useListFpMemberCouponsQuery>
export type ListFpMemberCouponsLazyQueryHookResult = ReturnType<typeof useListFpMemberCouponsLazyQuery>
export type ListFpMemberCouponsQueryResult = Apollo.QueryResult<
  ListFpMemberCouponsQuery,
  ListFpMemberCouponsQueryVariables
>
export const ListProductsDocument = gql`
  query listProducts(
    $limit: Int!
    $offset: Int
    $tableID: Int!
    $keyword: String!
    $vendorID: Int!
    $serviceType: LineItemServiceTypeForDineInEnum
  ) {
    listProducts(
      limit: $limit
      offset: $offset
      tableID: $tableID
      keyword: $keyword
      vendorID: $vendorID
      serviceType: $serviceType
    ) {
      pagination {
        count
        limit
        offset
        total
      }
      products {
        id
        priceSatangs
        totalPriceSatangs
        discountSatangs
        nameTh
        nameEn
        categoryID
        tags
        memberTierRankConditions
        memberIneligibleReason
        skipCustomNotesScreen
        excludedFromPromotion
        modifierGroups {
          id
        }
        images {
          id
          resizableURL
          thumbnailResizableURL
          originalURL
        }
        promotionProduct {
          productGroupRewards {
            productIDs
          }
          promotion {
            id
            strategyType
          }
        }
        promotionBadge
      }
    }
  }
`

/**
 * __useListProductsQuery__
 *
 * To run a query within a React component, call `useListProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProductsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      tableID: // value for 'tableID'
 *      keyword: // value for 'keyword'
 *      vendorID: // value for 'vendorID'
 *      serviceType: // value for 'serviceType'
 *   },
 * });
 */
export function useListProductsQuery(
  baseOptions: Apollo.QueryHookOptions<ListProductsQuery, ListProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListProductsQuery, ListProductsQueryVariables>(ListProductsDocument, options)
}
export function useListProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListProductsQuery, ListProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListProductsQuery, ListProductsQueryVariables>(ListProductsDocument, options)
}
export type ListProductsQueryHookResult = ReturnType<typeof useListProductsQuery>
export type ListProductsLazyQueryHookResult = ReturnType<typeof useListProductsLazyQuery>
export type ListProductsQueryResult = Apollo.QueryResult<ListProductsQuery, ListProductsQueryVariables>
export const ListScheduledContentsDocument = gql`
  query listScheduledContents($offset: Int!, $limit: Int!, $filter: ScheduledContentFilter!) {
    listScheduledContents(offset: $offset, limit: $limit, filter: $filter) {
      id
      titleEn
      titleTh
      descriptionEn
      descriptionTh
      placement
      type
      uri
      vendorID
      categoryID
      images {
        id
        originalURL
        resizableURL
        type
      }
      products {
        sku
        id
        active
        derivedColorScheme
        discountSatangs
        priceSatangs
        totalPriceSatangs
        nameTh
        nameEn
        customNotesLabelTh
        customNotesLabelEn
        skipCustomNotesScreen
        category {
          id
          nameTh
          nameEn
        }
        categoryID
        maxSpicyLevel
        images {
          id
          resizableURL
          originalURL
          type
        }
        tags
      }
    }
  }
`

/**
 * __useListScheduledContentsQuery__
 *
 * To run a query within a React component, call `useListScheduledContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListScheduledContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListScheduledContentsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListScheduledContentsQuery(
  baseOptions: Apollo.QueryHookOptions<ListScheduledContentsQuery, ListScheduledContentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListScheduledContentsQuery, ListScheduledContentsQueryVariables>(
    ListScheduledContentsDocument,
    options
  )
}
export function useListScheduledContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListScheduledContentsQuery, ListScheduledContentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListScheduledContentsQuery, ListScheduledContentsQueryVariables>(
    ListScheduledContentsDocument,
    options
  )
}
export type ListScheduledContentsQueryHookResult = ReturnType<typeof useListScheduledContentsQuery>
export type ListScheduledContentsLazyQueryHookResult = ReturnType<typeof useListScheduledContentsLazyQuery>
export type ListScheduledContentsQueryResult = Apollo.QueryResult<
  ListScheduledContentsQuery,
  ListScheduledContentsQueryVariables
>
export const LocationDocument = gql`
  query location($tableID: Int!, $vendorID: Int!) {
    location(tableID: $tableID, vendorID: $vendorID) {
      id
      nameEn
      nameTh
    }
  }
`

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      tableID: // value for 'tableID'
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useLocationQuery(baseOptions: Apollo.QueryHookOptions<LocationQuery, LocationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options)
}
export function useLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationQuery, LocationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options)
}
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>
export type LocationQueryResult = Apollo.QueryResult<LocationQuery, LocationQueryVariables>
export const OrderAdjustmentsDocument = gql`
  query orderAdjustments($orderID: Int) {
    orderAndFPMemberCoupons(orderID: $orderID) {
      orderInvalids
      order {
        id
        adjustmentCashTotalSatangs
        adjustmentStaffCashTotalSatangs
        totalSatangs
        totalDueSatangs
        lineItems {
          id
          quantity
          totalSatangs
          product {
            id
            nameTh
            nameEn
            vendor {
              id
              nameTh
            }
            category {
              id
              nameTh
            }
            discountSatangs
            priceSatangs
          }
        }
        lineItemTotalSatangs
        excludingVat
        lineItemVatSatangs
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          fullAmountSatangs
          adjustmentType
          invalidReason
          promotion {
            descriptionEn
            descriptionTh
            type
          }
          externalCoupon {
            id
            staffPrivilegeType
          }
        }
      }
    }
  }
`

/**
 * __useOrderAdjustmentsQuery__
 *
 * To run a query within a React component, call `useOrderAdjustmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderAdjustmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderAdjustmentsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderAdjustmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderAdjustmentsQuery, OrderAdjustmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrderAdjustmentsQuery, OrderAdjustmentsQueryVariables>(OrderAdjustmentsDocument, options)
}
export function useOrderAdjustmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderAdjustmentsQuery, OrderAdjustmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrderAdjustmentsQuery, OrderAdjustmentsQueryVariables>(OrderAdjustmentsDocument, options)
}
export type OrderAdjustmentsQueryHookResult = ReturnType<typeof useOrderAdjustmentsQuery>
export type OrderAdjustmentsLazyQueryHookResult = ReturnType<typeof useOrderAdjustmentsLazyQuery>
export type OrderAdjustmentsQueryResult = Apollo.QueryResult<OrderAdjustmentsQuery, OrderAdjustmentsQueryVariables>
export const OrderAndFpMemberCouponsDocument = gql`
  query orderAndFPMemberCoupons($orderID: Int) {
    orderAndFPMemberCoupons(orderID: $orderID) {
      order {
        id
        userID
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          promotionID
          memberCouponID
          promotion {
            descriptionEn
            descriptionTh
            id
            nameEn
            nameTh
            type
            memberTierRankConditions
            targetEntity
          }
          memberCoupon {
            id
            endDate
            redeemedAt
            startDate
            status
            promotion {
              id
              conditionDetailsEn
              conditionDetailsTh
              descriptionEn
              descriptionTh
              extPromotionID
              nameEn
              nameTh
              images {
                resizeableURL
              }
            }
          }
          externalCouponID
          externalCoupon {
            id
            isAvailable
            endTime
            amount
            serialNumber
            rewardType
            thumbnailImageURL
            name
            description
            memberCouponRefID
            staffPrivilegeType
          }
          adjustmentType
          source
          invalidReason
          invalidData {
            minimumSatangs
            remainingMinimumSatangs
          }
        }
      }
      coupons {
        id
        isAvailable
        endTime
        amount
        serialNumber
        rewardType
        thumbnailImageURL
        name
        memberCouponRefID
        description
        membership {
          id
          user {
            id
            lineDisplayName
            linePicture
          }
        }
      }
      autoPromotions {
        id
        code
        descriptionEn
        descriptionTh
        nameEn
        nameTh
        memberTierRankConditions
        targetEntity
      }
      manualPromotions {
        id
        code
        descriptionEn
        descriptionTh
        nameEn
        nameTh
        memberTierRankConditions
        targetEntity
      }
    }
  }
`

/**
 * __useOrderAndFpMemberCouponsQuery__
 *
 * To run a query within a React component, call `useOrderAndFpMemberCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderAndFpMemberCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderAndFpMemberCouponsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderAndFpMemberCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderAndFpMemberCouponsQuery, OrderAndFpMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrderAndFpMemberCouponsQuery, OrderAndFpMemberCouponsQueryVariables>(
    OrderAndFpMemberCouponsDocument,
    options
  )
}
export function useOrderAndFpMemberCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderAndFpMemberCouponsQuery, OrderAndFpMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrderAndFpMemberCouponsQuery, OrderAndFpMemberCouponsQueryVariables>(
    OrderAndFpMemberCouponsDocument,
    options
  )
}
export type OrderAndFpMemberCouponsQueryHookResult = ReturnType<typeof useOrderAndFpMemberCouponsQuery>
export type OrderAndFpMemberCouponsLazyQueryHookResult = ReturnType<typeof useOrderAndFpMemberCouponsLazyQuery>
export type OrderAndFpMemberCouponsQueryResult = Apollo.QueryResult<
  OrderAndFpMemberCouponsQuery,
  OrderAndFpMemberCouponsQueryVariables
>
export const OrderAndHhMemberCouponsDocument = gql`
  query orderAndHHMemberCoupons($orderID: Int!) {
    orderAndHHMemberCoupons(orderID: $orderID) {
      order {
        id
        userID
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          promotionID
          promotion {
            descriptionEn
            descriptionTh
            id
            nameEn
            nameTh
            type
            memberTierRankConditions
            targetEntity
          }
          memberCouponID
          memberCoupon {
            id
            endDate
            redeemedAt
            startDate
            status
            promotion {
              id
              conditionDetailsEn
              conditionDetailsTh
              descriptionEn
              descriptionTh
              extPromotionID
              nameEn
              nameTh
              images {
                resizeableURL
              }
            }
          }
          externalCouponID
          externalCoupon {
            id
            isAvailable
            endTime
            amount
            serialNumber
            rewardType
            thumbnailImageURL
            name
            description
            memberCouponRefID
            staffPrivilegeType
          }
          adjustmentType
          source
          invalidReason
          invalidData {
            minimumSatangs
            remainingMinimumSatangs
          }
        }
      }
      coupons {
        isAvailable
        coupon {
          id
          endDate
          redeemedAt
          startDate
          status
          promotion {
            id
            conditionDetailsEn
            conditionDetailsTh
            descriptionEn
            descriptionTh
            extPromotionID
            nameEn
            nameTh
            images {
              resizeableURL
            }
          }
        }
      }
      autoPromotions {
        id
        code
        descriptionEn
        descriptionTh
        nameEn
        nameTh
        memberTierRankConditions
        targetEntity
      }
      manualPromotions {
        id
        code
        descriptionEn
        descriptionTh
        nameEn
        nameTh
        memberTierRankConditions
        targetEntity
      }
    }
  }
`

/**
 * __useOrderAndHhMemberCouponsQuery__
 *
 * To run a query within a React component, call `useOrderAndHhMemberCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderAndHhMemberCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderAndHhMemberCouponsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderAndHhMemberCouponsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderAndHhMemberCouponsQuery, OrderAndHhMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrderAndHhMemberCouponsQuery, OrderAndHhMemberCouponsQueryVariables>(
    OrderAndHhMemberCouponsDocument,
    options
  )
}
export function useOrderAndHhMemberCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderAndHhMemberCouponsQuery, OrderAndHhMemberCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrderAndHhMemberCouponsQuery, OrderAndHhMemberCouponsQueryVariables>(
    OrderAndHhMemberCouponsDocument,
    options
  )
}
export type OrderAndHhMemberCouponsQueryHookResult = ReturnType<typeof useOrderAndHhMemberCouponsQuery>
export type OrderAndHhMemberCouponsLazyQueryHookResult = ReturnType<typeof useOrderAndHhMemberCouponsLazyQuery>
export type OrderAndHhMemberCouponsQueryResult = Apollo.QueryResult<
  OrderAndHhMemberCouponsQuery,
  OrderAndHhMemberCouponsQueryVariables
>
export const OrderFpWithPromotionDocument = gql`
  query orderFPWithPromotion($orderID: Int) {
    orderAndFPMemberCoupons(orderID: $orderID) {
      order {
        id
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          adjustmentType
          promotion {
            id
            type
          }
        }
        totalSatangs
        adjustmentTotalSatangs
        lineItemTotalSatangs
        userID
        status
        lineItemVatSatangs
        lineItems {
          id
          lineItemBatchID
          productID
          totalSatangs
          customNotes
          quantity
          invalids
          status
          createdAt
          product {
            id
            nameEn
            nameTh
            excludedFromPromotion
            images {
              id
              resizableURL
            }
          }
          modifiers {
            modifierID
            quantity
            modifier {
              nameEn
              nameTh
              priceSatangs
              invalids
              modifierGroup {
                multipleQuantity
              }
            }
          }
          createdByUser {
            id
            linePicture
            lineDisplayName
          }
          serviceType
        }
        table {
          id
          name
          status
        }
        user {
          id
          lineDisplayName
          linePicture
        }
        payments {
          id
          status
          type
        }
        paymentType
        paymentPhone
        omiseCardID
        omiseCardLastDigits
        omiseCardBrand
      }
    }
  }
`

/**
 * __useOrderFpWithPromotionQuery__
 *
 * To run a query within a React component, call `useOrderFpWithPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFpWithPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFpWithPromotionQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderFpWithPromotionQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderFpWithPromotionQuery, OrderFpWithPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrderFpWithPromotionQuery, OrderFpWithPromotionQueryVariables>(
    OrderFpWithPromotionDocument,
    options
  )
}
export function useOrderFpWithPromotionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderFpWithPromotionQuery, OrderFpWithPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrderFpWithPromotionQuery, OrderFpWithPromotionQueryVariables>(
    OrderFpWithPromotionDocument,
    options
  )
}
export type OrderFpWithPromotionQueryHookResult = ReturnType<typeof useOrderFpWithPromotionQuery>
export type OrderFpWithPromotionLazyQueryHookResult = ReturnType<typeof useOrderFpWithPromotionLazyQuery>
export type OrderFpWithPromotionQueryResult = Apollo.QueryResult<
  OrderFpWithPromotionQuery,
  OrderFpWithPromotionQueryVariables
>
export const OrderHhWithPromotionDocument = gql`
  query orderHHWithPromotion($orderID: Int) {
    orderAndHHMemberCoupons(orderID: $orderID) {
      order {
        id
        adjustments {
          id
          labelEn
          labelTh
          amountSatangs
          adjustmentType
          promotion {
            id
            type
          }
        }
        totalSatangs
        adjustmentTotalSatangs
        lineItemTotalSatangs
        userID
        status
        lineItemVatSatangs
        lineItems {
          id
          lineItemBatchID
          productID
          totalSatangs
          customNotes
          quantity
          invalids
          status
          createdAt
          product {
            id
            nameEn
            nameTh
            excludedFromPromotion
            images {
              id
              resizableURL
            }
          }
          modifiers {
            modifierID
            quantity
            modifier {
              nameEn
              nameTh
              priceSatangs
              invalids
              modifierGroup {
                multipleQuantity
              }
            }
          }
          createdByUser {
            id
            linePicture
            lineDisplayName
          }
          serviceType
        }
        table {
          id
          name
          status
        }
        user {
          id
          lineDisplayName
          linePicture
        }
        payments {
          id
          status
          type
        }
        paymentType
        paymentPhone
        omiseCardID
        omiseCardLastDigits
        omiseCardBrand
      }
    }
  }
`

/**
 * __useOrderHhWithPromotionQuery__
 *
 * To run a query within a React component, call `useOrderHhWithPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderHhWithPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderHhWithPromotionQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderHhWithPromotionQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderHhWithPromotionQuery, OrderHhWithPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrderHhWithPromotionQuery, OrderHhWithPromotionQueryVariables>(
    OrderHhWithPromotionDocument,
    options
  )
}
export function useOrderHhWithPromotionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderHhWithPromotionQuery, OrderHhWithPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrderHhWithPromotionQuery, OrderHhWithPromotionQueryVariables>(
    OrderHhWithPromotionDocument,
    options
  )
}
export type OrderHhWithPromotionQueryHookResult = ReturnType<typeof useOrderHhWithPromotionQuery>
export type OrderHhWithPromotionLazyQueryHookResult = ReturnType<typeof useOrderHhWithPromotionLazyQuery>
export type OrderHhWithPromotionQueryResult = Apollo.QueryResult<
  OrderHhWithPromotionQuery,
  OrderHhWithPromotionQueryVariables
>
export const PaymentMethodDocument = gql`
  query paymentMethod($vendorID: Int!, $orderID: Int!, $tableID: Int!) {
    currentOrder(tableID: $tableID, orderID: $orderID, vendorID: $vendorID) {
      id
      lineItemVatSatangs
      status
      adjustmentCashTotalSatangs
      adjustmentStaffCashTotalSatangs
      totalSatangs
      totalDueSatangs
      lineItemTotalSatangs
      userID
      paymentType
      paymentPhone
      omiseCardID
      omiseCardLastDigits
      omiseCardholderName
      omiseCardBrand
      payments {
        status
        type
      }
    }
    vendor(tableID: $tableID, vendorID: $vendorID) {
      id
      nameEn
      nameTh
      logoImage {
        resizableURL
        originalURL
      }
    }
    vendorSetting(vendorID: $vendorID) {
      paymentMethods
    }
  }
`

/**
 * __usePaymentMethodQuery__
 *
 * To run a query within a React component, call `usePaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *      orderID: // value for 'orderID'
 *      tableID: // value for 'tableID'
 *   },
 * });
 */
export function usePaymentMethodQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentMethodQuery, PaymentMethodQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PaymentMethodQuery, PaymentMethodQueryVariables>(PaymentMethodDocument, options)
}
export function usePaymentMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodQuery, PaymentMethodQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PaymentMethodQuery, PaymentMethodQueryVariables>(PaymentMethodDocument, options)
}
export type PaymentMethodQueryHookResult = ReturnType<typeof usePaymentMethodQuery>
export type PaymentMethodLazyQueryHookResult = ReturnType<typeof usePaymentMethodLazyQuery>
export type PaymentMethodQueryResult = Apollo.QueryResult<PaymentMethodQuery, PaymentMethodQueryVariables>
export const ProductDocument = gql`
  query product($id: Int!, $tableID: Int!, $vendorID: Int!) {
    product(id: $id, tableID: $tableID, vendorID: $vendorID) {
      id
      nameEn
      nameTh
      descriptionEn
      descriptionTh
      tasteDescriptionTh
      tasteDescriptionEn
      excludedFromPromotion
      discountSatangs
      priceSatangs
      totalPriceSatangs
      categoryID
      tags
      memberTierRankConditions
      memberIneligibleReason
      maxSpicyLevel
      skipCustomNotesScreen
      modifierGroups {
        id
      }
      ingredientList {
        id
        nameTh
        nameEn
      }
      images {
        id
        resizableURL
        thumbnailResizableURL
        originalURL
      }
      nutrition {
        calories
        caloriesFromFat
        carbG
        cholesterolMg
        fatG
        fiberG
        proteinG
        saturatedFatG
        servingsPerItem
        sodiumMg
        sugarsG
        transFatG
      }
      promotionProduct {
        productGroupRewards {
          productIDs
        }
        promotion {
          id
          strategyType
        }
      }
      promotionBadge
    }
  }
`

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tableID: // value for 'tableID'
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options)
}
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options)
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>
export const ProductWithModifierDocument = gql`
  query productWithModifier(
    $id: Int!
    $tableID: Int!
    $vendorID: Int!
    $availableService: ProductAvailableServiceEnum
  ) {
    product(id: $id, tableID: $tableID, vendorID: $vendorID, availableService: $availableService) {
      id
      skipCustomNotesScreen
      modifierGroups {
        id
        active
        position
        maximumQuantity
        minimumQuantity
        modifierGroupID
        productID
        modifierGroup {
          id
          nameEn
          nameTh
          vendorID
          multipleQuantity
          modifiers {
            id
            active
            nameEn
            nameTh
            position
            images {
              id
              alt
              resizableURL
            }
            priceSatangs
            invalids
          }
        }
      }
      promotionProduct {
        productGroupRewards {
          products {
            id
            priceSatangs
            totalPriceSatangs
            discountSatangs
            excludedFromPromotion
            nameTh
            nameEn
            categoryID
            tags
            memberTierRankConditions
            memberIneligibleReason
            skipCustomNotesScreen
            excludedFromPromotion
            images {
              id
              resizableURL
            }
            skipCustomNotesScreen
            modifierGroups {
              id
              active
              position
              maximumQuantity
              minimumQuantity
              modifierGroupID
              productID
              modifierGroup {
                id
                nameEn
                nameTh
                vendorID
                multipleQuantity
                modifiers {
                  id
                  active
                  nameEn
                  nameTh
                  position
                  images {
                    id
                    alt
                    resizableURL
                  }
                  priceSatangs
                  invalids
                }
              }
            }
          }
          rewardType
          rewardValue
        }
        promotion {
          id
          strategyType
          nameEn
          nameTh
        }
      }
    }
  }
`

/**
 * __useProductWithModifierQuery__
 *
 * To run a query within a React component, call `useProductWithModifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductWithModifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductWithModifierQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tableID: // value for 'tableID'
 *      vendorID: // value for 'vendorID'
 *      availableService: // value for 'availableService'
 *   },
 * });
 */
export function useProductWithModifierQuery(
  baseOptions: Apollo.QueryHookOptions<ProductWithModifierQuery, ProductWithModifierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProductWithModifierQuery, ProductWithModifierQueryVariables>(
    ProductWithModifierDocument,
    options
  )
}
export function useProductWithModifierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductWithModifierQuery, ProductWithModifierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProductWithModifierQuery, ProductWithModifierQueryVariables>(
    ProductWithModifierDocument,
    options
  )
}
export type ProductWithModifierQueryHookResult = ReturnType<typeof useProductWithModifierQuery>
export type ProductWithModifierLazyQueryHookResult = ReturnType<typeof useProductWithModifierLazyQuery>
export type ProductWithModifierQueryResult = Apollo.QueryResult<
  ProductWithModifierQuery,
  ProductWithModifierQueryVariables
>
export const TemporaryOrderWithPromotionDocument = gql`
  query temporaryOrderWithPromotion($tableID: Int!, $lineItems: [OrderLineItemInput]!) {
    temporaryOrderWithPromotion(tableID: $tableID, lineItems: $lineItems) {
      id
      adjustments {
        id
        labelEn
        labelTh
        amountSatangs
        adjustmentType
        promotion {
          id
          type
        }
      }
      totalSatangs
      adjustmentTotalSatangs
      lineItemTotalSatangs
    }
  }
`

/**
 * __useTemporaryOrderWithPromotionQuery__
 *
 * To run a query within a React component, call `useTemporaryOrderWithPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryOrderWithPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryOrderWithPromotionQuery({
 *   variables: {
 *      tableID: // value for 'tableID'
 *      lineItems: // value for 'lineItems'
 *   },
 * });
 */
export function useTemporaryOrderWithPromotionQuery(
  baseOptions: Apollo.QueryHookOptions<TemporaryOrderWithPromotionQuery, TemporaryOrderWithPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TemporaryOrderWithPromotionQuery, TemporaryOrderWithPromotionQueryVariables>(
    TemporaryOrderWithPromotionDocument,
    options
  )
}
export function useTemporaryOrderWithPromotionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TemporaryOrderWithPromotionQuery, TemporaryOrderWithPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TemporaryOrderWithPromotionQuery, TemporaryOrderWithPromotionQueryVariables>(
    TemporaryOrderWithPromotionDocument,
    options
  )
}
export type TemporaryOrderWithPromotionQueryHookResult = ReturnType<typeof useTemporaryOrderWithPromotionQuery>
export type TemporaryOrderWithPromotionLazyQueryHookResult = ReturnType<typeof useTemporaryOrderWithPromotionLazyQuery>
export type TemporaryOrderWithPromotionQueryResult = Apollo.QueryResult<
  TemporaryOrderWithPromotionQuery,
  TemporaryOrderWithPromotionQueryVariables
>
