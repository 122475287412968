import React, { FC, ReactNode } from 'react'
import Modal from 'components/base-ui/Modal'
import { Button } from 'components/base-ui'
import styled from 'styled-components'

const defaultButtonOptions: ButtonOptions = {
  outline: false,
  content: '',
  isProcessing: false,
  onClick: () => {},
}

export interface ButtonOptions {
  outline?: boolean
  content: ReactNode
  isProcessing?: boolean
  onClick: () => void
}

export interface ModalConfirmProps {
  children?: ReactNode
  isOpen: boolean
  isLoading?: boolean
  hideCloseButton?: boolean
  onRequestClose: () => void
  shouldCloseOnOverlayClick?: boolean
  btnAccept: ButtonOptions
  btnCancel?: ButtonOptions
  buttonWidth?: string
  buttonFontSize?: string
  disableCancelOnProcessing?: boolean
  testId?: string
}

const ModalConfirm: FC<ModalConfirmProps> = ({
  children,
  isOpen,
  isLoading = false,
  hideCloseButton = false,
  onRequestClose,
  shouldCloseOnOverlayClick = true,
  btnAccept = defaultButtonOptions,
  btnCancel = defaultButtonOptions,
  buttonWidth = '85%',
  disableCancelOnProcessing = false,
  testId = 'modalComponent',
}) => {
  return (
    <Modal
      id={testId}
      isOpen={isOpen}
      isLoading={isLoading}
      hideCloseButton={hideCloseButton}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      zindex={100}
    >
      {children}
      <ButtonWrapper className="button-wrapper" buttonWidth={buttonWidth}>
        <Button
          className="btn-accept"
          md
          minWidth="160px"
          isProcessing={btnAccept.isProcessing}
          disabled={btnAccept.isProcessing}
          onClick={() => {
            btnAccept.onClick()
          }}
          outline={btnAccept.outline}
          data-testid="comfirmBtn"
        >
          {btnAccept.content}
        </Button>
        {btnCancel.content && (
          <Button
            className="btn-cancel"
            md
            minWidth="160px"
            disabled={(disableCancelOnProcessing && btnAccept.isProcessing) || btnCancel.isProcessing}
            onClick={() => {
              btnCancel.onClick()
            }}
            outline={btnCancel.outline}
            data-testid="cancelPopUpBtn"
          >
            {btnCancel.content}
          </Button>
        )}
      </ButtonWrapper>
    </Modal>
  )
}

export default ModalConfirm

const ButtonWrapper = styled.div<{ buttonWidth: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  text-align: center;

  button {
    min-width: ${({ buttonWidth }) => buttonWidth};
    font-size: '';
    height: auto;
  }
`
