import React, { ReactNode, useRef, useState, useEffect, Children } from 'react'
import SliderFlickityClass from './SliderFlickityClass'
import useScript from 'hooks/useScripts'
import { createGlobalStyle } from 'styled-components'
import flickityStyles from 'styles/flickity-styles'

interface SliderFlickityProps {
  children?: ReactNode
  flickityRef?: (flkty: any) => void
  options: any
}

const SliderFlickity: React.FC<SliderFlickityProps> = ({ children, options, flickityRef }) => {
  const flikRef = useRef<any>()
  const [flktyIndex, setFlktyIndex] = useState<number>(options.initialIndex ?? 0)

  const _options = {
    ...options,
    initialIndex: flktyIndex,
    on: {
      ...options.on,
      change: function (index) {
        setFlktyIndex(index)
        options?.on?.change && options.on.change(index)
      },
    },
  }

  const _flickityRef = (_flikRef) => {
    flikRef.current = _flikRef
    flickityRef && flickityRef(_flikRef)
  }

  const childLength = Children.count(children)
  useEffect(() => {
    if (flktyIndex + 1 >= childLength && flikRef.current) {
      const newIndex = flktyIndex - 1
      flikRef.current.deactivate()
      flikRef.current.selectedIndex = newIndex
      flikRef.current.activate()
      flikRef.current.reloadCells()
      flikRef.current.resize()
      setFlktyIndex(newIndex)
    }
  }, [childLength])

  const SliderFlickityClassAny = SliderFlickityClass as any
  const status = useScript('/scripts/flickity.pkgd.min.js')
  return status === 'ready' ? (
    <>
      <FlickityStyles />
      <SliderFlickityClassAny options={_options} flickityRef={_flickityRef}>
        {children}
      </SliderFlickityClassAny>
    </>
  ) : null
}

const FlickityStyles = createGlobalStyle`
  ${flickityStyles}
`

export default SliderFlickity
