declare global {
  interface Window {
    currentScrollElement: any
  }
}

export const setElementCurrentScrollTop = (DOMElement) => {
  window.currentScrollElement = DOMElement
}

const getCurrentScrollTop = () => {
  const DOMScroll = window.currentScrollElement

  return DOMScroll ? DOMScroll.scrollTop : 0
}

interface Options {
  preventDefault?: any
  stopPropagation?: any
}

export const onClick = (fn: any = () => {}, options: Options = {}) => {
  let isTouchMove, scrollTopStart
  const isPreventDefault = options?.preventDefault ?? false
  const isStopPropagation = options?.stopPropagation ?? false

  if ('ontouchend' in window || 'ontouchend' in document.documentElement) {
    return {
      onClick: (e) => {
        isPreventDefault && e.preventDefault()
        isStopPropagation && e.stopPropagation()
      },
      onTouchEnd: (e) => {
        const scrollTopEnd = getCurrentScrollTop()
        if (!isTouchMove && scrollTopStart === scrollTopEnd) {
          fn(e)
        }
      },
      onTouchMove: () => {
        isTouchMove = true
      },
      onTouchStart: () => {
        isTouchMove = false
        scrollTopStart = getCurrentScrollTop()
      },
    }
  } else {
    return {
      onClick: fn,
    }
  }
}
