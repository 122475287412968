import React, { useMemo, useContext, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { AppContext } from 'contexts/AppProvider'
import { THEMES_UI } from 'brands'
import GenericLoader from './GenericLoader'
import { checkDineInIsOpen, checkBuffetIsOpen } from 'helpers/dine-in'
import PoweredByHato from './PoweredByHato'

const LoadingBrand = ({ show }) => {
  const { brand } = useContext(AppContext)
  const loadingTimer = useRef()

  useEffect(() => {
    if (show) {
      clearTimeout(loadingTimer.current)
      loadingTimer.current = setTimeout(() => {
        console.log('Can not load data completely.')
      }, 10000)
    } else {
      clearTimeout(loadingTimer.current)
    }
    return () => clearTimeout(loadingTimer.current)
  }, [show])

  const uiLoading = useMemo(() => {
    return checkDineInIsOpen() || checkBuffetIsOpen() || brand?.theme?.ui === THEMES_UI.GENERIC ? (
      <GenericLoader />
    ) : (
      <LogoGonDelivery>
        <LogoGonDeiveryElement01 src={`${process.env.PUBLIC_URL}/assets/gon/logo-gon-delivery-01.svg`} />
        <LogoGonDeiveryElement02Wrapper>
          <LogoGonDeiveryElement02 src={`${process.env.PUBLIC_URL}/assets/gon/logo-gon-delivery-02.svg`} />
          <LogoGonDeiveryElement03 src={`${process.env.PUBLIC_URL}/assets/gon/logo-gon-delivery-03.png`} />
        </LogoGonDeiveryElement02Wrapper>
      </LogoGonDelivery>
    )
  }, [brand])

  return (
    <LoadingWrapper className={show ? '-show' : ''}>
      {brand && (
        <>
          {uiLoading}
          <AppVersion>
            Version {process.env.REACT_APP_VERSION}
            <PoweredByHatoWrapper>
              <PoweredByHato size="sm" />
            </PoweredByHatoWrapper>
          </AppVersion>
        </>
      )}
    </LoadingWrapper>
  )
}

export default LoadingBrand

const LoadingWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 9999;

  &.-show {
    display: flex;
  }
`

const Float = keyframes`
  0% {
    // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    // box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-10px);
  }
  100% {
    // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
`

const Swing = keyframes`
  0% { transform: rotate(10deg); }
  100% { transform: rotate(-10deg); }
`

const LogoGonDelivery = styled.div`
  width: 345px !important;
  height: 255px !important;
  position: relative;
  transform: scale(0.75);
`

const LogoGonDeiveryElement01 = styled.img`
  position: absolute;
  width: 345px;
  height: auto;
  top: -3px;
  left: 0;
  animation: ${Float} 0.7s ease-in-out infinite;
`

const LogoGonDeiveryElement02Wrapper = styled.div`
  position: absolute;
  height: auto;
  top: 0;
  left: 0;
  animation: ${Float} 0.7s 0.0875s ease-in-out infinite;
`

const LogoGonDeiveryElement02 = styled.img`
  width: 345px;
  height: auto;
`

const LogoGonDeiveryElement03 = styled.img`
  width: 131px;
  height: auto;
  position: absolute;
  top: 99px;
  right: -12px;
  transform-origin: center -20px;
  animation: ${Swing} ease-in-out 0.7s infinite alternate;
`

const AppVersion = styled.div`
  height: initial !important;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  text-align: center;
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PoweredByHatoWrapper = styled.div`
  margin-top: 10px;
`
