import React, { lazy, useEffect, useContext } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { AppContext } from 'contexts/AppProvider'
import { onClick } from 'helpers/fastClick'

const ReactLazyPreload = (importStatement) => {
  const Component = lazy(importStatement)
  Component.preload = importStatement
  return Component
}

// Pages
const Home = ReactLazyPreload(() => import('pages/Home'))
const Checkout = ReactLazyPreload(() => import('pages/hato-store/Checkout'))
const CreditCardManagement = ReactLazyPreload(() => import('pages/CreditCardManagement'))
const ProductDetails = ReactLazyPreload(() => import('pages/ProductDetails'))
const Category = ReactLazyPreload(() => import('pages/Category'))
const MallCategories = ReactLazyPreload(() => import('pages/MallCategories'))
const SearchProducts = ReactLazyPreload(() => import('pages/SearchProducts'))
const VendorDetailsGonGang = ReactLazyPreload(() => import('pages/VendorDetailsGonGang'))
const Languages = ReactLazyPreload(() => import('pages/Languages'))
const PrivacyPolicy = ReactLazyPreload(() => import('pages/PrivacyPolicy'))
const RefundPolicy = ReactLazyPreload(() => import('pages/RefundPolicy'))
const TermsAndConditionsPolicy = ReactLazyPreload(() => import('pages/TermsAndConditionsPolicy'))
const QRCodeDetail = ReactLazyPreload(() => import('pages/QRCodeDetail'))
const RedirectToLiff = ReactLazyPreload(() => import('pages/RedirectToLiff'))
const RedirectHHToLiff = ReactLazyPreload(() => import('pages/RedirectHHToLiff'))
const UploadPaymentSlip = ReactLazyPreload(() => import('pages/UploadPaymentSlip'))
const Campaigns = ReactLazyPreload(() => import('pages/Campaigns'))
const CampaignDetails = ReactLazyPreload(() => import('pages/CampaignDetails'))
const Feedback = ReactLazyPreload(() => import('pages/Feedback'))
const BankDetails = ReactLazyPreload(() => import('pages/BankDetails'))
const RedirectFromTrueWallet = ReactLazyPreload(() => import('pages/RedirectFromTrueWallet'))
const RedirectFromOmise = ReactLazyPreload(() => import('pages/RedirectFromOmise'))
const ApplyCoupon = ReactLazyPreload(() => import('pages/ApplyCoupon'))
const MemberCoupon = ReactLazyPreload(() => import('pages/MemberCoupon'))
const Promotions = ReactLazyPreload(() => import('pages/Promotions'))
const DineIn = ReactLazyPreload(() => import('pages/dine-in'))
const PendingPayment = ReactLazyPreload(() => import('pages/dine-in/pages/PendingPayment'))

// Pages Buffet
const BuffetRouter = ReactLazyPreload(() => import('pages/buffet/BuffetRouter'))
const BuffetPendingPayment = ReactLazyPreload(() => import('pages/dine-in/pages/PendingPayment'))

// Pages Hato Heart
const HomeHatoHeart = ReactLazyPreload(() => import('pages/hato-heart/HomeHatoHeart'))
const BurnPoints = ReactLazyPreload(() => import('pages/hato-heart/BurnPoints'))
const Coupons = ReactLazyPreload(() => import('pages/hato-heart/Coupons'))

// Development
const ResetCurrentUser = ReactLazyPreload(() => import('pages/ResetCurrentUser'))

export const routes = [
  {
    path: '/home',
    exact: true,
    component: RedirectToLiff,
    pageLevel: 0,
    transitionName: 'fade',
  },
  {
    path: '/',
    exact: true,
    component: Home,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/brand',
    exact: true,
    component: RedirectToLiff,
    pageLevel: 0,
    transitionName: 'fade',
  },
  {
    path: '/brand/:brandSlug',
    exact: true,
    component: RedirectToLiff,
    pageLevel: 0,
    transitionName: 'fade',
  },
  {
    path: '/upload-payment-slip',
    exact: true,
    component: UploadPaymentSlip,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/upload-payment-slip-extend',
    exact: true,
    component: UploadPaymentSlip,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/customer-feedback',
    exact: true,
    component: Feedback,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/customer-feedback-extend',
    exact: true,
    component: Feedback,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/bank-details',
    exact: true,
    component: BankDetails,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/checkout',
    exact: true,
    component: Checkout,
    pageLevel: 6,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/credit-card-management',
    exact: true,
    component: CreditCardManagement,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/apply-coupon',
    exact: true,
    component: ApplyCoupon,
    pageLevel: 7,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/member-coupon',
    exact: true,
    component: MemberCoupon,
    pageLevel: 7,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/promotions',
    exact: true,
    component: Promotions,
    pageLevel: 7,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/search/:locationID',
    exact: true,
    component: SearchProducts,
    pageLevel: 4,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/search/:locationID/:search',
    exact: true,
    component: SearchProducts,
    pageLevel: 4,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/list-categories/:categorySlug',
    exact: true,
    component: MallCategories,
    pageLevel: 2,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/category/:categoryId/:locationID',
    exact: true,
    component: Category,
    pageLevel: 3,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/product-detail/:productId/:locationID',
    exact: true,
    component: ProductDetails,
    pageLevel: 5,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/store/:locationID',
    exact: true,
    component: VendorDetailsGonGang,
    pageLevel: 4,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/languages',
    exact: true,
    component: Languages,
    pageLevel: 50,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
    pageLevel: 50,
    transitionName: 'bottom',
  },
  {
    path: '/refund-policy',
    exact: true,
    component: RefundPolicy,
    pageLevel: 50,
    transitionName: 'bottom',
  },
  {
    path: '/terms-and-conditions-policy',
    exact: true,
    component: TermsAndConditionsPolicy,
    pageLevel: 50,
    transitionName: 'bottom',
  },
  {
    path: '/qrcode-detail',
    exact: true,
    component: QRCodeDetail,
    pageLevel: 50,
    transitionName: 'fade',
  },
  {
    path: '/redirect-from-true-wallet',
    exact: true,
    component: RedirectFromTrueWallet,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/redirect-from-omise',
    exact: true,
    component: RedirectFromOmise,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/campaigns',
    exact: true,
    component: Campaigns,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/campaigns/:campaignID',
    exact: true,
    component: CampaignDetails,
    pageLevel: 1,
    transitionName: 'fade',
    private: true,
  },

  // Hato Heart
  {
    path: '/hato-heart',
    exact: true,
    component: HomeHatoHeart,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/hato-heart/home',
    exact: true,
    component: RedirectHHToLiff,
    pageLevel: 0,
    transitionName: 'fade',
  },
  {
    path: '/hato-heart/burn-points',
    exact: true,
    component: BurnPoints,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/hato-heart/coupons',
    exact: true,
    component: Coupons,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/reset-current-user',
    exact: true,
    component: ResetCurrentUser,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },

  // Dine In
  {
    path: '/dine-in',
    exact: true,
    component: DineIn,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/dine-in-pending-payment',
    exact: true,
    component: PendingPayment,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  // buffet
  {
    path: '/buffet-pending-payment',
    component: BuffetPendingPayment,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
  {
    path: '/buffet',
    exact: false,
    component: BuffetRouter,
    pageLevel: 0,
    transitionName: 'fade',
    private: true,
  },
]

const findComponentForRoute = (path, routes) => {
  const matchingRoute = routes.find((route) =>
    matchPath(path, {
      path: route.path,
      exact: route.exact,
    })
  )
  return matchingRoute ? matchingRoute.component : null
}

export const preloadRouteComponent = (path) => {
  const component = findComponentForRoute(path, routes)
  if (component && component.preload) {
    component.preload()
  }
}

export function getPathWithState(path, state = {}) {
  const matchingRoute = routes.find((route) =>
    matchPath(path, {
      path: route.path,
      exact: route.exact,
    })
  )

  return {
    pathname: matchingRoute?.path,
    state: {
      pageLevel: matchingRoute?.pageLevel,
      transitionName: matchingRoute?.transitionName,
      ...state,
    },
  }
}

export const useNavigate = () => {
  const { setReferrerUrl } = useContext(AppContext)
  const history = useHistory()

  return (url, search) => {
    let _url = url
    if (typeof url === 'string') {
      _url = getPathWithState(url)
      _url.pathname = url
    }

    if (typeof search === 'string') {
      _url.search = search
    }

    setReferrerUrl(window.location.href)
    history.push(_url)
  }
}

export const LinkWithPreload = ({ to, search = '', children, enableFastClick = true, inActive = false, ...rest }) => {
  const { setReferrerUrl } = useContext(AppContext)
  const history = useHistory()

  let path = to
  let _to = to
  if (typeof to !== 'string') {
    path = to.pathname
  } else {
    _to = getPathWithState(to)
    _to.pathname = to
    _to.search = search
  }

  useEffect(() => {
    preloadRouteComponent(path)
  }, [])

  let clickObj = {}
  const btnOnClick = () => {
    if (!inActive) {
      setReferrerUrl(window.location.href)
      history.push(_to)
    }
  }
  clickObj = enableFastClick ? { ...onClick((e) => btnOnClick(e)) } : { onClick: btnOnClick }

  return (
    <a {...rest} {...clickObj}>
      {children}
    </a>
  )
}

export function getPageLevelByPath(path) {
  const matchingRoute = routes.find((route) =>
    matchPath(path, {
      path: route.path,
      exact: route.exact,
    })
  )
  return matchingRoute ? matchingRoute.pageLevel : 0
}

export function getTransitionNameByPath(path) {
  const matchingRoute = routes.find((route) =>
    matchPath(path, {
      path: route.path,
      exact: route.exact,
    })
  )
  return matchingRoute ? matchingRoute.transitionName : 0
}
