import React from 'react'
import styled from 'styled-components'
import { Icon } from 'components/base-ui'

const BtnPlay = ({ className = '', ...rest }) => {
  rest.className = `${rest.className ? `${rest.className} ` : ''} button`

  return (
    <BtnPlayStyled {...rest}>
      <Icon name="play" />
    </BtnPlayStyled>
  )
}

const BtnPlayStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid white;
  transition: 0.6s;

  i {
    position: relative;
    left: 2px;
    color: white;
    transition: 0.6s;
  }

  .touch &.touch {
    transition: 0s;
    background-color: white;
    transform: scale(1.1);

    i {
      transition: 0s;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

export default BtnPlay
