import React, { FC, createContext, useState, ReactNode, useMemo, useEffect, useRef, MutableRefObject } from 'react'
import { GlobalCartType } from 'services/hooks/dine-in/useGetGlobalCart'
import { VendorType } from 'services/hooks/dine-in/useGetVendorDetail'
import { LocationType } from 'services/hooks/dine-in/useGetLocation'
import { ProductModifier } from 'services/hooks/dine-in/useGetProductModifiers'
import { ListProductsQuery } from 'graphQL/dinein/generate/operations'
import LoadingBrand from 'components/LoadingBrand'
import { PrivateCartType, ProductCartType } from 'types/cart'
import { useLocalStorage } from 'react-use'
import { MODE_MODALCUSTOMERNOTES } from 'helpers/cart'
import { CurrentOrderQuery } from 'graphQL/dinein/generate/operations'
import useLoadingPage from 'hooks/useLoadingPage'
import {
  LineItemServiceEnum,
  LineItemServiceTypeForDineInEnum,
  MemberProductIneligibleReasonEnum,
  PromotionStrategyTypeEnum,
} from 'graphQL/dinein/generate/operations'

type ProductsType = ListProductsQuery['listProducts']['products']

export type OrderType = CurrentOrderQuery['currentOrder']
export type PaymentType = CurrentOrderQuery['currentOrder']['payments'][number]
export interface DineInContextInterface {
  tableId: number
  setTableId: (tableId: number) => void
  orderId: number
  setOrderId: (orderId: number) => void
  currentOrder: OrderType
  setCurrentOrder: (orderId: OrderType) => void
  vendor: VendorType
  setVendor: (vendor: VendorType) => void
  location: LocationType
  setLocation: (location: LocationType) => void
  isInitialed: boolean
  setIsInitialed: (isInitialed: boolean) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  productList: ProductsType
  setProductList: (productList: ProductsType) => void
  productSelected: ProductCartType
  setProductSelected: any
  modifiersSelected: ProductModifier[]
  setModifiersSelected: any
  isOpenModalProductModifiers: boolean
  setIsOpenModalProductModifiers: (isOpenModalProductModifiers: boolean) => void
  isLoadingModalProductModifiers: boolean
  setIsLoadingModalProductModifiers: (isLoadingModalProductModifiers: boolean) => void
  modeModalProductModifiers: string
  setModeModalProductModifiers: (mode: MODE_MODALCUSTOMERNOTES) => void
  privateCarts: PrivateCartType[]
  setPrivateCarts: any
  privateCartItemSelectedIndex: number
  setPrivateCartItemSelectedIndex: (index: number) => void
  quantityInPrivateCart: number
  globalCart: GlobalCartType
  setGlobalCart: any
  tableIsAvailable: boolean
  setTableIsAvailable: (tableIsAvailable: boolean) => void
  isShowPaymentFailed: boolean
  setIsShowPaymentFailed: (isShowPaymentFailed: boolean) => void
  isShowModalConfirmOverride: boolean
  setIsShowModalConfirmOverride: (isShowPaymentFailed: boolean) => void
  isShowPaymentPending: boolean
  setIsShowPaymentPending: (isShowPaymentPending: boolean) => void
  enabledCheckPendingPayment: MutableRefObject<boolean>
  orderPayment: MutableRefObject<PaymentType>
  serviceType?: LineItemServiceEnum
  setServiceType: (type: LineItemServiceEnum) => void
  serviceTypeDineIn: LineItemServiceTypeForDineInEnum
  setServiceTypeDineIn: (type: LineItemServiceTypeForDineInEnum) => void
  isFixedServiceType: boolean
  setIsFixedServiceType: (isFixedServiceType: boolean) => void
  setUseDisableBodyScroll: (useDisableBodyScroll: boolean) => void
  useDisableBodyScroll: boolean
  toggleAlertLabel: (message: string) => void
  showAlertLabel: boolean
  alertText: string
  setAlertText: (text: string) => void
  allowedSharingCoupon: boolean
  setAllowedSharingCoupon: (allowedSharingCoupon: boolean) => void
  removingRecommendItemId: number
  setRemovingRecommendItemId: (removingRecommendItemId: number) => void
}

export const DineInContext = createContext<DineInContextInterface>({} as DineInContextInterface)

const initialProductSelected: ProductCartType = {
  customNotes: '',
  modifiers: [],
  quantity: 1,
  skipCustomNotesScreen: false,
  item: {
    descriptionEn: '',
    descriptionTh: '',
    skipModifierScreen: false,
    active: false,
    availableServices: [],
    excludedFromListing: false,
    categoryID: 0,
    discountSatangs: 0,
    id: 0,
    excludedFromPromotion: false,
    skipCustomNotesScreen: false,
    images: [],
    memberTierRankConditions: [],
    modifierGroups: [],
    nameEn: '',
    nameTh: '',
    priceSatangs: 0,
    tags: [],
    totalPriceSatangs: 0,
    memberIneligibleReason: MemberProductIneligibleReasonEnum.MembershipRequired,
    promotionProduct: {
      productGroupRewards: [],
      promotion: {
        id: 0,
        strategyType: PromotionStrategyTypeEnum.BuyXGetYForZ,
      },
    },
    buffetPackageProducts: [],
    category: {
      id: 0,
      nameEn: '',
      nameTh: '',
      descriptionEn: '',
      descriptionTh: '',
      position: 0,
      images: [],
    },
    promotionBadge: PromotionStrategyTypeEnum.BuyXGetYForZ,
  },
}

const initialOrderPayment = {
  status: '',
  type: '',
}
interface Props {
  children: ReactNode
}
const DineInProvider: FC<Props> = ({ children }) => {
  const [tableId, setTableId] = useState<number>(0)
  const [orderId, setOrderId] = useState<number>(0)
  const [currentOrder, setCurrentOrder] = useState({} as OrderType)
  const [vendor, setVendor] = useState({} as VendorType)
  const [location, setLocation] = useState({} as LocationType)
  const [isInitialed, setIsInitialed] = useState(false)
  const { isLoadingPage: isLoading, setIsLoadingPage: setIsLoading } = useLoadingPage({
    initialIsLoadingPage: true,
  })
  const [isOpenModalProductModifiers, setIsOpenModalProductModifiers] = useState(false)
  const [isLoadingModalProductModifiers, setIsLoadingModalProductModifiers] = useState(false)
  const [modeModalProductModifiers, setModeModalProductModifiers] = useState(MODE_MODALCUSTOMERNOTES.DEFAULT)
  const [productList, setProductList] = useState<ProductsType>([])
  const [productSelected, setProductSelected] = useState(initialProductSelected)
  const [modifiersSelected, setModifiersSelected] = useState<ProductModifier[]>([])
  const [privateCarts, setPrivateCarts] = useLocalStorage<PrivateCartType[]>('private_cart', [])
  const [privateCartItemSelectedIndex, setPrivateCartItemSelectedIndex] = useState(-1)
  const [tableIsAvailable, setTableIsAvailable] = useState(true)
  const [isShowPaymentFailed, setIsShowPaymentFailed] = useState(false)
  const [isShowModalConfirmOverride, setIsShowModalConfirmOverride] = useState(false)
  const [isShowPaymentPending, setIsShowPaymentPending] = useState(false)
  const enabledCheckPendingPayment = useRef(true)
  const orderPayment = useRef<PaymentType>(initialOrderPayment as PaymentType)
  const [serviceType, setServiceType] = useState<LineItemServiceEnum>(LineItemServiceEnum.DineIn)
  const [serviceTypeDineIn, setServiceTypeDineIn] = useState<LineItemServiceTypeForDineInEnum>(
    LineItemServiceTypeForDineInEnum.DineIn
  )
  const [isFixedServiceType, setIsFixedServiceType] = useState<boolean>(false)
  const [useDisableBodyScroll, setUseDisableBodyScroll] = useState(true) // It will be "false" when we open private cart page
  const [allowedSharingCoupon, setAllowedSharingCoupon] = useState(false)

  const quantityInPrivateCart: number = useMemo(() => {
    const currentPrivateCart = privateCarts?.find((cart) => cart.orderId === currentOrder.id)
    if (currentPrivateCart) {
      return currentPrivateCart.products.reduce((previousQuantity, product) => previousQuantity + product.quantity, 0)
    }
    return 0
  }, [privateCarts, currentOrder])
  const [globalCart, setGlobalCart] = useState({} as GlobalCartType)
  const [showAlertLabel, setShowAlertLabel] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [removingRecommendItemId, setRemovingRecommendItemId] = useState(0)

  useEffect(() => {
    if (currentOrder?.payments && currentOrder.payments.length) {
      const { payments } = currentOrder
      const indexLastPyament = payments.length - 1
      orderPayment.current = payments[indexLastPyament]
    } else {
      orderPayment.current = initialOrderPayment as PaymentType
    }
  }, [currentOrder])

  useEffect(() => resetStateModalProductModifiers(), [isOpenModalProductModifiers])

  function resetStateModalProductModifiers() {
    if (!isOpenModalProductModifiers) {
      setTimeout(() => {
        setProductSelected(initialProductSelected)
        setModifiersSelected([])
        setModeModalProductModifiers(MODE_MODALCUSTOMERNOTES.DEFAULT)
      }, 300)
    }
  }

  const toggleAlertLabel = (message: string) => {
    setAlertText(message)
    setShowAlertLabel(true)
    setTimeout(() => {
      setShowAlertLabel(false)
      setAlertText('')
    }, 3500)
  }

  const value: DineInContextInterface = {
    tableId,
    setTableId,
    orderId,
    setOrderId,
    currentOrder,
    setCurrentOrder,
    vendor,
    setVendor,
    location,
    setLocation,
    isInitialed,
    setIsInitialed,
    isLoading,
    setIsLoading,
    isOpenModalProductModifiers,
    setIsOpenModalProductModifiers,
    isLoadingModalProductModifiers,
    setIsLoadingModalProductModifiers,
    productList,
    setProductList,
    productSelected,
    setProductSelected,
    modifiersSelected,
    setModifiersSelected,
    modeModalProductModifiers,
    setModeModalProductModifiers,
    privateCarts: privateCarts ? privateCarts : [],
    setPrivateCarts,
    privateCartItemSelectedIndex,
    setPrivateCartItemSelectedIndex,
    quantityInPrivateCart,
    globalCart,
    setGlobalCart,
    tableIsAvailable,
    setTableIsAvailable,
    isShowPaymentFailed,
    setIsShowPaymentFailed,
    isShowModalConfirmOverride,
    setIsShowModalConfirmOverride,
    isShowPaymentPending,
    setIsShowPaymentPending,
    enabledCheckPendingPayment,
    orderPayment,
    serviceType,
    setServiceType,
    serviceTypeDineIn,
    setServiceTypeDineIn,
    isFixedServiceType,
    setIsFixedServiceType,
    useDisableBodyScroll,
    setUseDisableBodyScroll,
    toggleAlertLabel,
    showAlertLabel,
    alertText,
    setAlertText,
    allowedSharingCoupon,
    setAllowedSharingCoupon,
    removingRecommendItemId,
    setRemovingRecommendItemId,
  }

  return (
    <DineInContext.Provider value={value}>
      {children}
      <LoadingBrand show={isLoading} />
    </DineInContext.Provider>
  )
}

export default DineInProvider
