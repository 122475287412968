import styled, { css } from 'styled-components'
import { FontSize } from 'styles/fontSize'
import { ColorPalette } from 'styles/colors'

const family = {
  brand: "'Prompt', sans-serif",
  bodyText: "'Bai Jamjuree', sans-serif",
}

interface FontWeight {
  regular: number
  semiBold: number
}

const fontWeight: FontWeight = {
  regular: 400,
  semiBold: 600,
}

interface FontStyleProps {
  size: keyof FontSize
  weight: keyof FontWeight
  color?: keyof ColorPalette
}

const generateFontStyle = ({ size, weight, color = 'base' }: FontStyleProps) => css`
  font-family: ${family.brand};
  font-weight: ${fontWeight[weight]};
  font-size: ${({ theme }) => theme.fontSize[size]};
  color: ${({ theme }) => theme.colors[color]};
  line-height: 1.5;
  margin: 0;
`

interface FontProps {
  onClick?: (e: Event) => void
  noWrap?: boolean
  color?: keyof ColorPalette
}

const FontStyles = css<FontProps>`
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'pre-wrap')};
  ${({ color, theme }) => color && `color: ${theme.colors[color]}`};
`

export const DisplayLarge = styled.h1<FontProps>`
  ${generateFontStyle({ size: 'xxxl', weight: 'semiBold' })}
  ${FontStyles}
`

export const DisplaySmall = styled.h2<FontProps>`
  ${generateFontStyle({ size: 'xxxl', weight: 'semiBold' })}
  ${FontStyles}
`

export const HeadLineLarge = styled.h3<FontProps>`
  ${generateFontStyle({ size: 'xxl', weight: 'semiBold' })}
  ${FontStyles}
`

export const HeadLineMedium = styled.h4<FontProps>`
  ${generateFontStyle({ size: 'xl', weight: 'semiBold' })}
  ${FontStyles}
`

export const HeadLineSmall = styled.h5<FontProps>`
  ${generateFontStyle({ size: 'base', weight: 'semiBold' })}
  ${FontStyles}
`

export const LabelSemiBoldExtraLarge = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'xl', weight: 'semiBold' })}
  ${FontStyles}
`

export const LabelSemiBoldLarge = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'base', weight: 'semiBold' })}
  ${FontStyles}
`

export const LabelSemiBoldMedium = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'lg', weight: 'semiBold' })}
  ${FontStyles}
`

export const LabelSemiBoldSmall = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'md', weight: 'semiBold' })}
  ${FontStyles}
`

export const LabelSemiBoldTiny = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'sm', weight: 'semiBold' })}
  ${FontStyles}
`

export const LabelSemiBoldExtraTiny = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'xs', weight: 'semiBold' })}
  ${FontStyles}
`

export const LabelExtraLarge = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'xl', weight: 'regular' })}
  ${FontStyles}
`

export const LabelLarge = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'base', weight: 'regular' })}
  ${FontStyles}
`

export const LabelMedium = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'lg', weight: 'regular' })}
  ${FontStyles}
`

export const LabelSmall = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'md', weight: 'regular' })}
  ${FontStyles}
`

export const LabelTiny = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'sm', weight: 'regular' })}
  ${FontStyles}
`

export const LabelExtraTiny = styled.h6<FontProps>`
  ${generateFontStyle({ size: 'xs', weight: 'regular' })}
  ${FontStyles}
`

export const BodyLarge = styled.span<FontProps>`
  ${generateFontStyle({ size: 'base', weight: 'regular', color: 'baseGrey' })}
  ${FontStyles}
`

export const BodyMedium = styled.span<FontProps>`
  ${generateFontStyle({ size: 'xl', weight: 'regular' })}
  ${FontStyles}
`

export const BodySmall = styled.span<FontProps>`
  ${generateFontStyle({ size: 'md', weight: 'regular', color: 'baseGrey' })}
  ${FontStyles}
`

export const BodyTiny = styled.span<FontProps>`
  ${generateFontStyle({ size: 'sm', weight: 'regular', color: 'baseGrey' })}
  ${FontStyles}
`

export const ParagraphSemiboldLarge = styled.p<FontProps>`
  ${generateFontStyle({ size: 'lg', weight: 'semiBold' })}
  ${FontStyles}
`

export const ParagraphSemiboldSmall = styled.p<FontProps>`
  ${generateFontStyle({ size: 'lg', weight: 'semiBold', color: 'baseGrey' })}
  ${FontStyles}
`

export const ParagraphLarge = styled.p<FontProps>`
  ${generateFontStyle({ size: 'lg', weight: 'regular' })}
  ${FontStyles}
`

export const ParagraphSmall = styled.p<FontProps>`
  ${generateFontStyle({ size: 'lg', weight: 'regular', color: 'baseGrey' })}
  ${FontStyles}
`

export const LinkLarge = styled.p<FontProps>`
  ${generateFontStyle({ size: 'lg', weight: 'semiBold' })}
  color: ${({ theme }) => theme.colors.infoDark};
  ${FontStyles}
  text-decoration: underline;
`

export const LinkMedium = styled.p<FontProps>`
  ${generateFontStyle({ size: 'md', weight: 'semiBold' })}
  color: ${({ theme }) => theme.colors.infoDark};
  ${FontStyles}
  text-decoration: underline;
`

export const LinkSmall = styled.p<FontProps>`
  ${generateFontStyle({ size: 'sm', weight: 'semiBold' })}
  color: ${({ theme }) => theme.colors.infoDark};
  ${FontStyles}
  text-decoration: underline;
`
