import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ThemeColor } from 'types/color'
import { Icon, Fonts } from 'components/base-ui'
import { onClick } from 'helpers/fastClick'
import media from 'styles/media'
const { screenXsMax } = media

const capNumberMaximumQuantity = 9999

const QuantitySwitch = ({
  testId,
  quantity,
  minValue = 0,
  maxValue = null,
  onNoticeOverMaxValue = () => {},
  onUpdate = (quantity) => {},
  lg = false,
  sm = false,
  xlg = false,
  green = false,
  disabled = false,
  disabledIncreasing = false,
  color = ThemeColor.primary,
  isTypable = false,
  onFocus = () => {},
  onBlur = () => {},
  onClickIncreaseQuantity = () => {},
}) => {
  const [number, setNumber] = useState(parseInt(quantity))

  useEffect(() => {
    setNumber(parseInt(quantity))
  }, [quantity])

  const _disabledIncreasing = disabledIncreasing ? true : quantity >= capNumberMaximumQuantity ? true : false

  return (
    <QuantityBox>
      <BtnNumber
        sm={sm}
        lg={lg}
        className="button decrease-quantity"
        {...onClick(() => {
          if (number !== minValue && !disabled) {
            decrease()
          }
        })}
        disabled={number === minValue || disabled}
        color={color}
        green={green}
        data-testid={`${testId}DecreaseBtn`}
      >
        <Icon className="minus" name="minus" />
      </BtnNumber>
      <Input
        disabled={disabled}
        as="input"
        lg={lg}
        sm={sm}
        xlg={xlg}
        type="text"
        value={number}
        readOnly={!isTypable}
        onChange={handleOnChangeQuantity}
        onFocus={onFocus}
        onBlur={(e) => {
          const number = filterValidQuantity(e.target.value)
          onUpdate(number)
          setNumber(number)
          onBlur()
        }}
        inputMode="numeric"
        style={{ width: !isTypable ? getWidthInputFromLengthOfQuantityNumber(quantity) : '' }}
      />
      <BtnNumber
        lg={lg}
        sm={sm}
        className="button increase-quantity"
        color={color}
        green={green}
        disabled={_disabledIncreasing}
        {...onClick(increase)}
        data-testid={`${testId}IncreaseBtn`}
      >
        <Icon className="plus" name="plus" />
      </BtnNumber>
    </QuantityBox>
  )

  function getWidthInputFromLengthOfQuantityNumber(number) {
    const str = `${number}`
    switch (true) {
      case str.length === 3:
        return sm ? '34px' : '60px'
      case str.length === 4:
        return sm ? '44px' : '76px'
      default:
        return ''
    }
  }

  function filterValidQuantity(quantity) {
    let number = quantity.replace(/^0|^\s|$\s|\D/i, '')
    number = number < minValue || isNaN(number) ? minValue : number
    number = maxValue && number > maxValue ? maxValue : number
    number = number ? number : minValue
    number = parseInt(number)
    number = number > capNumberMaximumQuantity ? capNumberMaximumQuantity : number
    return number
  }

  function handleOnChangeQuantity(e) {
    if (e.target.value !== '') {
      const number = filterValidQuantity(e.target.value)
      onUpdate(number)
      setNumber(number)
      onBlur()
    } else {
      setNumber('')
      onFocus()
    }
  }

  function decrease() {
    const _number = number < minValue + 1 ? minValue : number - 1
    onUpdate(_number)
    setNumber(_number)
  }

  function increase() {
    if (number + 1 > maxValue && maxValue !== null) {
      onNoticeOverMaxValue()
    }

    if ((maxValue !== null && number > maxValue - 1) || _disabledIncreasing) {
      return false
    } else {
      const _number = number + 1
      onUpdate(_number)
      setNumber(_number)
      onClickIncreaseQuantity()
    }
  }
}

export default QuantitySwitch

const QuantityBox = styled.div`
  display: flex;
  align-items: center;
`

const BtnNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: 0.6s;
  background-color: white;
  box-shadow: 0 0 0 1px ${(props) => props.theme.colors.orange150};

  ${(props) =>
    props.green
      ? `
    box-shadow: 0 0 0 1px ${props.theme.colors.green200};
    `
      : ''}

  ${(props) =>
    props.color === ThemeColor.primary
      ? `
    box-shadow: 0 0 0 1px ${props.theme.colors.primary};
    `
      : ''}

  i {
    transition: 0.6s;
    color: ${(props) => props.theme.colors.orange};

    ${(props) =>
      props.color === ThemeColor.primary
        ? `color: ${props.theme.colors.primary};`
        : `color: ${props.theme.colors.secondary};`}
  }

  .minus {
    font-size: 10px;
    transform: scale(0.25);
  }

  .plus {
    font-size: 12px;
  }

  .no-touch &:hover,
  .touch &.touch {
    transition: 0s;
    background-color: ${(props) => props.theme.colors.orange};
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.orange};

    ${(props) =>
      props.color === ThemeColor.primary
        ? ` background-color: ${props.theme.colors.primary};
            box-shadow: 0 0 0 1px ${props.theme.colors.primary};`
        : ` background-color: ${props.theme.colors.secondary};
            box-shadow: 0 0 0 1px ${props.theme.colors.secondary};`}

    i {
      transition: 0s;
      color: white;
    }
  }

  ${(props) =>
    props.sm
      ? `
    width: 22px;
    height: 22px;

    .minus {
      font-size: 10px;
      transform: scale(.2);
    }

    .plus {
      font-size: 10px;
    }
  `
      : ` @media (${screenXsMax}) {
    width: 25px;
    height: 25px;

    .minus {
      font-size: 12px;
      transform: scale(0.2);
    }

    .plus {
      font-size: 12px;
    }
  }`}

  ${(props) =>
    props.lg
      ? `
    width: 38px;
    height: 38px;

    .minus {
      font-size: 10px;
      transform: scale(.3);
    }

    .plus {
      font-size: 14px;
    }
  `
      : ''}

  ${(props) =>
    props.disabled
      ? `
    background-color: ${props.theme.colors.gray100} !important;
    box-shadow: none !important;

    i {
      color: ${props.theme.colors.gray200} !important;
    }
  `
      : ''}
`

const Input = styled(Fonts.BaseBold)`
  display: block;
  border: 0;
  outline: none;
  height: 32px;
  line-height: 32px;
  width: 46px;
  text-align: center;
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray900};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 5px;
  box-shadow: 0.5px 0.5px 3px 0px #00000014;
  background-color: transparent;
  margin-left: 8px;
  margin-right: 8px;

  :disabled {
    color: ${({ theme }) => theme.colors.baseGrey};
  }

  ${(props) =>
    props.readOnly
      ? `
    border: 0;
    box-shadow: none;
    margin-left: 0;
    margin-right: 0;
  `
      : ''}

  ${(props) =>
    props.sm
      ? `
    height: 22px;
    line-height: 22px;
    width: 24px;
    font-size: 14px;
  `
      : ''}

  ${(props) =>
    props.lg
      ? `
    height: 38px;
    line-height: 38px;
    width: 48px;
  `
      : ''}

  ${(props) =>
    props.xlg
      ? `
    height: 46px;
    line-height: 46px;
    width: 46px;
  `
      : ''}

  ${(props) =>
    props.xlg && !props.readOnly
      ? `
    height: 46px;
    line-height: 46px;
    width: 76px;
  `
      : ''}
  
  @media (${screenXsMax}) {
    height: ${({ disabled }) => (disabled ? 20 : 35)}px;
    width: 60px;
    font-size: 15px;

    ${({ sm }) =>
      sm &&
      ` width: 35px;
    font-size: 12px;`}
  }
`
