import { AppContext } from 'contexts/AppProvider'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { addressSelector } from 'store/slices/addressSlice'
import { isDevelopment } from 'variables/environment'
import * as Sentry from '@sentry/browser'
import { isValidDeliveryDateAndTimeslot, convertLineItemsToCart, isSameModifiers, Modifier } from 'helpers/cart'
import { sendAnalyticsAddToCart, sendAnalyticsRemoveFromCart } from 'helpers/analytics'
import { setLocationIDSession } from 'helpers/localStorage'
import { useMutation } from 'helpers/apollo-hooks'
import queriesCurrentCart from 'services/gqlQueries/queriesCurrentCart'
import { gql } from '@apollo/client'
import { ProductType } from 'types/delivery'

const UPDATE_CART = gql`
mutation createOrUpdateCart(
  $cartId: Int
  $lineItems: [CustomerCartLineItemInput]
  $addressId: Int
  $deliveryDate: String
  $deliveryTimeslot: String
  $specialInstructions: String
  $locationID: Int
  $returnContainer: Boolean
  $requestUtensils: Boolean
  $taxInvoice: InvoiceInput
  $paymentType: CustomerPaymentTypeEnum
  $paymentPhone: String
  $pickUp: Boolean
  $lineChannelID: String
  $containerReturnInfo: ContainerReturnInfoInputType
) {
  createOrUpdateCart(
    cart: {
      id: $cartId
      lineItems: $lineItems
      shippingAddressID: $addressId
      deliveryDate: $deliveryDate
      deliveryTimeslot: $deliveryTimeslot
      specialInstructions: $specialInstructions
      locationID: $locationID
      returnContainer: $returnContainer
      requestUtensils: $requestUtensils
      taxInvoice: $taxInvoice
      paymentType: $paymentType
      paymentPhone: $paymentPhone
      pickUp: $pickUp
      lineChannelID: $lineChannelID
      containerReturnInfo: $containerReturnInfo
    }
  ) {
    cart {
      ${queriesCurrentCart}
    }
    cartInvalids
    corrections
  }
}
`

export interface AddToCartParams {
  item: ProductType & { locationID: number; type: string }
  customNotes?: string
  quantity?: number
  onCompleted: () => void
  cartData?: any
  clearItem?: boolean
  useContainer?: boolean
  compareLocationID?: boolean
  modifiers?: any
}

interface AddToCartMultiItemsParams {
  items: AddToCartParams[]
  onCompleted?: VoidFunction
  cartData?: any
}

interface UdateCartCustomNotesParams {
  item: {
    id: number
    quantity: number
  }
  latestCustomNotes: string
  newCustomNotes: string
  latestModifiers: Modifier[]
  newModifiers: Modifier[]
  quantity: number
}

const useGlobalStateHook = () => {
  const address = useSelector(addressSelector)
  const {
    accessToken,
    cart,
    setCart,
    setFlagTriggerFetchCart,
    setIsOpenModalSystemError,
    getBasicCartVariablesForUpdated,
    userProfile,
    addToCartBusyState,
    setCurrentItemModalChangeStore,
    setIsOpenModalChangeStore,
    setIsReOrderingCheckout,
    setCurrentLocationID,
    setCartLocationID,
  } = useContext(AppContext)

  const [requestUpdateCart] = useMutation(UPDATE_CART, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  })

  async function updateCartAddress({ addressId, locationID, isClearCart = false, onCompleted = () => {} }) {
    let _lineItemsCart = cart && cart.lineItems ? convertLineItemsToCart(cart.lineItems) : []

    if (isClearCart) {
      _lineItemsCart = []
    }

    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: { id: addressId },
      locationID,
    })

    try {
      const result = await updateCart({ variables })

      // updateInCart
      const cartUpdatedResult = result?.data?.createOrUpdateCart?.cart ?? null
      if (cartUpdatedResult) {
        setCart(cartUpdatedResult)
      } else {
        setCart({ ...cart, addressId, locationID })
      }

      onCompleted?.()

      setTimeout(() => {
        setFlagTriggerFetchCart(true)
      }, 300)

      return result
    } catch (err) {
      console.log('Error update addressId to cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update address id to cart: ' + err))
    }
  }

  function selectFirstDateAndTimeslotFromAPI() {
    const firstDateAvailable = cart.deliverableDays[0]
    return {
      deliveryDate: firstDateAvailable.date,
      deliveryTimeslot: firstDateAvailable.timeslots[0],
    }
  }

  async function updateCart(payload) {
    // Wrap request for validation deliveryDate & Timeslots before updateCart.
    const deliverableDays = cart && cart.deliverableDays ? cart.deliverableDays : []
    if (cart && cart.deliverableDays.length > 0 && address.isAvailable) {
      if (cart.deliveryDate) {
        // Have Selected Date
        // Check date and timeslot is Valid
        const isValidDate = isValidDeliveryDateAndTimeslot({
          deliverableDays,
          deliveryDate: cart.deliveryDate,
          deliveryTimeslot: cart.deliveryTimeslot,
        })

        // if not valid select first date and timeslot from deliverableDays API
        if (!isValidDate) {
          payload.variables = {
            ...payload.variables,
            ...selectFirstDateAndTimeslotFromAPI(),
          }
        }
      } else {
        // First Time Selected Date to init first data of Delivery
        payload.variables = {
          ...payload.variables,
          ...selectFirstDateAndTimeslotFromAPI(),
        }
      }
    }

    return requestUpdateCart(payload)
  }

  function addItem({ lineItems, item, customNotes = '', quantity = 1, useContainer, modifiers = [] }) {
    // Check same customNotes, id and modifiers
    const _lineItems = JSON.parse(JSON.stringify(lineItems))
    const target = _lineItems.find(
      (_item) =>
        _item.productID === item.id && _item.customNotes === customNotes && isSameModifiers(modifiers, _item.modifiers)
    )
    if (target) {
      target.quantity += quantity
      target.useContainer = useContainer
      target.modifiers = modifiers
    } else {
      _lineItems.push({
        product: item,
        productID: item.id,
        quantity,
        customNotes,
        useContainer,
        modifiers,
      })
    }
    sendAnalyticsAddToCart({ userProfile, product: item, quantity })
    return _lineItems
  }

  async function addToCartMultiItems({ items = [], onCompleted = () => {}, cartData }: AddToCartMultiItemsParams) {
    // Cart Version APIs
    const _cart = cartData ? cartData : cart
    let _lineItems = _cart && _cart.lineItems ? _cart.lineItems : []
    items.forEach((_item) => {
      const { customNotes, quantity, useContainer, modifiers, item } = _item
      _lineItems = addItem({
        lineItems: _lineItems,
        item,
        customNotes,
        quantity,
        useContainer,
        modifiers,
      })
    })
    const _lineItemsCart = convertLineItemsToCart(_lineItems)

    // Set Cart APIs
    const variables = getBasicCartVariablesForUpdated({
      cart: _cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    try {
      await updateCart({ variables })

      // Refetch Cart When Update Cart
      setFlagTriggerFetchCart(true)
      onCompleted()
    } catch (err) {
      console.log('Error add to cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Add multi items to cart' + err))
    }
  }

  async function addToCart({
    item,
    customNotes = '',
    quantity = 1,
    onCompleted = () => {},
    cartData,
    clearItem = false,
    useContainer = false,
    compareLocationID = false,
    modifiers = [],
  }: AddToCartParams) {
    if (addToCartBusyState.current) {
      return false
    }

    addToCartBusyState.current = true

    // Cart Version APIs
    const _cart = cartData ? cartData : cart
    let _lineItems = _cart && _cart.lineItems ? _cart.lineItems : []

    // Check if cart aleary hasContainer this item auto checked useContainer.
    // if this item notSkipCustomNotes useContainer priority from params first.
    let _useContainer = false
    if (item.skipCustomNotesScreen || item.modifierGroups.length > 0) {
      _useContainer = cart && cart.hasContainer ? true : useContainer
    } else {
      _useContainer = useContainer
    }

    // Check new item location is same cart locationID
    if (_cart && _cart.locationID !== item.locationID && _lineItems.length > 0 && !clearItem && compareLocationID) {
      setCurrentItemModalChangeStore({
        item,
        quantity,
        customNotes,
        useContainer: _useContainer,
        modifiers,
        onCompleted,
      })
      setIsOpenModalChangeStore(true)
      return false
    }

    if (clearItem) {
      _lineItems = []
    }

    _lineItems = addItem({
      lineItems: _lineItems,
      item,
      customNotes,
      quantity,
      useContainer: _useContainer,
      modifiers,
    })
    const _lineItemsCart = convertLineItemsToCart(_lineItems)

    // Set Cart APIs
    const variables = getBasicCartVariablesForUpdated({
      cart: _cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    variables.locationID = item.locationID

    try {
      await updateCart({ variables })

      // Refetch Cart When Update Cart
      setFlagTriggerFetchCart(true)
      addToCartBusyState.current = false
      onCompleted()
    } catch (err) {
      console.log('Error add to cart: ', err)
      addToCartBusyState.current = false
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Add item to cart' + err))
    }
  }

  async function updateCartQuantityItem({ itemId, quantity, customNotes, modifiers }) {
    // Cart Version APIs
    const lineItems = cart && cart.lineItems ? cart.lineItems : []
    const _lineItems = JSON.parse(JSON.stringify(lineItems))

    const targetItem = _lineItems.find(
      (item) =>
        item.productID === itemId && item.customNotes === customNotes && isSameModifiers(modifiers, item.modifiers)
    )
    if (targetItem) {
      targetItem.quantity = quantity
    }

    const _lineItemsCart = convertLineItemsToCart(_lineItems)

    // Set Cart APIs
    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    try {
      await updateCart({ variables })

      // Refetch Cart When Update Cart
      setIsReOrderingCheckout(false)
      setFlagTriggerFetchCart(true)
    } catch (err) {
      console.log('Error update cart quantity: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update cart quantity item: ' + err))
    }
  }

  async function updateCartCustomNotesItem({
    item,
    latestCustomNotes,
    newCustomNotes,
    latestModifiers,
    newModifiers,
    quantity,
  }: UdateCartCustomNotesParams) {
    // Cart Version APIs
    const lineItems = cart && cart.lineItems ? convertLineItemsToCart(cart.lineItems) : []
    const _lineItems = JSON.parse(JSON.stringify(lineItems))

    // If Duplicate customNotes on same productID then combine item.
    // Have productID more than one item in lineItems.
    let countLineItemSameProductID = 0
    _lineItems.forEach((_item) => {
      if (_item.productID === item.id) {
        countLineItemSameProductID++
      }
    })
    const targetItemDuplicate =
      countLineItemSameProductID > 1
        ? _lineItems.find(
            (_item) =>
              _item.productID === item.id &&
              _item.customNotes === newCustomNotes &&
              isSameModifiers(_item.modifiers, newModifiers)
          )
        : undefined

    const targetItemIndex = _lineItems.findIndex(
      (_item) =>
        _item.productID === item.id &&
        _item.customNotes === latestCustomNotes &&
        isSameModifiers(_item.modifiers, latestModifiers)
    )
    const targetItem = _lineItems[targetItemIndex]

    if (targetItemDuplicate) {
      // Combine items
      targetItemDuplicate.quantity += quantity ? quantity : item.quantity
      // Remove old items
      _lineItems.splice(targetItemIndex, 1)
    } else {
      targetItem.customNotes = newCustomNotes
      targetItem.modifiers = newModifiers

      if (quantity) {
        targetItem.quantity = quantity
      }
    }

    const _lineItemsCart = convertLineItemsToCart(_lineItems)

    // Set Cart APIs
    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    try {
      await updateCart({ variables })

      // Refetch Cart When Update Cart
      setIsReOrderingCheckout(false)
      setFlagTriggerFetchCart(true)
    } catch (err) {
      console.log('Error update cart quantity: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update cart item customer notes: ' + err))
    }
  }

  async function removeCartItem({ itemId, customNotes, modifiers, quantity }) {
    // Cart Version APIs
    let lineItems = cart && cart.lineItems ? cart.lineItems : []
    const _lineItems = JSON.parse(JSON.stringify(lineItems))
    const targetIndex = _lineItems.findIndex(
      (item) =>
        item.productID === itemId && item.customNotes === customNotes && isSameModifiers(modifiers, item.modifiers)
    )

    sendAnalyticsRemoveFromCart({ product: _lineItems[targetIndex].product, quantity })

    _lineItems.splice(targetIndex, 1)

    const _lineItemsCart = convertLineItemsToCart(_lineItems)

    // Set Cart APIs
    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    try {
      await updateCart({ variables })

      // Refetch Cart When Update Cart
      setIsReOrderingCheckout(false)
      setFlagTriggerFetchCart(true)
    } catch (err) {
      console.log('Error remove cart item: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Remove item in cart: ' + err))
    }
  }

  async function updateCartDeliveryDateAndTimeslot({
    deliveryDate,
    deliveryTimeslot,
    scheduledReturnDate,
    scheduledReturnTimeslot,
    haveEligibleContainerTypeItem = false,
  }) {
    const _lineItemsCart = convertLineItemsToCart(cart.lineItems)

    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    const containerReturnInfo = haveEligibleContainerTypeItem
      ? {
          scheduledReturnDate,
          scheduledReturnTimeslot,
        }
      : null

    variables.deliveryDate = deliveryDate
    variables.deliveryTimeslot = deliveryTimeslot
    if (haveEligibleContainerTypeItem) {
      variables.containerReturnInfo = containerReturnInfo
    }

    try {
      await updateCart({ variables })

      // updateInCart
      setCart({ ...cart, deliveryDate, deliveryTimeslot, containerReturnInfo })

      // Refetch Cart When Update Cart
      setFlagTriggerFetchCart(true)
    } catch (err) {
      console.log('Error update delivery date & time to cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment &&
        Sentry.captureException(new Error('HatoHub error: Update cart delivery date and timeslot: ' + err))
    }
  }

  async function updateCartLocationID({ locationID }) {
    let _lineItemsCart = cart && cart.lineItems ? convertLineItemsToCart(cart.lineItems) : []

    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      locationID,
    })

    try {
      const result = await updateCart({ variables })

      // updateInCart
      const cartUpdatedResult = result?.data?.createOrUpdateCart?.cart ?? null
      if (cartUpdatedResult) {
        setCart(cartUpdatedResult)
      } else {
        setCart({ ...cart, locationID })
      }

      setCurrentLocationID(locationID)
      setCartLocationID(locationID)
      setLocationIDSession(locationID)

      setTimeout(() => {
        setFlagTriggerFetchCart(true)
      }, 300)

      return result
    } catch (err) {
      console.log('Error update locationID to cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update locationID to cart: ' + err))
    }
  }

  async function updateCartPickUp({ pickUp, locationID }: { pickUp: boolean; locationID?: number }) {
    const _cartLineItems = cart ? cart.lineItems : []
    const _lineItemsCart = convertLineItemsToCart(_cartLineItems)

    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
      pickUp,
      locationID,
    })

    try {
      await updateCart({ variables })

      // updateInCart
      setCart({ ...cart, pickUp, shippingAddressID: variables.addressId })

      // Refetch Cart When Update Cart
      setFlagTriggerFetchCart(true)
    } catch (err) {
      console.log('Error update pickUp to cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update cart pick up: ' + err))
    }
  }

  async function updateCartRequestUtensils(value) {
    const _lineItemsCart = convertLineItemsToCart(cart.lineItems)

    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    variables.requestUtensils = value

    try {
      // updateInCart
      setCart({ ...cart, requestUtensils: value })
      await updateCart({ variables })
    } catch (err) {
      console.log('Error update requestUtensils to cart: ', err)
      setIsOpenModalSystemError(true)
      // Refetch Cart When error to recover state of checkout page.
      setFlagTriggerFetchCart(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update cart request utensils: ' + err))
    }
  }

  async function updateCartTaxInvoice({ companyName, address: taxInvoiceAddress, taxID, id }) {
    const _lineItemsCart = convertLineItemsToCart(cart.lineItems)

    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    variables.taxInvoice = {
      id,
      companyName,
      address: taxInvoiceAddress,
      taxID,
    }

    try {
      await updateCart({ variables })

      // updateInCart
      setCart({ ...cart, taxInvoice: variables.taxInvoice })

      // Refetch Cart When Update Cart
      setFlagTriggerFetchCart(true)
    } catch (err) {
      console.log('Error update taxInvoice to cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update cart tax invoice: ' + err))
    }
  }

  async function clearCartTaxInvoice() {
    const _lineItemsCart = convertLineItemsToCart(cart.lineItems)

    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    variables.taxInvoice = null

    try {
      // updateInCart
      setCart({ ...cart, taxInvoice: null })
      await updateCart({ variables })
    } catch (err) {
      console.log('Error clear taxInvoice to cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update cart cleart tax invoice: ' + err))
    }
  }

  async function updateCartPaymentMethod({ paymentType, paymentPhone }) {
    const _lineItemsCart = convertLineItemsToCart(cart.lineItems)

    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    variables.paymentType = paymentType
    variables.paymentPhone = paymentPhone

    try {
      await updateCart({ variables })

      // updateInCart
      setCart({ ...cart, paymentType, paymentPhone })
    } catch (err) {
      console.log('Error update payment method to cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update cart payment method: ' + err))
    }
  }

  async function updateCartLineChannel({ lineChannelID }) {
    const _lineItemsCart = convertLineItemsToCart(cart.lineItems)

    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
    })

    variables.lineChannelID = lineChannelID

    try {
      await updateCart({ variables })

      // updateInCart
      setCart({ ...cart, lineChannelID })
    } catch (err) {
      console.log('Error update line channel to cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update cart line channel: ' + err))
    }
  }

  async function updateCartReOrdering({ lineItems, locationID }) {
    const _lineItemsCart = convertLineItemsToCart(lineItems)

    const variables = getBasicCartVariablesForUpdated({
      cart,
      lineItems: _lineItemsCart,
      address: address.main,
      locationID,
    })

    try {
      await updateCart({ variables })

      // Refetch Cart When Update Cart
      setFlagTriggerFetchCart(true)
    } catch (err) {
      console.log('Error update lineItems re-ordering cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Update cart re-ordering: ' + err))
    }
  }

  async function createEmptyCart({ locationID }) {
    const variables = getBasicCartVariablesForUpdated({
      address: address.main,
      locationID,
    })

    try {
      await updateCart({ variables })

      // Refetch Cart When Update Cart
      setFlagTriggerFetchCart(true)
    } catch (err) {
      console.log('Error update cart create empty cart: ', err)
      setIsOpenModalSystemError(true)
      !isDevelopment && Sentry.captureException(new Error('HatoHub error: Create empty cart: ' + err))
    }
  }

  return {
    updateCartAddress,
    addToCartMultiItems,
    addToCart,
    updateCartQuantityItem,
    updateCartCustomNotesItem,
    removeCartItem,
    updateCartDeliveryDateAndTimeslot,
    updateCartLocationID,
    updateCartPickUp,
    updateCartRequestUtensils,
    updateCartTaxInvoice,
    clearCartTaxInvoice,
    updateCartPaymentMethod,
    updateCartLineChannel,
    updateCartReOrdering,
    createEmptyCart,
  }
}

export default useGlobalStateHook
