import React from 'react'
import styled from 'styled-components'
import { Modal, Fonts, Button } from 'components/base-ui'
import { useTranslation } from 'react-i18next'

interface ModalConfirmWantToChangePaymentMethodProps {
  isOpen: boolean
  isProcessing: boolean
  onRequestClose: VoidFunction
  onClickConfirm: VoidFunction
}

const ModalConfirmWantToChangePaymentMethod = ({
  isOpen,
  isProcessing,
  onRequestClose,
  onClickConfirm,
}: ModalConfirmWantToChangePaymentMethodProps) => {
  const { t } = useTranslation()
  return (
    <Modal hideCloseButton={false} isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalContent>
        <Title>{t('dineInModalConfirmChangePaymentTitle')}</Title>

        <WrapperButton>
          <Button float md2 onClick={onRequestClose}>
            {t('dineInModalConfirmChangePaymentBtnBack')}
          </Button>
          <Button
            float
            md2
            outline
            onClick={onClickConfirm}
            isProcessing={isProcessing}
            disabled={isProcessing}
            style={{ whiteSpace: 'nowrap' }}
          >
            {t('dineInModalConfirmChangePaymentBtnConfirm')}
          </Button>
        </WrapperButton>
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirmWantToChangePaymentMethod

const ModalContent = styled.div`
  padding-top: 32px;
  padding-bottom: 16px;
  text-align: center;
`

const Title = styled(Fonts.H3)`
  margin-bottom: 32px;
`

const WrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
