import React, { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ScreenWithImage from 'components/dine-in/ScreenWithImage'
import { Button as BaseButton } from 'components/base-ui'

interface CreditCardFailedScreenProps {
  onChangePaymentMethod: () => void
  isProcessing: boolean
}

const imageUrl = `${process.env.PUBLIC_URL}/assets/dine-in/credit-card-timeout.svg`
const CreditCardFailedScreen: FC<CreditCardFailedScreenProps> = ({ onChangePaymentMethod, isProcessing }) => {
  const { t } = useTranslation()

  return (
    <ScreenWithImage
      imagePath={imageUrl}
      imageSize="70px"
      title={t('dineInCreditCardFailedTitle')}
      description={t('dineInCreditCardFailedDesc')}
      onClick={onChangePaymentMethod}
    >
      <Button md onClick={onChangePaymentMethod} isProcessing={isProcessing} disabled={isProcessing}>
        {t('dineInButtonChangePaymentType')}
      </Button>
    </ScreenWithImage>
  )
}

export default CreditCardFailedScreen

const Button = styled(BaseButton)<{ isThaiLang: boolean }>`
  width: ${({ isThaiLang }) => (isThaiLang ? 250 : 260)}px;
  height: 50px;
  padding-left: 0;
  padding-right: 0;
`
