import { useContext } from 'react'
import { TableStatus } from 'enums/dineIn'
import {
  OrderStatusEnum,
  PaymentStatusEnum,
  PaymentTypeEnum,
  StaffCallingReasonEnum,
} from 'graphQL/dinein/generate/operations'
import moment from 'moment'
import {
  BuffetCurrentOrder,
  buffetSelector,
  resetBuffetModal,
  setBuffetModal,
  setBuffetScreen,
} from 'store/slices/buffetSlice'
import { useSelector } from 'react-redux'
import { isProduction } from 'variables/environment'
import * as Sentry from '@sentry/browser'
import { AppContext } from 'contexts/AppProvider'
import { useAppDispatch } from 'store'
import useHandleBuffetOrder from 'hooks/buffet/useHandleBuffetOrder'
import { ModalEnum } from 'components/buffet/BuffetModals'
import { ScreenEnum } from 'components/buffet/BuffetOverlayPage'

export enum OrderInvalidEnum {
  TableIsNotAvailable = 'TableIsNotAvailable',
  PaymentPaid = 'PaymentPaid',
  CreditCardFailed = 'CreditCardFailed',
  PendingKplusPayment = 'PendingKplusPayment',
  PaymentIsNotCompleted = 'PaymentIsNotCompleted',
  PendingQRPayment = 'PendingQRPayment',
  PendingPayment = 'PendingPayment',
  OwnerNeedToCheckout = 'OwnerNeedToCheckout',
  AnotherHasOccupied = 'AnotherHasOccupied',
  OrderIsTimeup = 'OrderIsTimeup',
  ReadyToCheckout = 'ReadyToCheckout',
  PackageHasChanged = 'PackageHasChanged',
  UnableGetOrder = 'UnableGetOrder',
  CallingStaff = 'CallingStaff',
  StaffInitiateCheckout = 'StaffInitiateCheckout',
}

export interface ValidateCurrentOrderResult {
  isOK: boolean
  invalid: string
}

const useValidateCurrentOrder = () => {
  const { currentUser } = useContext(AppContext)
  const buffet = useSelector(buffetSelector)
  const dispatch = useAppDispatch()

  const validateCurrentOrder: (order: BuffetCurrentOrder, checkTimeup?: boolean) => ValidateCurrentOrderResult = (
    order: BuffetCurrentOrder,
    checkTimeup = false
  ) => {
    try {
      if (order) {
        const { status: orderStatus, payments, table, buffetEndTime, buffetPackage } = order

        const isOwner = currentUser?.id === order.userID && order.status !== OrderStatusEnum.StaffCheckout
        let paymentStatus: PaymentStatusEnum | '' = ''
        let paymentType: PaymentTypeEnum | '' = ''

        if (payments.length) {
          const indexLastPyament = payments.length - 1
          const { status, type } = payments[indexLastPyament]
          paymentType = type
          paymentStatus = status
        }

        const isQRPayment =
          paymentType === PaymentTypeEnum.KBankThaiQr || paymentType === PaymentTypeEnum.OmisePromptPay
        const isTrueWallet = paymentType === PaymentTypeEnum.OmiseTrueMoneyWallet
        const isCreditCard = paymentType === PaymentTypeEnum.OmiseCreditCard
        const isKplusNumber = paymentType === PaymentTypeEnum.KPaymentKPlusNumber
        let tableIsAvailable = !(table.status !== TableStatus.OCCUPIED || orderStatus === OrderStatusEnum.Cancelled)
        if (order.status === OrderStatusEnum.StaffCheckout) {
          return {
            isOK: false,
            invalid: OrderInvalidEnum.StaffInitiateCheckout,
          }
        }
        if (!tableIsAvailable) {
          return {
            isOK: false,
            invalid: OrderInvalidEnum.TableIsNotAvailable,
          }
        } else if (paymentStatus === PaymentStatusEnum.Paid) {
          return {
            isOK: false,
            invalid: OrderInvalidEnum.PaymentPaid,
          }
        } else if (isOwner && payments.length) {
          if (
            (paymentStatus === PaymentStatusEnum.Failed || paymentStatus === PaymentStatusEnum.PendingPayment) &&
            isCreditCard
          ) {
            return {
              isOK: false,
              invalid: OrderInvalidEnum.CreditCardFailed,
            }
          } else if (paymentStatus === PaymentStatusEnum.PendingPayment && isKplusNumber) {
            return {
              isOK: false,
              invalid: OrderInvalidEnum.PendingKplusPayment,
            }
          } else if (
            paymentStatus === PaymentStatusEnum.Failed ||
            (paymentStatus === PaymentStatusEnum.PendingPayment && isTrueWallet)
          ) {
            return {
              isOK: false,
              invalid: OrderInvalidEnum.PaymentIsNotCompleted,
            }
          }

          if (isQRPayment && paymentStatus === PaymentStatusEnum.PendingPayment) {
            return {
              isOK: false,
              invalid: OrderInvalidEnum.PendingQRPayment,
            }
          }
        } else if (!isOwner && paymentStatus === PaymentStatusEnum.PendingPayment) {
          return {
            isOK: false,
            invalid: OrderInvalidEnum.PendingPayment,
          }
        }

        const isCanCheckout = orderStatus !== OrderStatusEnum.Completed
        if (isOwner && !paymentStatus && isCanCheckout) {
          return {
            isOK: false,
            invalid: OrderInvalidEnum.OwnerNeedToCheckout,
          }
        }

        if (!isOwner && isCanCheckout && order.userID !== 0) {
          return {
            isOK: false,
            invalid: OrderInvalidEnum.AnotherHasOccupied,
          }
        }

        if (order.staffCallingReason === StaffCallingReasonEnum.Checkout) {
          return {
            isOK: false,
            invalid: OrderInvalidEnum.CallingStaff,
          }
        }

        if (buffetEndTime) {
          const now = moment()
          const endTime = moment(buffetEndTime)
          if (checkTimeup && now.isAfter(endTime)) {
            return {
              isOK: false,
              invalid: OrderInvalidEnum.OrderIsTimeup,
            }
          }
        }

        if (isCanCheckout && orderStatus === OrderStatusEnum.ReadyToCheckout) {
          return {
            isOK: false,
            invalid: OrderInvalidEnum.ReadyToCheckout,
          }
        }

        const { currentPackage } = buffet
        if (currentPackage.id !== 0 && currentPackage.id !== buffetPackage.id) {
          return {
            isOK: false,
            invalid: OrderInvalidEnum.PackageHasChanged,
          }
        }
        return {
          isOK: true,
          invalid: '',
        }
      } else {
        return {
          isOK: false,
          invalid: OrderInvalidEnum.UnableGetOrder,
        }
      }
    } catch (error) {
      isProduction && Sentry.captureException(new Error(`Buffet Error: validate order: ${error}`))
      return {
        isOK: false,
        invalid: OrderInvalidEnum.UnableGetOrder,
      }
    }
  }

  const { handleValidateResult, handleBuffetEndTime } = useHandleBuffetOrder({
    skipModal: [],
    skipScreen: [],
  })

  const handleBuffetGlobalCart = useHandleBuffetOrder({
    skipModal: [
      ModalEnum.TimeUp,
      ModalEnum.ReadyToCheckout,
      ModalEnum.ContinueCheckout,
      ModalEnum.ConfirmOverrideCheckout,
    ],
    skipScreen: [],
  })

  const handleBuffetCheckout = useHandleBuffetOrder({
    skipModal: [ModalEnum.TimeUp, ModalEnum.ReadyToCheckout, ModalEnum.ContinueCheckout],
    skipScreen: [ScreenEnum.CreditCardFailed, ScreenEnum.ResubmitPayment],
  })

  const handleBuffetPromotion = useHandleBuffetOrder({
    skipModal: [ModalEnum.ContinueCheckout],
    skipScreen: [],
  })

  const handleGlobalCartValidation = (order: BuffetCurrentOrder) => {
    const validateResult = validateCurrentOrder(order, false)
    handleBuffetGlobalCart.handleBuffetEndTime(buffet.currentOrder?.buffetEndTime)
    if (validateResult.isOK) {
      return validateResult
    }

    if (
      validateResult.invalid === OrderInvalidEnum.OwnerNeedToCheckout &&
      buffet.modal.type === ModalEnum.ConfirmCheckout
    ) {
      return validateResult
    } else if (
      validateResult.invalid === OrderInvalidEnum.OwnerNeedToCheckout &&
      buffet.modal.type !== ModalEnum.ConfirmCheckout
    ) {
      dispatch(setBuffetModal({ type: ModalEnum.ContinueCheckout, isProcessing: false }))
      return validateResult
    }
    if (
      validateResult.invalid === OrderInvalidEnum.StaffInitiateCheckout &&
      [ModalEnum.ConfirmCheckout, ModalEnum.ContinueCheckout].includes(buffet.modal.type)
    ) {
      dispatch(resetBuffetModal())
      dispatch(setBuffetScreen({ type: ScreenEnum.StaffInitiateCheckout, isProcessing: false }))
      return validateResult
    } else {
      handleBuffetGlobalCart.handleValidateResult(validateResult, order)
      return validateResult
    }
  }

  const handleCheckoutValidation = (order: BuffetCurrentOrder) => {
    const validateResult = validateCurrentOrder(order)
    if (
      validateResult.isOK ||
      validateResult.invalid === OrderInvalidEnum.OwnerNeedToCheckout ||
      validateResult.invalid === OrderInvalidEnum.CreditCardFailed ||
      validateResult.invalid === OrderInvalidEnum.PaymentIsNotCompleted
    ) {
      validateResult.isOK = true
      return validateResult
    }

    handleBuffetCheckout.handleValidateResult(validateResult, order)

    return validateResult
  }

  const handlePromotionValidation = (order: BuffetCurrentOrder) => {
    const validateResult = validateCurrentOrder(order)
    if (validateResult.isOK || validateResult.invalid === OrderInvalidEnum.OwnerNeedToCheckout) {
      validateResult.isOK = true
      return validateResult
    }

    handleBuffetPromotion.handleValidateResult(validateResult, order)
    return validateResult
  }

  const handleDefaultValidation = (order: BuffetCurrentOrder) => {
    const validateResult = validateCurrentOrder(order, true)
    handleBuffetEndTime(buffet.currentOrder?.buffetEndTime)
    if (!validateResult.isOK) {
      handleValidateResult(validateResult, order)
      return false
    }
    return true
  }

  return {
    validateCurrentOrder,
    handleGlobalCartValidation,
    handleCheckoutValidation,
    handlePromotionValidation,
    handleDefaultValidation,
  }
}

export default useValidateCurrentOrder
