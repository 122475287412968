/**
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 * Config - shadow
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 */

const shadow = {
  base: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  soft: '2px 2px 6px rgba(0, 0, 0, 0.1)',
  subtle: '0.5px 0.5px 3px rgba(0, 0, 0, 0.08)',
  heavey: '0px 3px 12px rgba(0, 0, 0, 0.3)',
  floatingButton: '0px 2px 8px rgba(0, 0, 0, 0.24)',
}

export default shadow
