import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { setNearestNoticeSession } from 'helpers/sessionStorage'
import { Modal, Button } from './base-ui'
import ModalNoticeNearestStore from './modals/ModalNoticeNearestStore'
import ModalOnlyMemberMenu from './modals/ModalOnlyMemberMenu'
import ModalOnlyMemberMenuSpecificCondition from './modals/ModalOnlyMemberMenuSpecificCondition'
import { weight } from 'components/base-ui/Fonts'
import { Fonts } from 'components/base-ui'
import { THEMES_UI } from 'brands'
import ModalWithImage from 'components/modals/ModalWithImage'
import { AppContext } from 'contexts/AppProvider'
import useGlobalStateHook from 'hooks/hato-store/useGlobalStateHook'
import { clearLocalStorage } from 'helpers/localStorage'
import { useSelector } from 'react-redux'
import { addressSelector } from 'store/slices/addressSlice'
import { useTranslation } from 'react-i18next'
import ModalStoreClosed from './modals/ModalStoreClosed'
import { getLiff } from 'helpers/liff'
const liff = getLiff()

const sessionExpiredIcon = `${process.env.PUBLIC_URL}/assets/generic/modal/clock.svg`
type Props = {
  children: ReactNode
}

const MainContainer: FC<Props> = ({ children }) => {
  const {
    currentItemModalChangeStore,
    isOpenModalChangeStore,
    setIsOpenModalChangeStore,
    isOpenModalStoreIsClose,
    setIsOpenModalStoreIsClose,
    isOpenModalSystemError,
    setIsOpenModalSystemError,
    systemErrorRequestType,
    isOpenModalSessionExpired,
    setIsOpenModalSessionExpired,
    isHatoHeart,
    isOpenModalApiRateLimit,
    setIsOpenModalApiRateLimit,
    isOpenModalLIFFInitailedFailed,
    themeUI,
    isOpenModalNoticeNearestStore,
    setIsOpenModalNoticeNearestStore,
    nextTimeOpen,
    isOpenModalOnlyMemberMenu,
    setIsOpenModalOnlyMemberMenu,
    vendorImageResizableURLForModalMemberMenu,
    isOpenModalOnlyMemberMenuSpecificCondition,
    setIsOpenModalOnlyMemberMenuSpecificCondition,
    arrayOnlyMemberMenuSpecificConditions,
    memberMenuIneligibleReason,
    membershipProgram,
    cart,
    lang,
    brand,
  } = useContext(AppContext)

  const { addToCart, updateCartAddress } = useGlobalStateHook()
  const { t } = useTranslation()
  const address = useSelector(addressSelector)

  // Modal: Handle case product on branch location is differnce (Future cases)
  const [isOpenModalItemDifferenceOnBranch, setIsOpenModalItemDifferenceOnBranch] = useState(false)

  // Modal: Handle case get nearest location from item on cart when change address.
  const [isOpenModalSendProductFromNearestLocation, setIsOpenModalSendProductFromNearestLocation] = useState(false)

  useEffect(() => {
    // if customer have cart and address but cart have not addressID or not save addressID then auto updateCartAddress
    if (
      cart &&
      address.isAvailable &&
      cart.shippingAddressID === null &&
      cart.shippingAddressID !== address.main.id &&
      !cart.pickUp
    ) {
      updateCartAddress({
        addressId: address.main.id,
        locationID: cart?.locationID,
      })
    }
  }, [cart, address.main, address.isAvailable])

  function handleCloseModalSendProductFromNearestLocation() {
    setIsOpenModalSendProductFromNearestLocation(false)
  }

  function handleCloseModalStoreIsClose() {
    setIsOpenModalStoreIsClose(false)
  }

  function handleCloseModalSystemError() {
    setIsOpenModalSystemError(false)

    if (systemErrorRequestType === 'QUERY') {
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }

  function onCloseModalSessionExpired() {
    setIsOpenModalSessionExpired(false)
    clearLocalStorage({ exceptKeys: ['private_cart'] })
    window.location.reload()
  }

  return (
    <>
      {children}
      <PreloadImage
        src={`${process.env.PUBLIC_URL}/assets/generic/modal/store-closed.png`}
        srcSet={`${process.env.PUBLIC_URL}/assets/generic/modal/store-closed.png 1x, ${process.env.PUBLIC_URL}/assets/generic/modal/store-closed@2x.png 2x,
            ${process.env.PUBLIC_URL}/assets/generic/modal/store-closed@3x.png 3x,
            `}
      />
      <PreloadImage
        src={`${process.env.PUBLIC_URL}/assets/generic/modal/warning.png`}
        srcSet={`${process.env.PUBLIC_URL}/assets/generic/modal/warning.png 1x, ${process.env.PUBLIC_URL}/assets/generic/modal/warning@2x.png 2x,
          ${process.env.PUBLIC_URL}/assets/generic/modal/warning@3x.png 3x`}
      />
      <PreloadImage
        alt="modal-food-passion-system-error"
        src={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network.png`}
        srcSet={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network.png 1x, ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network@2x.png 2x,
            ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network@3x.png 3x,
            `}
      />
      <PreloadImage
        alt="modal-food-passion-cooking"
        src={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-cooking.png`}
        srcSet={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-cooking.png 1x, ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-cooking@2x.png 2x,
            ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-cooking@3x.png 3x,
            `}
      />
      <Modal
        isOpen={isOpenModalItemDifferenceOnBranch}
        onRequestClose={() => setIsOpenModalItemDifferenceOnBranch(false)}
        hideCloseButton
      >
        <ModalContainer>
          <img
            alt="modal-food-passion-system-error"
            src={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network.png`}
            srcSet={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network.png 1x, ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network@2x.png 2x,
                ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network@3x.png 3x,
                `}
          />
          <ModalTitle>{t('modalItemDifferenceOnBranchTitle')}</ModalTitle>
          <ModalText>{t('modalItemDifferenceOnBranchDes')}</ModalText>
          <Button
            float
            minHeight
            lg
            padding="8px 10px"
            style={{ marginBottom: '18px' }}
            onClick={() => {
              setIsOpenModalItemDifferenceOnBranch(false)
            }}
          >
            {t('modalItemDifferenceOnBranchBtnChooseNewItem')}
          </Button>
          <Button
            float
            minHeight
            lg
            outline
            flexWrap
            padding="8px 10px"
            onClick={() => {
              setIsOpenModalItemDifferenceOnBranch(false)
            }}
          >
            {t('modalItemDifferenceOnBranchBtnChangeAddress')}
          </Button>
        </ModalContainer>
      </Modal>
      <Modal isOpen={isOpenModalChangeStore} onRequestClose={() => setIsOpenModalChangeStore(false)} hideCloseButton>
        <ModalContainer>
          <ModalTitle>{t('modalChangeStoreLocationTitle')}</ModalTitle>
          <ModalText>{t('modalChangeStoreLocationDes')}</ModalText>
          <Button
            float
            minHeight
            lg
            padding="8px 10px"
            style={{ marginBottom: '18px' }}
            onClick={() => {
              setIsOpenModalChangeStore(false)
            }}
          >
            {t('modalChangeStoreLocationButtonCancel')}
          </Button>
          <Button
            float
            minHeight
            lg
            outline
            flexWrap
            padding="8px 10px"
            onClick={() => {
              addToCart({ ...currentItemModalChangeStore, clearItem: true })
              setIsOpenModalChangeStore(false)
            }}
          >
            {t('modalChangeStoreLocationButtonConfirm1')}
            <SubtextButton>{t('modalChangeStoreLocationButtonConfirm2')}</SubtextButton>
          </Button>
        </ModalContainer>
      </Modal>
      <Modal
        isOpen={isOpenModalSystemError}
        onRequestClose={handleCloseModalSystemError}
        zindex="9999"
        hideCloseButton={false}
      >
        {(() => {
          switch (true) {
            case isHatoHeart:
              return (
                <ModalContainerGeneric>
                  <img
                    alt="warning"
                    src={`${process.env.PUBLIC_URL}/assets/generic/modal/warning.png`}
                    srcSet={`${process.env.PUBLIC_URL}/assets/generic/modal/warning.png 1x, ${process.env.PUBLIC_URL}/assets/generic/modal/warning@2x.png 2x,
                  ${process.env.PUBLIC_URL}/assets/generic/modal/warning@3x.png 3x,
                  `}
                  />
                  <ModalTitleGeneric>{t('messageDefaultSystemErrorTitle')}</ModalTitleGeneric>
                  <ModalTextGeneric>{t('messageDefaultSystemErrorDes')}</ModalTextGeneric>
                </ModalContainerGeneric>
              )
            case themeUI === THEMES_UI.GON:
              return (
                <ModalContainerGeneric>
                  <img
                    alt="modal-food-passion-system-error"
                    src={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network.png`}
                    srcSet={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network.png 1x, ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network@2x.png 2x,
                  ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-handle-error-network@3x.png 3x,
                  `}
                  />
                  <ModalTitleGeneric>{t('messageDefaultSystemErrorTitle')}</ModalTitleGeneric>
                  <ModalTextGeneric>{t('messageDefaultSystemErrorDes')}</ModalTextGeneric>
                </ModalContainerGeneric>
              )
            default:
              return (
                <ModalContainerGeneric>
                  <img
                    alt="warning"
                    src={`${process.env.PUBLIC_URL}/assets/generic/modal/warning.png`}
                    srcSet={`${process.env.PUBLIC_URL}/assets/generic/modal/warning.png 1x, ${process.env.PUBLIC_URL}/assets/generic/modal/warning@2x.png 2x,
                  ${process.env.PUBLIC_URL}/assets/generic/modal/warning@3x.png 3x,
                  `}
                  />
                  <ModalTitleGeneric>{t('messageGenericDefaultSystemErrorTitle')}</ModalTitleGeneric>
                  <ModalTextGeneric>{t('messageGenericDefaultSystemErrorDes')}</ModalTextGeneric>
                </ModalContainerGeneric>
              )
          }
        })()}
      </Modal>

      <ModalSessionExpired
        isOpen={isOpenModalSessionExpired}
        isLoading={true}
        onRequestClose={onCloseModalSessionExpired}
        hideCloseButton
        imagePath={sessionExpiredIcon}
        imageSize="120px"
        title={t('warningSessionExpTitle')}
        description={t('warningSessionExpDesc')}
        buttonText={t('continueOrdering')}
        md
      />

      <Modal
        isOpen={isOpenModalLIFFInitailedFailed}
        onRequestClose={() => {
          if (liff.isInClient()) {
            liff.closeWindow()
          } else {
            window.location.reload()
          }
        }}
        zindex="9999"
        hideCloseButton={false}
      >
        <ModalContainerGeneric>
          <img
            alt="warning"
            src={`${process.env.PUBLIC_URL}/assets/generic/modal/warning.png`}
            srcSet={`${process.env.PUBLIC_URL}/assets/generic/modal/warning.png 1x, ${process.env.PUBLIC_URL}/assets/generic/modal/warning@2x.png 2x,
                  ${process.env.PUBLIC_URL}/assets/generic/modal/warning@3x.png 3x,
                  `}
          />
          <ModalTitleGeneric>พบปัญหาการเชื่อมต่อกับ LINE</ModalTitleGeneric>
          <ModalTextGeneric>กรุณาปิดหน้าต่างแล้วเปิดเข้าใหม่มาอีกครั้ง</ModalTextGeneric>
          <ModalTitleGeneric>Sorry, we’re having trouble connecting with LINE.</ModalTitleGeneric>
          <ModalTextGeneric>Please close and reopen the window.</ModalTextGeneric>
        </ModalContainerGeneric>
      </Modal>

      <Modal
        isOpen={isOpenModalApiRateLimit}
        onRequestClose={() => setIsOpenModalApiRateLimit(false)}
        zindex="9999"
        hideCloseButton={false}
      >
        <ModalContainerGeneric>
          <img
            alt="warning"
            src={`${process.env.PUBLIC_URL}/assets/generic/modal/warning.png`}
            srcSet={`${process.env.PUBLIC_URL}/assets/generic/modal/warning.png 1x, ${process.env.PUBLIC_URL}/assets/generic/modal/warning@2x.png 2x,
                  ${process.env.PUBLIC_URL}/assets/generic/modal/warning@3x.png 3x,
                  `}
          />
          <ModalTitleGeneric>{t('modalAPIRateLimitTitle')}</ModalTitleGeneric>
        </ModalContainerGeneric>
      </Modal>

      <ModalStoreClosed
        isOpen={isOpenModalStoreIsClose}
        onRequestClose={handleCloseModalStoreIsClose}
        nextTimeOpen={nextTimeOpen}
        themeUI={themeUI}
      />

      {/* ModalNoticeNearestStore */}
      <ModalNoticeNearestStore
        zindex="9999"
        isOpen={isOpenModalNoticeNearestStore}
        onRequestClose={() => {
          setIsOpenModalNoticeNearestStore(false)
          setNearestNoticeSession(true)
        }}
      />
      <Modal
        isOpen={isOpenModalSendProductFromNearestLocation}
        onRequestClose={handleCloseModalSendProductFromNearestLocation}
        zindex="9999"
      >
        <ModalContainer>
          <img
            alt="modal-food-passion-cooking"
            src={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-cooking.png`}
            srcSet={`${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-cooking.png 1x, ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-cooking@2x.png 2x,
                ${process.env.PUBLIC_URL}/assets/food-passion/icon-modal-cooking@3x.png 3x,
                `}
          />
          <ModalTitle>{t('modalSendProductFromNearestLocationTitle')}</ModalTitle>
          <ModalTitleSub>{t('modalSendProductFromNearestLocationDes')}</ModalTitleSub>
          <Button onClick={handleCloseModalSendProductFromNearestLocation}>{t('btnOK')}</Button>
        </ModalContainer>
      </Modal>

      <ModalOnlyMemberMenu
        isOpen={isOpenModalOnlyMemberMenu}
        onRequestClose={() => setIsOpenModalOnlyMemberMenu(false)}
        vendorImageResizableURLForModalMemberMenu={vendorImageResizableURLForModalMemberMenu}
        themeUI={themeUI}
        hideRegisterButton={brand?.features?.hatostore?.hideRegisterMemberButton}
      />
      <ModalOnlyMemberMenuSpecificCondition
        isOpen={isOpenModalOnlyMemberMenuSpecificCondition}
        onRequestClose={() => setIsOpenModalOnlyMemberMenuSpecificCondition(false)}
        memberConditions={arrayOnlyMemberMenuSpecificConditions}
        memberMenuIneligibleReason={memberMenuIneligibleReason}
        vendorImageResizableURLForModalMemberMenu={vendorImageResizableURLForModalMemberMenu}
        themeUI={themeUI}
        lang={lang}
        membershipProgram={membershipProgram}
      />
    </>
  )
}

export default MainContainer

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ModalTitle = styled.div`
  font-weight: ${weight.medium};
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray900};
  text-align: center;
  margin-top: 20px;
  white-space: pre-wrap;
  line-height: 1.4em;
`

const ModalContainerGeneric = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 350px;
  margin-bottom: -10px;
`

const ModalTitleGeneric = styled(Fonts.H2)`
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
  color: black;
`

const ModalTextGeneric = styled(Fonts.Base)`
  text-align: center;
  color: black;
`

const ModalText = styled.div`
  font-weight: ${weight.normal};
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray700};
  text-align: center;
  margin-top: 10px;
  margin-bottom: 18px;
  white-space: pre-wrap;
`

const ModalTitleSub = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.colors.gray800};
  text-align: center;
  margin-top: 9px;
  margin-bottom: 20px;
  white-space: pre-wrap;
`

const SubtextButton = styled.div`
  font-weight: ${weight.normal};
  font-size: 14px;
  color: ${(props) => props.theme.colors.green400};
  width: 100%;
`

const PreloadImage = styled.img`
  display: none;
`

const ModalSessionExpired = styled(ModalWithImage)`
  button.button-modal-image {
    min-width: 85% !important;
  }
`
