/**
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 * Helpers - sesstionStorage
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
 */
const sessionStorageVersion = '1.0.0'

export const STORAGE_NAME = {
  session: 'indiedish-liff-line-session-storage',
}

export const getSession = () => {
  const session = JSON.parse(String(sessionStorage.getItem(STORAGE_NAME.session)))
  if (session && session.version === sessionStorageVersion) {
    return session
  } else {
    removeSession()
    return null
  }
}

export const setSession = (session: { [key: string]: any }) => {
  // Add Version
  session.version = sessionStorageVersion
  sessionStorage.setItem(STORAGE_NAME.session, JSON.stringify(session))
}

export const removeSession = () => sessionStorage.removeItem(STORAGE_NAME.session)

export const setNearestNoticeSession = (nearestNoticeInitialed) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.nearestNoticeInitialed = nearestNoticeInitialed
  setSession(newSession)
}

export const getNearestNoticeSession = () => {
  const session = getSession()
  return session && session.nearestNoticeInitialed ? session.nearestNoticeInitialed : false
}

export const setLineChannelIDSession = (lineChannelID: string) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.lineChannelID = lineChannelID
  setSession(newSession)
}

export const getLineChannelIDSession = () => {
  const session = getSession()
  return session && session.lineChannelID ? session.lineChannelID : ''
}

export const setIsRefreshMembershipSession = (isRefreshMembership: boolean) => {
  const session = getSession()
  const newSession = session ? session : {}
  newSession.isRefreshMembership = isRefreshMembership
  setSession(newSession)
}

export const getIsRefreshMembershipSession = () => {
  const session = getSession()
  return session && session.isRefreshMembership ? session.isRefreshMembership : false
}
