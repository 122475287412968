import React, { createContext, ReactNode, FC, useState } from 'react'

interface LoadingPageContextInterface {
  isLoadingPage: boolean
  setIsLoadingPage: (val: boolean) => void
}

export const LoadingPageContext = createContext<LoadingPageContextInterface>({} as LoadingPageContextInterface)

const listLandingPagesPathname = ['/', '/hato-heart', '/campaigns', '/dine-in', '/buffet']

interface Props {
  children: ReactNode
}
const LoadingPageProvider: FC<Props> = ({ children }) => {
  const pathName = window.location.pathname
  const isLandingPage = listLandingPagesPathname.indexOf(pathName) !== -1
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(isLandingPage)

  const value: LoadingPageContextInterface = {
    isLoadingPage,
    setIsLoadingPage,
  }

  return <LoadingPageContext.Provider value={value}>{children}</LoadingPageContext.Provider>
}

export default LoadingPageProvider
