import React, { FC } from 'react'
import styled from 'styled-components'
import { ModalFromBottom } from 'components/base-ui'
import { Fonts, Button } from 'components/base-ui'
import { useTranslation } from 'react-i18next'

interface ModalContinueCheckoutProps {
  onCancel: () => void
  onContinue: () => void
  isOpen: boolean
}

const ModalContinueCheckout: FC<ModalContinueCheckoutProps> = ({ onCancel, onContinue, isOpen }) => {
  const { t } = useTranslation()
  return (
    <ModalFromBottom
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={false}
      zindex={100}
      btnClose={null}
      hideCloseButton
      isLoading={false}
      isOpen={isOpen}
    >
      <ModalContent>
        <Fonts.H3>{t('dineInWarningCheckoutTitle')}</Fonts.H3>
        <Fonts.ActionBaseRegular>{t('dineInWarningCheckoutDesc')}</Fonts.ActionBaseRegular>
        <ButtonWrapper>
          <Button enableFastClick={false} onClick={onContinue}>
            {t('dineInContinueCheckout')}
          </Button>
          <Button enableFastClick={false} onClick={onCancel} outline>
            {t('dineInCancelCheckout')}
          </Button>
        </ButtonWrapper>
      </ModalContent>
    </ModalFromBottom>
  )
}

export default ModalContinueCheckout

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 7px;
  padding-bottom: 20px;
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-top: 20px;

  button {
    min-width: 200px;
  }
`
